import {signalApiClient} from "./apiClient";
import {HotelAllSearchResponse} from "../types/Hotel";
import {useCouponRequest, useCouponResponse} from "../types/coupons";
import {PlanSelectFormData} from "../types/planSelectForm";
import {getHotelTotalPeople} from "./hotel";
import {getBasicTotalPrice, getCarRentalTotalPrice, getOptionTotalPrice} from "./price";
import {convertSellerIdForDisplay} from "./convert";


export const getCoupon = async (controller: AbortController, planSelectFormData: PlanSelectFormData, couponCode: string) => {
  try {

    const params = setCouponQuery(planSelectFormData, couponCode);
    const response = await signalApiClient(controller).post("/coupon/coupon-search", JSON.stringify(params));

    return response.data as useCouponResponse;
  } catch (error) {
    console.error(error)
  }

  return undefined;
}

const setCouponQuery = (planSelectFormData: PlanSelectFormData, code: string): useCouponRequest => {
  const optionTotalPrice = getOptionTotalPrice(planSelectFormData);
  const carRentalTotalPrice = getCarRentalTotalPrice(planSelectFormData);
  const selectOptions = planSelectFormData.reserveOptions.filter((activity) => {
    return activity.active;
  })
  // 基本料金
  const basicTotal = getBasicTotalPrice( {
    isUpdate: false,
    step: 'total',
    updateHotelIndex: undefined,
    updatePlan: undefined,
    updateHotel: undefined,
    checkIn: planSelectFormData.searchParams.checkin,
    checkOut: planSelectFormData.searchParams.checkout
  }, planSelectFormData);
  return {
    businessId: convertSellerIdForDisplay(planSelectFormData.planData?.sellerIndo?.sellerId ?? 0),
    code: code,
    airport: planSelectFormData.searchParams.to,
    price: basicTotal,
    optionPrice: optionTotalPrice + carRentalTotalPrice,
    people: getCouponParamByPeople(planSelectFormData),
    room: planSelectFormData.searchParams.rooms,
    departureDate: planSelectFormData.searchParams.start,
    isOption: selectOptions.length > 0,
    isRentalCar: planSelectFormData.carRental.length > 0,
  };
}

const getCouponParamByPeople = (planSelectFormData: PlanSelectFormData): number => {
  let people = 0;
  planSelectFormData.searchParams.roomDetail.forEach((room) => {
    people += room.adult;
    room.child.forEach((child) => {
      if (child.meal && child.futon) {
        people++;
      }
    });
  });
  return people;
}
