import React from "react";

//css
import "../../../scss/common.scss";
import "../../../scss/component.scss";


const SectionCheck: React.FC = () => {

  return (
    <>
      <section>
        {/* 確認事項 */}
        <h2 className="heading-2 mb-24-md">確認事項</h2>
        <div className="card large-side">
          <div className="box-separate">
            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <ul className="list-box list-mb-large last-mb-small large fz-14">
                  <li>
                    <span className="fz-16 fz-18-md fw-b">最少催行人数：1名</span>
                    <p className="mt-5">
                      （ただし、選択した宿泊施設により、1名でご参加いただけない場合もございます。）
                    </p>
                  </li>
                  <li>
                    <span className="fz-16 fz-18-md fw-b">添乗員の有無：同行いたしません</span>
                  </li>
                  <li>
                    <span className="fz-16 fz-18-md fw-b">旅行代金に含まれるもの【重要】</span>
                    <ul className="list-number-circle mb-0 mt-5">
                      <li>個人包括旅行運賃適用の往復航空運賃</li>
                      <li>国内線旅客施設使用料</li>
                      <li>
                        行程表に明示した宿泊代、食事代
                        <ul className="list-note light-gray">
                          <li>
                            オプション付プランの場合はオプション代金（食事代又は体験プランなど、商品によってことなります）
                          </li>
                        </ul>
                      </li>
                      <li>消費税等の諸税・サービス料金</li>
                    </ul>
                  </li>
                </ul>
                <p className="fz-14">
                  上記以外は旅行代金に含まれません。
                  <br />
                  (例)ご自宅から発着地までの交通費・宿泊費
                </p>
              </div>
            </div>

            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <h3 className="heading-3 mb-8-md">旅行企画・実施</h3>
                <p className="fz-14">沖縄ツーリスト株式会社</p>
                <p className="fz-14">観光庁長官登録旅行業第155号</p>
                <p className="fz-14">
                  〒900-8602　沖縄県那覇市松尾1丁目2番3号
                </p>
                <p className="fz-14">一般社団法人日本旅行業協会正会員</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionCheck;
