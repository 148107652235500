import React from "react";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import { discountPrices } from "../../../types/price";
import { okinawaSaihakken } from "../../../config/price";

type Props = {
  discountPrices: discountPrices[];
  onlyDetail?: boolean | undefined;
};

const SectionTravelDiscountPrice: React.FC<Props> = (props) => {
  const discountPrices = props.discountPrices.find((params: discountPrices) => params.name === "okinawaSaihakken");
  const discountPrice = discountPrices?.price.toLocaleString() ?? false;

  if (!okinawaSaihakken || !discountPrice) {
    return <></>;
  }

  /**
   * 項目名と料金のみ表示する
   */
  if (props.onlyDetail) {
    return (
      <div className="box-total-short-sub_total-item">
        <div className="fz-14 fz-16-md">全国旅行支援 販売補助金交付額</div>
        <div className="fz-20 fz-22-md fw-b c-red">
          -{discountPrice}<span className="fz-10 fz-14-md">円</span>
        </div>
      </div>
    );
  }

  return (
    <div className="box-separate-item">
      <div className="box-separate-item-block large">
        <h3 className="c-red fz-16 fw-b mb-16">全国旅行支援</h3>
        <div className="box-separate-item-block-subtotal_list">
          <div className="box-separate-item-block-subtotal_list-item">
            <p className="box-separate-item-block-subtotal_list-item-label">
              販売補助金交付額
            </p>
            <p className="box-separate-item-block-subtotal_list-item-subtotal">
              -{discountPrice}円<span className="ml-5">×</span>1
            </p>
            <p className="box-separate-item-block-subtotal_list-total-total">
              <span className="fz-16 fz-18-md fz-20-md c-red">
                -{discountPrice}
              </span>
              <span className="fz-14 c-red">円</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SectionTravelDiscountPrice;
