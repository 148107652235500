import React, {useContext, useEffect} from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import "../scss/common.scss";
import "../scss/component.scss";
import { Footer, Header } from "./layout";
import { HeadBlock } from "./layout/HeadBlock";
import {PlanSelectFormData} from "../types/planSelectForm";
import {useLocation} from "react-router-dom";
import {defaultFooter, defaultFooterPages, siteTitle} from "../config/main";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";

//パレットカラー変更
const theme = createTheme({
  palette: {
    secondary: {
      light: "#3387df",
      main: "#0169D8",
      dark: "#004997",
      contrastText: "#fff",
    },
  },
});



export interface Props {
  children: React.ReactNode;
  title: string;
  planSelectFormData: PlanSelectFormData | undefined
  fixedBottom?:boolean; //下部に固定ナビがある場合はtrue
}

const MainTemplate: React.FC<Props> = ({
    planSelectFormData,
    children,
    title,
    fixedBottom
}) => {
    const tagManagerContext: TagManagerType = useContext(TagManagerContext);
    const simpleNavi = useLocation().pathname;
    const header = {
        logo: planSelectFormData?.logo
    }
    const footer = {
        sellerId: 0,
        supportDial: ''
    }

    //共通ページでのフッター情報設定
    if (defaultFooterPages.includes(simpleNavi)) {
        footer.sellerId = defaultFooter.sellerId;
        footer.supportDial = defaultFooter.supportDial;
    }

    if (planSelectFormData) {
        footer.sellerId = planSelectFormData?.planData?.sellerIndo.sellerId?? 0;
        if (planSelectFormData?.planData?.product?.supportDial) {
            footer.supportDial = planSelectFormData?.planData?.product.supportDial;
        } else if (planSelectFormData?.planData?.sellerIndo.supportDial) {
            footer.supportDial = planSelectFormData?.planData?.sellerIndo.supportDial
        }
    }
    // useEffect(() => {
    //   if (title !== siteTitle) {
    //     tagManagerContext.pageView(title);
    //   }
    // }, [title])
  return (
    <>
      <ThemeProvider theme={theme}>
        <HeadBlock title={title} />
  
        <div className="theme-wrap">
          <Header logo={header.logo}/>
          <main>
              <div className="l-page-content">
                {children}
              </div>
          </main>
          <div className={(fixedBottom? "pb-100":"")}>
            <Footer
            sellerId={footer.sellerId}
            supportDial={footer.supportDial}
            />
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default MainTemplate;
