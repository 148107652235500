
import { discountPrices } from "../types/price";
import { okinawaSaihakken } from "../config/price";
import {PlanSelectFormData} from "../types/planSelectForm";

/**
 * 全国割支援の割引の適用チェック
 * @param discountPrices
 */
export const isOkinawaSaihakken = (discountPrices: discountPrices[] | undefined) => {
    if (okinawaSaihakken && discountPrices && discountPrices.length > 0) {
        const findDiscountPrice = discountPrices.find((params: discountPrices) => params.name === "okinawaSaihakken");
        const discountPrice = findDiscountPrice?.price.toLocaleString() ?? false;
        if (discountPrice) {
            return true;
        }
    }

    return false;
}

/**
 * 全国割ホテル一覧用ラベル表示非表示判定
 * @param planSelectFormData
 * @param hotelCode
 */
export const isOkinawaSaihakkenLabel = (planSelectFormData: PlanSelectFormData | undefined, hotelCode: string) => {
    if (planSelectFormData?.planData?.discountSettings?.okinawaSaihakken) {
        const okinawaSaihakkenExcludeHotelCodes = planSelectFormData?.planData?.discountSettings?.okinawaSaihakken?.excludeHotels.split(",") ?? [];
        return !okinawaSaihakkenExcludeHotelCodes.includes(hotelCode)
    }

    return false;
}