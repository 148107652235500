import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

//input
import { InputCheckbox } from "./../../input/InputCheckbox";
import { InputsForm1 } from "./../../../types/InputsForm1";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";


type Props = {
  errors: FieldErrors;
  register: UseFormRegister<InputsForm1>;
}

const SectionPersonalInformation: React.FC<Props> = (props) => {
  return (
    <>
      {/* <!--同行者情報の入力--> */}
      <section>
       <div className="box-border-red">
          <p className="c-red fz-16 fz-18-md mb-16 ta-c fw-b">
            個人情報の取り扱いについて
          </p>
          <p className="fz-13 fz-16-md mb-16">
            入力いただくお客様の個人情報は、お客様との連絡に利用させていただくほか、お客様がお申込みいただいた旅行においてサービスの手続きに必要な範囲内で利用させていただきます。
          </p>
          <p className="mb-16 ta-c">
            <a href="https://otspremium.com/aboutus/privacypolicy/" target="_blank" className="fz-13">
              プライバシーポリシーはこちらをご覧ください。
              <i className="icon-blank ml-5"></i>
            </a>
          </p>
          <p className="fz-13 fz-16-md mb-16 fw-b ta-c-md">
            上記にご同意頂ける場合は「同意します」にチェックをいれてください。
          </p>
          <div className="box-light-red small">
            <div className="form-input-checkbox">
              <InputCheckbox
                name="agree"
                register={props.register}
                checkboxes={[
                  { id: "agree", label: "同意します", value: "yes" },
                ]}
                options={{ required: true }}
                className={"form-input-checkbox-icon"}
              ></InputCheckbox>
              <span className="form-input-checkbox-text fw-b">
                <label htmlFor="agree.0">同意します</label>
              </span>
            </div>
            {props.errors.agree && (
              <p className="form-error_text c-red ta-c">
                「同意します」にチェックをいれてください。
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionPersonalInformation;
