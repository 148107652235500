import React, { Fragment } from "react";
import { okinawaSaihakken } from "../../../config/price";

//css
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import {reserveCancelDetailResponce} from "../../../types/reserveCancel";
import {toCircled} from "../../../utils/convert";
import SectionTravelDiscountPrice from "../OkinawaSaihakken/SectionTravelDiscountPrice";
import SectionCouponsDiscountPrice from "../Coupons/SectionTravelDiscountPrice";

type Props = {
  reservation: reserveCancelDetailResponce;
};

const SectionTravelPrice: React.FC<Props> = (props) => {
  const reserve = props.reservation.data;
  return (
    <>
      <section>
        {/* 旅行代金 */}
        <h2 className="heading-2 mb-16 mb-24-md">旅行代金</h2>
        <div className="box-total-short">
          <div className="box-total-short-sub_total small">
            <div className="box-total-short-sub_total-item">
              <div className="fz-14 fz-16-md">
                {reserve.bookingData.status === "cancel" ?
                  <>基本旅行代金（キャンセル代金）</>
                :
                  <>基本旅行代金</>
                }
              </div>
              <div className="fz-20 fz-22-md fw-b c-red">{reserve.bookingData.travelPrice.basicTravel.toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
            </div>

            {reserve.subData.rentalCar && reserve.subData.rentalCar.length > 0 && (
              <>
                {reserve.subData.rentalCar.map((rentalCar, rIndex) => (
                  <Fragment key={rIndex}>
                    {rentalCar.data.status === "cancel" ?
                    <>
                      <div className="box-total-short-sub_total-item">
                        <div className="fz-14 fz-16-md">レンタカー{toCircled(rentalCar.data.order)}（キャンセル代金）</div>
                        <div className="fz-20 fz-22-md fw-b c-red">{rentalCar.data.cancelPrice.toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                      </div>
                    </>
                      :
                    <>
                      <div className="box-total-short-sub_total-item">
                        <div className="fz-14 fz-16-md">レンタカー{toCircled(rentalCar.data.order)}</div>
                        <div className="fz-20 fz-22-md fw-b c-red">{Number(rentalCar.data.total_price).toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                      </div>
                    </>
                    }
                  </Fragment>
                ))}
              </>
            )}

            {reserve.subData.options && reserve.subData.options.length > 0 && (
              <>
                {reserve.subData.options.map((option, oIndex) => (
                  <Fragment key={oIndex}>
                    {option.data.status === "cancel" ?
                    <>
                      <div className="box-total-short-sub_total-item">
                        <div className="fz-14 fz-16-md">追加オプション{toCircled(option.data.order)}（キャンセル代金）</div>
                        <div className="fz-20 fz-22-md fw-b c-red">{option.data.cancelPrice.toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                      </div>
                    </>
                      :
                    <>
                      <div className="box-total-short-sub_total-item">
                        <div className="fz-14 fz-16-md">追加オプション{toCircled(option.data.order)}</div>
                        <div className="fz-20 fz-22-md fw-b c-red">{Number(option.data.total_price).toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                      </div>
                    </>
                    }
                  </Fragment>
                ))}
              </>
            )}

            {reserve.bookingData.travelPrice.travelInsurance > 0 && (
              <>
                <div className="box-total-short-sub_total-item">
                  <div className="fz-14 fz-16-md">国内旅行傷害保険</div>
                  <div className="fz-20 fz-22-md fw-b c-red">{reserve.bookingData.travelPrice.travelInsurance.toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                </div>
              </>
            )}

            {reserve.bookingData.travelPrice.cancelInsurance > 0 && (
              <>
                <div className="box-total-short-sub_total-item">
                  <div className="fz-14 fz-16-md">旅のキャンセル保険</div>
                  <div className="fz-20 fz-22-md fw-b c-red">{reserve.bookingData.travelPrice.cancelInsurance.toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                </div>
              </>
            )}
            
            <SectionCouponsDiscountPrice isCoupon={(reserve.bookingData?.priceDetail?.couponsPrices && reserve.bookingData?.priceDetail?.couponsPrices.length > 0)} couponPrice={reserve.bookingData?.couponDiscountPrice ?? 0} couponName={reserve.bookingData?.couponName ?? ""} />
            
            {/* 全国旅行支援 補助金 */}
            {okinawaSaihakken && reserve.bookingData?.priceDetail?.discountPrices && reserve.bookingData.status !== "cancel" &&
              <SectionTravelDiscountPrice discountPrices={reserve.bookingData.priceDetail.discountPrices} onlyDetail={true}></SectionTravelDiscountPrice>
            }
          </div>
          <div className="box-total-short-total">
            <span className="box-total-short-total-heading">お支払い総額</span>
              <span className="box-total-short-total-pay">
                {Number(reserve.bookingData.totalPrice).toLocaleString()}
              <span className="c-red fz-16">円(税込)</span>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionTravelPrice;
