import React from "react";

//component
import { InputCheckbox } from "../../input/InputCheckbox";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { InputsForm5 } from "../../../types/InputsForm5";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import { okinawaSaihakken } from "../../../config/price";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {isOkinawaSaihakken} from "../../../utils/discount";

type Props = {
  planSelectFormData: PlanSelectFormData
  control: any;
  errors: FieldErrors;
  register: UseFormRegister<InputsForm5>;
};

const SectionTravelCampaignConfirm: React.FC<Props> = (props) => {
  return isOkinawaSaihakken(props.planSelectFormData.priceData?.result?.discountPrices) ? (
    <section>
      <div className="card large mt-20 mb-20">
        <p className="mb-20 fz-18 fw-b c-navy">
          ご予約の前に必ずご確認ください。
        </p>
        <div className="box-light-red small mb-24 mb-32-md">
          <div className="form-input-checkbox">
            <InputCheckbox
              name="travelCampaign.agree"
              register={props.register}
              checkboxes={[
                {
                  id: "travelCampaign.agree",
                  label: "同意します",
                  value: "yes",
                },
              ]}
              options={{ required: true }}
              className={"form-input-checkbox-icon"}
            ></InputCheckbox>
            <span className="form-input-checkbox-text pl-8 c-blue_gray fw-b">
              <label htmlFor="travelCampaign.agree.0">
                全国旅行支援事業（以下、本事業）に拠る補助金（旅行代金を補助する販売補助金と地域クーポン）の交付を受けるためには、下記3点の同意が必要となります。
              </label>
            </span>
          </div>
          {props.errors.travelCampaign?.agree && (
            <p className="form-error_text c-red ta-c">
              チェックをいれてください。
            </p>
          )}
        </div>
        <ol className="list-number-dot x-large">
          <li>
            本人確認および居住地確認
            <p>「居住地確認書類」及び「本人確認書類」の旅行先での提示が必要となります。</p>
            <ul className="list-note">
              <li>宿泊チェックインの際に提示ができなかった場合は、補助金相当額の返還を求めます。</li>
            </ul>
          </li>
          <li>
            対象除外地域が発生した場合の対応
            <p>
              新型コロナウイルス感染症（COVID-19）の感染状況等により、ご旅行先の都道府県が事業停止となった場合は補助金の対象外となります。
              <br />
              また、そのことを事由としてご旅行を取消される場合、通常の取消料が発生します。
            </p>
          </li>
          <li>
            宿泊事業者が本事業への参画を取消した場合の対応
            <p>
              ご宿泊当日時点で、宿泊先施設が本事業への参画登録が承認されていない場合は、補助金交付の対象外となります。
              <br />
              既に旅行代金が精算済みの場合でも、補助金相当額の返還を求めます。
            </p>
          </li>
        </ol>
        <p className="c-red">※2023年5月8日宿泊分より、宿泊施設でのワクチン接種歴又は陰性の検査結果の提示は不要となりました。</p>
      </div>
    </section>
  ) : (
    <></>
  );
};
export default SectionTravelCampaignConfirm;
