import {PlanSelectFormData} from "../types/planSelectForm";
import {siteTitle} from "../config/main";

export const getHeadTitle = (planSelectFormData: PlanSelectFormData, title: string|undefined) => {
    const sellerName = planSelectFormData.planData?.sellerIndo.companyInfo.name;
    if (title) {
        return title+ "｜" + sellerName + "｜" + siteTitle;
    }

    if (sellerName) {
        return sellerName + "｜" + siteTitle;
    }

    return siteTitle;
}