import {createContext, FC, useEffect, useState} from "react";
import {siteTitle} from "../config/main";


export interface TagManagerType {
  pageView: (page: string) => void
  purchase: (ecommerceData: {
      reserveNumber: string,
      price: number,
      itemName: string,
      itemId: string,
  }) => void
}


const TagManagerContext = createContext<TagManagerType>({} as TagManagerType);

const TagManagerProvider: FC = ({children}) => {
  const pageView = (title: string) => {
      if (title !== siteTitle) {
          setTimeout(() => {
              window.dataLayer.push({
                  'event': 'trackPageView',
                  'pageUrl': window.location.origin + window.location.pathname,
                  'pageTitle': title,
              })
          }, 200)
      }
  }
  const purchase = (ecommerceData: {
      reserveNumber: string,
      price: number,
      itemName: string,
      itemId: string,
  }) => {
      setTimeout(() => {
          window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                  id: ecommerceData.reserveNumber,
                  value: ecommerceData.price,
                  currency: "JPY",
                  items: [
                      {
                          item_name: ecommerceData.itemName,
                          item_id: ecommerceData.itemId,
                          price: ecommerceData.price,
                      }
                  ]
              }
          });
      }, 200)
  }

  return (
    <>
      <TagManagerContext.Provider
        value={{ pageView, purchase }}
      >
        {children}
      </TagManagerContext.Provider>
    </>
  );
}


export {TagManagerContext, TagManagerProvider}