import React, {useContext, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Navigate, useNavigate } from "react-router-dom";
import { clear as clearReservationCancel } from "../redux/reservationCancelSlice";

//MainTemplate
import MainTemplate from "./MainTemplate";

//css
import "../scss/common.scss";
import "../scss/component.scss";

//import parts
import { PartsCancellationChargeText } from "./parts";
import {SectionDetail, SectionTravelPrice} from "./section/ReservationCancelDetail";
import { reserveCancelDetailResponce } from "../types/reserveCancel";
import {getHeadTitle} from "../utils/title";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";

const ReservationCancelDetail: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      tagManagerContext.pageView( "予約情報詳細")
    }, 200)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  // 15分経過でログイン画面に戻す
  const [time, setTime] = useState(0);
  const timeoutMinutes = 15;
  const intervalSeconds = 5;

  useEffect(() => {
    const id = setInterval(() => {
      setTime((t) => t + 1);
    }, intervalSeconds * 1000); // 5秒ごとに更新
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    // 15分で終了
    if (time >= (timeoutMinutes * 60) / intervalSeconds) {
      // redux内のデータをクリア
      dispatch(clearReservationCancel());
      navigate("/login", { replace: true });
    }
    return;
  }, [time, dispatch, navigate]);

  // reduxに保存されているデータを取得
  const savedReservationCancelState = useSelector((state: RootState) => state.reservationCancel);

  // ログイン画面で取得した予約情報
  const saveReserveData = savedReservationCancelState.reservation as reserveCancelDetailResponce;
  const reservation = saveReserveData.data;
  const hidden_contents: string[] = [];

  // 予約情報が無ければログイン画面にリダイレクト
  if (reservation === undefined) {
    return <Navigate to={"/login"} replace />;
  }

  const handleClick = () => {
    navigate("/ReservationCancelFee");
  };

  if (!reservation.subData.rentalCar) {
    hidden_contents.push("rentalCar");
  }

  return (
    <MainTemplate
      title="予約情報詳細"
      planSelectFormData={undefined}
    >
      <div className="l-page-content-upper_navi">
        <div className="wrap-content-900">
          <h1 className="l-page-content-upper_navi-heading">予約情報詳細</h1>
        </div>
      </div>

      <div className="l-page-content">
        <div className="pt-24 pt-56-md">
          <div className="wrap-content-900">
            {/* 予約情報詳細 */}
            <SectionDetail reservation={saveReserveData} />

            {/* 旅行代金 */}
            <SectionTravelPrice reservation={saveReserveData}></SectionTravelPrice>

            {/* キャンセル料について */}
            <PartsCancellationChargeText hidden_contents={hidden_contents}></PartsCancellationChargeText>

            {/* 個別キャンセルボタン（※キャンセル後はボタン非表示） */}
            {reservation.bookingData.status === "reserved" && (
              <div className="mt-50 mt-64-md">
                <button
                  className="button-large-red w-100per"
                  onClick={() => handleClick()}
                >
                  旅行の一部または全体をキャンセルする
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainTemplate>
  );
};

export default ReservationCancelDetail;
