import React, { SyntheticEvent } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

//input
import { InputText } from "./../../input/InputText";
import { InputRadio } from "./../../input/InputRadio";
import { InputEmail } from "./../../input/InputEmail";
import { InputsForm2 } from "../../../types/InputsForm2";

import { PartsPrefecture } from "../../parts";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";

type Props = {
  control: any;
  errors: FieldErrors;
  getValues: any;
  register: UseFormRegister<InputsForm2>;
  sendTestMail: (email: string) => void;
}

const SectionApplicantOption: React.FC<Props> = (props) => {
  // テストメール送信ボタンの使用可・不可
  const [sendingTestMail, setSendingTestMail] = React.useState(false);

  // テストメール送信
  const handleSendTestMailClick = async (event: SyntheticEvent) => {
    setSendingTestMail(true);

    event.preventDefault();

    const targetEmail = props.getValues("applicant2.email");

    await props.sendTestMail(targetEmail);

    setSendingTestMail(false);
  }

  return (
    <>
      {/* <!--オプションの代表者情報の入力--> */}
      <section>
        <h2 className="heading-2 mt-0 mb-8">オプションの代表者情報</h2>
        <p className="mb-24">
          代表者情報が異なる場合は、入力内容を修正してください。
        </p>
        <div className="card mb-20">
          <div className="box-separator_bottom-large">
            {/* <!--名前(フリガナ)--> */}
            <div
              id="form_2-name-hurigana"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    氏名 <span className="">( フリガナ )</span>
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-pc">
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">セイ</div>
                      <div>
                        <InputText
                          name="applicant2.name.kana.last"
                          placeholder="例：オキナワ"
                          register={props.register}
                          options={{ required: true, maxLength: 10, pattern: /^[ァ-ンヴー]+$/i }}
                          error={props.errors.applicant2?.name?.kana?.last}
                        ></InputText>
                        {/* todo: error表示までcomponentに入れるか？ 入れないとmaxlengthとかの制御がめんどくさい。*/}
                        {props.errors.applicant2?.name?.kana?.last &&
                          props.errors.applicant2?.name?.kana?.last.type ===
                            "required" && (
                            <p className="form-error_text">
                              お名前（セイ）をご入力ください。
                            </p>
                          )}
                        {props.errors.applicant2?.name?.kana?.last &&
                          props.errors.applicant2?.name?.kana?.last.type ===
                            "maxLength" && (
                            <p className="form-error_text">
                              お名前（セイ）は10文字以内でご入力ください。
                            </p>
                          )}
                        {props.errors.applicant2?.name?.kana?.last &&
                          props.errors.applicant2?.name?.kana?.last.type ===
                            "pattern" && (
                            <p className="form-error_text">
                              全角カタカナでご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">メイ</div>
                      <div>
                        <InputText
                          name="applicant2.name.kana.first"
                          placeholder="例：タロウ"
                          register={props.register}
                          options={{ required: true, maxLength: 10, pattern: /^[ァ-ンヴー]+$/i }}
                          error={props.errors.applicant2?.name?.kana?.first}
                        ></InputText>
                        {props.errors.applicant2?.name?.kana?.first &&
                          props.errors.applicant2?.name?.kana?.first.type ===
                            "required" && (
                            <p className="form-error_text">
                              お名前（メイ）をご入力ください。
                            </p>
                          )}
                        {props.errors.applicant2?.name?.kana?.first &&
                          props.errors.applicant2?.name?.kana?.first.type ===
                            "maxLength" && (
                            <p className="form-error_text">
                              お名前（メイ）は10文字以内でご入力ください。
                            </p>
                          )}
                        {props.errors.applicant2?.name?.kana?.first &&
                          props.errors.applicant2?.name?.kana?.first.type ===
                            "pattern" && (
                            <p className="form-error_text">
                              全角カタカナでご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--住所--> */}
            <div
              id="form_2-address"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    都道府県
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div 
                  className={
                    "form-content-input" +
                    (props.errors.applicant2?.prefecture
                      ? " error"
                      : "")
                  }
                >
                  <div>
                    <div className="select_box-default w-200">
                      <PartsPrefecture
                        name="applicant2.prefecture"
                        register={props.register}
                        options={{required: true}}
                      >
                      </PartsPrefecture>
                    </div>
                    {props.errors.applicant2?.prefecture &&
                      props.errors.applicant2?.prefecture.type ===
                        "required" && (
                        <p className="form-error_text">
                          都道府県を選択してください。
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--ご連絡先--> */}
            <div
              id="form_2-tel"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    電話番号
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div>
                    <InputText
                      name="applicant2.tel"
                      placeholder="例：00011112222"
                      register={props.register}
                      options={{ required: true, pattern: /^(0{1}\d{9,10})$/i }}
                      error={props.errors.applicant2?.tel}
                    ></InputText>
                    <p className="fz-13">※当日、連絡がとれる電話番号をご入力ください</p>
                    {props.errors.applicant2?.tel &&
                      props.errors.applicant2?.tel.type ===
                        "required" && (
                        <p className="form-error_text">
                          携帯番号をご入力ください。
                        </p>
                      )}
                    {props.errors.applicant2?.tel &&
                      props.errors.applicant2?.tel.type ===
                        "pattern" && (
                        <p className="form-error_text">
                          携帯番号の形式でご入力ください。
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--メールアドレス--> */}
            <div
              id="form_2-mail_address"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    メールアドレス
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-mail">
                    <div className="form-column-mail-item">
                      <div>
                        <InputEmail
                          name="applicant2.email"
                          placeholder="例：mail@example.com"
                          register={props.register}
                          options={{
                            required: true,
                            maxLength: 200,
                            pattern: /^\S+@\S+$/i, // todo: もっとちゃんとしたのを入れる
                          }}
                          error={props.errors.applicant2?.email}
                        ></InputEmail>
                        <p className="fz-13">
                          ※半角英数字でご入力ください。
                        </p>
                        {props.errors.applicant2?.email &&
                          props.errors.applicant2?.email.type === "required" && (
                            <p className="form-error_text">
                              メールアドレスをご入力ください。
                            </p>
                          )}
                        {props.errors.applicant2?.email &&
                          props.errors.applicant2?.email.type ===
                            "maxLength" && (
                            <p className="form-error_text">
                              メールアドレスは200文字以内でご入力ください。
                            </p>
                          )}
                        {props.errors.applicant2?.email &&
                          props.errors.applicant2?.email.type === "pattern" && (
                            <p className="form-error_text">
                              メールアドレスを正しくご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-column-mail-item">
                      <div>
                        <button className="button-blue-form_button-small" onClick={handleSendTestMailClick} disabled={sendingTestMail}>
                          テストメールを送信
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="box-small-light-red mt-16">
                    <ul className="list-note light-gray">
                      <li>
                        予約完了メールは予約の確認、問い合わせ先などが記載されている大切なメールです。確実に受信できるように「テストメール送信」をお試しください。
                      </li>
                      <li>
                        迷惑メール設定をしている場合、「digitaldmoplatform.com
                        」のメールを受信出来るように設定をお願いいたします。
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="mt-16 mb-8 fw-b">
                      メールアドレス(確認)
                      <span className="label-required ml-5">必須</span>
                    </p>
                    <InputEmail
                      name="applicant2.email_confirm"
                      placeholder="例：mail@example.com"
                      register={props.register}
                      options={{
                        required: true,
                        validate: {
                          sameEmail: (value) => {
                            return props.getValues("applicant2.email") === value;
                          },
                        },
                      }}
                      error={props.errors.applicant2?.email_confirm}
                    ></InputEmail>
                    {props.errors.applicant2?.email_confirm &&
                      props.errors.applicant2?.email_confirm.type === "required" && (
                        <p className="form-error_text">
                          メールアドレスをご入力ください。
                        </p>
                      )}
                    {props.errors.applicant2?.email_confirm &&
                      props.errors.applicant2?.email_confirm.type === "sameEmail" && (
                        <p className="form-error_text">
                          メールアドレスが異なります。
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--性別--> */}
            <div
              id="form_2-sexual"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    性別
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div
                    className={
                      "form-content-input-sexual" +
                      (props.errors.applicant2?.gender ? " error" : "")
                    }
                  >
                    <InputRadio
                      name="applicant2.gender"
                      control={props.control}
                      radios={[
                        { label: "男性", value: "male" },
                        { label: "女性", value: "female" },
                      ]}
                      options={{ required: true }}
                      isRow={true}
                    ></InputRadio>
                    {props.errors.applicant2?.gender && (
                      <p className="form-error_text">
                        性別をご選択ください。
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionApplicantOption;
