import React from "react";
import {FreeSpace} from "../../types/Freespace";
import PartsTutorialButton from "../parts/PartsTutorialButton";


type Props = {
  logo: string|undefined
}

const Header: React.VFC<Props> = (
  {
    logo
  }
) => {
  return (
    <header className="l-site-header">
        <div className="wrap-content-900-large d-f-md jc-sb-md ai-c-md">
        <ul className="l-site-header-list">
          {logo ?
            <>
              <li key="header-logo" className="l-site-header-list-item company">
                <img src={logo} alt="" />
              </li>
              <li key="header-logo-text" className="l-site-header-list-item text">co-created with</li>
            </>:<></>
          }
            <li key="header-logo-platform-img" className="l-site-header-list-item platform">
            <img src={require("../../img/share/icon/logo_dmo.svg").default} alt="デジタルDMOプラットフォーム" />
            </li>
        </ul>

        {/* 操作ガイドボタン */}
        <PartsTutorialButton value="component-header"/>
        </div>
    </header>
  );
};

export default Header;