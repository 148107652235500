import React from "react";
import { UseFormRegister, RegisterOptions } from "react-hook-form";

type InputProps = {
  name: string;
  register: UseFormRegister<any>; // todo: どこかで定義したい
  options: RegisterOptions;
  selectOptions: {
    value: string;
    label: string;
  }[];
};

export const Select: React.VFC<InputProps> = (props) => {
  const { name, register, options, selectOptions } = props;

  return (
    <select {...register(name, options)} className={"form-input-text"}>
      {selectOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
