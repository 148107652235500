import React, { Fragment } from "react";

import { HashLink } from 'react-router-hash-link';

// for google map
import GoogleMapTemplate from "../google/Maps";

// for styles
import {
  Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {scheduleListModalData} from "../../types/planSelectConfirm";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  scheduleListModalData: scheduleListModalData,
  callback: () => void,
}

const ScheduleDetailModal: React.FC<Props> = ({
  isShow,
  scheduleListModalData,
  callback
}) => {

  // 座標を渡す(GoogleMap用)
  const position = {
    defaultZoom: 1,
    latitude: 1,
    longitude: 1,
    isMarkerShown: true
  }


  return (
    <>
    <div>
      <Modal
        open={isShow}
        onClose={callback}
        className="modal-simple dark"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <div className="modal-simple-content">
          <div className="modal-simple-content-close">
            <button className="button-clear" onClick={callback}>
              <i className="icon-modal-close-navy fz-30"></i>
            </button>
          </div>

          <div>
            <ul className="list-modal-schedule">
              <li className="list-modal-schedule-item">
                <HashLink className="list-modal-schedule-item-link" smooth to="#" onClick={callback}>ページトップ</HashLink>
              </li>
              {scheduleListModalData.travelSchedule?.map((travelSchedule, tIndex) => (
                <Fragment key={tIndex}>
                  <li className="list-modal-schedule-item">
                    <HashLink className="list-modal-schedule-item-link" smooth to={travelSchedule.anchor} onClick={callback}>{travelSchedule.label}</HashLink>
                  </li>
                </Fragment>
              ))}
              {scheduleListModalData.useOptions && (
                <>
                  <li className="list-modal-schedule-item">
                    <HashLink className="list-modal-schedule-item-link" smooth to="#set_option" onClick={callback}>追加済みのオプション</HashLink>
                  </li>
                </>
              )}
              <li className="list-modal-schedule-item">
                <HashLink className="list-modal-schedule-item-link" smooth to="#add_option" onClick={callback}>追加可能なオプション</HashLink>
              </li>
              <li className="list-modal-schedule-item">
                <HashLink className="list-modal-schedule-item-link" smooth to="#rentacar" onClick={callback}>追加可能なレンタカー</HashLink>
              </li>
              {scheduleListModalData.useCarRental && (
                <>
                  <li className="list-modal-schedule-item">
                    <HashLink className="list-modal-schedule-item-link" smooth to="#add_rentacar" onClick={callback}>追加済みのレンタカー</HashLink>
                  </li>
                </>
              )}
              <li className="list-modal-schedule-item">
                <HashLink className="list-modal-schedule-item-link" smooth to="#coupon" onClick={callback}>クーポンコード</HashLink>
              </li>
            </ul>
          </div>
          
        </div>
      </Modal>
    </div>
    </>
  );
};

export default ScheduleDetailModal;
