import React, {useEffect} from "react";

// for google map
import GoogleMapTemplate from "../google/Maps";

// for styles
import {
  Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {roomData} from "../../types/Hotel";
import {PlanSearch} from "../../types/Search";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  planSelectFormData: PlanSelectFormData,
  callback: () => void,
}

const ScheduleDetailModal: React.FC<Props> = ({
  isShow,
  planSelectFormData,
  callback
}) => {

  // 座標を渡す(GoogleMap用)
  const position = {
    defaultZoom: 1,
    latitude: 1,
    longitude: 1,
    isMarkerShown: true
  }
  
  const [rooms, setRooms] = React.useState<PlanSearch["roomDetail"]>();
  
  useEffect(() => {
    if (planSelectFormData.searchParams !== undefined) {
      const roomData = planSelectFormData.searchParams.roomDetail;
      setRooms(roomData);
    }
  },[])

  return (
    <>
    <div>
      <Modal
        open={isShow}
        onClose={callback}
        className="modal-simple"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <div className="modal-simple-content pt-0 pb-0">
          <div className="modal-simple-content-close">
            <button className="button-clear" onClick={callback}>
              <i className="icon-modal-close-navy fz-30"></i>
            </button>
          </div>
          <div className="box-separate">

            {rooms !== undefined ?
              <>
                {rooms.map((room, rIndex) => (
                  <div key={rIndex} className="box-separate-item">
                    <div className="box-separate-item-block">
                      <p className="fz-16 fw-b c-navy mb-8">{rIndex + 1}部屋目</p>
                      <div>
                        <dl className="d-f mb-8">
                          <dt className="w-80 pr-16 fz-14">大人{room.adult}名</dt>
                          <dd></dd>
                        </dl>
                        {room.child.length > 0 ?
                          <>
                            <dl className="d-f">
                              <dt className="w-80 pr-16 fz-14">子供{room.child.length}名</dt>
                              <dd className="fz-14">
                                {room.child.map((child, cIndex) => (
                                  <>
                                  {cIndex + 1}人目：{child.age}歳<br />
                                  </>
                                ))}
                              </dd>
                            </dl>
                          </>:<></>
                        }
                      </div>
                    </div>
                  </div>
                  ))}
              </>:<></>
            }

            <div className="box-separate-item">
              <div className="box-separate-item-block small">
                <p className="mb-24 fz-12 c-red ta-r">※部屋割は変更できません。</p>
                  <p className="ta-r">
                    <button onClick={callback} className="button-medium-orange w-60">
                    OK
                    </button>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default ScheduleDetailModal;
