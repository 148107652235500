import React, {useEffect, useState} from "react";


//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

const Page404: React.FC = () => {

  
 
  return (
    <MainTemplate title="" planSelectFormData={undefined}>

      <section className="pt-50 pt-100-md">
        <div className="wrap-content-900">
          <div className="card sp-small">
            <p className="mb-10 ta-c">
              <img
                src={
                  require("../img/share/icon/icon_searchzero.svg")
                    .default
                }
                className="d-ib"
                />
            </p>
            <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">ページが見つかりません</p>
            <p className="fz-16 ta-c-md c-blue_gray">
            お探しのページは一時的にアクセスできない状況にあるか、<br className="d-n d-b-md" />
            移動もしくは削除された可能性があります。
            </p>
          </div>
        </div>
      </section>
         
           
    </MainTemplate>
  );
};

export default Page404;
