import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { InputsForm1 } from "../../../types/InputsForm1";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";

type Props = {
  errors: FieldErrors;
  register: UseFormRegister<InputsForm1>;
}

const SectionOther: React.FC<Props> = (props) => {

  return (
    <>
      {/* <!--同行者情報の入力--> */}
      <section>
      <h2 className="heading-2">その他情報の入力</h2>
        <div className="card">
          <h3 className="heading-3-small mb-16">ご希望・ご質問など</h3>
          <textarea
            cols={30}
            rows={5}
            placeholder="ご希望・ご質問などありましたらこちらにご入力ください。"
            className={"form-input-textarea" + (props.errors.note ? " error" : "")}
            {...props.register("note", { maxLength: 1000 })}
          ></textarea>
          {props.errors.note &&
            props.errors.note.type === "maxLength" && (
            <p className="form-error_text">
              ご希望・ご質問は1000文字以内でご入力ください。
            </p>           
          )}
        </div>
      </section>
    </>
  );
};

export default SectionOther;
