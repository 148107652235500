import React from "react";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";

type InputProps = {
  name: string;
  register: UseFormRegister<any>; // todo: どこかで定義したい
  placeholder: string;
  isSmall?: boolean; // todo: もういっそclassNameを指定させる方がいいか？
  options: RegisterOptions;
  error?: FieldError;
};

// <input>要素を含んだ子コンポーネント
export const InputText: React.VFC<InputProps> = (props) => {
  const { name, register, placeholder, isSmall, options, error } = props;

  return (
    <>
      <input
        type="text"
        className={
          "form-input-text" +
          (isSmall ? " small" : "") +
          (error ? " error" : "")
        }
        placeholder={placeholder}
        {...register(name, options)}
      />
    </>
  );
};
