import React, {useContext, useEffect, useState} from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import {useLocation, useNavigate} from "react-router-dom";
import { RootState } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { save } from "../redux/form1Slice";

import { useSendTestMail } from "../utils/useSendTestMail";

import { InputsForm1 } from "../types/InputsForm1";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

//parts import
import FormStep from "./Form/FormStep";
import {
  SectionApplicant,
  SectionFellow,
  SectionOther,
  SectionPersonalInformation,
} from "./section/Form1"
import {PlanSelectFormData} from "../types/planSelectForm";
import {getFellowsCountArray, getTravelerNums} from "../utils/search";
import {isOption} from "../utils/reserve";
import {devLog} from "../utils/errors";
import {getHeadTitle} from "../utils/title";
import {FormSessionContext} from "../hooks/FormSession";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";
import { PartsTutorialButton } from "./parts";


const app = process.env.REACT_APP_MODE;
// lodingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Form1: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  const {checkSession} = useContext(FormSessionContext);
  const navigate = useNavigate();
  const location = useLocation()
  const [planSelectFormData, setPlanSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData }) //TODO: need change
  devLog("planSelectFormData", planSelectFormData);

  const classesForLoading = useStyles();

  const [ sendTestMail, { isLoading } ] = useSendTestMail();

  const storedForm1 = useSelector((state: RootState) => state.form1.form1);

  // todo: 入力STEP前で保存されたデータから拾う
  const fellows = getFellowsCountArray(planSelectFormData.data);

  // 同行者の初期値を設定する
  const addInitialFellows = () => {
    const currentFellows = storedForm1.followers;

    // 次の画面から戻って来た時は入力済みのデータを返す
    if (currentFellows.length > 0 && currentFellows[0].name.kana.first !== "") {
      return storedForm1;
    }

    // 同行者は動的に生成されるので、initialStateだと2つ目以降にデータがセットされない
    if (fellows.length > 0) {
      return {...storedForm1, ...{
          followers: fellows.map((_) => {
            return {
              name: {
                kana: {
                  last: "",
                  first: "",
                },
              },
              gender: "male",
              age: "",
              mileage_number: "",
              birthday_year: 1980,
              birthday_month: "01",
              birthday_day: "01",
            }
          })
        }};
    }

    return storedForm1;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0)
    tagManagerContext.pageView(getHeadTitle(planSelectFormData.data, "お客様情報入力"))
  },[])

  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<InputsForm1>({
    defaultValues: addInitialFellows(),
  });

  const backFrom = () => {
    navigate("/planselectconfirm", { state: {
        data: planSelectFormData.data
      } })
  }

  const onClickNext = () => {
    // note: AjaxZip3で都道府県を取得するとstateが更新されないので、DOMから直接取得してセットする
    const tmpPrefecture = document.getElementsByName('applicant.address.prefecture') as unknown as NodeList
    const prefecture = tmpPrefecture[0] as HTMLSelectElement
    setValue("applicant.address.prefecture", prefecture.value);
  }

  const onSubmit: SubmitHandler<InputsForm1> = (data) => {
    dispatch(save({ form1: data }));
    const session = checkSession(planSelectFormData.data);
    if (session) {
      if (isOption(planSelectFormData.data)) {
        navigate("/Form2",{ state: {
            data: planSelectFormData.data
          } });
      } else {
        navigate("/Form3",{ state: {
            data: planSelectFormData.data
          } });
      }
    }
  };

  // todo: ローディング的なものを表示する？
  // if (isSubmitting) {
  //   console.log("isSubmitting");
  // }
  
  let buttonLabel = "オプション情報入力へ進む";
  if (!isOption(planSelectFormData.data)) {
    buttonLabel = "保険加入の選択へ進む";
  }

  return (
    <MainTemplate title={getHeadTitle(planSelectFormData.data, "お客様情報入力")} planSelectFormData={planSelectFormData.data}>

        {/* ローディングの表示(APIのレスポンス待ち) */}
        <Backdrop className={classesForLoading.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">
              お客様情報入力
            </h1>
          </div>
        </div>

        <div className="wrap-content-900">
          <FormStep activeStep={1} isOption={isOption(planSelectFormData.data)}></FormStep>

          {/* 操作ガイドボタン */}
          <PartsTutorialButton value="component-content" />

          <form action="" onSubmit={handleSubmit(onSubmit)}>
            {/* <!--個人情報取扱について--> */}
            <SectionPersonalInformation register={register} errors={errors}></SectionPersonalInformation>

            {/* <!--申込者情報の入力--> */}
            <SectionApplicant 
              register={register}
              getValues={getValues} 
              setValue={setValue} 
              control={control} 
              errors={errors}
              sendTestMail={sendTestMail}
            >
            </SectionApplicant>

            {/* <!--同行者情報の入力--> */}
            <SectionFellow
              planSelectFormData={planSelectFormData.data}
              register={register}
              setValue={setValue} 
              control={control} 
              errors={errors}
              fellowCount={fellows.length}
            >
            </SectionFellow>

            {/*その他情報の入力*/}
            <SectionOther register={register} errors={errors}></SectionOther>

            {/* <!--モーダル--> */}
            {/* <!--次へ進む--> */}
            <div className="mt-48 mt-64-mt">
              <ul className="form-step_button mt-24">
                <li className="form-step_button-next">
                  <button
                    type="submit"
                    className="button-large-red"
                    // todo: class作る
                    style={{
                      display: "block",
                      border: "none",
                      width: "100%",
                    }}
                    // disabled={!isDirty || isSubmitting} // note: これだとhistory.back()で戻ったときにdisabledになる
                    disabled={isSubmitting}
                    onClick={onClickNext}
                  >
                    <span className="label-number-orange mr-5">2</span>
                    {buttonLabel}
                  </button>
                </li>
                <li className="form-step_button-back">
                  <a
                    href="javascript:void(0)"
                    onClick={backFrom}
                    className="button-small-border_gray_blue button-medium-md"
                  >
                    <i className="icon-left_arrow mr-5"></i>
                    予約内容の確認へ戻る
                  </a>
                </li>
              </ul>
            </div>
          </form>
        </div>

    </MainTemplate>
  );
};

export default Form1;
