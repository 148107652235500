import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InputsForm2 } from "../types/InputsForm2";

export interface Form2State {
  form2: InputsForm2;
}

const initialState: Form2State = {
  form2: {
    applicant2: {
      name: {
        kana: {
          last: "",
          first: "",
        },
      },
      prefecture: "",
      tel: "",
      email: "",
      email_confirm: "",
      gender: "male",
    },
    plans: [],
    options:[]
  },
};

export const form2Slice = createSlice({
  name: "form2",
  initialState,
  reducers: {
    save: (state, action: PayloadAction<Form2State>) => {
      state.form2 = { ...state.form2, ...action.payload.form2 };
    },
    clear: (state) => {
      state.form2 = initialState.form2;
    },
  },
});

// Action creators are generated for each case reducer function
export const { save, clear } = form2Slice.actions;

export default form2Slice.reducer;
