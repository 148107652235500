import React, { useEffect } from "react";

// for styles
import {
  Backdrop
} from "@material-ui/core/";

// list
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import { dateFormat } from "../../utils/convert";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  callback: () => void,
  hotelInfo: {
    currentCheckInTime: string;
    checkInDate: string;
    checkInStartTime: string;
    checkInEndTime: string;
  }
  handleChangeCheckInTime: (time: string, checkIn: string, checkInStart: string, checkInEnd: string) => void;
}

const HotelCheckInTimeModal: React.FC<Props> = ({
  isShow,
  callback,
  hotelInfo,
  handleChangeCheckInTime
}) => {


  // 選択可能な時間をプランのチェックイン開始・終了で絞り込む
  const generateHours = (): string[] => {
    const checkInStartHour = parseInt(hotelInfo.checkInStartTime.split(":")[0]);
    const checkInEndHour = parseInt(hotelInfo.checkInEndTime.split(":")[0]);

    const hours = Array.from(Array(48).keys()).map(i => {
      if (i < checkInStartHour || i > checkInEndHour) {
        return false;
      }

      return `0${i}`.slice(-2);
    })
    .filter(hour => typeof hour === 'string');

    return hours as string[];
  }

  const Hours = generateHours();

  const Minutes = [
    '00',
    '15',
    '30',
    '45',
  ];

  //セレクトボックス：時
  const [HouranchorEl, setHourAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedHourIndex, setSelectedHourIndex] = React.useState(0);
  const HourOpen = Boolean(HouranchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setHourAnchorEl(event.currentTarget);
  };

  const handleHMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSelectedHourIndex(index);
    setHourAnchorEl(null);
  };

  const HandleClose = () => {
    setHourAnchorEl(null);
  };

  //セレクトボックス：分
  const [MinutesanchorEl, setMinutesAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedMinutesIndex, setSelectedMinutesIndex] = React.useState(0);
  const MinutesOpen = Boolean(MinutesanchorEl);
  const handleMinutesClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setMinutesAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSelectedMinutesIndex(index);
    setMinutesAnchorEl(null);
  };

  const HandleMinutesClose = () => {
    setMinutesAnchorEl(null);
  };

  // 親から渡ってくるチェックイン時間をデフォルト値とする
  useEffect(() => {
    const current = hotelInfo.currentCheckInTime.split(":");

    const currentCheckInHour = current[0];
    const currentCheckInMinute = current[1];

    Hours.map((hour: string, i: number) => {
      if (hour === currentCheckInHour) {
        setSelectedHourIndex(i);
      }
      return null;
    });

    Minutes.map((minute: string, i: number) => {
      if (minute === currentCheckInMinute) {
        setSelectedMinutesIndex(i);
      }
      return null;
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelInfo.currentCheckInTime])



  return (
    <>
    <div>
      <Modal
        open={isShow}
        onClose={callback}
        className="modal-simple"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <div className="modal-simple-content pt-0 pb-0">
          <div className="modal-simple-content-close">
            <button className="button-clear" onClick={callback}>
              <i className="icon-modal-close-navy fz-30"></i>
            </button>
          </div>
          <div className="box-separate">
            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <div className="mb-24">
                  <p className="fz-16 fw-b c-navy mb-4">チェックイン ({`${hotelInfo.checkInStartTime}～${hotelInfo.checkInEndTime}`})</p>
                  <p className="fz-14">{dateFormat(hotelInfo.checkInDate, "YYYY年MM月DD日（ddd）")}</p>
                </div>

              <div className="d-f ai-c jc-c">
                {/* 時 */}
                <div>
                  <div className="form-select_list-wrap">
                    <List
                      component="div"
                      className="form-select_list"
                    >
                      <ListItem
                        button
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        // aria-label="when device is locked"
                        aria-expanded={HourOpen ? 'true' : undefined}
                        className="form-select_list-inner"
                        onClick={handleClickListItem}
                      >
                        <ListItemText
                          // primary="When device is locked"
                          secondary={Hours[selectedHourIndex]}
                          className="form-select_list-selected_text"
                        />
                      </ListItem>
                    </List>
                    <Menu
                      id="lock-menu"
                      anchorEl={HouranchorEl}
                      open={HourOpen}
                      className="form-select_list-options"
                      onClose={HandleClose}
                      MenuListProps={{
                        'aria-labelledby': 'lock-button',
                        role: 'listbox',
                      }}
                    >
                      {Hours.map((option, index) => (
                        <MenuItem
                          key={option}
                          // disabled={index === 0}
                          className="form-select_list-options-item"
                          selected={index === selectedHourIndex}
                          onClick={(event) => handleHMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>

                <span className="pl-10 pr-10">：</span>

                {/* 分 */}
                <div>
                  <div className="form-select_list-wrap">
                    <List
                      component="div"
                      className="form-select_list"
                    >
                      <ListItem
                        button
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        // aria-label="when device is locked"
                        aria-expanded={MinutesOpen ? 'true' : undefined}
                        className="form-select_list-inner"
                        onClick={handleMinutesClickListItem}
                      >
                        <ListItemText
                          // primary="When device is locked"
                          secondary={Minutes[selectedMinutesIndex]}
                          className="form-select_list-selected_text"
                        />
                      </ListItem>
                    </List>
                    <Menu
                      id="lock-menu"
                      anchorEl={MinutesanchorEl}
                      open={MinutesOpen}
                      className="form-select_list-options"
                      onClose={HandleMinutesClose}
                      MenuListProps={{
                        'aria-labelledby': 'lock-button',
                        role: 'listbox',
                      }}
                    >
                      {Minutes.map((option, index) => (
                        <MenuItem
                          key={option}
                          // disabled={index === 0}
                          className="form-select_list-options-item"
                          selected={index === selectedMinutesIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
              </div>
            </div>

            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <div className="ta-r">
                  <p>
                    <button
                      onClick={() => handleChangeCheckInTime(
                        `${Hours[selectedHourIndex]}:${Minutes[selectedMinutesIndex]}`,
                        hotelInfo.checkInDate,
                        hotelInfo.checkInStartTime,
                        hotelInfo.checkInEndTime
                      )}
                      className="button-medium-orange w-60"
                    >
                    OK
                    </button>
                  </p>                  
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default HotelCheckInTimeModal;
