
import PartsHotelNarrowDown from '../../parts/PartsHotelNarrowDown';

import {PlanSelectFormData} from "../../../types/planSelectForm";
import {HotelAllSearchResponse, hotelList} from "../../../types/Hotel";
import {
  diffUpdateHotelPrice,
  getHotelTypeLabel,
  hotelAreaCodeLabel, hotelTypeCodeLabel,
} from "../../../utils/hotel";
import SearchLoading from "../../loading/searchLoading";
import PartsHotelTagList from "../../parts/PartsHotelTagList";
import {isOkinawaSaihakkenLabel} from "../../../utils/discount";
import React from "react";
import {CircularProgress} from "@material-ui/core";
import SearchNotFound from "../../SearchNotFound";


// モーダルに何か渡したい場合はここ追加する
type Props = {
  areaSelectOption: string[],
  defaultStep: number,
  target: string | undefined,
  selectedHotelSort: number,
  sortHotels: (sortType: number) => void,
  loading: boolean,
  isListLoading: boolean,
  planSelectFormData: PlanSelectFormData,
  handleHotelDetail: (hotelData: hotelList) => void,
  isUpdate: boolean,
  updateHotelIndex: number | undefined,
  checkIn: string,
  checkOut: string,
  onPageHandlePlanDetail: (code: string) => void,
  hotelAllData: HotelAllSearchResponse | undefined,
  hotelAreas: string[],
  hotelTypes: string[],
  hotelAreasFilterState: string[],
  hotelTypesFilterState: string[],
  handleHotelAreasFilter: (event: React.MouseEvent<HTMLElement>, newRoomType: string[]) => void,
  handleHotelTypesFilter: (event: React.MouseEvent<HTMLElement>, newHotelAreas: string[]) => void,
}

const StaySelectModalStep1: React.FC<Props> = ({
  areaSelectOption,
  defaultStep,
  target,
  selectedHotelSort,
  sortHotels,
  loading,
  isListLoading,
  planSelectFormData,
  handleHotelDetail,
  isUpdate,
  updateHotelIndex,
  checkIn,
  checkOut,
  onPageHandlePlanDetail,
  hotelAllData,
  hotelAreas,
  hotelTypes,
  hotelAreasFilterState,
  hotelTypesFilterState,
  handleHotelAreasFilter,
  handleHotelTypesFilter,
}) => {

return (<>
  {/* 宿泊施設の絞り込み条件(ホテル指定の場合) */}
  {defaultStep === 1 && (
    <PartsHotelNarrowDown
      hotelAreas = {hotelAreas}
      hotelTypes= {hotelTypes}
      hotelAreasFilterState= {hotelAreasFilterState}
      hotelTypesFilterState= {hotelTypesFilterState}
      handleHotelAreasFilter= {handleHotelAreasFilter}
      handleHotelTypesFilter= {handleHotelTypesFilter}
      hotelAreaCodeLabel= {hotelAreaCodeLabel}
      hotelTypeCodeLabel= {hotelTypeCodeLabel}
    />
  )}

  {/* 宿泊施設の並び替え条件 */}
  <div className={(defaultStep === 1 ? " animation-fade active" : " animation-fade")}>
    <div className="d-f jc-sb mt-32 mt-40-md mb-16">
      <div className="fz-12 fz-14-md fw-b">
        検索結果<span className="c-red fz-20 fz-22-md ml-5 mr-5">{hotelAllData && hotelAllData?.hotelList.length >= 0 ? hotelAllData?.hotelList.length.toString() : "--"}</span>件
      </div>
      <div className="box-sort small">
        <ul className="box-sort-list">
          {target === "ホテル指定" && (
            <li key={"result_recommend"} className="box-sort-list-item">
              <button
                className={selectedHotelSort !== 0 ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                onClick={() => sortHotels(0)}>おすすめ順</button>
            </li>
          )
          }
          <li key={"result_cheapest"} className="box-sort-list-item">
            <button
              className={selectedHotelSort !== 1 ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
              onClick={() => sortHotels(1)}>価格が安い順</button>
          </li>
          <li key={"result_expensive"} className="box-sort-list-item">
            <button
              className={selectedHotelSort !== 2 ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
              onClick={() => sortHotels(2)}>価格が高い順</button>
          </li>
        </ul>
      </div>
    </div>
  </div>


  {/* 表示コンテンツ */}

  {loading ? (
    <SearchLoading isLoading={loading} />
  ) : (
    <>
      {/* 宿泊施設の検索結果 */}
      <div className={(defaultStep === 1 ? " animation-fade active" : " animation-fade")}>
        <div className="box-plan-bt_gray">
          <div className="box-plan-bt_gray-list">
            {hotelAllData?.hotelList?.map((hotel, hIndex) => (
              <div key={"hotel-" + hIndex} className="box-plan-bt_gray-list-item">
                <div className="box-stay">
                  <div className="box-stay-item">
                    <div className="box-stay-item-thumbnail large">
                      <img src={hotel.image_l_url} alt=""/>
                      {isOkinawaSaihakkenLabel(planSelectFormData, hotel.hotel_code) && (
                        <span className={"label-travel-support"}>全国割対象</span>
                      )}
                    </div>
                    <div className="box-stay-item-content">
                      <div className="box-stay-item-content-area">
                        <div className="list-stay-label">
                          {hotel.sub_area_name !== undefined && (
                            <ul className="list-stay-label-list">
                              <li className="list-stay-label-list-item">{hotel.sub_area_name}</li>
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="box-stay-item-content-heading">
                        {hotel.hotel_name}
                      </div>
                      <div className="mb-20-md">
                        <button onClick={() => handleHotelDetail(hotel)} className="button-border-small-blue w-100per w-a-md">ホテルの詳細を見る</button>
                      </div>

                      {/* 以下PCのみ */}
                      <div className="box-stay-item-content-label d-n d-b-md">
                        <div className="list-stay-label">
                          <ul className="list-stay-label-list mb-20-md">
                            {hotel.hotel_type_list?.map((hotelType, htIndex) => (
                              <li key={htIndex} className="list-stay-label-list-item">{getHotelTypeLabel(hotelType.hotel_type_code)}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="d-n d-b-md">
                          <PartsHotelTagList
                            hotel_facility_list={hotel.hotel_facility_list}
                            ulClass="parts-hotel_tag-list d-f fw-w"
                          ></PartsHotelTagList>
                        </div>
                      </div>
                    </div>

                    {/* sp時 */}
                    <div className="box-stay-item-content-sp mt-24 mb-16">
                      <div className="d-n-md">
                        <div className="list-stay-label">
                          <ul className="list-stay-label-list mb-20-md">
                            {hotel.hotel_type_list?.map((hotelType, htIndex) => (
                              <li key={htIndex} className="list-stay-label-list-item">{getHotelTypeLabel(hotelType.hotel_type_code)}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="mt-16">
                          <PartsHotelTagList
                            hotel_facility_list={hotel.hotel_facility_list}
                            ulClass="parts-hotel_tag-list d-f fw-w"
                          ></PartsHotelTagList>
                        </div>
                      </div>
                    </div>
                    <div className="box-stay-item-detail">
                      <div className="w-100per">
                          {hotel.sold_out_flg === 1 ? (<>
                            <div className="d-f jc-sb d-ib-md w-100per-md mb-0-md">
                              <div className="d-f d-b-md jc-sb ai-c fx-1">
                                <span className="button-medium-disabled w-100per">受付終了</span>
                              </div>
                            </div>
                          </>) : (<>
                            <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                              <p className="mb-5-md fz-13 fw-b ta-c-md">
                                追加料金<br />
                                <span className="fz-10 fz-11-md">（１人あたり）</span>
                              </p>
                              <p className="mb-10-md c-red fz-24 fw-b ta-c-md">
                                {diffUpdateHotelPrice(planSelectFormData, {
                                  isUpdate: isUpdate,
                                  step: 'hotel',
                                  updateHotelIndex: updateHotelIndex,
                                  updatePlan: undefined,
                                  updateHotel: hotel,
                                  checkIn: checkIn,
                                  checkOut: checkOut
                                })}<span className="fz-12">円〜</span>
                              </p>
                            </div>
                            <p className="mb-10-md">
                              <button
                                onClick={()=> onPageHandlePlanDetail(hotel.hotel_code)}
                                className="button-medium-orange button-variable sp-large w-100per"
                              >
                                この宿泊施設を<br className="d-n d-b-md" />
                                選択する
                              </button>
                            </p>
                          </>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {(hotelAllData?.hotelList.length === 0 && !loading && !isListLoading) && (
          <SearchNotFound></SearchNotFound>
        )}

        {!loading && isListLoading && (
          <>
            {hotelAllData && hotelAllData?.hotelList.length > 0 ? (
              <div className={"p-30 ta-c"}>
                <CircularProgress color="inherit" size={28} />
              </div>
            ): (
              <SearchLoading isLoading={true} />
            )}
          </>
        )}
      </div>
    </>
  )}
</>);
};

export default StaySelectModalStep1;