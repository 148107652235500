import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InputsForm4 } from "../types/InputsForm4";

export interface Form4State {
  form4: InputsForm4;
}

const initialState: Form4State = {
  form4: {
    payment: {
      cashcard_number: null,
      cashcard_month: null,
      cashcard_year: null,
      cashcard_name: "",
      cashcard_number_of_payments: null,
      cashcard_cvc: null,
      token: "",
    },
  },
};

export const form4Slice = createSlice({
  name: "form4",
  initialState,
  reducers: {
    save: (state, action: PayloadAction<Form4State>) => {
      state.form4 = { ...state.form4, ...action.payload.form4 };
    },
    clear: (state) => {
      state.form4 = initialState.form4;
    },
  },
});

// Action creators are generated for each case reducer function
export const { save, clear } = form4Slice.actions;

export default form4Slice.reducer;
