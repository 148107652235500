import React from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

import { Select } from "../input/Select";

type Props = {
  name: string;
  register: UseFormRegister<any>; // todo: どこかで定義したい
  options: RegisterOptions;
}

const PartsCardExpireYear: React.FC<Props> = (props) => {
  const thisYear = (new Date()).getFullYear().toString();
  const thisYear2digits = parseInt(thisYear.slice(-2));

  const maxYear = thisYear2digits + 8;  // 現在から8年後まで

  let selectOptions = [];

  for (let year = thisYear2digits; year <= maxYear; year++) {
    selectOptions.push({
      value: String(year),
      label: String(year).padStart(2, "0"),
    })
  }

  return (
    <Select
      name={props.name}
      register={props.register}
      options={props.options}
      selectOptions={selectOptions}
    />
  );
};

export default PartsCardExpireYear;