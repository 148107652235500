import React, {useEffect} from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { InputsForm3 } from "../../../types/InputsForm3";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { InputDate } from "../../input/InputDate";
import { InputCheckbox } from "../../input/InputCheckbox";

import {dateFormat,} from "../../../utils/convert";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {insuranceQuoteResponce} from "../../../types/insurance";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import {InputText} from "../../input/InputText";
import {InputHidden} from "../../input/InputHidden";

type Props = {
  planSelectFormData: PlanSelectFormData,
  insuranceData: insuranceQuoteResponce,
  control: any;
  errors: FieldErrors;
  getValues: any;
  setValue: any;
  form3: any;
  insuranceFee: number;
  register: UseFormRegister<InputsForm3>;
  updateInsurance(start: string, end: string):void
}

const SectionDisabilityInsurance: React.FC<Props> = (props) => {
  //ラジオボタンでアコーディオン開閉制御
  const [selectedValue01, setSelectedValue01] = React.useState('view');
  const handleChange01 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue01(event.target.value);
  };
  const searchParams = props.planSelectFormData.searchParams;
  const [selectedDateStart, setSelectedDateStart] = React.useState(props.form3.disability_insurance.subscription_period.start?? "");
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(props.form3.disability_insurance.subscription_period.end?? "");

  useEffect(() => {
    if (selectedDateStart && selectedDateEnd) {
      props.updateInsurance(selectedDateStart, selectedDateEnd);
    }
  }, [selectedDateStart, selectedDateEnd])

  //readOnly属性を付与するとdate-pickerが表示されなくなるので対策
  const ForceReadOnlyInput = React.forwardRef((props, ref) => (
    <input {...props} readOnly={true} />
  ));
  return (
    <>
      <section>
        {/* <!--国内旅行傷害保険--> */}
        <h2 className="heading-2 mb-8">国内旅行傷害保険</h2>
        <p className="mb-24 mb-30-md fw-b fz-18-md">航空機欠航・遅延時の宿泊費補償付プラン</p>
        <Accordion className="accordion-radio" expanded={selectedValue01 === 'view'}>
          <AccordionSummary className="accordion-radio-heading">
            <ul className="list-side">
              <li>
                <input
                  type="radio"
                  name="AccordionRadioStatus01"
                  id="AccordionRadioStatus01_view"
                  value="view"
                  checked={selectedValue01 === 'view'}
                  onChange={handleChange01}
                />
                <label htmlFor="AccordionRadioStatus01_view">詳細を見る</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="AccordionRadioStatus01"
                  id="AccordionRadioStatus01_hidden"
                  value="hidden"
                  onChange={handleChange01}
                />
                <label htmlFor="AccordionRadioStatus01_hidden">閉じる</label>
              </li>
            </ul>
          </AccordionSummary>
          <AccordionDetails className="accordion-radio-content">
            <div className="box-separator_bottom-large">
              <div className="box-separator_bottom-large-item">
                <p className="mb-24">
                  国内旅行中のケガによる入院、通院はもちろん賠償事故や手荷物の盗難まで幅広く補償します。さらに航空機の欠航・遅延により、搭乗予定日の同日中に代替便が利用できなかった場合に宿泊1泊につき1万円を補償します。
                </p>
                <div className="box-border-blue small">
                  <div className="d-f jc-sb ai-c">
                    <p className="fz-16 fz-18-md fw-b">保険料</p>
                    <p>
                      <span className="c-red">
                        {/* todo: 親から取得する。期間で可変するのか確認する。 */}
                        <span className="fz-28 fw-b">{ props.insuranceData.price.DTA.toLocaleString("ja-JP") }</span>円
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="box-separator_bottom-large-item">
                <div className="form-content">
                  <div className="form-content-heading small">
                    <h3 className="heading-3-small">保険加入期間</h3>
                  </div>
                  <div className="form-content-input">
                    <div className="form-column-pc">
                      <div className="form-column-pc-item">
                        <div className="heading-item_6 fw-b">開始日</div>
                        <div>
                          <div className="form-date-seal">
                            <div className="form-input-date-icon-wrap">
                              {/* todo: 日付の整合性チェックをどこでやるか？ */}
                              <DatePicker
                                dateFormat="yyyy/MM/dd"
                                locale='ja'
                                selected={new Date(selectedDateStart)}
                                value={dateFormat(selectedDateStart, 'YYYY/MM/DD')}
                                className="form-input-date"
                                minDate={new Date(searchParams.start)}
                                maxDate={new Date(searchParams.end)}
                                customInput={<ForceReadOnlyInput />}
                                onChange={(updateDate: Date|null) => {
                                  const _date = dayjs(updateDate).format('YYYY-MM-DD');
                                  setSelectedDateStart(_date);
                                  props.setValue("disability_insurance.subscription_period.start", _date);
                                }}
                              />
                              <InputHidden
                                name="disability_insurance.subscription_period.start"
                                value={selectedDateStart}
                                placeholder=""
                                register={props.register}
                                options={{
                                  required: false,
                                  validate: {
                                    correctPeriod: (value) => {
                                      const start = value;
                                      const end = props.getValues("disability_insurance.subscription_period.end");

                                      // 開始・終了ともに未入力
                                      if (!start && !end) {
                                        // 保険加入するにチェックが入っている場合は必須とする
                                        if (props.getValues("disability_insurance.we_do_join") === "yes") {
                                          return false;
                                        }

                                        // 未加入なら未入力でOK
                                        return true;
                                      }

                                      // 開始が未入力なのに終了が入力済みならNG
                                      if (!start && end) {
                                        return false;
                                      }

                                      return (new Date(end)) > (new Date(start));
                                    },
                                  },
                                }}
                                error={props.errors.disability_insurance?.subscription_period?.start}
                              ></InputHidden>
                              {/* 選択中の日付 */}
                              <p className="form-date-seal-label">{dateFormat(selectedDateStart, "YYYY/MM/DD")}</p>
                            </div>
                          </div>
                          {props.errors.disability_insurance?.subscription_period?.start &&
                            props.errors.disability_insurance?.subscription_period?.start.type === "required" && (
                              <p className="form-error_text">
                                開始日をご入力ください。
                              </p>
                            )}
                          {props.errors.disability_insurance?.subscription_period?.start &&
                            props.errors.disability_insurance?.subscription_period?.start.type === "joinInsurance" && (
                              <p className="form-error_text">
                                保険加入期間を正しくご入力ください。
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="form-column-pc-item">
                        <div className="heading-item_6 fw-b">終了日</div>
                        <div>
                          <div className="form-date-seal">
                            <div className="form-input-date-icon-wrap">
                              {/* todo: 日付の整合性チェックをどこでやるか？ */}
                              <DatePicker
                                dateFormat="yyyy/MM/dd"
                                locale='ja'
                                selected={new Date(selectedDateEnd)}
                                value={dateFormat(selectedDateEnd, 'YYYY/MM/DD')}
                                className="form-input-date"
                                minDate={new Date(searchParams.start)}
                                maxDate={new Date(searchParams.end)}
                                customInput={<ForceReadOnlyInput />}
                                onChange={(updateDate: Date|null) => {
                                  const _date = dayjs(updateDate).format('YYYY-MM-DD');
                                  setSelectedDateEnd(_date);
                                  props.setValue("disability_insurance.subscription_period.end", _date);
                                }}
                              />
                              <InputHidden
                                name="disability_insurance.subscription_period.end"
                                value={selectedDateEnd}
                                placeholder=""
                                register={props.register}
                                options={{
                                  required: false,
                                  validate: {
                                    correctPeriod: (value) => {
                                      const start = props.getValues("disability_insurance.subscription_period.start");
                                      const end = value;

                                      // 開始・終了ともに未入力
                                      if (!start && !end) {
                                        // 保険加入するにチェックが入っている場合は必須とする
                                        if (props.getValues("disability_insurance.we_do_join") === "yes") {
                                          return false;
                                        }

                                        // 未加入なら未入力でOK
                                        return true;
                                      }

                                      // 開始が未入力なのに終了が入力済みならNG
                                      if (!start && end) {
                                        return false;
                                      }

                                      return (new Date(end)) > (new Date(start));
                                    },
                                  },
                                }}
                                error={props.errors.disability_insurance?.subscription_period?.end}
                              ></InputHidden>
                              {/* 選択中の日付 */}
                              <p className="form-date-seal-label">{dateFormat(selectedDateEnd, "YYYY/MM/DD")}</p>
                            </div>
                            
                          </div>
                        </div>
                        {props.errors.disability_insurance?.subscription_period?.end &&
                          props.errors.disability_insurance?.subscription_period?.end.type === "required" && (
                            <p className="form-error_text">
                              終了日をご入力ください。
                            </p>
                          )}
                        {props.errors.disability_insurance?.subscription_period?.end &&
                          props.errors.disability_insurance?.subscription_period?.end.type === "correctPeriod" && (
                            <p className="form-error_text">
                              保険加入期間を正しくご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                    <ul className="list-note red mt-8">
                      <li>
                        前泊・後泊などにより旅行期間が前後する場合は、正しい日程にご修正ください。
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="box-separator_bottom-large-item">
                <h3 className="heading-3-small mb-16">保険金額</h3>
                <table className="table-plan fz-10 fz-14-md ta-c">
                  <tbody>
                    <tr className="table-plan-title">
                      <td colSpan={2} className="table-bgc-nevy">
                        旅行期間(保険期間)
                      </td>
                      <td className="table-bgc-nevy">
                        1泊2日
                        <br className="d-n-md" />
                        まで
                      </td>
                      <td className="table-bgc-nevy">
                        3泊4日
                        <br className="d-n-md" />
                        まで
                      </td>
                      <td className="table-bgc-nevy">
                        6泊7日
                        <br className="d-n-md" />
                        まで
                      </td>
                      <td className="table-bgc-nevy">
                        13泊14日
                        <br className="d-n-md" />
                        まで
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-bgc-light_gray w-25 w-a-sm"
                        rowSpan={3}
                      >
                        傷害
                      </td>
                      <td className="table-bgc-light_gray">
                        死亡・
                        <br className="d-n-md" />
                        後遺障害
                      </td>
                      <td className="fw-b">730万円</td>
                      <td className="fw-b">551万円</td>
                      <td className="fw-b">485万円</td>
                      <td className="fw-b">311万円</td>
                    </tr>
                    <tr>
                      <td className="table-bgc-light_gray">
                        入院保険金
                        <br className="d-n-md" />
                        日額
                      </td>
                      <td className="fw-b">6,000円</td>
                      <td className="fw-b">4,000円</td>
                      <td className="fw-b">4,000円</td>
                      <td className="fw-b">2,000円</td>
                    </tr>
                    <tr>
                      <td className="table-bgc-light_gray">
                        通院保険金
                        <br className="d-n-md" />
                        日額
                      </td>
                      <td className="fw-b">3,000円</td>
                      <td className="fw-b">2,000円</td>
                      <td className="fw-b">2,000円</td>
                      <td className="fw-b">1,000円</td>
                    </tr>
                    <tr>
                      <td className="table-bgc-light_gray" colSpan={2}>
                        賠償責任
                      </td>
                      <td className="fw-b" colSpan={4}>
                        3,000万円
                      </td>
                    </tr>
                    <tr>
                      <td className="table-bgc-light_gray" colSpan={2}>
                        救援者費用
                      </td>
                      <td className="fw-b">50万円</td>
                      <td className="fw-b">80万円</td>
                      <td className="fw-b">80万円</td>
                      <td className="fw-b">60万円</td>
                    </tr>
                    <tr>
                      <td className="table-bgc-light_gray" colSpan={2}>
                        携行品
                        <br className="d-n-md" />
                        (自己負担3,000円)
                      </td>
                      <td className="fw-b">20万円</td>
                      <td className="fw-b">20万円</td>
                      <td className="fw-b">10万円</td>
                      <td className="fw-b">10万円</td>
                    </tr>
                    <tr>
                      <td className="table-bgc-light_gray" colSpan={2}>
                        国内航空機欠航・
                        <br />
                        遅延時宿泊費補償
                      </td>
                      <td className="fw-b" colSpan={4}>
                        1万円
                      </td>
                    </tr>
                    <tr className="table-plan-total">
                      <td className="fw-b table-bgc-light_red" colSpan={2}>
                        保険料
                      </td>
                      <td className="fw-b table-bgc-light_red" colSpan={4}>
                        1,000円
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul className="list-note-number small mb-0 light-gray mt-16">
                  <li>日帰りを含みます。</li>
                  <li>補償内容の詳細は<a href="https://chubb.meclib.jp/TAE5394/book/data/TAE5394.pdf" target="_blank" rel="noreferrer">約款</a>をご確認ください。</li>
                  <li>旅行先でピッケル等を使用する山岳登攀、グライダー操縦等、特に危険なスポーツを行う場合はお引受できません。</li>
                </ul>
              </div>

              {/* <!--告知事項--> */}
              <div className="box-separator_bottom-large-item">
                <h3 className="heading-3-small mb-16">告知事項</h3>
                <div className="box-light-gray small">
                  <p className="fz-16 fw-b mb-8">国内旅行傷害保険</p>
                  <p className="mb-16">
                    告知事項が事実と異なる場合、契約を解除したり保険金をお支払いできない場合があります。申込者（契約者）と旅行者（被保険者）が異なる場合は、ご契約者は被保険者に保険申込内容を確認の上、入力をおこなってください。
                  </p>
                  <div className="box-white small mb-16 fz-14">
                    今回のご旅行中に補償する他の同種の保険契約（同時に申し込む契約を含みます）と合算した後の傷害死亡・後遺障害の保険金額は1,000万円（契約者と被保険者が同一の場合は3,000万円）を超えません。
                  </div>
                  <ul className="list-note light-gray ">
                    <li>
                      他の同種の保険契約については「重要事項説明書」の注意喚起情報・告知義務・通知義務の項目をご覧ください。ご契約を解除したり保険金のお支払いができない場合がありますので十分にご注意ください。
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!--重要事項説明--> */}
              <div className="box-separator_bottom-large-item">
                <h3 className="heading-3-small mb-16">重要事項説明</h3>
                <div className="box-light-gray small mb-24">
                  <p className="mb-16">
                    保険加入条件および重要事項説明書には、特に
                    <span className="c-red">
                      確認頂きたい事項、お客様に不利になる事項の記載
                    </span>
                    があるため、重要事項説明書を最後まで必ずご確認ください。
                  </p>
                  <p className="ta-c">
                    <a href="https://www.chubbtravelinsurance.jp/content/dam/chubbglobaltravel/Staticfiles/ACETravel/ATIJP/files/Terms_and_Conditions_ImportantInfo_Domestic.pdf#0" target="_blank" rel="noreferrer">
                      保険加入条件および重要事項説明書 (PDF)を表示
                      <i className="icon-blank ml-5"></i>
                    </a>
                  </p>
                </div>

                <ul className="list-alert-blue mb-16 mb-32-md">
                  <li>PDFデータをご用意しておりますので、印刷または端末にダウンロードをお願いいたします。</li>
                  <li>
                      内容に関してお問い合わせがございましたら下記メールアドレスにてお問い合わせください。<br />
                      チャブ保険変更受付センター（メール）：TIMCC-Support@chubb.com
                  </li>
                  <li>
                    迷惑メール対策等で、ドメイン指定受信を設定されている場合に、メールが正しく届かないことがございます。以下のドメインを受信できるように設定してください。<br />
                    @email.chubbtravelins.com
                  </li>
                  <li>契約者および被保険者は日本に住んでいる方に限ります。また、保険金受取人のための銀行口座を日本国内に有している必要があります。</li>
                </ul>

                <p className="ta-c-md c-red mb-16 fw-b">
                  国内旅行傷害保険に加入される場合は以下の項目をチェックしてください。
                </p>
                <div className="box-light-red mb-8">
                  <div className="form-input-checkbox-left">
                    <InputCheckbox
                      name="disability_insurance.agree"
                      register={props.register}
                      checkboxes={[
                        {
                          id: "disability_insurance.agree",
                          label: "",
                          value: "yes"
                        },
                      ]}
                      options={{
                        required: false,
                        disabled: !props.insuranceData.price.isDTA,
                        validate: {
                          joinInsurance: (value) => {

                            // 保険加入するにチェックが入っている場合は必須とする
                            if (!value && props.getValues("disability_insurance.we_do_join") === "yes") {
                              return false;
                            }

                            return true;
                          },
                        },
                      }}
                      className={"form-input-checkbox-icon"}
                    ></InputCheckbox>
                    <span className="form-input-checkbox-text">
                      <label htmlFor="disability_insurance.agree.0">
                        上記告知事項は「はい」です。また、本保険の普通保険約款および特約を本契約の内容とすることを了承し、保険加入条件および重要事項説明書の内容を確認の上了承し、同意します。
                      </label>
                    </span>
                  </div>
                  {props.errors.disability_insurance?.agree && (
                    <p className="form-error_text c-red ta-c mt-4">
                      国内旅行傷害保険に加入される場合は<br />「同意します」にチェックをいれてください。
                    </p>
                  )}
                </div>

                <div className="box-light-red">
                  <div className="form-input-checkbox-left">
                    <InputCheckbox
                      name="disability_insurance.we_do_join"
                      register={props.register}
                      checkboxes={[
                        {
                          id: "disability_insurance.we_do_join",
                          label: "",
                          value: "yes"
                        },
                      ]}
                      options={{ required: false,
                        disabled: !props.insuranceData.price.isDTA
                      }}
                      className={"form-input-checkbox-icon"}
                    ></InputCheckbox>
                    <span className="form-input-checkbox-text">
                      <label htmlFor="disability_insurance.we_do_join.0">
                        旅行者全員、国内旅行傷害保険に加入します。（81歳以上の方を除く）
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </section>
    </>
  );
};

export default SectionDisabilityInsurance;
