/**
 * ホテルの種別コード
 */
export const hotelTypeCode: {[key:string]: string} = {
  "all" : "すべて",
  "1": "リゾートホテル",
  "2": "シティホテル",
  "3": "ビジネスホテル",
  "4": "コンドミニアム",
  "5": "ゲストハウス",
  "6": "旅館",
  "7": "民泊",
  "8": "ペンション",
  "9": "その他宿泊施設",
  "10": "一棟貸切",
}

/**
 * ホテルの部屋タイプコード
 */
export const roomTypeCode:  {[key:string]: string} = {
  "1": "シングル",
  "2": "セミダブル",
  "3": "ダブル",
  "4": "ツイン",
  "5": "トリプル",
  "6": "4ベット",
  "7": "和室",
  "8": "和洋室",
  "9": "一棟貸切",
  "99": "その他",
}

/**
 * ホテルのサービスタグ画像名管理（レンジ、コンロ以外）
 */
export const hotelTagImage: {[key:string]: string} = {
  "フリーWiFi": "01_tag_free-wifi.svg",
  "無料駐車場": "02_tag_free-parking.svg",
  "有料駐車場": "03_tag_paid-parking.svg",
  "屋内プール": "04_tag_indoor-pool.svg",
  "屋外プール(夏季営業)": "05_tag_outdoor-pool-in-summer.svg",
  "ビーチ": "06_tag_beach.svg",
  "大浴場": "07_tag_large-public-bath.svg",
  "温泉": "08_tag_hot-spring.svg",
  "スパ": "09_tag_spa.svg",
  "露天風呂": "10_tag_outdoor-bath.svg",
  "全室禁煙": "11_tag_non-smoking.svg",
  "売店・コンビニ": "12_tag_convenience-store.svg",
  "レストラン・食堂": "13_tag_restaurant.svg",
  "コネクティングルーム": "14_tag_connecting-room.svg",
  "全室キッチン付": "15_tag_with-kitchen.svg",
  "キッチン": "16_tag_kitchen.svg",
  "フィットネスジム": "17_tag_fitness-gym.svg",
  "各種マッサージサービス(有料)": "18_tag_paid-massage-service.svg",
  "エステサロン(有料)": "19_tag_paid-beauty-salon.svg",
  "自動販売機": "20_tag_vending-machine.svg",
  "調理器具・食器類": "21_tag_kitchenware-tableware.svg",
  "洗濯物": "22_tag_washing-machine.svg",
  "衣類乾燥機": "23_tag_clothes-dryer.svg",
  "コインランドリー(有料)": "24_tag_paid-coin-laundry.svg",
  "送迎バス": "25_tag_courtesy-bus.svg",
  "ラウンジ": "26_tag_lounge.svg",
  "バーベキューガーデン": "27_tag_bbq-garden.svg",
  "喫煙所": "30_tag_smoking-area.svg",
  "ペット可": "31_tag_pet-friendly.svg",
}

/**
 *  ホテルエリアコード
 */
export const okinawaAreaCodeLabel: {[key:string]: string} = {
  all : "すべて",
  naha : "那覇",
  onnason : "恩納村・読谷村",
  chatan : "北谷・沖縄市",
  motobu : "本部・今帰仁",
  nago : "名護",
  ginowan : "浦添・宜野湾・北中城",
  miyako : "宮古島",
  yaeyama : "石垣島（八重山諸島）",
  tomishiro : "糸満・豊見城・八重瀬",
  shimajiri : "南城・南風原・与那原",
  chubu : "うるま・金武・宜野座",
  iejima : "伊江島",
  kunigami : "大宜味村・国頭村",
  kerama : "慶良間諸島",
  kumejima : "久米島",
  yonagunijima : "与那国島",
  mimasakasantou : "美作三湯（湯郷・湯原・奥津）・西粟倉・東粟倉・蒜山",
  kurashiki2 : "倉敷・総社・井笠・高梁",
  okayama2 : "岡山・牛窓・備前・玉野",
  shoudoshima : "小豆島",
  kotohira : "琴平",
  marugame : "丸亀・坂出",
  takamatsu : "高松・東讃",
  uwajima : "宇和島・南予",
  imabari : "今治・しまなみ海道・東予",
  matuyama : "松山・道後温泉・中予",
  sapporo: "札幌",
  wakkanai: "稚内・留萌・利尻・礼文",
  abashiri: "網走・紋別・北見・知床",
  kushiro: "釧路・阿寒・川湯・根室",
  obihiro: "帯広・十勝",
  hidaka: "日高・えりも",
  furano: "富良野・美瑛・トマム",
  asahikawa: "旭川・層雲峡・旭岳",
  chitose: "千歳・支笏・苫小牧・滝川・夕張・空知",
  otaru: "小樽・キロロ・積丹",
  niseko: "ルスツ・ニセコ",
  hakodate: "函館・湯の川・大沼・奥尻",
  noboribetsu: "洞爺・登別・室蘭",
  ueno: "上野",
  nanto: "南砺市",
}