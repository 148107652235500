import {PlanSelectFormData} from "../types/planSelectForm";
import {
  activityField, activityInputFields,
  activityMultipleField,
  activityMultipleFields,
  InputsForm2,
} from "../types/InputsForm2";
import {dateFormat, toDisplayTime} from "./convert";
import _ from "lodash";

export const getPlanInputFields = (planSelectFormData: PlanSelectFormData) => {
  const planInputFields:activityInputFields = [];
  const plans = planSelectFormData.activity.filter((activity) => {
    return activity.active;
  });
  
  if (plans.length > 0) {
    plans.forEach((plan, index) => {
      if (plan.plan_info.add_field_list) {
        const fields:activityField = {};
        const multipleField:activityMultipleField = {};
        const multipleFields:activityMultipleFields = {};
        plan.plan_info.add_field_list.forEach((field,fIndex) => {
          const optionListData:any[] = [];
          if (field?.option_list !== undefined){
            field?.option_list.forEach((optionList, oIndex) => {
              optionListData.push({
                id: "plans."+index+"."+plan.plan_info.plan_code+"."+fIndex+"."+field.field_code+"."+oIndex,
                label: optionList.name,
                value: optionList.value,
                a_tag_href: optionList.a_tag_href,
                a_tag_value: optionList.a_tag_value,
              });
            });
          }

          if (field.multiple_display_type === "0") {
            // フォームに対して一つ
            fields[field.field_code] = {
              index: fIndex,
              field_name: field.field_name,
              field_code: field.field_code,
              field_memo: field.field_memo,
              field_type: field.field_type,
              required_type: field.required_type === "1",
              unit: field.unit,
              option_list: optionListData,
              value: "",
            };
          } else {
            // 参加人数分
            multipleField[field.field_code] = {
              index: fIndex,
              field_name: field.field_name,
              field_memo: field.field_memo,
              field_type: field.field_type,
              required_type: field.required_type === "1",
              unit: field.unit,
              option_list: optionListData,
              value: "",
            };
          }
        })

        plan.price_list?.forEach((priceList, pIndex) => {
          const priceFields: activityMultipleField[] = [];
          if (priceList.num !== undefined && Object.keys(multipleField).length > 0) {
            for(let n = 0; n < priceList.num; n++){
              priceFields[n] = multipleField;
            }
            multipleFields[priceList.type_code] = {
              priceName: priceList.name,
              priceCode: priceList.type_code,
              fields: priceFields
            };
          }
        })
        let time = "";
        if (plan.time !== "no_settime") {
          time = plan.time;
        }

        planInputFields[index] = {
          planCode: plan.plan_info.plan_code,
          planName: plan.plan_info.plan_name,
          useDate: dateFormat(plan.date, 'YYYY年MM月DD日（ddd）') + " " + toDisplayTime(time),
          fields: _.sortBy(fields, 'index'),
          multipleFields: _.sortBy(multipleFields, 'index')
        }
      }
    })
  }
  
  return planInputFields;
}

export const getOptionsInputFields = (planSelectFormData: PlanSelectFormData) => {
  const optionsInputFields:activityInputFields = [];
  const options = planSelectFormData.reserveOptions.filter((activity) => {
    return activity.active;
  });
  
  if (options.length > 0) {
    options.forEach((option, index) => {
      if (option.plan_info.add_field_list) {
        let num = 0;
        option.price_list?.forEach((priceList) => {
          if (priceList.num !== undefined) {
            num += priceList.num;
          }
        })
        const fields:activityField = {};
        const multipleField:activityMultipleField = {};
        const multipleFields:activityMultipleFields = {};
        option.plan_info.add_field_list.forEach((field,fIndex) => {
          const optionListData:any[] = [];
          if (field?.option_list !== undefined) {
            field?.option_list.forEach((optionList, oIndex) => {
              optionListData.push({
                id: "plans." + index + "." + option.plan_info.plan_code + "." + fIndex + "." + field.field_code+"."+oIndex,
                label: optionList.name,
                value: optionList.value,
                a_tag_href: optionList.a_tag_href,
                a_tag_value: optionList.a_tag_value,
              });
            });
          }
          if (field.multiple_display_type === "0") {
            // フォームに対して一つ
            fields[field.field_code] = {
              index: fIndex,
              field_name: field.field_name,
              field_code: field.field_code,
              field_memo: field.field_memo,
              field_type: field.field_type,
              required_type: field.required_type === "1",
              unit: field.unit,
              option_list: optionListData,
              value: "",
            };
          } else {
            // 参加人数分
            multipleField[field.field_code] = {
              index: fIndex,
              field_name: field.field_name,
              field_memo: field.field_memo,
              field_type: field.field_type,
              required_type: field.required_type === "1",
              unit: field.unit,
              option_list: optionListData,
              value: "",
            };
          }
        })

        option.price_list?.forEach((priceList, pIndex) => {
          const priceFields: activityMultipleField[] = [];
          if (priceList.num !== undefined  && Object.keys(multipleField).length > 0) {
            for(let n = 0; n < priceList.num; n++){
              priceFields[n] = multipleField;
            }
            multipleFields[priceList.type_code] = {
              priceName: priceList.name,
              priceCode: priceList.type_code,
              fields: priceFields
            };
          }
        })

        optionsInputFields[index] = {
          planCode: option.plan_info.plan_code,
          planName: option.plan_info.plan_name,
          useDate: dateFormat(option.date, 'YYYY年MM月DD日（ddd）') + " " + toDisplayTime(option.time),
          fields: _.sortBy(fields, 'index'),
          multipleFields: _.sortBy(multipleFields, 'index')
        }
      }
      
    })
  }

  return optionsInputFields;
}
