import DatePicker from "react-datepicker";
import {dateFormat} from "../../../utils/convert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CounterNumberOfUnitsCarRental from "../../counter/carRentalCounterNumberOfUnits";
import {getCarRentalCountNum} from "../../../utils/carRental";
import {SearchNotFound} from "../../index";
import React from "react";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {carRentalPlanData, carRentalSaveData, carRentalSearchResponsePlanList} from "../../../types/carRental";

type Props = {
  step: number
  rentalCarStatus: string
  departureDate: string
  changeDepartureDate: (newValue: Date | null) => void
  planSelectFormData: PlanSelectFormData
  isLoading: boolean
  borrowingTimeOpen: boolean
  handleBorrowingTimeClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  borrowingTimeOptions: string[]
  selectedBorrowingTimeIndex: number
  borrowingTimeAnchorEl: null | HTMLElement
  borrowingTimeHandleClose: () => void
  handleBorrowingTimeClick: (event: React.MouseEvent<HTMLElement>, index: number,) => void
  borrowingPlaceOpen: boolean
  handleBorrowingPlaceClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  officeCodes: {label: string, code: string}[]
  selectedBorrowingPlaceIndex: number
  borrowingPlaceAnchorEl: null | HTMLElement
  borrowingPlaceHandleClose: () => void
  handleBorrowingPlaceClick: (event: React.MouseEvent<HTMLElement>, index: number) => void
  returnDate: string
  changeReturnDate: (newValue: Date | null) => void
  returnTimeOpen: boolean
  handleReturnTimeClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  returnTimeOptions: string[]
  selectedReturnTimeIndex: number
  returnTimeAnchorEl: null | HTMLElement
  returnTimeHandleClose: () => void
  handleReturnTimeClick: (event: React.MouseEvent<HTMLElement>, index: number) => void
  returnPlaceOpen: boolean
  handleReturnPlaceClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  selectedReturnPlaceIndex: number
  returnPlaceAnchorEl: null | HTMLElement
  returnPlaceHandleClose: () => void
  handleReturnPlaceClick: (event: React.MouseEvent<HTMLElement>, index: number) => void
  searchCarRental: () => void
  rentalRows: carRentalSearchResponsePlanList[]
  carRentalPlanParams: carRentalSaveData
  addSaveDataByStep1: (count:number, plan_code:string, type: string) => void
  callBackModal: () => void
}

const RentalModalStep1: React.FC<Props> = ({ ...props }) => {

  //readOnly属性を付与するとdate-pickerが表示されなくなるので対策
  const ForceReadOnlyInput = React.forwardRef((props, ref) => (
    <input {...props} readOnly={true} />
  ));
  return (
    <>
      {props.step === 1 && (
        <>
          <div className="bgc-white mb-48-md mb-40">
            <div className="wrap-content-900-large wrap-padding-md pt-6">
              <p
                className="heading-2 fz-26-md mt-24 mb-20 mt-50-md">レンタカーを追加される方は下記よりお選びください</p>
              <div className="d-f ai-c">
                {props.rentalCarStatus === "chitose" ? (<>
                  <div className="w-100 w-170-md fx-sh">
                    <img src={require("../../../img/share/rentacar/logo_suzukirentacar_hokkaido.svg").default}
                         alt="OTS RENT-A-CAR"/>
                  </div>
                  <p className="fz-12 fz-16-md lh-1_5 lh-1_75 ml-8 ml-24-md">
                    スズキレンタカー北海道は安全・安心・高品質なサービスが特徴のレンタカー会社。<br/>
                    全車種新車3年以内のものを取り揃えているので安心・安全にご利用いただけます。
                  </p>
                </>) :props.rentalCarStatus === "heisei" ? (<>
                  <div className="w-100 w-150-md fx-sh">
                    <img src={require("../../../img/share/rentacar/logo_heisei_car_rentals.svg").default}
                         alt="OTS RENT-A-CAR"/>
                  </div>
                  <p className="fz-12 fz-16-md lh-1_5 lh-1_75 ml-8 ml-24-md">
                    平成レンタカーは、岡山、香川、愛媛に展開する地元密着型のレンタカー会社です。<br/>
                    比較的新しい年式の車を、きれいな状態でお安く貸し出しております。
                    主要駅前や空港に店舗を構えています。
                  </p>
                </>) : (<>
                  <div className="w-100 w-130-md fx-sh">
                    <img src={require("../../../img/share/rentacar/logo_ots_rentacar.svg").default}
                         alt="OTS RENT-A-CAR"/>
                  </div>
                  <p className="fz-12 fz-16-md lh-1_5 lh-1_75 ml-8 ml-24-md">
                    レンタカーなら、OTSレンタカーにお任せ！多彩なラインナップのお車をご用意しております。空港から営業所までの無料送迎サービスもございます。
                  </p>
                </>)}
              </div>
              <div className="box-light-gray medium mt-24 mt-16-md">
                {props.rentalCarStatus === "chitose" ? (<>
                  <dl className="d-f mb-5 fz-12 fz-13-md">
                    <dt>営業時間：</dt>
                    <dd>
                      4月1日から10月31日まで 8：00～19：00
                      <br/>
                      11月1日から3月31日まで 8：00～18：00
                    </dd>
                  </dl>
                  <dl className="d-f mb-5 fz-12 fz-13-md">
                    <dt>住所：</dt>
                    <dd>
                      北海道千歳市流通2丁目3番14
                    </dd>
                  </dl>
                  <dl className="d-f mb-5 fz-12 fz-13-md">
                    <dt>電話番号：</dt>
                    <dd className="pr-10">
                      0123-27-7000
                    </dd>
                    <dt>FAX：</dt>
                    <dd>
                      0123-26-7777
                    </dd>
                  </dl>
                  <dl className="d-f mb-5 fz-12 fz-13-md">
                    <dt>アクセス：</dt>
                    <dd>
                      新千歳空港から無料送迎バスにて7～8分
                    </dd>
                  </dl>
                  <p
                    className="d-f fz-12 fz-13-md c-default">送迎ご利用の際、営業終了時間30分前までにご返却をお願いします。</p>
                </>) : props.rentalCarStatus === "heisei" ? (<>
                  <div className="fz-12 fz-13-md fw-b c-navy mb-8">【営業所】</div>
                  <div className="mb-10">
                    <div className="fz-12 fz-13-md fw-b c-navy mb-2">岡山県</div>
                    <p className="c-default fz-12 fz-13-md">
                      岡山駅前店：JR岡山駅・東口から徒歩5分！（岡山市北区駅前町2-3-25 ルフォン岡山駅前1Ｆ）<br/>
                      岡山空港店：岡山空港から無料送迎！（岡山市北区日応寺1072-1）
                    </p>
                  </div>
                  <div className="mb-10">
                    <div className="fz-12 fz-13-md fw-b c-navy mb-2">香川県</div>
                    <p className="c-default fz-12 fz-13-md">
                      高松駅前店 ：高松駅すぐ前！（高松市寿町1-1-22）<br/>
                      高松空港店 ：高松空港から無料送迎！ （松市香南町岡1225-1）
                    </p>
                  </div>
                  <div className="mb-10">
                    <div className="fz-12 fz-13-md fw-b c-navy mb-2">愛媛県</div>
                    <p className="c-default fz-12 fz-13-md">
                      松山空港店：松山空港から無料送迎車で30秒！（松山市南吉田町2750-13
                      コナンパーキング内）
                    </p>
                  </div>
                </>) : (<>
                  <div className="fz-12 fz-13-md fw-b c-navy mb-8">【注意事項】</div>
                  <ul className="list-dot-blue_gray">
                    <li key="1">表示されている料金は、免責補償料込となります。</li>
                    <li key="2">配車・返車は、営業所の営業時間内<span
                      className="c-red">（8:00～19:00／時期・店舗により変動あり）</span>となります。
                    </li>
                    <li
                      key="3">早朝・深夜発着の飛行機をご選択いただいた場合、手配をお断りさせていただく場合がございますので予めご了承ください。
                    </li>
                    <li
                      key="4">車種につきましては、「ご希望」として承ることが可能ですが、ご希望に沿いかねる場合や当日同等クラスの他の車種を代わりにご用意させて頂く場合がございますので、予めご了承ください。
                    </li>
                    <li key="5">掲載されている写真はイメージです。実際にご利用いただける車種と異なる場合がございます。
                    </li>
                  </ul>
                </>)}
              </div>

              <div className="box-separator mt-24 mt-32-md pb-20 pb-40-md">
                {/* 貸出 */}
                <div className="box-separator-item pc-large">
                  <div className="d-f-md ai-c">
                    <p className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md ml-16-md fx-sh w-100-md">貸出</p>
                    <div className="fx-1 d-f-md">
                      <div className="w-50per-md d-f pr-6-md">
                        <div className="w-50per pr-6">
                          <label htmlFor="borrowing_date" className="d-b c-blue_gray fz-12 fz-14-md mb-5">貸出日</label>
                          <div className="form-date-seal">
                            <div className="form-input-date-icon-wrap">
                              <DatePicker
                                dateFormat="yyyy/MM/dd"
                                locale='ja'
                                selected={new Date(props.departureDate)}
                                value={dateFormat(props.departureDate, 'YYYY/MM/DD')}
                                className="form-input-date"
                                onChange={props.changeDepartureDate}
                                minDate={new Date(props.planSelectFormData.searchParams.start)}
                                maxDate={new Date(props.planSelectFormData.searchParams.end)}
                                customInput={<ForceReadOnlyInput/>}
                                disabled={props.isLoading}
                              />
                              <p className="form-date-seal-label"> {dateFormat(props.departureDate, "YYYY/MM/DD")}</p>
                            </div>
                          </div>
                        </div>
                        <div className="w-50per pl-6">
                          <p className="c-blue_gray fz-12 fz-14-md mb-5">時間</p>
                          <div className="form-select_list-wrap">
                            <List
                              component="div"
                              className="form-select_list"
                            >
                              <ListItem
                                button
                                id="borrowing_time"
                                aria-haspopup="listbox"
                                aria-controls="borrowing_menu"
                                // aria-label="when device is locked"
                                aria-expanded={props.borrowingTimeOpen ? 'true' : undefined}
                                className="form-select_list-inner"
                                onClick={props.handleBorrowingTimeClickListItem}
                              >
                                <ListItemText
                                  // primary="When device is locked"
                                  secondary={props.borrowingTimeOptions[props.selectedBorrowingTimeIndex]}
                                  className="form-select_list-selected_text text_medium"
                                />
                              </ListItem>
                            </List>
                            <Menu
                              id="borrowing_menu"
                              anchorEl={props.borrowingTimeAnchorEl}
                              open={props.borrowingTimeOpen}
                              className="form-select_list-options"
                              onClose={props.borrowingTimeHandleClose}
                              MenuListProps={{
                                'aria-labelledby': 'borrowing_time',
                                role: 'listbox',
                              }}
                            >
                              {props.borrowingTimeOptions.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  // disabled={index === 0}
                                  className="form-select_list-options-item"
                                  selected={index === props.selectedBorrowingTimeIndex}
                                  onClick={(event) => props.handleBorrowingTimeClick(event, index)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        </div>
                      </div>

                      <div className="w-50per-md mt-16 mt-0-md pl-6-md">
                        <p className="c-blue_gray fz-12 fz-14-md mb-5">貸出店舗</p>
                        <div className="form-select_list-wrap">
                          <List
                            component="div"
                            className="form-select_list"
                          >
                            <ListItem
                              button
                              id="borrowing_place"
                              aria-haspopup="listbox"
                              aria-controls="borrowing_place_menu"
                              // aria-label="when device is locked"
                              aria-expanded={props.borrowingPlaceOpen ? 'true' : undefined}
                              className="form-select_list-inner"
                              onClick={props.handleBorrowingPlaceClickListItem}
                            >
                              <ListItemText
                                // primary="When device is locked"
                                secondary={props.officeCodes[props.selectedBorrowingPlaceIndex]["label"]}
                                className="form-select_list-selected_text text_medium"
                              />
                            </ListItem>
                          </List>
                          <Menu
                            id="borrowing_place_menu"
                            anchorEl={props.borrowingPlaceAnchorEl}
                            open={props.borrowingPlaceOpen}
                            className="form-select_list-options"
                            onClose={props.borrowingPlaceHandleClose}
                            MenuListProps={{
                              'aria-labelledby': 'borrowing_place',
                              role: 'listbox',
                            }}
                          >
                            {props.officeCodes.map((option, index) => (
                              <MenuItem
                                key={option["code"]}
                                // disabled={index === 0}
                                className="form-select_list-options-item"
                                selected={index === props.selectedBorrowingPlaceIndex}
                                onClick={(event) => props.handleBorrowingPlaceClick(event, index)}
                              >
                                {option["label"]}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 返却 */}
                <div className="box-separator-item pc-large">
                  <div className="d-f-md ai-c">
                    <p className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md ml-16-md fx-sh w-100-md">返却</p>
                    <div className="fx-1 d-f-md">
                      <div className="w-50per-md d-f pr-6-md">
                        <div className="w-50per pr-6">
                          <label htmlFor="return_date" className="d-b c-blue_gray fz-12 fz-14-md mb-5">返却日</label>
                          <div className="form-date-seal">
                            <div className="form-input-date-icon-wrap">
                              <DatePicker
                                dateFormat="yyyy/MM/dd"
                                locale='ja'
                                selected={new Date(props.returnDate)}
                                value={dateFormat(props.returnDate, 'YYYY/MM/DD')}
                                className="form-input-date"
                                onChange={props.changeReturnDate}
                                minDate={new Date(props.planSelectFormData.searchParams.start)}
                                maxDate={new Date(props.planSelectFormData.searchParams.end)}
                                customInput={<ForceReadOnlyInput/>}
                                disabled={props.isLoading}
                              />
                              <p className="form-date-seal-label"> {dateFormat(props.returnDate, "YYYY/MM/DD")}</p>
                            </div>
                          </div>
                        </div>
                        <div className="w-50per pl-6">
                          <p className="c-blue_gray fz-12 fz-14-md mb-5">時間</p>
                          <div className="form-select_list-wrap">
                            <List
                              component="div"
                              className="form-select_list"
                            >
                              <ListItem
                                button
                                id="borrowing_time"
                                aria-haspopup="listbox"
                                aria-controls="borrowing_menu"
                                // aria-label="when device is locked"
                                aria-expanded={props.returnTimeOpen ? 'true' : undefined}
                                className="form-select_list-inner"
                                onClick={props.handleReturnTimeClickListItem}
                              >
                                <ListItemText
                                  // primary="When device is locked"
                                  secondary={props.returnTimeOptions[props.selectedReturnTimeIndex]}
                                  className="form-select_list-selected_text text_medium"
                                />
                              </ListItem>
                            </List>
                            <Menu
                              id="borrowing_menu"
                              anchorEl={props.returnTimeAnchorEl}
                              open={props.returnTimeOpen}
                              className="form-select_list-options"
                              onClose={props.returnTimeHandleClose}
                              MenuListProps={{
                                'aria-labelledby': 'borrowing_time',
                                role: 'listbox',
                              }}
                            >
                              {props.returnTimeOptions.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  // disabled={index === 0}
                                  className="form-select_list-options-item"
                                  selected={index === props.selectedReturnTimeIndex}
                                  onClick={(event) => props.handleReturnTimeClick(event, index)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        </div>
                      </div>

                      <div className="w-50per-md mt-16 mt-0-md pl-6-md">
                        <p className="c-blue_gray fz-12 fz-14-md mb-5">返却店舗</p>
                        <div className="form-select_list-wrap">
                          <List
                            component="div"
                            className="form-select_list"
                          >
                            <ListItem
                              button
                              id="borrowing_place"
                              aria-haspopup="listbox"
                              aria-controls="borrowing_place_menu"
                              // aria-label="when device is locked"
                              aria-expanded={props.returnPlaceOpen ? 'true' : undefined}
                              className="form-select_list-inner"
                              onClick={props.handleReturnPlaceClickListItem}
                            >
                              <ListItemText
                                // primary="When device is locked"
                                secondary={props.officeCodes[props.selectedReturnPlaceIndex]["label"]}
                                className="form-select_list-selected_text text_medium"
                              />
                            </ListItem>
                          </List>
                          <Menu
                            id="borrowing_place_menu"
                            anchorEl={props.returnPlaceAnchorEl}
                            open={props.returnPlaceOpen}
                            className="form-select_list-options"
                            onClose={props.returnPlaceHandleClose}
                            MenuListProps={{
                              'aria-labelledby': 'borrowing_place',
                              role: 'listbox',
                            }}
                          >
                            {props.officeCodes.map((option, index) => (
                              <MenuItem
                                key={option["code"]}
                                // disabled={index === 0}
                                className="form-select_list-options-item"
                                selected={index === props.selectedReturnPlaceIndex}
                                onClick={(event) => props.handleReturnPlaceClick(event, index)}
                              >
                                {option["label"]}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pb-20 pb-40-md">
                <button
                  className="button-large-red w-100per"
                  onClick={props.searchCarRental}
                >
                  上記の条件で検索する
                </button>
              </div>
            </div>
          </div>

          <div className="wrap-content-900-large wrap-padding-md ">
            <p className="heading-2 fz-26-md mt-24 mb-20 mt-50-md">レンタカー</p>
            <div className="box-card-rentacar">
              {props.rentalRows.length > 0 ?
                <>
                  {props.rentalRows?.map((row, index) => (
                    <div key={index} className={(() => {
                      if (index !== 0) {
                        return ("box-card-rentacar-item mt-8")
                      } else {
                        return ("box-card-rentacar-item")
                      }
                    })()}>
                      <div className="box-card-rentacar-detail">
                        <div className="box-card-rentacar-detail-left">
                          <div><img src={row.image_url} alt={row.car_type_code} className="d-b w-100per"/></div>
                          {/*<span className="label-gray-sausage mt-8">*/}
                          {/*        空車：<i className="icon-possible"></i>*/}
                          {/*</span>*/}
                        </div>
                        <div className="box-card-rentacar-detail-right">
                          <p className="fz-14 fz-16-sm fw-b c-navy">{row.plan_name}</p>
                          <dl className="box-card-rentacar-detail-right-table mt-8">
                            <div className="box-card-rentacar-detail-right-table-item">
                              <dt className="box-card-rentacar-detail-right-table-item-heading">車種</dt>
                              <dd className="box-card-rentacar-detail-right-table-item-text">{row.class_model}</dd>
                            </div>
                            {row.max_member &&
                              <div className="box-card-rentacar-detail-right-table-item">
                                <dt className="box-card-rentacar-detail-right-table-item-heading">定員</dt>
                                <dd
                                  className="box-card-rentacar-detail-right-table-item-text">{row.max_member.toString()}人
                                </dd>
                              </div>
                            }
                          </dl>
                          <ul className="d-f fxw-w mt-4">
                            {row.default_option_list.map((tagRow, tIndex) => (
                              <li key={tIndex}><span className="label-gray-small mr-4 mt-4">{tagRow.option_name}</span></li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="box-card-rentacar-price">
                        <p className="mr-8 mr-0-md ta-r-sm">
                        <span
                          className="d-ib d-b-sm fz-12 fz-13-sm fw-b mr-8 mr-0-md c-navy">追加代金 (一台あたり)</span>
                          <span className="d-b-sm fz-20 fz-24-sm fw-b lh-1-md c-red mt-5-md">{Number(row.price).toLocaleString()}<span
                            className="fz-12 fz-12-sm fw-b c-red">円</span></span>
                        </p>

                        {/* カウンター */}
                        <div className="pl-32-md pr-0-md mt-24-sm">
                          <CounterNumberOfUnitsCarRental
                            type="car"
                            defaultCount={getCarRentalCountNum(props.carRentalPlanParams, row.plan_code)}
                            plan_code={row.plan_code}
                            updateCounter={props.addSaveDataByStep1}></CounterNumberOfUnitsCarRental>
                        </div>

                      </div>
                    </div>
                  ))}
                </>
                :
                <>
                  <SearchNotFound></SearchNotFound>
                </>
              }
            </div>
          </div>


          <div className="modal-fixed-bottom">
            <button onClick={props.callBackModal} className="button-medium-orange padding-large w-100per">オプションを選択する
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default RentalModalStep1;