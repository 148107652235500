import React from "react";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";

type Props = {
  calcAge: (input :{year: number, month: number, day: number}) => number;
  toGenderLabel: (gender: string) => string;
  applicant: {
    name: {
      kana: {
        first: string;
        last: string;
      }
    };
    age: number;
    gender: string;
    email: string;
  };
  followers: {
    name: {
      kana: {
        last: string;
        first: string;
      },
    },
    gender: string;
    age: string;
    mileage_number: string;
    birthday_year: number;
    birthday_month: string;
    birthday_day: string;
  }[];
}

const SectionParticipant: React.FC<Props> = (props) => {

  return (
    <>
      <section>
        <h2 className="heading-2 mt-40-md">参加者情報</h2>
        <div className="card">
          <table className="table-responsive">
            <tbody>
              <tr>
                <td className="table-bgc-light_navy ta-c-md fw-b">
                  参加者1
                </td>
                <td>
                  <p>
                    <span className="mr-10 fw-b">
                      {`${props.applicant.name.kana.last} ${props.applicant.name.kana.first}`}様
                    </span>
                    <span className="fz-14 mr-10">{props.applicant.age}歳</span>
                    <span className="fz-14">{props.toGenderLabel(props.applicant.gender)}</span>
                  </p>
                  {props.applicant.email && (
                    <p className="fz-14">
                      Emailアドレス：{props.applicant.email}
                    </p>
                  )}
                </td>
              </tr>

              {props.followers.map((follower, index) => (
                <tr key={index}>
                  <td className="table-bgc-light_navy ta-c-md fw-b">
                    参加者{index + 2}
                  </td>
                  <td>
                    <p>
                      <span className="fw-b mr-10">{`${follower.name.kana.last} ${follower.name.kana.first}`} 様</span>
                      <span className="fz-14 mr-10">
                        {props.calcAge({
                          year: follower.birthday_year,
                          month: parseInt(follower.birthday_month),
                          day: parseInt(follower.birthday_day),
                        })}歳
                      </span>
                      <span className="fz-14">{props.toGenderLabel(follower.gender)}</span>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default SectionParticipant;