import React, {useContext, useEffect, useState} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { save } from "../redux/form4Slice";
import { RootState } from "../redux/store";
import { InputsForm4 } from "../types/InputsForm4";
import { useTravelPrice } from "../utils/useTravelPrice";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

//parts import
import FormStep from "./Form/FormStep";
import {
  SectionPayment
} from "./section/Form4"
import SectionTravelPrice from "./section/SectionTravelPrice";
import {PlanSelectFormData} from "../types/planSelectForm";
import {getPriceRequestParams} from "../utils/price";
import {creditAPI, getCreditRequestPrams} from "../utils/credit";
import {insuranceQuoteRequest} from "../types/insurance";
import {getBackSearchURL} from "../utils/search";
import {isOption} from "../utils/reserve";
import {devLog} from "../utils/errors";
import {getHeadTitle} from "../utils/title";
import {FormSessionContext} from "../hooks/FormSession";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";
import { PartsTutorialButton } from "./parts";


// lodingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const Form4: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  const {checkSession} = useContext(FormSessionContext);
  const classesForLoading = useStyles();
  const location = useLocation()
  const [planSelectFormData, setPlanSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData }) //TODO: need change

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  // reduxに保存されているデータをデバッグ
  // for debug
  const savedState = useSelector((state: RootState) => state);
  devLog("redux form1〜3", {
    ...savedState.form1,
    ...savedState.form2,
    ...savedState.form3,
  });

  const savedForm1 = savedState.form1.form1;
  const savedForm3 = savedState.form3.form3;
  devLog("savedForm3", savedForm3)

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit,
    register,
  } = useForm<InputsForm4>({
  });

  const [creditLoading, setCreditLoading] = useState(false)
  const [isCreditError,setIsCreditError] = useState(false);
  const [isFreeCredit, setIsFreeCredit] = useState(true);
  const onSubmit: SubmitHandler<any> = async (data) => {
    const session = checkSession(planSelectFormData.data);
    if (!session) {
      return;
    }
    
    setCreditLoading(true);
    const creditRequestPrams = getCreditRequestPrams(planSelectFormData.data, savedForm1, savedForm3, data);
    const resultCredit = await creditAPI(creditRequestPrams);
    setCreditLoading(false);
    if (resultCredit.status !== 200) {
      // 与信エラー
      setIsCreditError(true)
      window.scrollTo(0, 0)
    } else if (resultCredit.data !== null)  {
      dispatch(save({ form4: {
        payment: {
          cashcard_number: data.payment?.cashcard_number ?? "",
          cashcard_month: data.payment?.cashcard_month ?? "",
          cashcard_year: data.payment?.cashcard_year ?? "",
          cashcard_name: data.payment?.cashcard_name ?? "",
          cashcard_number_of_payments: data.payment?.cashcard_number_of_payments ?? "",
          cashcard_cvc: data.payment?.cashcard_cvc ?? "",
          token: resultCredit.responce.paymentId,
        }
        }}));
      planSelectFormData.data.priceData = {status: 200, result: resultCredit.responce.resultPlanPrice};
      
      navigate("/Form5",{ state: {
          data: planSelectFormData.data
        } });
    }
    
    
  };

  // 料金確認APIリクエストパラメータ
  const priceParams = getPriceRequestParams(planSelectFormData.data, savedForm1, savedForm3);

  // 料金確認
  const [ priceData, { isLoading, isError, error } ] = useTravelPrice({data: priceParams});

  // todo: API呼び出しエラー時の処理
  if (isError) {
    console.log("error", error);
  }

  useEffect(() => {
    if(priceData?.status === 200) {
      planSelectFormData.data.travelPrice = priceData.result.insurance.insuranceCancelAmount;
      tagManagerContext.pageView(getHeadTitle(planSelectFormData.data, "決済情報の入力"))
      if (priceData.result.taxTotalPrice > 0) {
        setIsFreeCredit(false);
      }
    } else if (priceData && isError) {
      alert("システムエラーが発生しました、申し訳ありませんが時間を置いて再度プランの設定をお願いします。");
      window.location.href = getBackSearchURL(planSelectFormData.data.searchParams, planSelectFormData.data.id);
      tagManagerContext.pageView(getHeadTitle(planSelectFormData.data, "決済情報の入力"))
    }
  }, [priceData])
  // todo フォームデータチェック（ない場合はstep１に戻る、プランセレクトデータがない場合は404）


  let buttonIconLabel = "5";
  if (!isOption(planSelectFormData.data)) {
    buttonIconLabel = "4";
  }
  return (
      <MainTemplate title={getHeadTitle(planSelectFormData.data, "決済情報の入力")} planSelectFormData={planSelectFormData.data}>
        
        {/* ローディングの表示(APIのレスポンス待ち) */}
        <Backdrop className={classesForLoading.backdrop} open={isLoading || creditLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">
              決済情報の入力
            </h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
           <FormStep activeStep={4} isOption={isOption(planSelectFormData.data)}></FormStep>

           {/* 操作ガイドボタン */}
           <PartsTutorialButton value="component-content" />

            <form action="" onSubmit={handleSubmit(onSubmit)}>
              {/* <!--アラート--> */}
              {isCreditError ?
                <>
                  <div className="wrap-content-900-no_padding">
                    <div className="box-alert-red-small icon-alert">
                      <p className="fz-14 fz-16-md c-red">
                        クレジットカードエラー
                      </p>
                      <p className="fz-14">
                        ご入力いただいたカード情報（カード番号、有効期限、セキュリティコード）に誤りがないかご確認ください。<br/>
                        入力内容に誤りがない場合、カードの有効期限が切れているか、利用限度額を超過している可能性があります。<br/>
                        詳しくは、ご利用のカード会社へお問い合わせください。
                      </p>
                    </div>
                  </div>
                </>:
                <>
                  <div className="box-border-blue small icon-alert">
                    <p className="fz-16 c-blue">
                      旅行代金をご確認のうえ、決済情報を入力してください。
                    </p>
                  </div>
                </>
              }

              {/* 旅行代金 */}
              <SectionTravelPrice
                priceData={priceData}
              />

              {/*お支払い方法 */}
              {!isFreeCredit && (
                <SectionPayment
                  register={register}
                  getValues={getValues}
                  control={control}
                  errors={errors}
                >
                </SectionPayment>
              )}

              {/* <!--次へ進む--> */}
              <div className="mt-48 mt-64-md">
                <ul className="form-step_button mt-24">
                  <li className="form-step_button-next">
                    {/* <a href="/Form2/" className="button-large-red">
                      <span className="label-number-orange mr-5">5</span>
                      予約の最終確認をする
                    </a> */}
                    <button
                      type="submit"
                      className="button-large-red"
                      // todo: class作る
                      style={{
                        display: "block",
                        border: "none",
                        width: "100%",
                      }}
                      // disabled={!isDirty || isSubmitting} // note: これだとhistory.back()で戻ったときにdisabledになる
                      disabled={isSubmitting}
                    >
                      <span className="label-number-orange mr-5">{buttonIconLabel}</span>
                      予約の最終確認へ進む
                    </button>
                  </li>
                  <li className="form-step_button-back">
                    <button
                      type="button"
                      className="button-small-border_gray_blue button-medium-md"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <i className="icon-left_arrow mr-5"></i>
                      保険加入の選択に戻る
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>

    </MainTemplate>
  );
};

export default Form4;
