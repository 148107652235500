import React from "react";

// for styles
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";


// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  optionIndex:number,
  optionName:string
  callback: () => void,
  optionCancel(carRentalIndex:number): void
}

const DialogOptionCancel: React.FC<Props> = ({
  isShow,
  callback,
  optionIndex,
  optionName,
  optionCancel,
}) => {

  return (
    <>
    {/* ダイアログ:検索変更 */}
    <div>
      <Dialog
        open={isShow}
        onClose={callback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog"
      >
        <DialogTitle id="alert-dialog-title" className="dialog-title">
          {"追加済みオプションのキャンセル"}
        </DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText id="alert-dialog-description" className="dialog-content-text">
            {optionName}をキャンセルしてもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button className="button-dialog-border-small-blue w-110" onClick={callback}>いいえ</Button>
          <Button className="button-dialog-small-blue w-110" onClick={() => optionCancel(optionIndex)} autoFocus>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  );
};

export default DialogOptionCancel;
