import {useApi} from "./useApi";
import {SearchFlightRequest, SearchFlightsRequest, SearchFlightsResponse} from "../types/FlightV2";


/**
 * フライト検索（往路復路どちらかのみ）
 * @param params
 */
export const searchFlight = async (params: SearchFlightRequest) => {
  const response = await useApi.post({
    url: "/air/flight-search",
    data: params
  });

  return response.data as SearchFlightRequest;
}

/**
 * フライト検索（往路復路）
 * @param params
 */
export const searchFlights = async (params: SearchFlightsRequest) => {
  const response = await useApi.post({
    url: "/air/flights-search",
    data: params
  });

  return response.data as SearchFlightsResponse;
}