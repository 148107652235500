import { useEffect, useRef } from "react";

const useModalHeightChange = (open: boolean) => {
  const wasOpen = useRef(open);

  useEffect(() => {
    if (open && !wasOpen.current) {
      requestAnimationFrame(() => {
        const windowHeight =
          typeof window !== "undefined" ? window.innerHeight : 0;

        const modalContent = document.querySelector(
          ".js-modal-height"
        ) as HTMLElement;

        let modalContentHeight = modalContent ? modalContent.offsetHeight : 0;

        const modalScrollTarget = document.querySelector(
          ".js-modal-scroll"
        ) as HTMLElement;

        // モーダルの高さがウィンドウの高さより大きい場合はスクロールバーを表示
        if (modalScrollTarget) {
          if (modalContentHeight > windowHeight) {
            modalScrollTarget.classList.add("is-scroll");
          } else {
            modalScrollTarget.classList.remove("is-scroll");
          }
        }
      });
    }
    wasOpen.current = open;
  }, [open]);
};

export default useModalHeightChange;
