import React from "react";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

type Props = {
  hidden_contents?: string[];
};

const PartsCancellationChargeText: React.VFC<Props> = (props) => {
  return (
    <>
      <h2 className="heading-2 mb-8 mb-16-md">キャンセル料金について</h2>
      <p className="mb-16 mb-24-md">ご予約（旅行契約）の成立後にお客様の都合により旅行契約を解除した場合には、所定の取消料を申し受けます。</p>
      <div className="card large">
        <h3 className="heading-3 mb-0">変動型ツアーのキャンセル料金</h3>
        <p className="mb-16 fz-14">（お客様お1人様につき）</p>
        <table className="mb-24 mb-32-md table-xsmall">
          <tbody>
            <tr>
              <td className="table-bgc-light_navy w-50per w-a-md fw-b ta-c">旅行契約解除日</td>
              <td className="table-bgc-light_navy fw-b ta-c">取消料</td>
            </tr>
            <tr>
              <th className="w-40per fz-13 fz-16-md fw-b">55日前まで</th>
              <td>下記「航空券取消料」の合計額（一区間あたり）</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">54日前～21日前まで</th>
              <td>下記「航空券取消料」の合計額（一区間あたり）</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">20日前～8日前まで</th>
              <td>旅行代金の20％又は<br />下記「航空券取消料」の合計額のいずれか高い方</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">7日前～2日前まで</th>
              <td>旅行代金の30％又は<br />下記「航空券取消料」の合計額のいずれか高い方</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">前日</th>
              <td>旅行代金の40％又は<br />下記「航空券取消料」の合計額のいずれか高い方</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">当日</th>
              <td>旅行代金の50％又は<br />下記「航空券取消料」の合計額のいずれか高い方</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">旅行開始後及び無連絡不参加</th>
              <td>旅行代金の100％</td>
            </tr>
          </tbody>
        </table>

        {/* 航空券取消料 */}
        <h3 className="heading-3 mb-0">航空券取消料</h3>
        <p className="mb-16 fz-14">（ANA・JAL：個人包括旅行運賃利用の場合の1区間に対する取消料）</p>
        <table className="mb-16 table-xsmall">
          <tbody>
            <tr>
              <th className="table-bgc-light_navy w-55per w-40per-md fw-b ta-c">旅行契約解除日</th>
              <th className="table-bgc-light_navy fw-b ta-c">取消料</th>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">搭乗55日前まで</th>
              <td className="fz-12 fz-16-md">1区間につき500円</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">搭乗54日前～21日前まで</th>
              <td className="fz-12 fz-16-md">1区間につき2,000円</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">搭乗20日前～8日前まで</th>
              <td className="fz-12 fz-16-md">1区間につき3,000円</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">搭乗7日前～前日まで</th>
              <td className="fz-12 fz-16-md">1区間につき6,000円</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">出発当日（航空便出発前）</th>
              <td className="fz-12 fz-16-md">1区間につき9,000円</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">出発当日（航空便出発後）</th>
              <td className="fz-12 fz-16-md">100％</td>
            </tr>
          </tbody>
        </table>
        <ul className="list-note light-gray mb-24 mb-32-md">
          <li>ご契約後に契約内容を変更する場合はいったん取消の上（所定の取消料がかかります）、新規ご予約をお願いします。</li>
          <li>起算日は、弊社営業時間内の受付となりますのでご注意ください。</li>
        </ul>

        {props?.hidden_contents && props.hidden_contents.includes("rentalCar") === false && (
          <>
            {/* レンタカー取消料 */}
            <h3 className="heading-3 mb-0">レンタカー取消料</h3>
            <p className="mb-16 fz-14">（ご予約内容をやむを得ない場合取消可能です。但し規定により下記の予約手数料を申し受けます。）</p>
            <table className="mb-16 table-xsmall">
              <tbody>
                <tr>
                  <th className="w-60per w-40per-md table-bgc-light_navy fw-b ta-c">契約の解除日</th>
                  <th className="table-bgc-light_navy fw-b ta-c">取消料</th>
                </tr>
                <tr>
                  <th className="fz-13 fz-16-md fw-b">乗車日の7日前</th>
                  <td>無料</td>
                </tr>
                <tr>
                  <th className="fz-13 fz-16-md fw-b">乗車日の6日前〜3日前</th>
                  <td>20%</td>
                </tr>
                <tr>
                  <th className="fz-13 fz-16-md fw-b">乗車日の2日前〜前日</th>
                  <td>30%</td>
                </tr>
                <tr>
                  <th className="fz-13 fz-16-md fw-b">当日以降</th>
                  <td>50%</td>
                </tr>
              </tbody>
            </table>
            <ul className="list-note light-gray mb-24 mb-32-md">
              <li>ご予約の乗車時間を1時間以上過ぎてもご連絡のない場合は、予約の取り消しとさせていただきます。</li>
              <li>
                契約時間内に返還できない事態が発生したときは、営業所に連絡し、許可を得てください。
                <br />
                無断で契約期間を延長した場合は、貸渡約款違反となり、延滞違約金をお支払いいただきますので、特にご注意ください。
              </li>
            </ul>
          </>
        )}

        {/* 追加オプション取消料 */}
        <h3 className="heading-3 mb-16">追加オプション取消料</h3>
        <table className="mb-16 table-xsmall">
          <tbody>
            <tr>
              <th className="table-bgc-light_navy fw-b ta-c w-55per w-40per-md">契約の解除日</th>
              <th className="table-bgc-light_navy fw-b ta-c">取消料</th>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">利用開始日の4日前まで</th>
              <td>無料</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">利用開始日の3日前～2日前まで</th>
              <td>20%</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">利用開始日前日</th>
              <td>30%</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">利用開始日当日</th>
              <td>50%</td>
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">利用開始後又は無連絡不参加</th>
              <td>100%</td>
            </tr>
          </tbody>
        </table>
        <ul className="list-note light-gray">
          <li>各コース・オプショナルプランに適用取消料が記載されている場合は、その取消料を適用いたします。</li>
        </ul>
      </div>
    </>
  );
};

export default PartsCancellationChargeText;
