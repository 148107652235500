/**
 * 標準のフッター情報を表示するページ
 */
export const defaultFooterPages = [
    '/login'
]

export const defaultFooter = {
    sellerId: 0,
    supportDial: '098-917-2593'
}

export const siteTitle = 'デジタルDMOプラットフォーム';