/**
 * 年齢計算
 * 
 * @returns 
 */
export const useToGenderLabel = () => {

    const toGenderLabel = (gender: string): string => {
      return gender === "female" ? "女性" : "男性";
    }

    return [ toGenderLabel ] as const;
};