import {PlanSelectFormData} from "../types/planSelectForm";

const app = process.env.REACT_APP_MODE;

export const isPlanSelectConfirmError = (planData: PlanSelectFormData | undefined) => {
  if (planData && planData.hotel.length > 0 && planData.flight.length > 0) {
    return false;
  }
  
  return true;
}

export const isPlanSelectComplete = (planData: PlanSelectFormData | undefined) => {
  if (planData && planData.hotel.length > 0 && planData.flight.length > 0) {
    return false;
  }
  
  return true;
}

export const devLog = (message?: any, ...optionalParams: any[]) => {
  if (app === 'dev') {
    console.log("develop mode: " + message, optionalParams);
  }
}