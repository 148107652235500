import {PlanSearch, roomDetail} from "../types/Search";
import {
  CheepsFlight, connectionFlights, connectionFlightTotalPrice, currentFlightEntity,
  FReservationConnectionFlight,
  FReservationFlight,
  FReservationFlights,
  SearchFlightsRequest
} from "../types/FlightV2";
import {FlightType, FReservationFlightEntity} from "../types/Flight";
import {PlanSelectFormData, PlanSelectFromFlightType} from "../types/planSelectForm";
import {isConnectionFlight, isVacancy} from "./flight";
import {addOtsRate, getProfitRate} from "./price";
import {getHotelTotalPeople} from "./hotel";
import {reserveFlightData} from "../types/reserve";
import {convertAirClassCode} from "./convert";
import { flightTimeControl } from "../types/sellerProducts";

export const getSearchFlightsRequestParams = (searchParams: PlanSearch, airline: string): SearchFlightsRequest => {
  const params: SearchFlightsRequest = {
    "inboundDepartureDate": convertFlightDate(searchParams?.start),
    "outboundDepartureDate": convertFlightDate(searchParams?.end),
    "inboundDepapo": searchParams.from,
    "inboundArrapo": searchParams.to,
    "outBoundDepapo": searchParams.to,
    "outBoundArrapo": searchParams.from,
    "air_code": airline
  }

  if (searchParams.c_airport_to && searchParams.c_airport_to !== "") {
    params.outBoundArrapo = searchParams.c_airport_to;
  }

  return  params
}

/**
 * フライトAPI用に日付のフォーマット変更
 * @param date
 */
export const convertFlightDate = (date: string) => {
  return (date).replace(/-/g, "");  // "20220622" format
}

/**
 * 日時で並び替え
 * @param flights
 */
export const sortFlights = (flights: FReservationFlights[] | undefined) => {
  if (flights && flights.length > 0) {
    return flights.sort( (flightA, flightB) => {
      return Number(flightA.FReservationFlight[0].dep) - Number(flightB.FReservationFlight[0].dep);
    } );
  }

  return [];
}

export const getCheepsFlight = (airline: string, flights: FReservationFlights[], roomData: roomDetail[]) => {
  const cheeps: CheepsFlight = {
    flight: undefined,
    index: 0,
    price: 0
  }
  if (flights.length > 0) {
    flights.forEach((flight, index) => {
      let totalPrice = flight.FReservationFlight[0].adult_fee;
      if (flight.FReservationFlight[0].FReservationConnectionFlight) {
        const connectionFlightPrices = getConnectionFlightPrices(flight.FReservationFlight[0].FReservationConnectionFlight[0], {
          adultTotalFee: 0,
          childTotalFee: 0
        });
        totalPrice += connectionFlightPrices.adultTotalFee;
      }

      // 空席確認
      const vacancy = isVacancy(airline, flight.FReservationFlight[0], roomData);

      if (cheeps.price > totalPrice && vacancy) {
        cheeps.price = totalPrice;
        cheeps.index = index;
        cheeps.flight = flight;
      } else if (cheeps.price === 0 && vacancy) {
        cheeps.price = totalPrice;
        cheeps.index = index;
        cheeps.flight = flight;
      }
    })
  }

  return cheeps;
}

export const getConnectionFlightPrices = (connectionFlight: FReservationConnectionFlight, connectionPrice:connectionFlightTotalPrice ): connectionFlightTotalPrice => {
  if (connectionFlight) {
    connectionPrice.adultTotalFee += connectionFlight.adult_fee;
    connectionPrice.childTotalFee += connectionFlight.child_fee;
    if (connectionFlight.FReservationConnectionFlight[0]) {
      return getConnectionFlightPrices(connectionFlight.FReservationConnectionFlight[0], connectionPrice);
    }
  }

  return connectionPrice;
}

export const getCurrentFlight = (flights: currentFlightEntity[], airline: string, action: string) => {
  const currentFlight = flights.filter((flight) => {
    if (airline === 'JAL') {
      return flight.airline === airline || flight.airline === 'JL/JL';
    }

    return flight.airline === airline;
  });

  if (currentFlight.length > 0) {
    return action === 'inbound' ? currentFlight[0].inbound : currentFlight[0].outbound;
  }

  return undefined;
}

export const lastCurrentFlight = (connectionFlight: FReservationConnectionFlight[]): FReservationConnectionFlight[] => {
  if (connectionFlight[0] && connectionFlight[0].FReservationConnectionFlight.length > 0) {
    return  lastCurrentFlight(connectionFlight[0].FReservationConnectionFlight);
  }

  return connectionFlight;
}

export const getConnectionFlights = (reservationConnectionFlight: FReservationConnectionFlight[] | undefined, connectionFlights: connectionFlights[]): connectionFlights[] => {
  if (reservationConnectionFlight && reservationConnectionFlight.length) {
    connectionFlights.push(reservationConnectionFlight[0]);
    if (reservationConnectionFlight[0].FReservationConnectionFlight.length > 0) {
      return getConnectionFlights(reservationConnectionFlight[0].FReservationConnectionFlight, connectionFlights);
    }
  }

  return connectionFlights;
}

export const getLastFlightAirCode = (reservationConnectionFlight: FReservationConnectionFlight[] | undefined, airCode: string): string => {
  if (reservationConnectionFlight && reservationConnectionFlight.length > 0) {
    airCode = reservationConnectionFlight[0].air_code;
    return getLastFlightAirCode(reservationConnectionFlight[0].FReservationConnectionFlight, airCode);
  }

  return airCode;
}

export const getLastFlightAirSubNo = (reservationConnectionFlight: FReservationConnectionFlight[] | undefined, airSubNo: string): string => {
  if (reservationConnectionFlight && reservationConnectionFlight.length > 0) {
    airSubNo = reservationConnectionFlight[0].air_sub_no;
    return getLastFlightAirSubNo(reservationConnectionFlight[0].FReservationConnectionFlight, airSubNo);
  }

  return airSubNo;
}


export const getSelectedFlights = (flightEntities: currentFlightEntity[]) => {
  const selectedFlights: PlanSelectFromFlightType[] = [];
  const currentFlights = flightEntities.filter((flight) => {
    return flight.current;
  })

  if (currentFlights.length > 0) {
    currentFlights.forEach((flight) => {
      selectedFlights.push({
        airline: flight.airline,
        entity: flight.inbound,
        action: "inbound",
        bookingClass: getFlightBookingClass(flight.inbound[0].class_code),
        connection: isConnectionFlight(flight.inbound),
        selected: true,
        totalPrice: 0,
        useDate: flight.inbound[0]?.date ?? "",
      });
      selectedFlights.push({
        airline: flight.airline,
        entity: flight.outbound,
        action: "outbound",
        bookingClass: getFlightBookingClass(flight.outbound[0].class_code),
        connection: isConnectionFlight(flight.outbound),
        selected: true,
        totalPrice: 0,
        useDate: flight.outbound[0]?.date ?? "",
      });
    });
    return selectedFlights;
  }

  return [];
}


export const isConnection = (flights:FReservationFlight | undefined): boolean => {
  if(flights) {
    return (flights.FReservationConnectionFlight.length > 0)
  } else {
    return false;
  }
}


// フライトの差分料金計算（選択されたフライトを元に再計算する）
export const getDiffFlightPrice = (target: FReservationFlight, currentFlight: FlightType | undefined, planSelectFormData: PlanSelectFormData): string => {
  const otsRate = getProfitRate(planSelectFormData);
  const totalPeople = getHotelTotalPeople(planSelectFormData);

  // フライト 大人、子供料金取得（一人当たり）
  const currentFlightPerPrices = {
    adult: 0,
    child: 0
  }
  currentFlightPerPrices.adult += currentFlight?.flight[0].adult_fee ?? 0;
  currentFlightPerPrices.child += currentFlight?.flight[0].child_fee ?? 0;
  if (currentFlight?.flight[0] && currentFlight?.flight[0].FReservationConnectionFlight.length > 0) {
    const connectionPrices = getConnectionFlightPrices(currentFlight?.flight[0].FReservationConnectionFlight[0], {adultTotalFee: 0, childTotalFee: 0});
    currentFlightPerPrices.adult += connectionPrices.adultTotalFee;
    currentFlightPerPrices.child += connectionPrices.childTotalFee;
  }
  // フライト 大人、子供料金取得（一人当たり）
  const targetFlightPerPrices = {
    adult: 0,
    child: 0
  }

  targetFlightPerPrices.adult += target.adult_fee;
  targetFlightPerPrices.child += target.child_fee;
  if (target.FReservationConnectionFlight.length > 0) {
    const connectionPrices = getConnectionFlightPrices(target.FReservationConnectionFlight[0], {adultTotalFee: 0, childTotalFee: 0});
    targetFlightPerPrices.adult += connectionPrices.adultTotalFee;
    targetFlightPerPrices.child += connectionPrices.childTotalFee;
  }
  // compare flight

  //  合計算出
  let currentFlightTotal = 0;
  let targetFlightTotal = 0;
  planSelectFormData.searchParams.roomDetail.forEach((roomDetail, rIndex) => {
    currentFlightTotal += currentFlightPerPrices.adult * roomDetail.adult;
    targetFlightTotal += targetFlightPerPrices.adult * roomDetail.adult;

    roomDetail.child.forEach((child) => {
      // hotel料金加算
      let childPrice = 0;

      // flight料金加算
      if (child.age === 12) {
        currentFlightTotal += currentFlightPerPrices.adult;
        targetFlightTotal += targetFlightPerPrices.adult;
      } else if (child.age >= 3) {
        currentFlightTotal += currentFlightPerPrices.child;
        targetFlightTotal += targetFlightPerPrices.child;
      }
    })
  });

  const diff = (addOtsRate(targetFlightTotal / totalPeople, otsRate) - addOtsRate(currentFlightTotal / totalPeople, otsRate))

  const sign = Math.sign(diff) == 1 ? '+' : ''
  return sign + diff.toLocaleString()
}

export const createReserveFlightNumber = (flighs: FReservationFlight | undefined) => {
  if (flighs) {
    return flighs.air_code + flighs.air_sub_no + flighs.class_code + getConnectionFlightsNumber(flighs.FReservationConnectionFlight, "");
  }
  
  return "";
}

export const getConnectionFlightsNumber = (connectionFlight: FReservationConnectionFlight[], flightNumber: string): string => {
  if (connectionFlight.length > 0) {
    flightNumber += "_" + connectionFlight[0].air_code + connectionFlight[0].air_sub_no + connectionFlight[0].class_code;
    if (connectionFlight[0].FReservationConnectionFlight.length > 0) {
      return  getConnectionFlightsNumber(connectionFlight[0].FReservationConnectionFlight, flightNumber)
    }
  }

  return flightNumber;
}

export const isChangeAirport = (planSearch: PlanSearch, action: string, airport: string | null | undefined) => {
  if (action === 'inbound' && planSearch.from !== airport) {
    return true;
  }

  return false;
}



export const getConnectionFlightArrapo = (connectionFlights: FReservationConnectionFlight[], arrapo: string): string => {
  if (connectionFlights && connectionFlights.length > 0) {
    arrapo = connectionFlights[0].arrapo;
    if (connectionFlights[0].FReservationConnectionFlight.length > 0) {
      return getConnectionFlightArrapo(connectionFlights[0].FReservationConnectionFlight, arrapo);
    }
  }

  return arrapo;
};


/**
 * フライト予約のリクエストパラメータセット
 * @param flightDetail
 * @param sort
 * @param round
 * @param connection
 */
export const setReserveFlightData = (flightDetail: FReservationFlightEntity | connectionFlights, sort:number, round:number, connection:string):reserveFlightData => {

  return {
    "sort": sort,
    "connection": connection,
    "departure_date": flightDetail.date,
    "m_round_id": round,
    "adult_fee": flightDetail.adult_fee,
    "child_fee": flightDetail.child_fee,
    "depapo": flightDetail.depapo,
    "arrapo": flightDetail.arrapo,
    "dep": flightDetail.dep,
    "arv": flightDetail.arv,
    "air_code": flightDetail.air_code,
    "air_sub_no": flightDetail.air_sub_no,
    "class_code": flightDetail.class_code,
    "office_code": flightDetail.office_code,
    "fee_ref_no": flightDetail.fee_ref_no,
  };
}

export const getFlightClassLabel = (classCode: string) => {
  const flightClass = convertAirClassCode(classCode);
  
  if (flightClass === "J") {
    return "クラスJ";
  }
  
  return "普通席";
}

export const getFlightBookingClass = (classCode: string) => {
  const flightClass = convertAirClassCode(classCode);
  
  if (flightClass === "J") {
    return "J";
  }
  
  return "Y";
}

/**
 * フライト時間をチェックする
 * @param flightTimeControl 
 * @param targetTime 
 * @param flightDirection 
 * @returns 
 */
export const isValidFlightTimeControl = (flightTimeControl: flightTimeControl, targetTime: string, flightDirection: string) => {
  // flightTimeControl.timeが`00:00`の形式でない場合はtrueを返す(制限しない)
  if (!/^[0-9]{2}:[0-9]{2}$/.test(flightTimeControl.time)) {
    return true;
  }
  const controlHour = Number(flightTimeControl?.time.substring(0, 2));
  const controlMinute = Number(flightTimeControl?.time.substring(3, 5));
  const targetHour = Number(targetTime.substring(0, 2));
  const targetMinute = Number(targetTime.substring(2, 4));
  const controlDate = new Date();
  const targetDate = new Date();
  controlDate.setHours(controlHour);
  controlDate.setMinutes(controlMinute);
  targetDate.setHours(targetHour);
  targetDate.setMinutes(targetMinute);

  if (flightDirection === "arrival") {
    // targetDateがcontrolDate以前かどうか
    if (targetDate <= controlDate) {
      return true;
    }
  } else if (flightDirection === "departure") {
    // targetDateがcontrolDate以降かどうか
    if (targetDate >= controlDate) {
      return true;
    }
  }
  return false;
}

/**
 * フライト時間でフィルタリング
 * @param flights 
 * @param flightTimeControl 
 * @param flightDirection 
 * @returns 
 */
export const filterFlightTime = (flights: FReservationFlights[] | undefined, flightTimeControl: flightTimeControl | undefined, flightDirection: string) => {
  if (!flights?.length || flightTimeControl?.isActive !== "enabled" || !flightTimeControl?.time || !["arrival", "departure"].includes(flightDirection)) {
    return flights ?? [];
  }
  const directionParam = flightDirection === "arrival" ? "arv" : "dep";
  return flights.filter((flight) => {
    let targetTime = flight.FReservationFlight[0][directionParam]; // 対象の時間
    // 乗り継ぎ便があるか(往路のみ)
    if (flightDirection === "arrival" && flight.FReservationFlight[0].FReservationConnectionFlight.length) {
      const connectionFlights = getConnectionFlights(flight.FReservationFlight[0].FReservationConnectionFlight, []); // 乗り継ぎ便を取得
      targetTime = connectionFlights[0][directionParam];
    }
    return isValidFlightTimeControl(flightTimeControl, targetTime, flightDirection);
  });
}
