import React, {Fragment, useEffect} from "react";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {dateFormat, toDisplayTime, toCircled} from "../../../utils/convert";
import {getCompleteScheduleData} from "../../../utils/schedule";
import {planSelectScheduleCompleteList} from "../../../types/planSelectConfirm";
import {carRentalSaveData} from "../../../types/carRental";
import {getCarRentalAnshinOptionItem, getCarRentalOfficeLabel, isCarRentalOptions} from "../../../utils/carRental";
import {
  getFlightCodeLabel,
  getFlightNumberForDisplay,
  getFlightPeopleConfirmLabel,
  getFlightTime
} from "../../../utils/flight";
import {showMealLabel, showRoomText} from "../../../utils/hotel";
import {devLog} from "../../../utils/errors";
import {getFlightClassLabel, getConnectionFlights, isConnection} from "../../../utils/flightV2";
import {FReservationConnectionFlight} from "../../../types/FlightV2";

type Props = {
  planSelectFormData: PlanSelectFormData,
  h2Class?: string,
  cardClass?: string,
}

// todo スケジュールテンプレートとわかる変数名に変更する（複数箇所で使用しているため）
const SectionDetail: React.FC<Props> = ({
  planSelectFormData,
  h2Class,
  cardClass,
}) => {
  
  const searchPrams = planSelectFormData.searchParams;
  const [schedule, setSchedule] = React.useState<planSelectScheduleCompleteList[]>();
  useEffect(() => {
    const scheduleData = getCompleteScheduleData(planSelectFormData);
    devLog("scheduleData", scheduleData);
    setSchedule(scheduleData);
  }, []);
  
  const getH2Class = () => {
    if (h2Class) {
      return h2Class;
    }
    
    return "heading-2 mb-16 mt-32-md mb-20-md";
  }
  
  const getCardClass = () => {
    if (cardClass) {
      return cardClass;
    }
    
    return "card large";
  }

  // 末尾の「便」を抜いた表示用フライト番号を返す
  const getFlightNumberForDisplayExcludeSuffix = (flightData: { airline: string, entity: { air_code: string, air_sub_no: string }[] }) => {
    const flightNumber = getFlightNumberForDisplay(flightData.airline, flightData.entity[0].air_code, flightData.entity[0].air_sub_no);

    return flightNumber.replace("便", "");
  }

  
  return (
    <>
    <section>
        {/* /行程表 */}
        <h2 className={getH2Class()}>行程表</h2>
        <div className={getCardClass()}>
          <table className="table-responsive table-small-top_bottom">
            <tbody>
              <tr className="table-plan-subtitle">
                <th
                  colSpan={2}
                  className="table-bgc-light_navy fz-16-md ta-c-md fw-b"
                >
                  旅行期間
                </th>
                <td>
                  <p>出発日：{dateFormat(searchPrams.start, 'YYYY年MM月DD日(ddd)')} 〜</p>
                  <p>帰着日：{dateFormat(searchPrams.end, 'YYYY年MM月DD日(ddd)')}</p>
                  <p>{planSelectFormData.info.days}日間</p>
                </td>
              </tr>

              {schedule? <>
                {schedule.map((scheduleData, indexOfScheduleData) => (
                  <Fragment key={indexOfScheduleData}>
                    {scheduleData.first.data !== undefined ? <>
                      <tr className="table-responsive-group">
                        <th
                          className="table-bgc-light_navy fz-16-md ta-c-md w-140-md fw-b"
                          rowSpan={scheduleData.first.rowSpan}
                        >
                          {dateFormat(scheduleData.date,"YYYY年")}
                          <br className="d-n d-b-md" />
                          {dateFormat(scheduleData.date,"MM月DD日(ddd)")}
                        </th>
                          {scheduleData.first.type === "flight" ?
                            <>
                              <td className="table-responsive-group-heading table-bgc-light_gray w-140-md ta-c fw-b">
                                フライト{scheduleData.first.data.entity.length > 1 && toCircled(1)}
                              </td>
                              <td>
                                <p>
                                  {/* {scheduleData.first.data.airline + scheduleData.first.data.entity[0].air_sub_no}（{getFlightCodeLabel(scheduleData.first.data.entity[0].depapo)} {getFlightTime(scheduleData.first.data.entity[0].dep)} - {getFlightCodeLabel(scheduleData.first.data.entity[0].arrapo)} {getFlightTime(scheduleData.first.data.entity[0].arv)}） */}
                                  { getFlightNumberForDisplayExcludeSuffix(scheduleData.first.data) }（{getFlightCodeLabel(scheduleData.first.data.entity[0].depapo)} {getFlightTime(scheduleData.first.data.entity[0].dep)} - {getFlightCodeLabel(scheduleData.first.data.entity[0].arrapo)} {getFlightTime(scheduleData.first.data.entity[0].arv)}）
                                </p>
                                <p>■{getFlightClassLabel(scheduleData.first.data.entity[0].class_code)}</p>
                                {getConnectionFlights(scheduleData.first.data.entity[0].FReservationConnectionFlight, []).map((connectionFlight) => (
                                  <>
                                    <p>
                                      { getFlightNumberForDisplayExcludeSuffix({airline: scheduleData.first.data.airline, entity: [{air_code: connectionFlight.air_code, air_sub_no: connectionFlight.air_sub_no}]}) }（{getFlightCodeLabel(connectionFlight.depapo)} {getFlightTime(connectionFlight.dep)} - {getFlightCodeLabel(connectionFlight.arrapo)} {getFlightTime(connectionFlight.arv)}）
                                    </p>
                                    <p>■{getFlightClassLabel(connectionFlight.class_code)}</p>
                                  </>
                                ))}

                                <p>■内訳：{getFlightPeopleConfirmLabel(planSelectFormData.searchParams.roomDetail)}<br/>※12歳以上は大人運賃が適用されます。</p>
                              </td>
                            </>
                            : scheduleData.first.type === "activity" ?
                              <>
                                <td className="table-responsive-group-heading table-bgc-light_gray w-140-md ta-c fw-b">
                                  基本プラン
                                </td>
                                <td>
                                  <p className="mb-5">{dateFormat(scheduleData.first.data.date, "YYYY年MM月DD日(ddd)")} {toDisplayTime(scheduleData.first.data.time)}</p>
                                  <p>
                                    {scheduleData.first.data.plan_info.plan_name}
                                  </p>
                                  <p>
                                    主催会社：{scheduleData.first.data.plan_info.supplier_name}
                                  </p>
                                </td>
                              </>
                            : scheduleData.first.type === "options" ?
                              <>
                                <td className="table-responsive-group-heading table-bgc-light_gray w-140-md ta-c fw-b">
                                  追加オプション
                                </td>
                                <td>
                                  <p className="mb-5">{dateFormat(scheduleData.first.data.date, "YYYY年MM月DD日(ddd)")} {toDisplayTime(scheduleData.first.data.time)}</p>
                                  <p>
                                    {scheduleData.first.data.plan_info.plan_name}
                                  </p>
                                  <p>
                                    主催会社：{scheduleData.first.data.plan_info.supplier_name}
                                  </p>
                                </td>
                              </>
                            : scheduleData.first.type === "carRental" && scheduleData.date === scheduleData.first.data.departure_date ?
                              <>
                              <td className="table-responsive-group-heading table-bgc-light_gray w-140-md ta-c fw-b">
                                レンタカー<br /> (貸出)
                              </td>
                                <td>
                                  <div className="mb-10">
                                    <p>{dateFormat(scheduleData.first.data.departure_date, "YYYY年MM月DD日(ddd)")} {scheduleData.first.data.departure_time}</p>
                                    <p>{scheduleData.first.data.departure_office_label}</p>
                                  </div>
                                  <div>
                                      {scheduleData.first.data.detail.map((carRentalDetail: any, cIndex: any) => (
                                        <>
                                          <p>
                                            ■プラン：
                                            <Fragment key={cIndex}>
                                              <span className="mr-20">{carRentalDetail.planDetail.plan_name}</span>
                                            </Fragment>
                                          </p>
                                          <p>
                                            ■車種：
                                            <Fragment key={cIndex}>
                                              <span className="mr-20">{carRentalDetail.planDetail.class_model}</span>
                                            </Fragment>
                                          </p>
                                          
                                          {isCarRentalOptions(carRentalDetail.count_option) && (
                                            <p>
                                              ■オプション：
                                              {carRentalDetail.count_option.anshin.used && <span className="mr-20">{getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin.option_name, 'label')}</span>}
                                              {carRentalDetail.count_option.anshin_premium.used && <span className="mr-20">{getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin_premium.option_name, 'label')}</span>}
                                              {carRentalDetail.count_option.baby_seat.num > 0 && <span className="mr-20">ベビーシート×{carRentalDetail.count_option.baby_seat.num}</span>}
                                              {carRentalDetail.count_option.child_seat.num > 0 && <span className="mr-20">チャイルドシート×{carRentalDetail.count_option.child_seat.num}</span>}
                                              {carRentalDetail.count_option.junior_seat.num > 0 && <span className="mr-20">ジュニアシート×{carRentalDetail.count_option.junior_seat.num}</span>}
                                            </p>
                                          )}
                                        </>
                                      ))}
                                  </div>
                                </td>
                              </>
                            : scheduleData.first.type === "carRental" && scheduleData.date === scheduleData.first.data.return_date ?
                              <>
                              <td className="table-responsive-group-heading table-bgc-light_gray w-140-md ta-c fw-b">
                                レンタカー<br /> (返却)
                              </td>
                                <td>
                                  <div className="mb-10">
                                    <p>{dateFormat(scheduleData.first.data.return_date, "YYYY年MM月DD日(ddd)")} {scheduleData.first.data.return_time}</p>
                                    <p>{scheduleData.first.data.return_office_label}</p>
                                  </div>
                                  <div>
                                      {scheduleData.first.data.detail.map((carRentalDetail: any, cIndex: any) => (
                                        <>
                                          <p>
                                            ■プラン：
                                            <Fragment key={cIndex}>
                                              <span className="mr-20">{carRentalDetail.planDetail.plan_name}</span>
                                            </Fragment>
                                          </p>
                                          <p>
                                            ■車種：
                                            <Fragment key={cIndex}>
                                              <span className="mr-20">{carRentalDetail.planDetail.class_model}</span>
                                            </Fragment>
                                          </p>

                                          {isCarRentalOptions(carRentalDetail.count_option) && (
                                            <p>
                                              ■オプション：
                                              {carRentalDetail.count_option.anshin.used && <span
                                                className="mr-20">{getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin.option_name, 'label')}</span>}
                                              {carRentalDetail.count_option.anshin_premium.used && <span
                                                className="mr-20">{getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin.option_name, 'label')}</span>}
                                              {carRentalDetail.count_option.baby_seat.num > 0 && <span
                                                className="mr-20">ベビーシート×{carRentalDetail.count_option.baby_seat.num}</span>}
                                              {carRentalDetail.count_option.child_seat.num > 0 && <span
                                                className="mr-20">チャイルドシート×{carRentalDetail.count_option.child_seat.num}</span>}
                                              {carRentalDetail.count_option.junior_seat.num > 0 && <span
                                                className="mr-20">ジュニアシート×{carRentalDetail.count_option.junior_seat.num}</span>}
                                            </p>)}
                                        </>

                                      ))}
                                  </div>
                                </td>
                              </>
                                    : scheduleData.first.type === "hotel" ?
                                      <>
                                        <td
                                          className="table-responsive-group-heading table-bgc-light_gray w-140-md ta-c fw-b">
                                宿泊
                              </td>
                                <td>
                                  <p>{scheduleData.first.data.detail.hotel_name}</p>
                                  <p>■部屋タイプ：{scheduleData.first.data.roomData.room_name}</p>
                                  <p>■部屋数：{showRoomText(scheduleData.first.data.plan)}</p>
                                  <p>■プラン：{scheduleData.first.data.plan.room_plan_detail.plan_name}</p>
                                  <p>
                                    ■食事条件：{showMealLabel(
                                      scheduleData.first.data.plan.room_plan_detail.meal_breakfast,
                                      scheduleData.first.data.plan.room_plan_detail.meal_lunch,
                                      scheduleData.first.data.plan.room_plan_detail.meal_dinner
                                    )}
                                  </p>
                                </td>
                              </>
                            :<></>
                          }
                      </tr>
                    </>:<></>}
                    {scheduleData.first.data !== undefined ? <>
                    {scheduleData.rows?.map((row, index) => (
                      <Fragment key={index}>

                        {row.type === "flight" ?
                          row.data.entity.map((d:any,i:number) => {
                            return(
                            <Fragment key={i}>
                              <tr className="table-responsive-group">
                                <td className="table-responsive-group-heading table-bgc-light_gray ta-c fw-b">
                                  フライト{d?.num && toCircled(Number(d.num))}
                                </td>
                                <td>
                                  <p>
                                    {/* {row.data.airline + d.air_sub_no}（{getFlightCodeLabel(d?.depapo)} {getFlightTime(d?.dep)} - {getFlightCodeLabel(d?.arrapo)} {getFlightTime(d?.arv)}） */}
                                    { getFlightNumberForDisplayExcludeSuffix({ airline: row.data.airline, entity: [{ air_code: d.air_code, air_sub_no: d.air_sub_no }]  }) }（{getFlightCodeLabel(d?.depapo)} {getFlightTime(d?.dep)} - {getFlightCodeLabel(d?.arrapo)} {getFlightTime(d?.arv)}）
                                  </p>
                                  <p>■{getFlightClassLabel(d?.class_code)}</p>
                                  {getConnectionFlights(d.FReservationConnectionFlight, []).map((connectionFlight) => (
                                    <>
                                      <p>
                                        { getFlightNumberForDisplayExcludeSuffix({airline: row.data.airline, entity: [{air_code: connectionFlight.air_code, air_sub_no: connectionFlight.air_sub_no}]}) }（{getFlightCodeLabel(connectionFlight.depapo)} {getFlightTime(connectionFlight.dep)} - {getFlightCodeLabel(connectionFlight.arrapo)} {getFlightTime(connectionFlight.arv)}）
                                      </p>
                                      <p>■{getFlightClassLabel(connectionFlight.class_code)}</p>
                                    </>
                                  ))}
                                  <p>■内訳：{getFlightPeopleConfirmLabel(planSelectFormData.searchParams.roomDetail)}<br/>※12歳以上は大人運賃が適用されます。</p>
                                </td>
                              </tr>
                            </Fragment>
                            )
                          })
                        :

                        <tr className="table-responsive-group">
                          {row.type === "activity" ?
                              <>
                                <td className="table-responsive-group-heading table-bgc-light_gray ta-c fw-b">
                                  基本プラン
                                </td>
                                <td>
                                  <p className="mb-5">{dateFormat(row.data.date, "YYYY年MM月DD日(ddd)")} {toDisplayTime(row.data.time)}</p>
                                  <p>
                                    {row.data.plan_info.plan_name}
                                  </p>
                                  <p>
                                    主催会社：{row.data.plan_info.supplier_name}
                                  </p>
                                </td>
                              </>
                          : row.type === "options" ?
                              <>
                                <td className="table-responsive-group-heading table-bgc-light_gray ta-c fw-b">
                                  追加オプション
                                </td>
                                <td>
                                  <p className="mb-5">{dateFormat(row.data.date, "YYYY年MM月DD日(ddd)")} {toDisplayTime(row.data.time)}</p>
                                  <p>
                                    {row.data.plan_info.plan_name}
                                  </p>
                                  <p>
                                    主催会社：{row.data.plan_info.supplier_name}
                                  </p>
                                </td>
                              </>
                          : row.type === "carRental" && scheduleData.date === row.data.departure_date ?
                              <>
                                <td className="table-responsive-group-heading table-bgc-light_gray ta-c fw-b">
                                  レンタカー<br /> (貸出)
                                </td>
                                <td>
                                  <div className="mb-10">
                                    <p>{dateFormat(row.data.departure_date, "YYYY年MM月DD日(ddd)")} {row.data.departure_time}</p>
                                    <p>{row.data.departure_office_label}</p>
                                  </div>
                                  <div>
                                      {row.data.detail.map((carRentalDetail: any, cIndex: any) => (
                                        <>
                                          <p>
                                            ■プラン：
                                            <Fragment key={cIndex}>
                                              <span className="mr-20">{carRentalDetail.planDetail.plan_name}</span>
                                            </Fragment>
                                          </p>
                                          <p>
                                            ■車種：
                                            <Fragment key={cIndex}>
                                              <span className="mr-20">{carRentalDetail.planDetail.class_model}</span>
                                            </Fragment>
                                          </p>
                                          {isCarRentalOptions(carRentalDetail.count_option) && (
                                            <p>
                                              ■オプション：
                                              {carRentalDetail.count_option.anshin.used && <span
                                                className="mr-20">{getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin.option_name, 'label')}</span>}
                                              {carRentalDetail.count_option.anshin_premium.used && <span
                                                className="mr-20">{getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin_premium.option_name, 'label')}</span>}
                                              {carRentalDetail.count_option.baby_seat.num > 0 && <span
                                                className="mr-20">ベビーシート×{carRentalDetail.count_option.baby_seat.num}</span>}
                                              {carRentalDetail.count_option.child_seat.num > 0 && <span
                                                className="mr-20">チャイルドシート×{carRentalDetail.count_option.child_seat.num}</span>}
                                              {carRentalDetail.count_option.junior_seat.num > 0 && <span
                                                className="mr-20">ジュニアシート×{carRentalDetail.count_option.junior_seat.num}</span>}
                                            </p>)}
                                        </>
                                      ))}
                                  </div>
                                </td>
                              </>
                                : row.type === "carRental" && scheduleData.date === row.data.return_date ?
                                  <>
                                    <td className="table-responsive-group-heading table-bgc-light_gray ta-c fw-b">
                                  レンタカー<br /> (返却)
                                </td>
                                <td>
                                  <div className="mb-10">
                                    <p>{dateFormat(row.data.return_date, "YYYY年MM月DD日(ddd)")} {row.data.return_time}</p>
                                    <p>{row.data.return_office_label}</p>
                                  </div>
                                  <div>
                                      {row.data.detail.map((carRentalDetail: any, cIndex: any) => (
                                        <>
                                          <p>
                                            ■プラン：
                                            <Fragment key={cIndex}>
                                              <span className="mr-20">{carRentalDetail.planDetail.plan_name}</span>
                                            </Fragment>
                                          </p>
                                          <p>
                                            ■車種：
                                            <Fragment key={cIndex}>
                                              <span className="mr-20">{carRentalDetail.planDetail.class_model}</span>
                                            </Fragment>
                                          </p>
                                          {isCarRentalOptions(carRentalDetail.count_option) && (
                                            <p>
                                              ■オプション：
                                              {carRentalDetail.count_option.anshin.used && <span
                                                className="mr-20">{getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin.option_name, 'label')}</span>}
                                              {carRentalDetail.count_option.anshin_premium.used && <span
                                                className="mr-20">{getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin_premium.option_name, 'label')}</span>}
                                              {carRentalDetail.count_option.baby_seat.num > 0 && <span
                                                className="mr-20">ベビーシート×{carRentalDetail.count_option.baby_seat.num}</span>}
                                              {carRentalDetail.count_option.child_seat.num > 0 && <span
                                                className="mr-20">チャイルドシート×{carRentalDetail.count_option.child_seat.num}</span>}
                                              {carRentalDetail.count_option.junior_seat.num > 0 && <span
                                                className="mr-20">ジュニアシート×{carRentalDetail.count_option.junior_seat.num}</span>}
                                            </p>)}
                                        </>
                                      ))}
                                  </div>
                                </td>
                                  </>
                                  : row.type === "hotel" ?
                                    <>
                                      <td className="table-responsive-group-heading table-bgc-light_gray ta-c fw-b">
                                      宿泊
                                </td>
                                <td>
                                  <p>{row.data.detail.hotel_name}</p>
                                  <p>■部屋タイプ：{row.data.roomData.room_name}</p>
                                  <p>■部屋数：{showRoomText(row.data.plan)}</p>
                                  <p>■プラン：{row.data.plan.room_plan_detail.plan_name}</p>
                                  <p>
                                    ■食事条件：{showMealLabel(
                                      row.data.plan.room_plan_detail.meal_breakfast,
                                      row.data.plan.room_plan_detail.meal_lunch,
                                      row.data.plan.room_plan_detail.meal_dinner
                                    )}
                                  </p>
                                </td>
                            </>
                            :<></>
                          }
                        </tr>
                        }
                      </Fragment>
                    ))}
                    </>:<></>}
                  </Fragment>
                ))}
              </>:<></>}
            </tbody>
          </table>
        </div>
      </section> 
    </>
  );
};

export default SectionDetail;
