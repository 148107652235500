import React from "react";
import {FieldError, RegisterOptions, UseFormRegister} from "react-hook-form";
import InputForRadioAndCheckbox from "./InputForRadioAndCheckbox";

type InputProps = {
  checkboxes: { id?: string; label: string; value: string }[];
  className?: string;
  name: string;
  options: RegisterOptions;
  register: UseFormRegister<any>; // todo: どこかで定義したい
  showLabel?: boolean;
  useCheckBoxIdToName?:boolean;
  error?: FieldError;
};

export const InputCheckbox: React.VFC<InputProps> = (props) => {
  const { checkboxes, className, name, options, register, showLabel, useCheckBoxIdToName, error } = props;
    
  return (
    <>
      {checkboxes.map((checkbox, index) => {
        return (
            <React.Fragment key={checkbox.value}>
              <InputForRadioAndCheckbox
                id={name + '.' + index}
                type={"checkbox"}
                value={checkbox.value}
                className={className}
                {...register(name, options)}
                style={{ visibility: "visible" }}
              />
              {showLabel && <label className="mr-16" htmlFor={name + '.' + index}>{checkbox.label}</label>}
            </React.Fragment>
        );
      })}
    </>
  );
};
