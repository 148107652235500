import React, {useEffect} from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography, CircularProgress,
} from "@material-ui/core";


//モーダルをインポート
import { 
  ConnectingFlightModal,
  FlightModalApi
} from "./../../modal"

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";

import LoadingOverlay from 'react-loading-overlay-ts';

// lib
import { 
  getFlightCodeLabel,
  getFlightCodeByLabel, 
  getFlightTime,
  getAirLineNameInJalGroup,
  getAirLineNameInAnaGroup,
  getAirLineLogo,
  getFlightNumberForDisplay
} from "../../../utils/flight";
import dayjs from "dayjs";

// types
import { 
  Flights,
  FlightType,
  FReservationFlightEntity,
  FlightEntity 
} from "../../../types/Flight";
import { PlanSearch } from "../../../types/Search";

// config
import { airCode } from "../../../config/Airport";
import {
  currentFlightEntity,
  flightsEntity,
  FReservationConnectionFlight,
  FReservationFlight,
  FReservationFlights
} from "../../../types/FlightV2";
import {getCurrentFlight, getFlightClassLabel, isConnection, lastCurrentFlight} from "../../../utils/flightV2";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {makeStyles} from "@material-ui/core/styles";

type Props = {
  planSelectFormData: PlanSelectFormData
  search: PlanSearch | undefined,
  canSelect: boolean,
  isLoading: {
    all: boolean,
    ANA: boolean,
    JAL: boolean
  },
  flights: flightsEntity,
  currentFlightEntities: currentFlightEntity[],
  anaHasNoFlight: boolean,
  jalHasNoFlight: boolean,
  changeAirportCallback: (airport:string) => void,
  changeFlightCallback: (airline:string, action:string, currentFlights:FReservationFlight[], airport: string | null | undefined ) => void,
  profitRate: string | undefined,
}



// flight tab loadingのスタイル
const useStyles = makeStyles((theme) => ({
  flightTabLoading: {
    marginLeft: '10px',
    verticalAlign: 'middle',
    color: '#1A3268'
  },
}));

const SectionFlight: React.FC<Props> = ({
  planSelectFormData,
  search,
  canSelect,
  isLoading,
  flights,
  currentFlightEntities,
  anaHasNoFlight,
  jalHasNoFlight,
  changeAirportCallback,
  changeFlightCallback,
  profitRate,
}) => {
  const loadingStyle = useStyles();


  // 乗継便の到着時間を取得
  const getArrivalFlight = (flights:FReservationFlight[]): FReservationConnectionFlight | undefined  => {
    if(flights && flights.length > 0) {
      const lastFlight = lastCurrentFlight(flights[0].FReservationConnectionFlight);
      if (lastFlight.length > 0) {
        return lastFlight[0];
      }
    }
    return undefined;
  }


  const airlines = (
    name: string, // ANA,JAL,etc..
    index: number,
    logo: string,
    inbound: FReservationFlight[] | undefined,
    outbound: FReservationFlight[] | undefined,
  ) => {
    return {
      name,
      index,
      logo,
      inbound,
      outbound
    };
  };

  const currentAirlinesFlight = [
    airlines(
      "ANA",
      0,
      require("../../../img/share/flight/logo_ana.png").default,
      getCurrentFlight(currentFlightEntities,"ANA", "inbound"),
      getCurrentFlight(currentFlightEntities,"ANA", "outbound"),
    ),
    airlines(
      "JAL",
      1,
      require("../../../img/share/flight/logo_jal.png").default,
      getCurrentFlight(currentFlightEntities,"JAL", "inbound"),
      getCurrentFlight(currentFlightEntities,"JAL", "outbound"),
    ),
  ];

  //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  //モーダル初期表示ページを設定
  const [step, setStep] = React.useState(1);
  const stepHandle = (step: number) => {
    setStep(step)
  }

  // air_sub_no
  const [currentSubNumber, setSubNumber] = React.useState('1');

  const [currentFlights, setCurrentFlights] = React.useState<FlightType>();
  
  // モーダルのステータスを追加する
  const [connectingFlightModal, setConnectingFlightModal] = React.useState(false);
  const [flightModal, setFlightModal] = React.useState(false);

  // send flight data
  const [entities, setEntities] = React.useState<FReservationFlight[]>();

  // 乗継便の詳細モーダル
  const handleConnectingDetailModal = (airline:string, travelType:string, _flights:FReservationFlight[] | undefined) => {
    if(_flights) {
      setCurrentFlights({
        ...currentFlights,
        page: "top",
        airline: airline,
        currentNumbers: _flights.map((f) => f.air_sub_no),
        action: travelType,
        flight: _flights
      })
      setEntities(_flights)
    }
    setConnectingFlightModal(!connectingFlightModal)
  }

  // モーダルのhandleを追加する
  const handleConnectingFlightModal = () => {
    setConnectingFlightModal(!connectingFlightModal)
  }

  // section onclick button event
  const handleButtonOnClick = (airline:string, travelType:string, _flights:FReservationFlight[] | undefined) => {
    if(_flights) {
      setCurrentFlights({
        ...currentFlights,
        page: "top",
        airline: airline,
        currentNumbers: _flights.map((f) => f.air_sub_no),
        action: travelType,
        flight: _flights
      })
      setFlightModal(!flightModal)
    }
  } 

  const modalCallback = () => {
    setFlightModal(!flightModal)
  }

  const handleFlightSelectCallback = (airline:string, action:string, currentFlights: FReservationFlight[], airport: string | null | undefined ) => {
    changeFlightCallback(airline, action, currentFlights, airport)
    setFlightModal(!flightModal)
  }

  //------------------------------------------------------------------

  // ANAが就航していない区間の場合はJALのタブを初期状態にする
  const [value, setValue] = React.useState(anaHasNoFlight ? 1 : 0);
  useEffect(() => {
    if (anaHasNoFlight) {
      setValue(0)
    }
    if (anaHasNoFlight && !jalHasNoFlight) {
      setValue(1)
    }
    
  }, [anaHasNoFlight, jalHasNoFlight])

  //フライトタブ
  const handleChangeTabFlight = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);

    // タブ切り替え時に初期データの航空会社も切り替える
    changeAirportCallback(newValue === 0 ? "ANA" : "JAL");
    // changeFlightCallback(newValue === 0 ? "ANA" : "JAL", "outbound", currentCheapestFlights, false, true);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


  return (
    <>
      <section>
          <div className="wrap-content-900">
            { canSelect == false ?
               <>
                <h2 className="heading-2">フライトの選択</h2>
                <div className="box-white large mt-24">
                  <p className="c-light_gray fw-b">
                    基本プランの日程を設定後に選択いただけます。
                  </p>
                </div> 
              </>
              : canSelect ?
              <>
                <h2 className="heading-2">フライトの選択</h2>
                <p className="fz-16 fw-b mb-8 mb-20-md">
                  ご希望のフライトをお選びください
                </p>
                <ul className="list-note mb-24">
                  <li key={"fsection_desc1"} >往路・復路で異なる航空会社の組み合わせはできません。全ての便を同一の航空会社で選択してください。</li>
                  <li key={"fsection_desc2"} >初日または最終日に基本プランを利用する場合は、接続時間に余裕を持ってフライトをご指定ください</li>
                </ul>
              </>:<></>
            }
          </div>

        { canSelect  ?
            <div className="wrap-content-900-no_padding">
            {/* フライト選択後 */}
            <div className="mt-24">
              <div className="tab-flight">
                <Box className="tab-flight-content">
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChangeTabFlight}
                      className="tab-flight-content-list"
                      variant="fullWidth"
                      TabIndicatorProps={{ style: { background: "none" } }}
                    >
                      <Tab
                        label="ANAから選択"
                        className="tab-flight-content-list-item"
                        style={anaHasNoFlight || isLoading.JAL ? { color: "lightgray" } : {}}
                        disabled={anaHasNoFlight || isLoading.ANA}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={<>
                          <span style={{display:'inline-block'}}>
                            JALから選択
                            {isLoading.all && isLoading.JAL && (<CircularProgress className={loadingStyle.flightTabLoading} size={10} color="primary" />)}
                          </span>
                        </>}
                        className="tab-flight-content-list-item "
                        style={jalHasNoFlight || isLoading.JAL ? { color: "lightgray" } : {}}
                        disabled={jalHasNoFlight || isLoading.JAL}
                      />
                    </Tabs>
                  </Box>
                  <div className="tab-flight-inner">
                    { currentAirlinesFlight.map((airline, index) => (
                      <TabPanel key={"tabp"+airline.index} value={value} index={airline.index}>
                        {
                          (airline.name === "ANA" && anaHasNoFlight) || (airline.name === "JAL" && jalHasNoFlight) ?
                            <div className="tab-flight-content-detail">
                              <div className="tab-flight-content-detail-list">
                                <p className="fz-16 fw-b pb-10 ta-c">当該区間では、運航している便がございません。</p>
                              </div>
                            </div>
                            :
                              <LoadingOverlay
                                  active={airline.name === "ANA"? isLoading.ANA : isLoading.JAL}
                                  spinner
                              >
                            <div className="tab-flight-content-detail">
                              <div className="tab-flight-content-detail-list">
                                { airline.inbound ?
                                <div className="tab-flight-content-detail-list-item">
                                  <div className="box-flight-select">
                                    <div className="box-flight-select-heading">
                                      <>
                                        <ul className="box-flight-select-heading-label">
                                          <li key={"inbound_" + airline.name + index} className="box-flight-select-heading-label-item outbound">
                                            往路
                                          </li>
                                          { isConnection(airline.inbound[0]) ?
                                            <li key={"ic" + airline.name + index} className="box-flight-select-heading-label-item connecting">
                                              乗継便
                                            </li>
                                          : "" }
                                        </ul>
                                      </>
                                      <p className="box-flight-select-heading-text">
                                        {getFlightClassLabel(airline.inbound[0].class_code) ?
                                          <span className="class-code">{getFlightClassLabel(airline.inbound[0].class_code)}</span>
                                        : ""}
                                        <span>{dayjs(airline.inbound[0].date, "YYYYMMDD").format('YYYY年MM月DD日（ddd）')}</span>
                                      </p>
                                    </div>
      
                                    <div className="box-flight-select-detail">
                                      <div className="box-flight-select-detail-schedule">
                                        <div className="box-flight-select-detail-schedule-airline">
                                          <div className="mr-10">
                                            <p className="box-flight-select-detail-schedule-airline-logo">
                                              <img
                                                src={ getAirLineLogo(airline.name, airline.inbound[0].air_code, airline.inbound[0].air_sub_no) }
                                                alt="フライト：住路"
                                              />
                                            </p>
                                            <p className="box-flight-select-detail-schedule-airline-label">
                                              { getFlightNumberForDisplay(airline.name, airline.inbound[0].air_code, airline.inbound[0].air_sub_no) }
                                            </p>
                                          </div>
      
                                          <div>
                                            <ul className="box-flight-select-detail-schedule-airline-time">
                                              <li key={"time_dep"+index} className="box-flight-select-detail-schedule-airline-time-item">
                                                <p className="box-flight-select-detail-schedule-airline-time-item-heading">
                                                {/* { r.inbound?.connection ? getFlightTime(getArrivalFlight(r.name, "inbound")?.dep) : getFlightTime(r.inbound?.dep)} */}
                                                {getFlightTime(airline.inbound[0].dep)}
                                                </p>
                                                <p className="box-flight-select-detail-schedule-airline-time-item-label">
                                                  {getFlightCodeLabel(airline.inbound[0].depapo)}
                                                </p>
                                              </li>
                                              <li key={"time_arv"+index} className="box-flight-select-detail-schedule-airline-time-item">
                                                <p className="box-flight-select-detail-schedule-airline-time-item-heading">
                                                  { isConnection(airline.inbound[0]) ? getFlightTime(getArrivalFlight(airline.inbound)?.arv) : getFlightTime(airline.inbound[0].arv)}
                                                </p>
                                                <p className="box-flight-select-detail-schedule-airline-time-item-label">
                                                  { isConnection(airline.inbound[0]) ? getFlightCodeLabel(getArrivalFlight(airline.inbound)?.arrapo) : getFlightCodeLabel(airline.inbound[0].arrapo) }
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
      
                                      <div className="box-flight-select-detail-status">
                                        {/* inbound */}
                                        <button
                                          className="button-border-medium"
                                          onClick={() => handleButtonOnClick(airline.name, "inbound", airline.inbound)}
                                          disabled={airline.name === "ANA"? isLoading.ANA : isLoading.JAL}
                                        >
                                          変更する
                                        </button>
                                      </div>
                                    </div>
                                    { isConnection(airline.inbound[0]) ?
                                    <p>
                                      <button
                                        onClick={() => handleConnectingDetailModal(airline.name, "inbound", airline.inbound)}
                                        className="button-clear-blue-underline fz-13 fw-b p-0"
                                      >
                                        乗継便詳細を見る
                                      </button>
                                    </p>
                                    : '' }
                                  </div>
                                </div> : ''
                                }
      
                                { airline.outbound ?
                                <div className="tab-flight-content-detail-list-item">
                                  <div className="box-flight-select">
                                    <div className="box-flight-select-heading">
                                      <>
                                        <ul className="box-flight-select-heading-label">
                                          <li key={"outbound_" + airline.name + index} className="box-flight-select-heading-label-item outbound">
                                            復路
                                          </li>
                                          { isConnection(airline.outbound[0]) ?
                                            <li key={"oc" + airline.name + index} className="box-flight-select-heading-label-item connecting">
                                              乗継便
                                            </li>
                                          : "" }
                                        </ul>
                                      </>
                                      <p className="box-flight-select-heading-text">
                                        {getFlightClassLabel(airline.outbound[0].class_code) ?
                                          <span className="class-code">{getFlightClassLabel(airline.outbound[0].class_code)}</span>
                                        : ""}
                                        <span>{dayjs(airline.outbound[0].date, "YYYYMMDD").format('YYYY年MM月DD日（ddd）')}</span>
                                      </p>
                                    </div>
      
                                    <div className="box-flight-select-detail">
                                      <div className="box-flight-select-detail-schedule">
                                        <div className="box-flight-select-detail-schedule-airline">
                                          <div className="mr-10">
                                            <p className="box-flight-select-detail-schedule-airline-logo">
                                              <img
                                                src={ getAirLineLogo(airline.name, airline.outbound[0].air_code, airline.outbound[0].air_sub_no) }
                                                alt="フライト：復路"
                                              />
                                            </p>
                                            <p className="box-flight-select-detail-schedule-airline-label">
                                              { getFlightNumberForDisplay(airline.name, airline.outbound[0].air_code, airline.outbound[0].air_sub_no) }
                                            </p>
                                          </div>
      
                                          <div>
                                            <ul className="box-flight-select-detail-schedule-airline-time">
                                              <li key={"otime_dep"+index} className="box-flight-select-detail-schedule-airline-time-item">
                                                <p className="box-flight-select-detail-schedule-airline-time-item-heading">
                                                  {getFlightTime(airline.outbound[0].dep)}
                                                </p>
                                                <p className="box-flight-select-detail-schedule-airline-time-item-label">
                                                  {getFlightCodeLabel(airline.outbound[0].depapo)}
                                                </p>
                                              </li>
                                              <li key={"otime_arv"+index} className="box-flight-select-detail-schedule-airline-time-item">
                                                <p className="box-flight-select-detail-schedule-airline-time-item-heading">
                                                { isConnection(airline.outbound[0]) ? getFlightTime(getArrivalFlight(airline.outbound)?.arv) : getFlightTime(airline.outbound[0].arv)}
                                                </p>
                                                <p className="box-flight-select-detail-schedule-airline-time-item-label">
                                                { isConnection(airline.outbound[0]) ? getFlightCodeLabel(getArrivalFlight(airline.outbound)?.arrapo) : getFlightCodeLabel(airline.outbound[0].arrapo) }
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
      
                                      <div className="box-flight-select-detail-status">
                                        {/* outbound */}
                                        <button
                                          className="button-border-medium"
                                          onClick={ () => handleButtonOnClick(airline.name, "outbound", airline.outbound) }
                                          disabled={airline.name === "ANA"? isLoading.ANA : isLoading.JAL}
                                        >
                                          変更する
                                        </button>
                                      </div>
                                    </div>
                                    { isConnection(airline.outbound[0]) ?
                                      <p>
                                        <button
                                          onClick={() => handleConnectingDetailModal(airline.name, "outbound", airline.outbound)}
                                          className="button-clear-blue-underline fz-13 fw-b p-0"
                                        >
                                          乗継便詳細を見る
                                        </button>
                                      </p>
                                      : '' }
                                  </div>
                                </div> : ''
                                }   
                              </div>
                            </div>
                            </LoadingOverlay>
                        }

                    </TabPanel>
                    )) }
                  </div>
                </Box>
              </div>
            </div>
          </div>
          :<></>
        }
      </section>


      {/* 乗継便確認 */}       
      <ConnectingFlightModal flights={entities} airline={currentFlights?.airline} isShow={connectingFlightModal} callback={handleConnectingFlightModal} />
      {/* モーダル：フライト選択 */}
      {<FlightModalApi planSelectFormData={planSelectFormData} profitRate={profitRate} pageRef="top" changeFlightCallback={handleFlightSelectCallback} search={search} currentFlights={currentFlights} changeAirportCallback={changeAirportCallback} isShow={flightModal} callback={modalCallback} />}
    </>
  );
};

export default SectionFlight;
