import React, {useContext, useEffect} from "react";

import { Link as Scroll } from 'react-scroll';

//Mui
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
}from "@material-ui/core/";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";
import { PartsTutorialButton } from "./parts";

const Faq: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      tagManagerContext.pageView( "よくあるご質問")
    }, 200)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])
  
  return (
    <MainTemplate title="よくあるご質問" planSelectFormData={undefined}>
        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">よくあるご質問</h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">

            {/* ローカルナビ（ここから） */}
            <section className="parts-loaclnavi mt-32-md mt-24 pt-16">

              {/* 操作ガイドボタン */}
              <PartsTutorialButton value="component-content" />

              <h2 className="heading-2 mt-0 mb-16 mb-24-md">目次</h2>
              <div className="card large-side-medium">
                <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                  <li className="parts-loaclnavi-list-item">
                    <Scroll to="sec_01" className="parts-loaclnavi-list-item-link" smooth={true}>カスタマーサポートについて</Scroll>
                  </li>

                  <li className="parts-loaclnavi-list-item">
                    <Scroll to="sec_02" className="parts-loaclnavi-list-item-link" smooth={true}>予約申込について</Scroll>
                  </li>

                  <li className="parts-loaclnavi-list-item">
                    <Scroll to="sec_03" className="parts-loaclnavi-list-item-link" smooth={true}>支払いについて</Scroll>
                  </li>

                  <li className="parts-loaclnavi-list-item">
                    <Scroll to="sec_04" className="parts-loaclnavi-list-item-link" smooth={true}>予約後の流れについて</Scroll>
                  </li>

                  <li className="parts-loaclnavi-list-item">
                    <Scroll to="sec_05" className="parts-loaclnavi-list-item-link" smooth={true}>飛行機について</Scroll>
                  </li>

                  <li className="parts-loaclnavi-list-item">
                    <Scroll to="sec_06" className="parts-loaclnavi-list-item-link" smooth={true}>宿泊施設について</Scroll>
                  </li>

                  <li className="parts-loaclnavi-list-item">
                    <Scroll to="sec_07" className="parts-loaclnavi-list-item-link" smooth={true}>レンタカーについて</Scroll>
                  </li>

                  <li className="parts-loaclnavi-list-item">
                    <Scroll to="sec_08" className="parts-loaclnavi-list-item-link" smooth={true}>その他</Scroll>
                  </li>
                  
                </ul>
              </div>
            </section>
            {/* ローカルナビ（ここまで） */}


            {/* 1.カスタマーサポートについて　ここから */}    

            {/* section: 1.カスタマーサポートについて */}
            <section id="sec_01" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">1.カスタマーサポートについて</h2>

              <div className="faq-list">
                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">カスタマーサポートの営業時間について教えてください。</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                       <p>デジタルDMOカスタマーサポート</p> 
                       <p>【営業時間】月曜～金曜（10:00～18:00）　土曜（10:00～14:00）　日曜・祝祭日・年末年始休み</p>
                       <p>【電話番号】<a href="tel:098-917-2593">098-917-2593</a></p>
                       <p>【メール】<a href="mailto:digitaldmoplatform_cs@otsinternational.jp">digitaldmoplatform_cs@otsinternational.jp</a></p>
                      
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">カスタマーサポートで予約を取ってもらえますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      カスタマーサポートでは予約の取り方（操作方法）についてのご案内や（台風など）イレギュラー時の対応は行っておりますが、電話・メールでのご予約はお受けしておりません。オンラインでご予約ください。
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </section>

            {/* 1.カスタマーサポートについて　ここまで */}


            {/* section: 2.予約申込について */}
            <section id="sec_02" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">2.予約申込について</h2>
              <div className="faq-list mt-32">
                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">何日前まで予約受付可能ですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      ご旅行出発の5日前まで24時間オンラインにてご予約が可能です。<br />
                      なお、空席・空室状況に応じて価格が変動する旅行商品となっておりますので、早めのご予約がお得になっております。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">電話での予約は出来ますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      ご予約はウェブサイト上でのみのお取り扱いとなります。<br />
                      大変恐縮ではございますがカスタマーサポートでのご予約は承っておりませんので、何卒ご理解賜りますようお願い申し上げます。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">キャンセル待ちはできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      お受けしておりません。WEBサイトでご選択可能な、空席・空室のある商品をご予約ください。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">一度に予約できる最大人数は？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p className="mb-8">大人、子供、同伴幼児あわせて9名様までご予約可能です。</p>
                      <p className="mb-8">10名様以上の場合、改めて新規でのご予約お願いいたします。</p>
                      <ul className="list-note">
                        <li>時価販売商品のため、同一内容でもご予約のタイミングにより旅行代金が異なる場合がございます。</li>
                        <li>1回目の予約完了後、2回目以降の予約が満室・満席となり、ご予約いただけない可能性がございます。</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">往路と復路で利用空港を変えての予約はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      エリアによっては、利用空港を変えることが出来ます。（一部路線のみ）<br />
                      例）関東エリア　⇒　行きは羽田空港、帰りは成田空港を利用<br />
                      　　関西エリア　⇒　行きは伊丹空港、帰りは関西または神戸空港を利用
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                <Accordion className="faq-list-item-accordion">
                  <AccordionSummary className="faq-list-item-accordion-heading">
                    <div className="faq-list-item-accordion-heading-text">ホテルの食事は含まれていますか？</div>
                    <div className="faq-list-item-accordion-heading-icon"></div>
                  </AccordionSummary>
                  <AccordionDetails className="faq-list-item-accordion-detail">
                    ご予約いただく宿泊施設や宿泊プランによって食事条件が異なります。<br />
                    ご予約の際にご宿泊を希望されるホテルのプラン詳細画面をご確認ください。すでにご予約が完了している場合は、予約完了メールまたは予約確認画面をご確認ください。
                  </AccordionDetails>
                </Accordion>
              </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">発着地が異なる友人と現地で合流します。ホテルは同室で予約できますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      それぞれご予約完了まで操作していただきますので、当ツアー商品では同じお部屋の手配はできかねます。
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </section>
            {/* 2.予約申込について　ここまで */}


            {/* section: 3.予約申込について */}
             <section id="sec_03" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">3.支払いについて</h2>
              <div className="faq-list mt-32">
                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">どのような支払方法がありますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      クレジットカード（VISA・Master・JCB・AMEX・ダイナース）でのお支払いが可能です。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">支払い期限の延長はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      ご予約と同時にクレジットカード決済を行いますので、お支払い期限は変更できかねます。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">クレジットカード会社からの引き落としはいつ頃になりますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      正確な引落日につきましては弊社ではわかりかねます。お支払い手続きをされましたクレジットカード会社へ直接お問い合わせください。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">クレジットカード決済時のセキュリティコードとは何ですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>セキュリティコードとは、クレジットカードによる決済処理の際に、クレジットカード番号とは別のセキュリティコードを入力する事で、不正利用リスクの軽減を図るための番号です。</p>
                      <p className="mb-12">セキュリティコードは、クレジットカード裏面の署名欄に記載されている3桁の数字です。なお、セキュリティコードが、7桁の場合は最後の3桁を入力ください。</p>
                      <p>
                        <a href="https://dp.otsfreestyle.jp/tour/support/images/creditcard-01.jpg" target="_blank" rel="noopener noreferrer">https://dp.otsfreestyle.jp/tour/support/images/creditcard-01.jpg</a>
                      </p>
                      <p className="mb-16">
                        <a href="https://dp.otsfreestyle.jp/tour/support/images/creditcard-02.jpg" target="_blank" rel="noopener noreferrer">https://dp.otsfreestyle.jp/tour/support/images/creditcard-02.jpg</a>
                      </p>

                      <p>カードの裏面のご署名欄に印刷された数字の末尾3桁がセキュリティコードです。</p>
                      <p>
                        <a href="https://dp.otsfreestyle.jp/tour/support/images/creditcard-03.jpg" target="_blank" rel="noopener noreferrer">https://dp.otsfreestyle.jp/tour/support/images/creditcard-03.jpg</a>
                      </p>
                      
                      <p>カードによっては、表面のクレジットカード番号右上または左上に4桁で記載されていることもございます。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">クレジットカード決済をしたのですが、別のクレジットカードに変更できますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      クレジットカードで決済いただいた後は、別のクレジットカードに変更いたしかねます。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">クレジットカード決済では、申込者本人以外のカードを使用することはできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      できかねます。必ずお申込者ご本人のクレジットカードで決済してください。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">クレジットカードのエラーが出ます。</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p className="mb-8">エラーになる事由として考えられること</p>
                      <p>■入力内容に誤りがある場合■<br /></p>
                      <ul className="list-dot">
                        <li>カード番号</li>
                        <li>有効期限</li>
                        <li>確認番号（セキュリティコード）</li>
                        <li>支払い回数</li>
                      </ul>

                      <p className="mt-16">■他のカードをご利用いただく必要がある場合■</p>
                      <ul className="list-dot">
                        <li>プリペイドカード</li>
                        <li>名義が異なる</li>
                      </ul>
                        
                      <p className="mt-16">■クレジットカード会社へご確認いただく必要がある場合■</p>
                      <ul className="list-dot">
                        <li>与信取得の判定が保留</li>
                      </ul>

                      <p className="mt-16">■クレジットカード会社へご確認いただくまたは他のカードをご利用いただく必要がある場合■</p>
                      <ul className="list-dot mb-8">
                        <li>カード自体が利用できない</li>
                        <li>利用回数に制限がある</li>
                        <li>限度額を超過している</li>
                      </ul>
                      <ul className="list-note ">
                        <li>クレジットカード会社への連絡はご本人様からに限られておりますので何卒ご了承ください。</li>
                      </ul>

                    </AccordionDetails>
                  </Accordion>
                </div>

              </div>
            </section>
            {/* 3.予約申込について　ここまで */}


            {/* section: 4.予約後の流れについて */}
            <section id="sec_04" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">4.予約後の流れについて</h2>
              <div className="faq-list mt-32">
                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">航空券の受領方法を教えてください。</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      航空券はeチケットですので、メール添付にてお送りいたします。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">ｅチケットはいつ頃届きますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      ご出発の約3週間～1週間前までにメールにてお送りいたします。発券の都合上、異なる場合もございます。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">搭乗手続きはどのようにしたらいいですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p className="fw-b mb-8">【ANAグループ】</p>
                      <p className="mb-8">ANAより2023年3月31日をもってANAグループの国内線にて提供しているSKiPサービスが終了になるとの発表がございました。</p>
                      <p className="mb-8">2023年4月1日以降運航の、ANA（全日本空輸）、ADO（エア・ドゥ）、SNA（ソラシドエア）、SFJ（スターフライヤー）をご利用のお客様は、ご搭乗便出発の24時間前以降にご自身にて各航空会社のWebサイトからご搭乗手続き（オンラインチェックイン）をお願いいたします。搭乗日・便名・確認番号・搭乗者のお名前を入力の上、お手続きください。</p>
                      <p className="fw-b">■ オンラインチェックインの手順</p>
                      <ol className="mb-16">
                        <li>下記より各航空会社のWebサイトへアクセス</li>
                        <li>お客様のご予約情報(氏名、搭乗日、便名等)をご入力<br />※ご予約情報は弊社よりお渡しするeチケットをご確認ください。</li>
                        <li>座席指定(未指定の場合)</li>
                        <li>チェックイン完了後、QRコードを発行</li>
                      </ol>

                      <p className="mb-8">■ オンラインチェックイン時のご注意事項</p>
                      <ul className="list-dot mb-8">
                        <li>QRコードが表示されているeチケットをお持ちの場合でも、オンラインチェックインもしくは空港のカウンターにてチェックイン手続きが必要となります。</li>
                        <li>ご搭乗便出発の24時間前から20分前までにお済ませください。</li>
                        <li>旅行会社ではオンラインチェックイン手続きはお受けできません。</li>
                      </ul>
                      <p className="mb-16">※カウンターでお手続きをする場合は、混雑が予想されますので、時間に余裕をもって空港へお越しください。</p>

                      <p className="fw-b mb-8">■ 各航空会社のオンラインチェックインページは下記 よりご確認ください。</p>
                      <p className="mb-8">【各航空会社ホームページ】</p>
                      <p className="mb-16">
                        <a href="https://www.ana.co.jp/" target="_blank">ANA（全日本空輸）</a><br />
                        <a href="https://www.airdo.jp/" target="_blank">ADO（エア・ドゥ）</a><br />
                        <a href="https://www.solaseedair.jp/" target="_blank">SNA（ソラシドエア）</a><br />
                        <a href="https://www.starflyer.jp/" target="_blank">SFJ（スターフライヤー）</a>
                      </p>

                      <p className="fw-b mb-8">【JALグループ】</p>
                      <p className="mb-8">eチケットお客さま控えをご持参のうえ、航空会社カウンター、または自動チェックイン機にてご搭乗手続きをお願いいたします。</p>
                      <p className="mb-8">座席指定がお済で荷物をお預けにならないお客様は、直接保安検査場にお進みいただけます。</p>
                      <p className="mb-8">詳細は、下記をご確認ください。</p>
                      <p>▼JALタッチ＆ゴーサービス</p>
                      <p><a href="https://www.jal.co.jp/dom/boarding/touchandgo/" target="_blank">https://www.jal.co.jp/dom/boarding/touchandgo/</a></p>

                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">空港へは何分前までに行ったらいいですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>国内線は、ご出発の1時間前までに空港にお越しいただくようにご案内しております。</p>
                      <p>搭乗手続きを済ませて、ご出発の20分前までに保安検査場をご通過ください。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">予約内容の変更はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>ご予約完了後の内容変更（航空便・宿泊施設・日程などの変更）はお受けできません。一旦全てのご予約をキャンセルの上、再予約となります。なお、キャンセル日により所定のキャンセル料がかかる場合がございますのでご注意ください。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">参加者を変更することはできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p className="mb-8">
                        参加者（名前・年齢・性別）の変更はできません。一旦全てのご予約をキャンセルの上、再予約となりますので、ご予約時に正しいお名前・ご出発時の年齢・性別でご登録ください。
                      </p>
                      <ul className="list-note">
                        <li>キャンセル日により所定のキャンセル料がかかる場合がございますのでご注意ください。</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">キャンセル料金はいつからかかりますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      キャンセルポリシーをご確認ください。
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">予約のキャンセルはどのようにすればよいですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        ご予約確認ページにログインをして、キャンセルを行ってください。<br />
                        なお、参加人数の増減やお客様の交替はいたしかねます。<br />
                        お名前や年齢の訂正についてもご予約いただいたツアーの取消の後、再度新規でのご予約となります。<br />
                        すでに取消料対象期日の場合は、取消料が必要となります。<br />
                        また、再予約の時点でツアー予約の受付を終了している場合もございますので予めご了承ください。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">予約のキャンセルをしたいのですが、キャンセル受付時間に制限はありますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                    <p className="mb-8">24時間マイページからお客様ご自身でのキャンセル手続きが可能です。</p>
                      <p>予約詳細確認ページへのログイン</p>
                      <p className="mb-8"><a href="https://digitaldmoplatform.com/login/">https://digitaldmoplatform.com/login/</a></p>

                      <p className="mb-8">※マイページのログインには、旅行予約番号と予約申込時にご登録いただいたメールアドレスの入力が必要です。</p>
                      <p>旅行予約番号は予約完了メールにてご確認いただけます。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">キャンセル後の返金方法・時期について教えてください。</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>お申込み時にご利用されたクレジットカード会社を通してご返金いたします。詳細はクレジットカード会社にご確認ください。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </section>
            {/* 4.予約後の流れについて　ここまで */}


            {/* section: 5.飛行機について */}
            <section id="sec_05" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">5.飛行機について</h2>
              <div className="faq-list mt-32">
                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">搭乗日当日、空席があれば便を変更できますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>当社ツアーは包括旅行割引運賃の航空券を利用しており、航空便を変更することはできません。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">座席のグレードアップはできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>ANA プレミアムシート、JAL クラスJ・ファーストクラスは事前予約ができません。 搭乗日当日、空席がございましたら追加料金にて、グレードアップも可能です。航空会社カウンターにてご確認ください。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">座席指定はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        オンライン予約画面でのお座席の指定はできません。<br />
                        オンライン予約後、並び席で空きがあれば弊社にて事前に指定いたします。<br />
                        繁忙期や、飛行機の機材が小さいため指定枠が少ない等さまざまな要因により、座席指定ができない場合もございます。<br />
                        eチケットがお手元に届きましたら便名や座席をご確認ください。eチケットに座席の記載がない場合は、お客様ご自身で航空会社ホームページより座席指定を行っていただくか、当日空港のチェックイン機もしくはチェックインカウンターで座席指定をお願いいたします。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">マイレージは貯まりますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p className="mb-8">
                        ANA・JALマイレージカードをお持ちのお客様は通常マイルの50％のマイルが貯まります。<br />
                        当日空港にて、お客様ご自身で登録可能でございますが、事前に「お客様番号」をご連絡くださいましたら、弊社にて事前登録も承っております。
                      </p>
                      <ul className="list-note">
                        <li>ANAの提携航空会社のカードをお持ちの場合は、事前登録をお受けいたしかねます。当日空港にてご登録お願いいたします。</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">何歳から座席が必要ですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p className="mb-8">国内線は3歳から座席が必要です。0-2歳以下は大人の膝の上にお座りいただけます。</p>
                      <ul className="list-note">
                        <li>大人1人様につき1名のみ</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">那覇空港の乗継時間はどのくらい必要ですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        ANA・JALグループともに、那覇空港での乗継時間は最低30分です。<br />
                        その他の空港につきまして、各航空会社のホームページをご覧ください。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">手荷物に関してどのようなルールがありますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        手荷物に関しては、航空会社によってルールが異なります。
                      </p>
                      <p className="mb-16">航空会社のホームページをご確認ください。</p>
                      <p>▼ANA</p>
                      <p className="mb-16"><a href="https://www.ana.co.jp/ja/jp/domestic/prepare/baggage/" target="_blank" rel="noopener noreferrer">https://www.ana.co.jp/ja/jp/domestic/prepare/baggage/</a></p>

                      <p>▼JAL</p>
                      <p><a href="https://www.jal.co.jp/dom/baggage/?yclid=YJAD.1579247767.mukfmqedcq3s7WLQfTdGH21lOcQkxpp135hknZy7ZZXF5LDK8hG0yYS5O59Kbg–" target="_blank" rel="noopener noreferrer">https://www.jal.co.jp/dom/baggage/?yclid=YJAD.1579247767.mukfmqedcq3s7WLQfTdGH21lOcQkxpp135hknZy7ZZXF5LDK8hG0yYS5O59Kbg–</a></p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">妊娠していますが、飛行機に乗れますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>妊娠中のお客様もご搭乗いただけます。出産予定日を含め28日以内のお客様は診断書の提出が必要です。</p>
                      <p className="mb-16">航空会社のホームページをご確認ください。</p>
                      <p>▼ANA</p>
                      <p className="mb-16"><a href="https://www.ana.co.jp/ja/jp/serviceinfo/domestic/support/family/maternity.html" target="_blank" rel="noopener noreferrer">https://www.ana.co.jp/ja/jp/serviceinfo/domestic/support/family/maternity.html</a></p>

                      <p>▼JAL</p>
                      <p><a href="https://www.jal.co.jp/jp/ja/dom/support/smilesupport/mama.html" target="_blank" rel="noopener noreferrer">https://www.jal.co.jp/jp/ja/dom/support/smilesupport/mama.html</a></p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">赤ちゃんや幼児向けのサービスはありますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">

                      <p className="mb-16">航空会社のホームページをご確認ください。</p>
                      <p>▼ANA</p>
                      <p className="mb-16"><a href="https://www.ana.co.jp/ja/jp/serviceinfo/domestic/support/family/" target="_blank" rel="noopener noreferrer">https://www.ana.co.jp/ja/jp/serviceinfo/domestic/support/family/</a></p>

                      <p>▼JAL</p>
                      <p><a href="https://www.jal.co.jp/dom/support/smilesupport/baby.html" target="_blank" rel="noopener noreferrer">https://www.jal.co.jp/dom/support/smilesupport/baby.html</a></p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">機内食はありますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>国内線・普通席（クラスJを含む）をご利用の場合は、機内食はございません。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">心臓ペースメーカーを装着していますが、飛行機に乗ることはできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>ご搭乗いただけます。<br />
                          特別な手続きは必要ありませんが、スムーズに保安検査場を通過するために、事前に検査係員に心臓ペースメーカーを装着している旨をお知らせいただくか、ペースメーカー使用の証明書などをご用意いただくことをおすすめいたします。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">飛行機のみの予約はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>航空券のみのご予約は承っておりません。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">乗継便を利用しますが、途中の空港で外に出てもいいですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>乗り継ぎ空港での待ち時間が長い場合、空港内の一般エリアや、空港の外に出ることは可能ですが、乗継便への再搭乗の際に、改めて保安検査場を通過する必要がございます。</p>
                      <p>くれぐれも乗り遅れのないようにお気をつけください。</p>
                      <ul className="list-note">
                        <li>乗遅れの場合の振替便の提供、航空券代金の返金は行っておりません。</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">予定していた航空機に乗り遅れた場合、どうすればよいですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>航空券はご予約された日付・便のみに搭乗いただけます。万が一、お乗り遅れの場合、空港の搭乗予定航空会社係員へ連絡を願います。</p>
                      <p>以降の対応は航空会社の判断によりますが、新たに航空券を買い直す必要が発生する場合もございます。</p>
                      <p>また、お乗り遅れの場合、以降の区間を搭乗する権利も同時に消滅する場合もございます。</p>
                      <p>出発直前に航空券を購入する場合、当初お求めいただきました航空券代金に比べ、大変高額になる場合が多くございます。</p>
                      <p>お乗り遅れにならないように余裕をもって空港にお越しいただきますようお願いいたします。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">搭乗予定の航空機が欠航してしまいました。どうすればよいですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>悪天候（台風・雪・霧）や自然災害などによりお客様の搭乗予定便の運航が懸念されると航空会社が判断した場合、各航空会社WEBサイトにてお客様ご自身で、搭乗日の前日から翌日の便に振替手続きができます。再度航空券をご購入いただく必要はございません。</p>
                      <p>お手持ちの「eチケットお客様控」にて搭乗可能です。</p>
                      <ul className="list-note">
                        <li>お客様自身の判断で利用予定便に搭乗されなかった場合や欠航確定前のキャンセル等につきましては、お客様都合のキャンセル扱いとなり、既定の手数料を頂くこととなりますのでご注意ください。</li>
                      </ul>
                      <p>なお、AIRDO、ソラシドエア、スターフライヤーをご予約のお客様はWEBでの振替手続きはできません。振替の際は利用航空会社空港カウンターにてお手続きいただくか、弊社までご連絡ください。</p>
                      <p className="mb-16">当社営業時間外の運航状況はお客様各自でご確認ください。</p>

                      <p>▼ANA運行状況のご案内（国内線）</p>
                      <p className="mb-16"><a href="https://www.ana.co.jp/fs/dom/jp/" target="_blank" rel="noopener noreferrer">https://www.ana.co.jp/fs/dom/jp/</a></p>

                      <p>▼JAL運航状況のご案内（国内線）</p>
                      <p><a href="https://www.jal.co.jp/cms/other/ja/weather_info_dom.html" target="_blank" rel="noopener noreferrer">https://www.jal.co.jp/cms/other/ja/weather_info_dom.html</a></p>
                    </AccordionDetails>
                  </Accordion>
                </div>

              </div>
            </section>
            {/* 5.飛行機について　ここまで */}


            {/* section: 6.宿泊施設について */}
            <section id="sec_06" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">6.宿泊施設について</h2>
              <div className="faq-list mt-32">
                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">禁煙または喫煙部屋の指定はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        ご予約いただく宿泊施設や宿泊プランによって部屋タイプ条件が異なります。<br />
                        ご予約の際にご宿泊を希望されるホテルのプラン詳細画面をご確認ください。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">部屋の眺望の指定はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        ご予約いただく宿泊施設や宿泊プランによって部屋タイプ条件が異なります。<br />
                        ご予約の際にご宿泊を希望されるホテルのプラン詳細画面をご確認ください。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">和室・洋室など部屋タイプを選ぶことはできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        ご予約いただく宿泊施設や宿泊プランによって部屋タイプ条件が異なります。<br />
                        ご予約の際にご宿泊を希望されるホテルのプラン詳細画面をご確認ください。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">駐車代金はかかりますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        ご利用いただく宿泊施設によって、駐車代金がかかる場合もございます。<br />
                        駐車台数が限られている宿泊施設においては、宿泊施設側から有料駐車場へのご案内をさせて頂く場合がございます。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>


                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">宿泊のみの予約はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>宿泊のみのご予約は承っておりません。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </section>
            {/* 2.予約申込について　ここまで */}


            {/* section: 7.レンタカーについて */}
            <section id="sec_07" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">7.レンタカーについて</h2>
              <div className="faq-list mt-32">
                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">レンタカーは空港から借りられますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>【那覇空港（国内線）】</p>
                      <p>空港での配車は行っておりません。営業所で貸出手続きが必要となります。</p>
                      <p className="mb-16">空港1階到着ロビーを外に出て、レンタカー乗り場へお越しください。</p>

                      <p>【新石垣空港・宮古空港】</p>
                      <p>到着ロビーで各レンタカー会社の係員がお待ちしております。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">レンタカーの空港乗り捨てはできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>空港乗り捨てはできません。営業所へご返却ください。 空港と営業所間の送迎車・送迎バスが運行しております。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">送迎バスの出発時刻は決まっていますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>約15分おきの巡回運行になっております。ただし、道路状況により前後する場合がございます。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">空港からレンタカーの営業所までの移動時間はどのくらいですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <ul className="list-dot mb-8">
                        <li>那覇空港：約15-20分程度</li>
                        <li>新石垣空港：約5分</li>
                        <li>宮古空港：約5分</li>
                      </ul>
                      <ul className="list-note">
                        <li>道路状況により前後する場合がございます。</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">レンタカー会社の営業時間を教えてください。</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>OTSレンタカー</p>
                      <p>8：00～19：00 （DFS営業所は9：00～19：00）</p>
                      <p>レンタカーの貸出時間・返却時間はレンタカー会社の営業時間内となっております。</p>
                      <p><a href="https://www.otsinternational.jp/otsrentacar/okinawa/office/" target="_blank" rel="noopener noreferrer">https://www.otsinternational.jp/otsrentacar/okinawa/office/</a></p>               
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">免責補償制度とは何ですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        免責とは、事故の際に保険では補償されないお客様ご負担額の事をいい、対物免責と車両免責は各5万円です。<br />
                        ご利用いただくレンタカー1台あたり1日につき、免責補償料1,500円（税別）を現地レンタカー受付にてお支払いいただいた上で、お客様のご負担額を補償するのが、対物車両事故免責補償制度です。<br />
                        なお、対物車両事故免責補償制度にご加入いただいても、ノン・オペレーション・チャージ（N.O.C）は申し受けます。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">免責補償制度には必ず加入しないといけないですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        ご加入はあくまでも任意です。（免許取得後半年以内の場合は必須）<br />
                        万が一、対物車両事故免責補償未加入で事故に遭われた場合の補償は、一部お客様のご負担となってしまいます。<br />
                        （ご自身が起こした事故だけでなく、当て逃げ等による被害の場合も同様です）<br />
                        楽しいご旅行にしていただくためにも、当社はご加入を強くおすすめしております。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">レンタカーの車種は指定できますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>車種の指定はお受けできません。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">レンタカーの返却時、ガソリンの満タン返しは必要ですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>
                        ガソリンは満タンにてご返却ください。<br />
                        一部、ガソリン満タン返し不要のプランもございます。該当プランについては、その旨プランの詳細画面に記載しております。
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">チャイルドシート・ベビーシート・ジュニアシートは有料ですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>有料となります。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">チャイルドシート・ベビーシート・ジュニアシートの適応条件について</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>補助シートには、ベビーシート・チャイルドシート・ジュニアシートがございます。</p>
                      <p>お子様の成長具合で異なりますので、下記を目安にしてください。</p>

                      <p>
                        ▼ベビーシート（赤ちゃん用）<br />
                        新生児～1才頃<br />
                        身長75㎝以下<br />
                        体重10㎏未満<br /><br />

                        ▼チャイルドシート（幼児用）<br />
                        生後6ヵ月頃～4才頃<br />
                        身長105cm以下<br />
                        体重18㎏未満<br /><br />

                        ▼ジュニアシート（子供用）<br />
                        4才頃～6才頃<br />
                        身長135cm以下<br />
                        体重32㎏未満
                      </p>
    
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">禁煙・喫煙車の指定はできますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>全車種、禁煙車 となります。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">荷物はどれくらい積めますか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>乗車人数や、お持ちいただく荷物によって異なります。<br /><br />

                        ▼Sクラス（乗用車5名乗）<br />
                        キャリーバッグ2個（M1個・S1個）<br /><br />

                        ▼Kクラス（乗用車4名乗）<br />
                        キャリーバッグ2個（S2個）<br /><br />

                        ▼A・HAクラス（乗用車5名乗）<br />
                        キャリーバッグ2個（M2個）<br /><br />

                        ▼WSクラス（乗用車5～7名乗）<br />
                        キャリーバッグ3個（M3個）<br /><br />

                        ▼WAクラス（ワゴンタイプ8名乗）<br />
                        キャリーバッグ4個（M4個）<br /><br />

                        ＜参考＞<br />
                        キャリーバッグS／2～3泊<br />
                        キャリーバッグM／3～5泊
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

              </div>
            </section>
            {/* レンタカーについて　ここまで */}

            {/* section: 8.その他 */}
            <section id="sec_08" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">8.その他</h2>
              <div className="faq-list mt-32">
                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">ダイナミックパッケージとは何ですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p>お客様のご予定に合わせて、自由に航空券・宿泊・レンタカー・観光プランを組み合わせることができるパッケージ商品です。組み合わせに応じてリアルタイムに最新価格を取得し旅行代金をご案内いたします。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">予約確認メールが届きません。</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p className="mb-16">ご迷惑をおかけして申し訳ございません。メール送信後、翌営業日までに弊社からメールが届かない場合は、お手数ですが弊社カスタマーセンターまでご連絡をお願いいたします。なお、お手数ですが下記について今一度ご確認をお願いいたします。</p>

                      <p>※注意事項※</p>
                      <p>旅行契約を承諾する旨の確認通知メールの到達の有無は、契約成立に影響するものではございませんのでメールを確認できなかったとしても契約成立となりますのでご注意ください。</p>
                      <p>またメール受信有無を確認する前に、２重でご予約された場合も通常の取消料が適用されますのでご注意ください。</p>
                      
                      <ul className="list-number-dot">
                        <li>登録アドレス<br />ご登録内容に間違いがないかご確認ください。</li>
                        <li>お客様のメーラー（メールソフト）のセキュリティレベル<br />セキュリティレベルが高いために、受信できていない可能性も考えられます。</li>
                        <li>迷惑メールフォルダ<br />プロバイダやセキュリティソフト等の迷惑メール対策機能が働き、自動的に迷惑メールに切り替えられてしまう場合がございます。また、弊社からお送りするメールの種類や内容によっても一部迷惑メール扱いにされてしまう場合があるようです。<br />
                            以前は届いたのに今回は届かないという場合も恐れ入りますが迷惑メールフォルダを一度ご確認ください。<br />
                            迷惑メールフォルダへの受信を回避する方法として、以下のドメインを受信できるように設定をお願いいたします。【@otsfreestyle.jp】
                        </li>
                      </ul>

                      <p>この様なケースは特にフリーメールに多い為、ご登録のメールアドレスは可能な限りフリーメール以外を設定していただきますようお願いいたします。</p>
                      <p className="mb-16">メールの受信設定については、こちらからご確認お願いいたします。 </p>
                      <p>Yahoo!メール</p>
                      <p className="mb-16"><a href="https://www.yahoo-help.jp/app/answers/detail/p/622/a_id/80584/" target="_blank" rel="noopener noreferrer">https://www.yahoo-help.jp/app/answers/detail/p/622/a_id/80584/</a></p>

                      <p>Gmail</p>
                      <p className="mb-16"><a href="https://support.google.com/mail/troubleshooter/2935079?rd=1#ts=2935090" target="_blank" rel="noopener noreferrer">https://support.google.com/mail/troubleshooter/2935079?rd=1#ts=2935090</a></p>

                      <p>NTT docomo</p>
                      <p className="mb-16"><a href="https://www.nttdocomo.co.jp/info/spam_mail/measure/index.html" target="_blank" rel="noopener noreferrer">https://www.nttdocomo.co.jp/info/spam_mail/measure/index.html</a></p>

                      <p>au</p>
                      <p className="mb-16"><a href="https://www.au.com/support/service/mobile/trouble/mail/email/filter/" target="_blank" rel="noopener noreferrer">https://www.au.com/support/service/mobile/trouble/mail/email/filter/</a></p>

                      <p>softbank</p>
                      <p className="mb-16"><a href="https://www.softbank.jp/mobile/iphone/support/antispam/email_i/" target="_blank" rel="noopener noreferrer">https://www.softbank.jp/mobile/iphone/support/antispam/email_i/</a></p>

                      <p>登録アドレスの変更も承りますので、お気軽に弊社カスタマーセンターまでご連絡をお願いいたします。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="faq-list-item">
                  <Accordion className="faq-list-item-accordion">
                    <AccordionSummary className="faq-list-item-accordion-heading">
                      <div className="faq-list-item-accordion-heading-text">予約内容の確認をしたい場合は、どのようにしたらいいですか？</div>
                      <div className="faq-list-item-accordion-heading-icon"></div>
                    </AccordionSummary>
                    <AccordionDetails className="faq-list-item-accordion-detail">
                      <p className="mb-8">マイページからご予約内容の確認が可能です。</p>
                      <p>予約詳細確認ページへのログイン</p>
                      <p className="mb-8"><a href="https://digitaldmoplatform.com/login/">https://digitaldmoplatform.com/login/</a></p>

                      <p className="mb-8">※マイページのログインには、旅行予約番号と予約申込時にご登録いただいたメールアドレスの入力が必要です。</p>
                      <p>旅行予約番号は予約完了メールにてご確認いただけます。</p>
                    </AccordionDetails>
                  </Accordion>
                </div>        

              </div>
            </section>
            {/* 7.その他　ここまで */}


          </div>
        </div>

           
    </MainTemplate>
  );
};

export default Faq;
