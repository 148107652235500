import React, {useEffect, useState, useRef} from "react";


//MainTemplate
import MainTemplate from "./MainTemplate";


//scss
import "../scss/common.scss";
import "../scss/component.scss";
import ServiceStop from "./ServiceStop";

const PageServiceStop: React.FC = () => {

  return (
    <MainTemplate title="" planSelectFormData={undefined}>

      <ServiceStop></ServiceStop>
           
    </MainTemplate>
  );
};

export default PageServiceStop;
