/**
 * 50までの数字を丸数字(string)に変換する
 * 
 * @returns 
 */
export const useToCircledNumberLabel = () => {

    const toCircledNumberLabel = (index: number): string => {
      if (index > 50) {
        return '';
      }

      const circledNumbers = "⓪①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳㉑㉒㉓㉔㉕㉖㉗㉘㉙㉚㉛㉜㉝㉞㉟㊱㊲㊳㊴㊵㊶㊷㊸㊹㊺㊻㊼㊽㊾㊿";

      return circledNumbers[index];
    }

    return [ toCircledNumberLabel ] as const;
};