import { Navigate, Outlet } from "react-router-dom";

import { ReactNode } from "react";

// ページ追加時に追加する
import {
  Form1,
  Form2,
  Form3,
  Form4,
  Form5,
  Form6,
  PlanSelectTop,
  PlanSelectComplete,
  Page404,
  PageServiceStop,
  Conditions,
  Agreement
} from "../components/";

// ページ追加する
export const ROUTES = {
  Default: "",
  Form1: "/form1",
  Form2: "/form2",
  Form3: "/form3",
  Form4: "/form4",
  Form5: "/form5",
  Form6: "/form6",
  PlanSelectTop: "/:id",
  PlanSelectConfirm: "/planselectconfirm",
  PlanSelectComplete: "/planselectcomplete",
  ReservationCancelLogin: "/login",
  ReservationCancelDetail: "/ReservationCancelDetail",
  ReservationCancelFee: "/ReservationCancelFee",
  Page404: "/Page404",
  PageServiceStop: "/PageServiceStop",
  Conditions: "/Conditions",
  Agreement: "/Agreement",
  Faq: "/Faq",
};
