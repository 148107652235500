import React, {useContext, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Navigate, useNavigate } from "react-router-dom";
import { useReserveCancel } from "../utils/useReserveCancel";
import {clear as clearReservationCancel, save} from "../redux/reservationCancelSlice";

//モーダルをインポート
import Modal from "@material-ui/core/Modal";
import { 
  CancelIndividualModal,
  CancelAllModal,
} from "./modal"

import MainTemplate from "./MainTemplate";

//css
import "../scss/common.scss";
import "../scss/component.scss";

import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from "@material-ui/core";
import {reserveCancelDetailResponce} from "../types/reserveCancel";
import {dateFormat, toCircled, toDisplayTime} from "../utils/convert";
import {getCarRentalOfficeLabel} from "../utils/carRental";
import dayjs from "dayjs";
import {hasReservePartCancel} from "../utils/reserve";
import {useReserveInfo} from "../utils/useReserveInfo";
import {devLog} from "../utils/errors";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";
import { PartsTutorialButton } from "./parts";


// lodingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ReservationCancelFee: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      tagManagerContext.pageView( "キャンセル料金のご案内")
    }, 200)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const classesForLoading = useStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // reduxに保存されているデータを取得
  const savedReservationCancelState = useSelector((state: RootState) => state.reservationCancel);

  // ログイン画面で取得した予約情報
  const reservation = savedReservationCancelState.reservation as reserveCancelDetailResponce;
  
  // 予約キャンセル
  const [ cancelReserve, cancelResult, { isCancelLoading, isCancelError, cancelError } ] = useReserveCancel();
  const today = dayjs().format('MM月DD日');
  const [optionsCancel, setOptionsCancel] = React.useState<{id: string}[]>([]);
  const [carRentalCancel, setCarRentalCancel] = React.useState<{id: string}[]>([]);
  const [cancelPartTotalPrice, setCancelPartTotalPrice] = React.useState(0);

  // 予約情報
  const [ fetchReserveInfo, reserveInfo, { isLoading, isError, error } ] = useReserveInfo();
  
  //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  //モーダル初期表示ページを設定
  const [step, setStep] = React.useState(1);
  const stepHandle = (step: number) => {
    setStep(step)
  }
  
  // モーダルのステータスを追加する
  const [cancelIndividualModal, setCancelIndividualModal] = React.useState(false);
  const [cancelAllModal, setCancelAllModal] = React.useState(false);

  //ラジオボタンでの表示内容を制御
  const [RadioStatus, setRadioStatus] = React.useState("CancelIndividual");
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioStatus(event.target.value);
  };

  // 15分経過でログイン画面に戻す
  const [time, setTime] = useState(0);

  const timeoutMinutes = 15;

  const intervalSeconds = 5;

  useEffect(() => {
    const id = setInterval(() => {
      setTime(t => t + 1);
    }, intervalSeconds * 1000); // 5秒ごとに更新

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    // 15分で終了
    if (time >= (timeoutMinutes * 60 / intervalSeconds)) {
      // redux内のデータをクリア
      dispatch(clearReservationCancel());
      navigate("/login", { replace: true });
    }

    return;
  }, [ time, dispatch, navigate ])


  // todo: キャンセル処理が完了したらどこかに遷移する？
  // もしdetailに飛ぶのであれば、reduxに保存したデータをアップデートする
  useEffect(() => {
    if (cancelResult.message == null) {
      return;
    }
    if (cancelResult.status !== 200) {
      alert("キャンセル処理に失敗しました。");
      return;
    }
    if (reservation && reservation.data && reservation.data.bookingData) {
      fetchReserveInfo({
        bookingId: reservation.data.bookingData.bookingId,
        bookingEmail: reservation.data.bookingData.applicant.email
      });
    }

  }, [ cancelResult ]);

  useEffect(() => {

    if (!reserveInfo ||  (reserveInfo && reserveInfo.data.message == null)) {
      return;
    }

    if (reserveInfo.status !== 200) {
      alert("ログインに失敗しました。");
      return;
    }
    devLog("reserveInfo", reserveInfo);
    dispatch(save({ reservation: reserveInfo }));

    navigate("/ReservationCancelDetail");

  }, [reserveInfo])

  // 予約情報が無ければ or キャンセル済みならログイン画面にリダイレクト
  if (!reservation.data || reservation.data.bookingData == null || reservation.data.bookingData.status !== "reserved") {
    return (
      <Navigate
        to={"/login"}
        replace
      />
    );
  }
  const status: string = reservation.data.bookingData.status ?? "reserved";


  // モーダルの閉じるボタンをクリックした
  const handleCloseModal = () => {
    setCancelIndividualModal(false);
    setCancelAllModal(false);
  }


  /**
   * 個別キャンセル実行
   */
  // 「キャンセル内容を確認する」のボタンをクリックした(=個別キャンセルのモーダルを開く)
  const handleOpenIndividualModal  = () => {
    setCancelIndividualModal(true);
  }

  // 個別キャンセルのモーダル内でキャンセルボタンをクリックした
  const handleCancelIndividual = () => {
    setCancelIndividualModal(false);

    const bookingId = reservation.data.bookingData.bookingId;

    const bookingEmail = reservation.data.bookingData.applicant.email;

    // todo: チェックされた追加オプションを取得する

    // キャンセルAPI呼び出し(結果はuseEffectで受け取る)
    if(carRentalCancel.length > 0 || optionsCancel.length > 0) {
      cancelReserve({
        cancelType: "part",
        bookingId: bookingId,
        bookingEmail: bookingEmail,
        carRental: carRentalCancel,
        option: optionsCancel,
      });
    } else {
      alert("キャンセルする追加オプションまたはレンタカーを選択してください。");
    }
  }

  /**
   * 追加オプションチェックボックス イベント
   * @param event
   */
  const onChangeOptions = (event:React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked) {
      optionsCancel.push({
        id: event.target.value
      });
      setOptionsCancel([...optionsCancel])
    } else {
      if (optionsCancel.length > 0) {
        const removeIndex = optionsCancel.findIndex((option) => {
          return option.id === event.target.value;
        })
        
        if (removeIndex >= 0){
          optionsCancel.splice(removeIndex, 1);
          setOptionsCancel([...optionsCancel])
        }
      }
    }
    updateCancelPartTotalPrice(carRentalCancel, optionsCancel);
  }

  /**
   * レンタカーチェック インベント
   * @param event
   */
  const onChangeCarRental = (event:React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked) {
      carRentalCancel.push({
        id: event.target.value
      });
      setCarRentalCancel([...carRentalCancel])
    } else {
      if (carRentalCancel.length > 0) {
        const removeIndex = carRentalCancel.findIndex((carRental) => {
          return carRental.id === event.target.value;
        })
        
        if (removeIndex >= 0){
          carRentalCancel.splice(removeIndex, 1);
          setCarRentalCancel([...carRentalCancel])
        }
      }
    }
    updateCancelPartTotalPrice(carRentalCancel, optionsCancel);
  }
  
  const updateCancelPartTotalPrice = (updateCarRentalCancel: {id: string}[], updateOptionCancel: {id: string}[]) => {
    let total = 0;
    if (updateCarRentalCancel.length > 0) {
      reservation.cancelData.carRental.forEach((carRental) => {
        if (updateCarRentalCancel.find((checkCarRental) => checkCarRental.id === carRental.id)) {
          total += carRental.cancelPrice;
        }
      })
    }
 
    if (updateOptionCancel.length > 0) {
      reservation.cancelData.options.forEach((option) => {
        if (updateOptionCancel.find((checkOption) => checkOption.id === option.id)) {
          total += option.cancelPrice;
        }
      })
    }

    setCancelPartTotalPrice(total);
  }


  /**
   * 全体キャンセル
   */
  // 「旅行全体をキャンセルする」のボタンをクリックした(=全体キャンセルのモーダルを開く)
  const handleOpenAllModal  = () => {
    setCancelAllModal(true);
  }

  // 全体キャンセルのモーダル内でキャンセルボタンをクリックした
  const handleCancelAll = () => {
    setCancelAllModal(!cancelAllModal)

    const bookingId = reservation.data.bookingData.bookingId;

    const bookingEmail = reservation.data.bookingData.applicant.email;

    // キャンセルAPI呼び出し(結果はuseEffectで受け取る)
    cancelReserve({
      cancelType: "all",
      bookingId: bookingId,
      bookingEmail: bookingEmail,
    });
  }

  // 戻るボタンをクリックした
  const handleGoBack = () => {
    navigate("/ReservationCancelDetail");
  }


  return (
    <MainTemplate title="キャンセル料金のご案内" planSelectFormData={undefined}>

        {/* ローディングの表示(APIのレスポンス待ち) */}
        <Backdrop className={classesForLoading.backdrop} open={isCancelLoading || isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>


        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">キャンセル料金のご案内</h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
            <div className="pt-24 pt-50-md">

              {/* 操作ガイドボタン */}
              <PartsTutorialButton value="component-content" />

              {/* <!--アラート--> */}
              <div className="box-alert-red-small icon-alert">
                <p className="fz-14 fz-16-md fw-b c-red">
                  本日でのキャンセルには以下の料金が発生いたしますので内容をご確認ください。
                </p>
              </div>

              {/* キャンセル内容のご選択 */}
              <h2 className="heading-2">キャンセル内容のご選択</h2>
              <div className="card">
                <p className="mb-16">キャンセルを希望される方は、以下いずれかをご選択ください。</p>
                <ul className="d-f-md">
                  <li key="CancelIndividual" className="mb-8 mb-0-md">
                    <input
                      type="radio"
                      name="AccordionRadioStatus01"
                      id="AccordionRadioStatus01_view"
                      value="CancelIndividual"
                      checked={RadioStatus === "CancelIndividual"}
                      onChange={handleChangeRadio}
                    />
                    <label htmlFor="AccordionRadioStatus01_view">追加オプションの個別キャンセル</label>
                  </li>
                  <li key="CancelAll" className="ml-20-md">
                    <input
                      type="radio"
                      name="AccordionRadioStatus01"
                      id="AccordionRadioStatus01_hidden"
                      value="CancelAll"
                      checked={RadioStatus === "CancelAll"}
                      onChange={handleChangeRadio}
                    />
                    <label htmlFor="AccordionRadioStatus01_hidden">ご予約全体のキャンセル</label>
                  </li>
                </ul>
              </div>

              {/* 追加オプション個別キャンセル */}
              <div className={"searchbox-content-item-group-block" + (RadioStatus === "CancelIndividual" ? "":" d-n")}>
                <h2 className="heading-2">追加オプションの個別キャンセル</h2>
                <p className="mb-24">追加いただいたオプション、レンタカーが個別にキャンセル可能です。</p>


                {hasReservePartCancel(reservation) ?
                <>
                  <div className="part-group">
                    {reservation.cancelData.carRental?.map((row, rId) => (
                      <div className="part-group-item">
                        <div className="card-small">
                          <div className="box-cancel_option">
                            <div className="box-cancel_option-content">
                              <div className="box-separate">
                                <div className="box-separate-item">
                                  <div className="box-separate-item-block">
                                    <input
                                      id={"cancel_retacar_" + rId}
                                      type="checkbox"
                                      value={row.id}
                                      className="form-input-checkbox-icon"
                                      onChange={(e) => onChangeCarRental(e)}
                                    />
                                    <span className="form-input-checkbox-text text-large">
                                  <label htmlFor={"cancel_retacar_" + rId}>
                                    レンタカー{toCircled(row.data.order)}
                                  </label>
                                </span>
                                  </div>
                                </div>

                                <div className="box-separate-item">
                                  <div className="box-separate-item-block">
                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">貸出</dt>
                                      <dd className="box-cancel_option-content-dl-dd">{dateFormat(row.data.departure_date, 'YYYY年MM月DD日（ddd）')}／{getCarRentalOfficeLabel(row.data.detail[0].departure_office_code)}</dd>
                                    </dl>

                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">返却</dt>
                                      <dd className="box-cancel_option-content-dl-dd">{dateFormat(row.data.return_date, 'YYYY年MM月DD日（ddd）')}／{getCarRentalOfficeLabel(row.data.detail[0].return_office_code)}</dd>
                                    </dl>

                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">車種</dt>
                                      <dd className="box-cancel_option-content-dl-dd">
                                        {row.data.car_detail.map((carDetail) => (
                                          <>
                                            {carDetail.car_model}{carDetail.num > 1 && (<> × {carDetail.num.toString()}</>)}
                                          </>
                                        ))}
                                      </dd>
                                    </dl>

                                    {row.data.count_option.baby_seat > 0 || row.data.count_option.child_seat > 0 || row.data.count_option.junior_seat > 0 && (
                                      <>
                                        <dl className="box-cancel_option-content-dl">
                                          <dt className="box-cancel_option-content-dl-dt">オプション</dt>
                                          <dd className="box-cancel_option-content-dl-dd">
                                            {row.data.count_option.baby_seat > 0 && (
                                              <>
                                                乳幼児ベビーシート × {row.data.count_option.baby_seat}
                                              </>
                                            )}
                                            {row.data.count_option.child_seat > 0 && (
                                              <>
                                                幼児用チャイルドシート × {row.data.count_option.child_seat}
                                              </>
                                            )}
                                            {row.data.count_option.junior_seat > 0 && (
                                              <>
                                                学童用ジュニアシート × {row.data.count_option.junior_seat}
                                              </>
                                            )}
                                          </dd>
                                        </dl>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="box-cancel_option-status">
                              <p className="fz-12 fz-14-md ta-c-md mb-8-md">キャンセル料<br className="d-n d-b-md" />（{today}時点）</p>
                              <p className="fz-20 fw-b c-red">
                                {row.cancelPrice.toLocaleString()}
                                <span className="fz-12">円</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    {reservation.cancelData.options?.map((row,oId) => (
                      <div className="part-group-item">
                        <div className="card-small">
                          <div className="box-cancel_option">
                            <div className="box-cancel_option-content">
                              <div className="box-separate">
                                <div className="box-separate-item">
                                  <div className="box-separate-item-block">
                                    <input
                                      id={"cancel_option_" + oId}
                                      type="checkbox"
                                      value={row.id}
                                      className="form-input-checkbox-icon"
                                      onChange={(e) => onChangeOptions(e)}
                                    />
                                    <span className="form-input-checkbox-text text-large">
                                  <label htmlFor={"cancel_option_" + oId}>
                                    追加オプション{toCircled(row.data.order)}
                                  </label>
                                </span>
                                  </div>
                                </div>

                                <div className="box-separate-item">
                                  <div className="box-separate-item-block">
                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">ご利用日</dt>
                                      <dd className="box-cancel_option-content-dl-dd">
                                        {dateFormat(row.data.use_date, 'YYYY年MM月DD日（ddd）')} {toDisplayTime(row.data.use_time)}
                                      </dd>
                                    </dl>

                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">プラン名</dt>
                                      <dd className="box-cancel_option-content-dl-dd">{row.data.planName}</dd>
                                    </dl>

                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">申込内容</dt>
                                      <dd className="box-cancel_option-content-dl-dd">
                                        {row.data.price_type_detail.map((price_type_detail) => (
                                          <>
                                            {price_type_detail.name}　×{price_type_detail.num}
                                          </>
                                        ))}
                                      </dd>
                                    </dl>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="box-cancel_option-status">
                              <p className="fz-12 fz-14-md ta-c-md mb-8-md">キャンセル料<br className="d-n d-b-md" />（{today}時点）</p>
                              <p className="fz-20 fw-b c-red">
                                {row.cancelPrice.toLocaleString()}
                                <span className="fz-12">円</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="box-total box mt-32 mt-50-md">
                    <span className="box-total-heading small">キャンセル代金 合計</span>
                    <span className="box-total-pay small">
                      {cancelPartTotalPrice.toLocaleString()}
                      <span className="c-red fz-14 fz-16-md">円(税込)</span>
                    </span>
                  </div>

                  {/* <!--次へ進む--> */}
                  <div className="mt-48 mt-64-mt">
                    <ul className="form-step_button mt-24">

                      {/* ※追加オプションがない場合は確定ボタンはなし */}
                      <li key="cancel-button" className="form-step_button-next">
                        <button onClick={handleOpenIndividualModal} className="button-large-red w-100per">
                          キャンセル内容を確定する
                        </button>
                      </li>

                      <li key="cancel-section-reserve-back" className="form-step_button-back">
                        <button
                          className="button-small-border_gray_blue button-medium-md"
                          onClick={handleGoBack}
                        >
                          <i className="icon-left_arrow mr-5"></i>
                          予約情報詳細に戻る
                        </button>
                      </li>
                    </ul>
                  </div>
                </>:
                  <>
                    {/* ※追加オプションがない場合のみ表示 */}
                    <div className="box-border-red mb-32">
                      <p className="ta-c c-red fz-16 fw-b">キャンセル可能な<br className="d-n-md" />追加オプションはありません。</p>
                    </div>
                  </>
                }
              </div>

               {/* 追加オプション全体キャンセル */}
               <div className={"searchbox-content-item-group-block" + (RadioStatus === "CancelAll" ? "":" d-n")}>

                 {status === "reserved" ?
                   <>
                     {/* ご予約全体のキャンセル */}
                     <h2 className="heading-2 mb-16 mb-24-md">ご予約全体のキャンセル</h2>
                     <div className="box-total-short">
                       <div className="box-total-short-sub_total">
                         <div className="box-total-short-sub_total-item">
                           <div className="fz-14 fz-16-md">基本旅行代金</div>
                           <div className="fz-20 fz-22-md fw-b c-red">{reservation.cancelData.basicTotal.toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                         </div>

                         {reservation.cancelData.carRental.length > 0 && (
                           <>
                             <div className="box-total-short-sub_total-item">
                               <div className="fz-14 fz-16-md">レンタカー</div>
                               <div className="fz-20 fz-22-md fw-b c-red">{reservation.cancelData.carRentalTotal.toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                             </div>
                           </>
                         )}

                         {reservation.cancelData.options.length > 0 && (
                           <>
                             <div className="box-total-short-sub_total-item">
                               <div className="fz-14 fz-16-md">追加オプション</div>
                               <div className="fz-20 fz-22-md fw-b c-red">{reservation.cancelData.optionTotal.toLocaleString()}<span className="fz-10 fz-14-md">円</span></div>
                             </div>
                           </>
                         )}
                       </div>
                       <div className="box-total-short-total">
                         <span className="box-total-short-total-heading">キャンセル代金 合計</span>
                         <span className="box-total-short-total-pay">
                        {Number(reservation.cancelData.cancelTotal).toLocaleString()}
                           <span className="c-red fz-16">円(税込)</span>
                    </span>
                       </div>
                     </div>

                     {/* 状態が”キャンセル済み”の場合に表示 */}
                     <div className="card small mt-24 mt-50-md mb-32">
                       <p className="mb-10 fz-16 fz-18-md fw-b">保険契約のキャンセル</p>
                       <p className="mb-10">保険契約のキャンセルはチャブ保険のお客様確認ページから別途お手続きが必要となります。</p>
                       <p className="mb-16 mb-24-md">
                         <a href="https://ssp.chubbtravelinsurance.com/ctijp" target="_blank" className="fz-12" rel="noreferrer">
                           保険契約のキャンセルはこちら<i className="icon-blank ml-5"></i>
                         </a>
                       </p>

                       <ul className="list-note light-gray">
                         <li key="cancel-fee-note-1">証券番号と保険始期日はチャブ保険の契約完了のご案内メールをご確認ください。</li>
                         <li key="cancel-fee-note-2">保険金はキャンセル手続き後、チャブ保険から返金となります。</li>
                       </ul>
                     </div>

                     {/* <!--次へ進む--> */}
                     <div className="mt-48 mt-64-mt">
                       <ul className="form-step_button mt-24">

                         {/* ※追加オプションがない場合は確定ボタンはなし */}
                         <li key="travel-cancel-button" className="form-step_button-next">
                           <button onClick={handleOpenAllModal} className="button-large-red w-100per">
                             旅行全体をキャンセルする
                           </button>
                         </li>

                         <li key="cancel-fee-reserve-back" className="form-step_button-back">
                           <button
                             className="button-small-border_gray_blue button-medium-md"
                             onClick={handleGoBack}
                           >
                             <i className="icon-left_arrow mr-5"></i>
                             予約情報詳細に戻る
                           </button>
                         </li>
                       </ul>
                     </div>
                   </>
                   :
                   <>
                     {/* ※キャンセル済みの場合のみ表示 */}
                     <div className="box-border-red mb-32">
                       <p className="ta-c c-red fz-16 fw-b">既に全体キャンセル済みです。</p>
                     </div>
                   </>
                 }

              </div>
            </div>
          </div>
        </div>


        {/* モーダル：個別キャンセル */}
        <CancelIndividualModal
          isShow={cancelIndividualModal}
          onClickCancel={handleCancelIndividual}
          onClickClose={handleCloseModal}
          reserveData={reservation}
          cancelRentalCarIds={carRentalCancel}
          cancelOptionIds={optionsCancel}
        />

        {/* モーダル：全体キャンセル */}
        <CancelAllModal isShow={cancelAllModal} onClickCancel={handleCancelAll} onClickClose={handleCloseModal} />

    </MainTemplate>
  );
};

export default ReservationCancelFee;
