import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { InputsForm3 } from "../../../types/InputsForm3";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { InputCheckbox } from "../../input/InputCheckbox";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import {insuranceQuoteResponce} from "../../../types/insurance";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {dateFormat} from "../../../utils/convert";



type Props = {
  insuranceData: insuranceQuoteResponce,
  planSelectFormData: PlanSelectFormData,
  control: any;
  errors: FieldErrors;
  getValues: any;
  insuranceFee: number;
  register: UseFormRegister<InputsForm3>;
}

const SectionCancellationInsurance: React.FC<Props> = (props) => {
  //ラジオボタンでアコーディオン開閉制御
  const [selectedValue02, setSelectedValue02] = React.useState('view');
  const handleChange02 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue02(event.target.value);
  };

  return (
    <>
      <section>
        {/* <!--国内旅行傷害保険--> */}
        <h2 className="heading-2">旅のキャンセル保険</h2>
        <Accordion className="accordion-radio" expanded={selectedValue02 === 'view'}>
          <AccordionSummary className="accordion-radio-heading">
            <ul className="list-side">
              <li>
                <input
                  type="radio"
                  name="AccordionRadioStatus02"
                  id="AccordionRadioStatus02_view"
                  value="view"
                  checked={selectedValue02 === 'view'}
                  onChange={handleChange02}
                />
                <label htmlFor="AccordionRadioStatus02_view">詳細を見る</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="AccordionRadioStatus02"
                  id="AccordionRadioStatus02_hidden"
                  value="hidden"
                  onChange={handleChange02}
                />
                <label htmlFor="AccordionRadioStatus02_hidden">閉じる</label>
              </li>
            </ul>
          </AccordionSummary>
          <AccordionDetails className="accordion-radio-content">
          <div className="box-separator_bottom-large">
            <div className="box-separator_bottom-large-item">
              <p className="mb-24">
                旅のキャンセル保険<sup>※1</sup>とは、本人や家族の急な病気やけが、交通機関の遅延、ペットの死亡・危篤などで、やむをえず旅行に行けなかった場合に発生するキャンセル費用<sup>※2</sup>を補償する保険です。<sup>※3</sup><br />
                お支払いの主な対象事例や、保険金請求に関するご案内は<a href="https://tripcxl.chubbtravelinsurance.com/content/dam/chubbglobaltravel/Staticfiles/Doc/JP/TRIPCXL/ClaimGuigeTripCXL.pdf#0" target="_blank" rel="noreferrer">こちら</a>よりご確認ください。<br />
                自己都合（出張・仕事含）によるキャンセル費用は補償対象外です。 取扱料金、取消手続手数料、事務手数料（または左記に該当するもの）は補償対象外となります。詳しくは【<a href="https://docs.chubbtravelinsurance.com/aceStatic/ACETravel/ATIJP/Contents/TripCXLCoverage.png" target="_blank" rel="noreferrer">お支払いいただく料金と「旅のキャンセル保険」の補償範囲</a>】をご覧ください
              </p>
              <ol className="list-note-number mb-0 light-gray fz-14-md fz-10">
                <li>
                  「旅のキャンセル保険」は、ネット専用旅行関連サービス取消費用補償保険のペットネームです。
                </li>
                <li>
                  企画旅行等参加予定者が旅行最初の搭乗を中止した日または旅行関連サービスを中途で取りやめた日以降に提供を受ける旅行関連サービスについて、旅行最初の搭乗を中止したことまたは旅行関連サービスを中途で取りやめたことにより、取消料、違約料、旅行業務取扱料その他の名目において、旅行業者、運送機関、宿泊施設等との契約上払戻しを受けられない費用またはこれから支払うことを要する費用をいいます。
                </li>
                <li>
                  旅のキャンセル保険はお申し込みいただいたときから補償が開始されるため、お申し込み後に解約された場合、保険料の全額は返金されません。解約の際は、解約日から終期までの未経過期間に応じて日割をもって計算した保険料の返還となりますので、ご承知おきください。
                </li>
              </ol>

              <ul className="list-note-text light-gray fz-10 fz-14-md mt-16">
                <li>
                  補償内容の詳細は<a href="https://chubb.meclib.jp/TAE5403/book/data/TAE5403.pdf" target="_blank" rel="noreferrer">約款</a>をご確認ください。
                </li>
              </ul>
            </div>

            <div className="box-separator_bottom-large-item">
              <div className="box-border-blue small mb-16">
                <div className="d-f jc-sb ai-c">
                  <p className="fz-16 fw-b">保険金額</p>
                  <p>
                    <span className="c-red">
                      <span className="fz-28 fw-b">
                        {props.planSelectFormData.travelPrice.toLocaleString("ja-JP")}
                      </span>
                      円
                    </span>
                  </p>
                </div>
              </div>

              <div className="box-border-blue small mb-16">
                <div className="d-f jc-sb ai-c">
                  <p className="fz-16 fw-b">保険料</p>
                  <p>
                    <span className="c-red">
                      <span className="fz-28 fw-b">
                        {props.insuranceData.price.TripCXL.toLocaleString("ja-JP")}
                      </span>
                      円
                    </span>
                  </p>
                </div>
              </div>

              <div className="box-border-blue small mb-32 mb-40-md">
                <div className="d-f-md jc-sb  ai-c">
                  <p className="fz-16 fw-b mb-5 mb-0-md">保険加入期間</p>
                  <p>{dateFormat(props.insuranceData.price.TripCXLDate.startDate, 'YYYY年MM月DD日')} 〜 {dateFormat(props.insuranceData.price.TripCXLDate.endDate, 'YYYY年MM月DD日')}</p>
                </div>
              </div>

              <div className="box-light-gray small mb-24 mb-32-md">
                <p className="mb-16">
                  保険加入条件および重要事項説明書には、特に
                  <span className="c-red">
                    確認頂きたい事項、お客様に不利になる事項の記載
                  </span>
                  があるため、重要事項説明書を最後まで必ずご確認ください。
                </p>
                <p className="ta-c">
                  <a href="https://tripcxl.chubbtravelinsurance.com/content/dam/chubbglobaltravel/Staticfiles/Doc/JP/TRIPCXL/Important_Info_in_JPN_TripCXL2021RT.pdf" target="_blank" rel="noreferrer">
                    保険加入条件および重要事項説明書 (PDF)を表示
                    <i className="icon-blank ml-5"></i>
                  </a>
                </p>
              </div>

              <ul className="list-alert-blue mb-24">
                <li>PDFデータをご用意しておりますので、印刷または端末にダウンロードをお願いいたします。</li>
                <li>
                  内容に関してお問い合わせがございましたら下記メールアドレスにてお問い合わせください。<br />
                  チャブ保険変更受付センター（メール）：TIMCC-Support@chubb.com
                </li>
                <li>
                  迷惑メール対策等で、ドメイン指定受信を設定されている場合に、メールが正しく届かないことがございます。以下のドメインを受信できるように設定してください。<br />
                  @email.chubbtravelins.com
                </li>
                <li>契約者および被保険者は日本に住んでいる方に限ります。また、保険金受取人のための銀行口座を日本国内に有している必要があります。</li>
              </ul>

              <p className="ta-c-md c-red mb-16 fw-b">
                旅のキャンセル保険に加入される場合は以下の項目をチェックしてください。
              </p>
              <div className="box-light-red mb-8">
                <div className="form-input-checkbox-left">
                  <InputCheckbox
                    name="cancellation_insurance.agree"
                    register={props.register}
                    checkboxes={[
                      {
                        id: "cancellation_insurance.agree",
                        label: "",
                        value: "yes"
                      },
                    ]}
                    options={{
                      required: false,
                      validate: {
                        joinInsurance: (value) => {

                          // 保険加入するにチェックが入っている場合は必須とする
                          if (!value && props.getValues("cancellation_insurance.we_do_join") === "yes") {
                            return false;
                          }

                          return true;
                        },
                      },
                    }}
                    className={"form-input-checkbox-icon"}
                  ></InputCheckbox>
                  <span className="form-input-checkbox-text">
                    <label htmlFor="cancellation_insurance.agree.0">
                      本保険の普通保険約款および特約を本契約の内容とすることを了承し、重要事項説明書の内容を確認の上、同意します。
                    </label>
                  </span>
                </div>
                {props.errors.cancellation_insurance?.agree && (
                  <p className="form-error_text c-red ta-c mt-4">
                    旅のキャンセル保険に加入される場合は<br />「同意します」にチェックをいれてください。
                  </p>
                )}         
              </div>

              <div className="box-light-red">
                <div className="form-input-checkbox-left">
                  <InputCheckbox
                    name="cancellation_insurance.we_do_join"
                    register={props.register}
                    checkboxes={[
                      {
                        id: "cancellation_insurance.we_do_join",
                        label: "",
                        value: "yes"
                      },
                    ]}
                    options={{ required: false }}
                    className={"form-input-checkbox-icon"}
                  ></InputCheckbox>
                  <span className="form-input-checkbox-text">
                    <label htmlFor="cancellation_insurance.we_do_join.0">
                      旅のキャンセル保険に加入します。
                    </label>
                  </span>
                </div>              
              </div>
            </div>
          </div>

          </AccordionDetails>
        </Accordion>
      </section>
    </>
  );
};

export default SectionCancellationInsurance;
