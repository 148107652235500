import React from "react";
import LoadingOverlay from "react-loading-overlay-ts";

type Props = {
  isLoading: boolean,
}

const SearchLoading: React.FC<Props> = ({
  isLoading
}) => {

  return (
    <>
      <LoadingOverlay active={isLoading} >
        <div className="pb-100 pt-80"></div>
      </LoadingOverlay>
    </>
  );
};

export default SearchLoading;