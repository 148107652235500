import React from "react";
import AppTemplate from "../components/Form4";

const Form4: React.FC = () => {
  return (
    <AppTemplate>
      <>ダッシュボード</>;
    </AppTemplate>
  );
};

export default Form4;
