import React from "react";

// for styles
import Backdrop from "@material-ui/core/Backdrop";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  onClickCancel: () => void,
  onClickClose: () => void,
}

const CancelAllModal: React.FC<Props> = ({
  isShow,
  onClickCancel,
  onClickClose
}) => {

  return (
    <>
    <Modal
      open={isShow}
      onClose={onClickClose}
      className="modal-simple"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div className="modal-simple-content">
        <div className="modal-simple-content-close">
          <button className="button-clear" onClick={onClickClose}>
            <i className="icon-modal-close-navy fz-30"></i>
          </button>
        </div>
        <div className="box-separate">

          <div className="box-separate-item">
            <div className="box-separate-item-block">
              <p className="ta-c fw-b">旅行全体を<br />キャンセルしてもよろしいですか?</p>
            </div>
          </div>

          <div className="box-separate-item">
            <div className="box-separate-item-block">
              <p className="mb-16">
                <button onClick={onClickCancel} className="button-medium-red w-100per fz-16 fw-b">
                  キャンセルする
                </button>
              </p>

              <p>
                <button onClick={onClickClose} className="button-border-medium w-100per">
                  閉じる
                </button>
              </p>

            </div>
          </div>
        </div>
      </div>
        
      </Modal>
    </>
  );
};

export default CancelAllModal;
