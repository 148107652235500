import { useEffect, useState } from "react";
import { useApi } from "./useApi";
import {priceResponce} from "../types/price";
import {insuranceQuoteRequest, insuranceQuoteResponce} from "../types/insurance";
import {devLog} from "./errors";

/**
 * 旅行代金確認
 * 
 * @returns 
 */
export const useInsurancePrice = () => {

  const [isLoading, setLoading] = useState(false);

  const [error, setError] = useState<string|null>(null);
  const [isError, setIsError] = useState(false);

  const [insuranceData, setInsuranceData] = useState<insuranceQuoteResponce>({
    "status": 200,
    "message": "",
    "price": {
      "DTA": 0,
      "TripCXL": 0,
      "isDTA": true,
      "isTripCXL": true,
      "TripCXLDate": {
        "startDate": "",
        "endDate": ""
      },
      ServiceInsurance: {
        isServiceInsurance: false,
        freeSpace: {
          open: "disable",
          pc: "",
          sp: "",
        },
      },
    }});

  const fetchInsurance = async (input: insuranceQuoteRequest) => {
    setLoading(true);

    try {
      const response = await useApi.post({
        url: "/insurance/quote",
        data: input
      });

      const data = response.data as insuranceQuoteResponce;

      if (data.status === 200) {
        devLog("insuranceData", data);
        setInsuranceData(data);
      } else if (data.status !== 500 && (data.price.isTripCXL || data.price.isTripCXL)) {
        setInsuranceData(data);
      } else {
        setIsError(true)
        setError("保険料金の取得に失敗しました、申し訳ありませんが時間を置いて再度プランの設定をお願いします。");
      }

    } catch (error) {
      setIsError(true)
      setError("保険料金の取得に失敗しました、申し訳ありませんが時間を置いて再度プランの設定をお願いします。");
    }

    setLoading(false);

    return;
  }

  return [ fetchInsurance, insuranceData, { isLoading, isError, error } ] as const;
};