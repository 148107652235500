import React from "react";

// for google map
import GoogleMapTemplate from "../google/Maps";

// for styles
import {
  Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

//モーダルをインポート
import Modal from "@material-ui/core/Modal";
import {
  ComprehensiveOptionDetailsModal
} from "./"
import { IActivity } from "../../types/Activity";
import {dateFormat, toDisplayTime} from "../../utils/convert";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  callback: () => void,
  changeActivity(activity:IActivity):void
  activity: IActivity | undefined
}

const DateOfUseModal: React.FC<Props> = ({
  isShow,
  callback,
  activity,
  changeActivity
}) => {

   //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  //モーダル初期表示ページを設定
  const [step, setStep] = React.useState(1);
  const stepHandle = (step: number) => {
    setStep(step)
  }

  // モーダルのステータスを追加する
  const [status, setStatus] = React.useState(false);
  
  // モーダルのhandleを追加する
  const handleOnClick = () => {
    setStep(2)
    setStatus(!status)
  }

  const selectedCallback = (data:IActivity | undefined) => {
    if (data) {
      changeActivity(data);
    }
    callback();
  }

  return (
    <>
    <div>
      <Modal
        open={isShow}
        onClose={callback}
        className="modal-simple"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <div className="modal-simple-content pt-0 pb-0">
          <div className="modal-simple-content-close">
            <button className="button-clear" onClick={callback}>
              <i className="icon-modal-close-navy fz-30"></i>
            </button>
          </div>
          <div className="box-separate">

            {activity?
              <>
                <div className="box-separate-item">
                  <div className="box-separate-item-block">
                    <p className="fz-16 fw-b c-navy mb-4">ご利用日</p>
                    <p className="fz-14">{dateFormat(activity.date, "YYYY年MM月DD日(ddd)")} {toDisplayTime(activity.time)}</p>
                  </div>
                </div>

                <div className="box-separate-item">
                  <div className="box-separate-item-block">
                    <p className="fz-16 fw-b mb-8 c-navy">参加人数</p>
                    {activity.price_list?.map((price, priceIndex) => (
                      <dl className="d-f jc-sb c-navy" key={priceIndex}>
                        <dt className="fz-14">{price.name}</dt>
                        <dd className="fz-14 fw-b">✕{price.num ?? 0 }</dd>
                      </dl>
                    ))}
                  </div>
                </div>

                <div className="box-separate-item">
                  <div className="box-separate-item-block">
                    <div className="d-f jc-sb ai-c">
                      <p>
                        <button onClick={callback} className="button-medium-orange w-60">
                          OK
                        </button>
                      </p>

                      <p>
                        {/* 仮遷移先：遷移先のモーダル作成後に変更 */}
                        <button onClick={() => selectedCallback(activity)} className="button-border-medium button-clear-blue p-0 w-100per">
                          変更する<i className="icon-right-arrow-simple ml-5"></i>
                        </button>
                      </p>
                    </div>

                  </div>
                </div>
              </>
              :<></>
            }
          </div>
        </div>
      </Modal>
        {/* ここに作成したモーダルを追加する */}
        {/* STEP1 */}
        {/* <ComprehensiveOptionDetailsModal  activity={activity} stepHandler={stepHandle} defaultStep={step} isShow={status} callback={selectedCallback} /> */}
    </div>
    </>
  );
};

export default DateOfUseModal;
