import React, {Fragment, useState} from "react";

//css
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import {reserveCancelDetailResponce} from "../../../types/reserveCancel";
import {convertToPeople, dateFormat, formatToDate, getAge, toCircled, toDisplayTime} from "../../../utils/convert";
import {getActivityUseTime} from "../../../utils/plan";
import {getReserveGenderLabel} from "../../../utils/reserve";
import {
  convertCancelDetailFlightData,
  getFlightCodeByLabel,
  getFlightCodeLabel,
  getFlightNumberForDisplay,
  getFlightTime, getReserveFlightPeoples
} from "../../../utils/flight";
import {
  getFlightClassLabel,
} from "../../../utils/flightV2";
import MyPageOptionSelectModal from "../../modal/MyPageOptionSelectModal";
import {IActivity} from "../../../types/Activity";
import { PartsTutorialButton } from "../../parts";
import {getCarRentalAnshinOptionItem} from "../../../utils/carRental";


//--------------------------------------------------
// テストデータ
//--------------------------------------------------
//テストデータ(参加者)
const createData_participant = (
  participant_id: number,
  participant_name: string,
  participant_old: number,
  participant_sexual: string,
  participant_option: string,
  ) => {
  return { participant_id, participant_name, participant_old, participant_sexual, participant_option };
};

const rows_participant = [
  createData_participant(1,"オキナワ タロウ", 41,"男性","",),
  createData_participant(2,"オキナワ ハナコ", 40,"女性","",),
  createData_participant(3,"オキナワ  イチロウ", 14,"男性","",),
  createData_participant(4,"オキナワ サクラ", 11,"女性","",),
  createData_participant(5,"オキナワ ジロウ", 2,"男性","添い寝：あり",),
];


//テストデータ(フライト：住路)
const createData_living_route = (
  createData_living_route_id: string,
  createData_living_route_departure_date: string,
  createData_living_route_flight_number: string,
  createData_living_route_class: string,
  createData_living_route_seat: string,
  ) => {
  return { createData_living_route_id,
    createData_living_route_departure_date,
    createData_living_route_flight_number,
    createData_living_route_class,
    createData_living_route_seat,
  };
};

const rows_createData_living_route = [
  createData_living_route(
    "①",
    "2021年3月15日(月)", 
    "ANA463(羽田空港 07:25 - 那覇空港 10:19)",
    "普通席",
    "大人3席／子供1席",
    ),

    createData_living_route(
      "②",
      "2021年3月15日(月)", 
      "ANA463那覇空港 10:55 - 石垣空港 12:55)",
      "普通席",
      "大人3席／子供1席",
      ),
];

//テストデータ(フライト：復路)
const createData_return_trip = (
  createData_return_trip_id: string,
  createData_return_trip_departure_date: string,
  createData_return_trip_flight_number: string,
  createData_return_trip_class: string,
  createData_return_trip_seat: string,
  ) => {
  return { createData_return_trip_id,
    createData_return_trip_departure_date,
    createData_return_trip_flight_number,
    createData_return_trip_class,
    createData_return_trip_seat,
  };
};

const rows_createData_return_trip = [
  createData_return_trip(
    "①",
    "2021年3月17日(水)", 
    "ANA463(那覇空港 07:25 - 羽田空港 10:19)",
    "普通席",
    "大人3席／子供1席",
    ),

    createData_return_trip(
      "②",
      "2021年3月15日(月)", 
      "ANA463那覇空港 10:55 - 石垣空港 12:55)",
      "普通席",
      "大人3席／子供1席",
      ),
];

//テストデータ(宿泊)
const createData_stay = (
  createData_stay_hotel: string,
  createData_stay_number: string,
  createData_stay_checkin: string,
  createData_stay_checkout: string,
  createData_stay_roomtype: string,
  createData_stay_plan: string,
  createData_stay_room_number: number,

  ) => {
  return { 
    createData_stay_hotel,
    createData_stay_number,
    createData_stay_checkin,
    createData_stay_checkout,
    createData_stay_roomtype,
    createData_stay_plan,
    createData_stay_room_number,
  };
};

const rows_createData_stay = [
  createData_stay(
    "ホテルモントレ沖縄　スパ＆リゾート",
    "111-2222",
    "2021年3月15日(月) 15:00",
    "2021年3月17日(水) 11:00",
    "	デラックス＜禁煙／6F～11F＞",
    "【2021 OTS夏旅】期間限定！非日常を感じて寛ぎステイ＜食事なし＞",
    3
    ),

    createData_stay(
      "ロワジール那覇",
      "333-4444",
      "2021年3月15日(月) 15:00",
      "2021年3月17日(水) 11:00",
      "	デラックス＜禁煙／6F～11F＞",
      "【2021 OTS夏旅】期間限定！非日常を感じて寛ぎステイ＜食事なし＞",
      4
    ),
];

//テストデータ(基本プラン)
const createData_plan = (
  createData_plan_id: string,
  createData_plan_number: string,
  createData_plan_date: string,
  createData_plan_plan: string,
  createData_plan_detail: string,
  ) => {
  return { 
    createData_plan_id,
    createData_plan_number,
    createData_plan_date,
    createData_plan_plan,
    createData_plan_detail
  };
};
const rows_createData_plan = [
  createData_plan(
    "①",
    "111-2222",
    "2021年3月16日(火) 11:00",
    "手ぶらOK最新GoPro【青の洞窟体験ダイビング】完全貸切！新品マウスピース！無料撮影♪話題のGoPro9使用！すぐにスマホへ！ベテランインストラクターで初心者も安心！当日予約大歓迎！徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯",
    "10歳～54歳×2　8歳〜9歳×1　55歳〜59歳×1　新品マウスピース販売×1",
    ),
    createData_plan(
      "②",
      "111-2222",
      "2021年3月16日(火) 11:00",
      "手ぶらOK最新GoPro【青の洞窟体験ダイビング】完全貸切！新品マウスピース！無料撮影♪話題のGoPro9使用！すぐにスマホへ！ベテランインストラクターで初心者も安心！当日予約大歓迎！徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯",
      "10歳～54歳×2　8歳〜9歳×1　55歳〜59歳×1　新品マウスピース販売×1",
    ),
];

//テストデータ(レンタカー)
const createData_rentacar = (
  createData_rentacar_id: string,
  createData_rentacar_number: string,
  createData_rentacar_status: string,
  createData_rentacar_lending: string,
  createData_rentacar_return: string,
  createData_rentacar_class: string,
  createData_rentacar_option: string,
  ) => {
  return { 
    createData_rentacar_id,
    createData_rentacar_number,
    createData_rentacar_status,
    createData_rentacar_lending,
    createData_rentacar_return,
    createData_rentacar_class,
    createData_rentacar_option,
  };
};
const rows_createData_rentacar = [
  createData_rentacar(
    "①",
    "111-2222",
    "キャンセル済み",
    "2021年3月15日(月) OTSレンタカー 臨空豊崎営業所(那覇空港)",
    "2021年3月17日(水) OTSレンタカー 臨空豊崎営業所(那覇空港)",
    "Sクラス(フィット、ヴィッツ)×1 HBFクラス(カローラフィールダーW×B)×1",
    "幼児用チャイルドシート×1 学童用ジュニアシート×1",
    ),
    createData_rentacar(
    "②",
    "111-2222",
    "",
    "2021年3月15日(月) OTSレンタカー 臨空豊崎営業所(那覇空港)",
    "2021年3月17日(水) OTSレンタカー 臨空豊崎営業所(那覇空港)",
    "Sクラス(フィット、ヴィッツ)×1 HBFクラス(カローラフィールダーW×B)×1",
    "幼児用チャイルドシート×1 学童用ジュニアシート×1",
    ),
];

//テストデータ(追加オプション)
const createData_option = (
  createData_option_id: string,
  createData_option_number: string,
  createData_option_status: string,
  createData_option_date: string,
  createData_option_plan: string,
  createData_option_detail: string,
  ) => {
  return { 
    createData_option_id,
    createData_option_number,
    createData_option_status,
    createData_option_date,
    createData_option_plan,
    createData_option_detail,
  };
};
const rows_createData_option = [
  createData_option(
    "①",
    "111-2222",
    "キャンセル済み",
    "2021年3月16日(火) 11:00",
    "手ぶらOK最新GoPro【青の洞窟体験ダイビング】完全貸切！新品マウスピース！無料撮影♪話題のGoPro9使用！すぐにスマホへ！ベテランインストラクターで初心者も安心！当日予約大歓迎！徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯",
    "10歳～54歳×2　8歳〜9歳×1　55歳〜59歳×1　新品マウスピース販売×1",
    ),
    createData_option(
      "①",
      "111-2222",
      "",
      "2021年3月16日(火) 11:00",
      "手ぶらOK最新GoPro【青の洞窟体験ダイビング】完全貸切！新品マウスピース！無料撮影♪話題のGoPro9使用！すぐにスマホへ！ベテランインストラクターで初心者も安心！当日予約大歓迎！徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯",
      "10歳～54歳×2　8歳〜9歳×1　55歳〜59歳×1　新品マウスピース販売×1",
    ),
];



//-------------------- ↑ここまでテストデータ↑ -----------------------------


// todo: 型指定する
type Props = {
  reservation: reserveCancelDetailResponce;
};


const SectionDetail: React.FC<Props> = (props) => {
  const [activity, setActivity] = useState<IActivity>();
  const [isActivityModal, setIsActivityModal] = useState(false);
  const reserve = props.reservation.data;
  const flights = convertCancelDetailFlightData(props.reservation.data.subData.flight);
  const flightPeoples = getReserveFlightPeoples(props.reservation);

  const airLineName = props.reservation.data.subData.flight.length > 0 ? props.reservation.data.subData.flight[0].data.airline : "ANA";

  // 末尾の「便」を抜いた表示用フライト番号を返す
  const getFlightNumberForDisplayExcludeSuffix = (flightData: { airCode: string, airSubNo: string }) => {
    const flightNumber = getFlightNumberForDisplay(airLineName, flightData.airCode, flightData.airSubNo);

    return flightNumber.replace("便", "");
  }
  
  const handleActivityDetailClick = (planData: IActivity) => {
    if (planData) {
      setActivity({...planData});
      setIsActivityModal(true);
    }
  }
  
  const handleActivityModal = () => {
    setIsActivityModal(false);
  }

  return (
    <>
      <section>

        {/* 操作ガイドボタン */}
        <PartsTutorialButton value="component-content" />

        {/* 予約情報詳細 */}
        <h2 className="heading-2 mt-0 mb-16 mb-24-md">予約情報詳細</h2>
        <div className="card large">
          <div className="mb-16 mb-24-md">
            <table className="table-xsmall">
              <tbody>
                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b w-110 w-175-md">
                    状態
                  </th>
                  <td>
                    {/* 以下のステータスのどちらかが入る */}
                    { reserve.bookingData.status === 'reserved' ?
                      <span className="label-light_blue-sausage">予約済み</span>
                      :
                      <span className="label-orange-sausage">キャンセル済み</span>
                    }
                  </td>
                </tr>

                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b">
                    旅行予約番号
                  </th>
                  <td>
                    {/* 12-345678 */}
                    {reserve.bookingData.bookingId}
                  </td>
                </tr>

                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b">
                    予約日
                  </th>
                  <td>
                    {dateFormat(reserve.bookingData.bookingDate, 'YYYY年MM月DD日(ddd)')}
                  </td>
                </tr>

                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b">
                    予約者
                  </th>
                  <td>
                    {/* 沖縄 太郎 */}
                    {reserve.bookingData.applicant.applicantName}
                  </td>
                </tr>

                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b">
                    予約完了メール<br className="d-n d-b-md" />送信先
                  </th>
                  <td>
                    <div className="mb-8">{reserve.bookingData.applicant.email}</div>
                    <ul className="list-note light-gray">
                      <li>迷惑メール設定をしている場合、「digitaldmoplatform.com」のメールを受信出来るように設定をお願いいたします。</li>
                      <li>予約完了メールはお送りしておりますので必ずご確認ください。</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* 状態が”キャンセル済み”の場合に表示 */}
          <div className="box-border-gray-bold mt-24 mb-32">
            <p className="mb-10 fz-16 fz-18-md fw-b">保険契約のキャンセル</p>
            <p className="mb-5">保険契約のキャンセルはチャブ保険のお客様確認ページから別途お手続きが必要となります。</p>
            <p className="mb-16 mb-24-md">
              <a href="https://ssp.chubbtravelinsurance.com/ctijp" target="_blank" className="fz-12 fz-14-md" rel="noreferrer">
                保険契約のキャンセルはこちら<i className="icon-blank ml-5"></i>
              </a>
            </p>
            <ul className="list-note light-gray">
              <li>証券番号と保険始期日はチャブ保険の契約完了のご案内メールをご確認ください。</li>
              <li>保険金はキャンセル手続き後、チャブ保険から返金となります。</li>
            </ul>
          </div>

          {/* 参加者情報 */}
          <div className="mb-16 mb-24">
            <table className="table-responsive table-xsmall">
              <tbody>
                <tr>
                  <th
                    colSpan={2}
                    className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large"
                  >
                    参加者情報
                  </th>
                </tr>
                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b w-175-md">
                    参加者{toCircled(1)}
                  </th>
                  <td>
                    <p>
                      <span className="mr-10 fw-b">{reserve.bookingData.representative.lastName + reserve.bookingData.representative.firstName} 様</span>
                      <span className="mr-10 fz-14">{reserve.bookingData.representative.age}歳</span>
                      <span className="fz-14">{getReserveGenderLabel(reserve.bookingData.representative.gender)}</span>
                    </p>
                  </td>
                </tr>
                {reserve?.bookingData?.travelCompanions?.map((travelCompanions, tIndex) => (
                  <Fragment key={tIndex}>
                    <tr>
                      <th className="table-bgc-light_gray ta-c-md fw-b w-175-md">
                        参加者{toCircled(tIndex + 2)}
                      </th>
                      <td>
                        <p>
                          <span className="mr-10 fw-b">{travelCompanions.lastNameKana + travelCompanions.firstNameKana} 様</span>
                          <span className="mr-10 fz-14">{getAge(travelCompanions.birthdate)}歳</span>
                          <span className="fz-14">{getReserveGenderLabel(travelCompanions.gender)}</span>
                        </p>
                        {/* todo 項目の追加が必要 */}
                        {getAge(travelCompanions.birthdate) <= 12 && (
                          <>
                            {travelCompanions.meal && travelCompanions.futon ?
                              <>
                                <p className="fz-12">食事・布団あり</p>
                              </>
                            : travelCompanions.meal ?
                              <>
                                <p className="fz-12">食事あり、布団なし</p>
                              </>
                            : travelCompanions.futon ?
                              <>
                                <p className="fz-12">食事なし、布団あり</p>
                              </>
                            : !travelCompanions.futon && !travelCompanions.meal ?
                              <>
                                <p className="fz-12">食事・布団なし</p>
                              </>:<></>
                            }
                          </>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* フライト */}
          <div className="mb-16 mb-24">
            <table className="table-xsmall">
              <tbody>
                <tr>
                  <td className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={3}>フライト</td>
                </tr>
                <tr>
                  <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={3}>往路</th>
                </tr>
          
                {flights.inbound.map((row, iId) => (
                  <Fragment key={iId}>
                  <tr>
                    <th className="table-bgc-light_gray w-80 w-110-md ta-c fw-b" rowSpan={4}>フライト<br />{toCircled(iId + 1)}</th>
                    <th className="table-bgc-light_gray w-70 w-100-md ta-c fw-b">搭乗日</th>
                    <td>{dateFormat(row.departure_date, 'YYYY年MM月DD日（ddd）')}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray w-70 w-100-md ta-c fw-b">便名</th>
                    <td>{ getFlightNumberForDisplayExcludeSuffix({ airCode: row.air_code, airSubNo: row.air_sub_no}) }({getFlightCodeLabel(row.depapo) + ' ' + getFlightTime(row.dep)} - {getFlightCodeLabel(row.arrapo) + ' ' + getFlightTime(row.arv)})</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray w-70 w-100-md ta-c fw-b">クラス</th>
                    <td>{getFlightClassLabel(row.class_code)}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray w-70 w-100-md ta-c fw-b">席数</th>
                    <td>
                      大人 {flightPeoples.adults.toString()} 席 {flightPeoples.child < 1 ? "" : "/ 子供 " + flightPeoples.child.toString() + "席"} {flightPeoples.infant < 1 ? "" : "/ 幼児 " + flightPeoples.infant.toString() + "名"}
                      <br/>
                      ※12歳以上は大人運賃が適用されます。
                    </td>
                  </tr>
                  </Fragment>
                ))}
          
                {/* 復路 */}
                <tr>
                  <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={3}>復路</th>
                </tr>
                {flights.outbound.map((row, oId) => (
                  <Fragment key={oId}>
                    <tr>
                      <th className="table-bgc-light_gray w-80 w-110-md ta-c fw-b" rowSpan={4}>フライト<br />{toCircled(oId + 1)}</th>
                      <th className="table-bgc-light_gray w-70 w-100-md ta-c fw-b">搭乗日</th>
                      <td>{dateFormat(row.departure_date, 'YYYY年MM月DD日（ddd）')}</td>
                    </tr>
                    <tr>
                      <th className="table-bgc-light_gray w-70 w-100-md ta-c fw-b">便名</th>
                      <td>{ getFlightNumberForDisplayExcludeSuffix({ airCode: row.air_code, airSubNo: row.air_sub_no}) }({getFlightCodeLabel(row.depapo) + ' ' + getFlightTime(row.dep)} - {getFlightCodeLabel(row.arrapo) + ' ' + getFlightTime(row.arv)})</td>
                    </tr>
                    <tr>
                      <th className="table-bgc-light_gray w-70 w-100-md ta-c fw-b">クラス</th>
                      <td>{getFlightClassLabel(row.class_code)}</td>
                    </tr>
                    <tr>
                      <th className="table-bgc-light_gray w-70 w-100-md ta-c fw-b">席数</th>
                      <td>
                        大人 {flightPeoples.adults.toString()} 席 {flightPeoples.child < 1 ? "" : "/ 子供 " + flightPeoples.child.toString() + "席"} {flightPeoples.infant < 1 ? "" : "/ 幼児 " + flightPeoples.infant.toString() + "名"}
                        <br/>
                        ※12歳以上は大人運賃が適用されます。
                      </td>
                    </tr>
                  </Fragment>
                ))}
          
              </tbody>
            </table>  
          </div>

          {/* 宿泊 */}
          <div className="mb-16 mb-24">
            <table className="table-xsmall">
              <tbody>
                <tr>
                  <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>宿泊</th>
                </tr>
                
                {/* 宿泊1 */}
                {reserve.subData.hotel.map((row, hId) => (
                  <Fragment key={hId}>
                  <tr>
                    <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>{row.data.hotelName}</th>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">予約番号</th>
                    <td>{row.data.bookingNumber?? ""}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">チェックイン</th>
                    <td>{dateFormat(row.data.check_in, "YYYY年MM月DD日（ddd）")}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">チェックアウト</th>
                    <td>{dateFormat(row.data.check_out, "YYYY年MM月DD日（ddd）")}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">部屋タイプ</th>
                    <td>{row.data.roomName}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">予約プラン</th>
                    <td>{row.data.planName}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">客室数</th>
                    <td>{row.data.count_rooms}室</td>
                  </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>  
          </div>

          {reserve.subData.plan && (
            <>
              {/* 基本プラン */}
              <div className="mb-16 mb-24">
                <table className="table-xsmall">
                  <tbody>
                  <tr>
                    <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>基本プラン</th>
                  </tr>

                  {/* 基本プラン1 */}

                  {reserve.subData.plan.map((row,rId) => (
                    <Fragment key={rId}>
                      <tr>
                        <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>基本プラン{toCircled(row.data.order)}</th>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">予約番号</th>
                        <td>{row.data.bookingNumber ?? ""}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">ご利用日</th>
                        <td>{dateFormat(row.data.use_date, "YYYY年MM月DD日（ddd）") + toDisplayTime(row.data.use_time)}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">プラン名</th>
                        <td>
                          {row.data.planName}
                          {row.data?.planData && (
                            <>
                              <br/>
                              <button
                                  className="button-border-small mt-4"
                                  onClick={() => handleActivityDetailClick(row.data?.planData ?? undefined)}
                              >
                                プラン詳細
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">申し込み内容</th>
                        <td>
                          {row.data.price_type_detail.map((priceDetail, pId) => (
                            <Fragment key={pId}>
                              {priceDetail.num > 0 && (
                                <>
                                  {priceDetail.name} × {priceDetail.num}
                                </>
                              )}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {reserve.subData.rentalCar  && (
            <>
              {/* レンタカー */}
              <div className="mb-16 mb-24">
                <table className="table-xsmall">
                  <tbody>
                  <tr>
                    <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>レンタカー</th>
                  </tr>

                  {reserve.subData?.rentalCar?.map((row, rIndex) => (
                    <Fragment key={rIndex}>
                      <tr>
                        <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>
                          <div className="d-f jc-sb">
                            <p>レンタカー{toCircled(row.data.order)}</p>
                            {
                              (() => {
                                if (row.data.status === "cancel") {
                                  return (<p><span className="label-orange-sausage">キャンセル済み</span></p>);
                                }
                              })()
                            }
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">予約番号</th>
                        <td>{row.data.bookingNumber}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">貸出</th>
                        <td>
                          {row.data.departure_date_label}<br/>
                          {row.data.departure_office_label}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">返却</th>
                        <td>
                          {row.data.return_date_label}<br/>
                          {row.data.return_office_label}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">車種</th>
                        <td>
                          {row.data.car_detail.map((carDetail, cId) => (
                            <Fragment key={cId}>
                              {carDetail.car_model}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">オプション</th>
                        <td>
                          {row.data.car_detail[0]?.count_option?.anshin?.used && (
                            <>
                              {getCarRentalAnshinOptionItem(row.data.car_detail[0].count_option.anshin.option_name, 'label')}　
                            </>
                          )}
                          {row.data.car_detail[0]?.count_option?.anshin_premium?.used && (
                            <>
                              {getCarRentalAnshinOptionItem(row.data.car_detail[0].count_option.anshin_premium.option_name, 'label')}　
                            </>
                          )}
                          {row.data.count_option.baby_seat > 0 && (
                            <>
                              乳幼児ベビーシート × {row.data.count_option.baby_seat}　
                            </>
                          )}
                          {row.data.count_option.child_seat > 0 && (
                            <>
                              幼児用チャイルドシート × {row.data.count_option.child_seat}　
                            </>
                          )}
                          {row.data.count_option.junior_seat > 0 && (
                            <>
                              学童用ジュニアシート × {row.data.count_option.junior_seat}　
                            </>
                          )}
                        </td>
                      </tr>

                    </Fragment>
                  ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {/* 追加オプション */}
          {reserve.subData.options && (
            <>
              <div className="mb-16 mb-24">
                <table className="table-xsmall">
                  <tbody>
                  <tr>
                    <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>追加オプション</th>
                  </tr>

                  {/* 追加オプション1 */}
                  {reserve.subData?.options?.map((row,oIndex) => (
                    <Fragment key={oIndex}>
                      <tr>
                        <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>
                          <div className="d-f jc-sb">
                            <p>追加オプション{toCircled(row.data.order)}</p>
                            {
                              (() => {
                                if (row.data.status === "cancel") {
                                  return (<p><span className="label-orange-sausage">キャンセル済み</span></p>);
                                }
                              })()
                            }
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">予約番号</th>
                        <td>{row.data.bookingNumber}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">ご利用日</th>
                        <td>{dateFormat(row.data.use_date, 'YYYY年MM月DD日（ddd）') + toDisplayTime(row.data.use_time)}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">プラン名</th>
                        <td>
                          {row.data.planName}
                          {row.data?.planData && (
                            <>
                              <br/>
                              <button
                                  className="button-border-small mt-4"
                                  onClick={() => handleActivityDetailClick(row.data?.planData ?? undefined)}
                              >
                                プラン詳細
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">申し込み内容</th>
                        <td>
                          {row.data.price_type_detail.map((priceDetail, pId) => (
                            <Fragment key={pId}>
                              {priceDetail.num > 0 && (
                                <>
                                  {priceDetail.name} × {priceDetail.num}
                                </>
                              )}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                  </tbody>
                </table>
              </div>
            </>
          )}


          {/* サービス保険 */}
          {reserve.bookingData.insurance?.serviceInsurance?.enable && (
              <>
                <div className="mb-16 mb-24">
                  <table className="table-xsmall mb-16">
                    <tbody>
                    <tr>
                      <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large"
                          colSpan={2}>国内旅行傷害保険
                      </th>
                    </tr>

                    <tr>
                      <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">保険加入期間</th>
                      <td>
                        {formatToDate(reserve.bookingData.insurance.serviceInsurance.tripStartDate)}〜{formatToDate(reserve.bookingData.insurance.serviceInsurance.tripEndDate)}×{convertToPeople(reserve.bookingData.insurance.serviceInsurance.people)}
                        <br/>
                        ※お申込みのツアーには、国内旅行傷害保険が付帯されています。<a href="https://ots.digitaldmoplatform.com/lp/insurance_include/" target="_blank">詳細はこちら</a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </>
          )}
          
          {/* 国内旅行傷害保険 */}
          {reserve.bookingData.insurance.insurance && (
            <>
              <div className="mb-16 mb-24">
                <table className="table-xsmall mb-16">
                  <tbody>
                  <tr>
                    <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>国内旅行傷害保険</th>
                  </tr>

                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">保険加入期間</th>
                    <td>{dateFormat(reserve.bookingData.insurance.period?.start, 'YYYY年MM月DD日(ddd)')}〜{dateFormat(reserve.bookingData.insurance.period?.end, 'YYYY年MM月DD日(ddd)')}×{reserve.bookingData.priceDetail.insurance.DTA.people}</td>
                  </tr>
                  </tbody>
                </table>
                <ul className="list-note light-gray mb-16">
                  <li>ご契約内容および契約内容の変更は別途ご案内の「保険契約手続き完了のご案内メール」に記載のChubb損害保険㈱お客様確認用ページをご参照ください。</li>
                </ul>
                <p className="mb-16 mb-24-md">

                  <a href="https://ssp.chubbtravelinsurance.com/ctijp" target="_blank" className="fz-12" rel="noreferrer">

                    Chubb損害保険㈱お客様確認用ページ<i className="icon-blank ml-5"></i>
                  </a>
                </p>
              </div>
            </>
          )}

          {/* 旅のキャンセル保険 */}
          {reserve.bookingData.insurance.cancelInsurance && (
            <>
              <div>
                <table className="table-xsmall mb-16">
                  <tbody>
                  <tr>
                    <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>旅のキャンセル保険</th>
                  </tr>

                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">保険加入期間</th>
                    <td>
                      {
                        reserve.bookingData.priceDetail.insurance.TripCXL.tripStartDate || reserve.bookingData.priceDetail.insurance.TripCXL.tripEndDate ? (
                          <>
                            {dateFormat(reserve.bookingData.priceDetail.insurance.TripCXL.tripStartDate, 'YYYY年MM月DD日(ddd)')}〜{dateFormat(reserve.bookingData.priceDetail.insurance.TripCXL.tripEndDate, 'YYYY年MM月DD日(ddd)')}
                          </>
                        ) : (
                          <></>
                        )
                      }
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ul className="list-note light-gray mb-16">
                  <li>ご契約内容および契約内容の変更は別途ご案内の「保険契約手続き完了のご案内メール」に記載のChubb損害保険㈱お客様確認用ページをご参照ください。</li>
                </ul>
                <p>

                  <a href="https://ssp.chubbtravelinsurance.com/ctijp" target="_blank" className="fz-12" rel="noreferrer">

                    Chubb損害保険㈱お客様確認用ページ<i className="icon-blank ml-5"></i>
                  </a>
                </p>
              </div>
            </>
          )}
        </div>
      </section>
      <MyPageOptionSelectModal activity={activity} isShow={isActivityModal} callback={handleActivityModal} />
    </>
  );
};

export default SectionDetail;
