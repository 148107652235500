import React, {useContext, useEffect, useState} from "react";

import { useForm, SubmitHandler } from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import { RootState } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { save } from "../redux/form2Slice";
import { InputsForm2 } from "../types/InputsForm2";

import { useSendTestMail } from "../utils/useSendTestMail";
import { useToCircledNumberLabel } from "../utils/useToCircledNumberLabel";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

//parts import
import FormStep from "./Form/FormStep";
import {
  SectionApplicantOption,
  SectionPlanCheck,
} from "./section/Form2"
import {PlanSelectFormData} from "../types/planSelectForm";
import {getOptionsInputFields, getPlanInputFields} from "../utils/form2";
import {isOption} from "../utils/reserve";
import {devLog} from "../utils/errors";
import {getHeadTitle} from "../utils/title";
import {FormSessionContext} from "../hooks/FormSession";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";
import { PartsTutorialButton } from "./parts";

const app = process.env.REACT_APP_MODE;

// lodingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Form2: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  const {checkSession} = useContext(FormSessionContext);
  const navigate = useNavigate();
  const location = useLocation()
  const [planSelectFormData, setPlanSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData }) //TODO: need change

  const classesForLoading = useStyles();

  const [ sendTestMail, { isLoading } ] = useSendTestMail();

  const [ toCircledNumberLabel ] = useToCircledNumberLabel();

  const storedForm2 = useSelector((state: RootState) => state.form2.form2);
  useEffect(() => {
    window.scrollTo(0, 0)
    tagManagerContext.pageView(getHeadTitle(planSelectFormData.data, "オプション情報入力"))
  }, []);

  const dispatch = useDispatch();
  const savedState = useSelector((state: RootState) => state);
  devLog("redux form1〜4", {
    ...savedState.form1,
    ...savedState.form2,
    ...savedState.form3,
    ...savedState.form4,
  });
  const savedForm1 = savedState.form1.form1;

  const setApplicant2 = () => {
    devLog("savedForm1", savedForm1);
    const applicant = {
      name: {
        kana: {
          last: "",
          first: "",
        },
      },
      prefecture: savedForm1.applicant.address.prefecture,
      tel: savedForm1.applicant.tel,
      email: savedForm1.applicant.email,
      email_confirm: savedForm1.applicant.email_confirm,
      gender: "male",
    };
    if (savedForm1.representative.is_applicant_join === "yes") {
      applicant.name = {
        kana: savedForm1.applicant.name.kana,
      }
      applicant.gender = savedForm1.applicant.gender;
    } else {
      applicant.name = {
        kana: savedForm1.representative.name.kana
      }
      applicant.tel = savedForm1.representative.tel;
      applicant.gender = savedForm1.representative.gender;
    }
    
    return {...storedForm2, ...{
        applicant2: applicant
    }}

  }

  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit,
    register,
  } = useForm<InputsForm2>({
    defaultValues: setApplicant2(),
  });
  
  const onSubmit: SubmitHandler<InputsForm2> = (data) => {
    const session = checkSession(planSelectFormData.data);
    if (session) {
      dispatch(save({ form2: data }));
      navigate("/Form3",{ state: {
          data: planSelectFormData.data
        } });
    }
  };
  
  let submitLabel = '保険加入の選択へ進む';

  
  return (
    <MainTemplate title={getHeadTitle(planSelectFormData.data, "オプション情報入力")} planSelectFormData={planSelectFormData.data}>

        {/* ローディングの表示(APIのレスポンス待ち) */}
        <Backdrop className={classesForLoading.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">
              オプション情報入力
            </h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
            <FormStep activeStep={2} isOption={isOption(planSelectFormData.data)}></FormStep>

            {/* 操作ガイドボタン */}
            <PartsTutorialButton value="component-content" />

            <form action="" onSubmit={handleSubmit(onSubmit)}>
              {/* <!--オプションの代表者情報の入力--> */}
              <SectionApplicantOption
                register={register}
                getValues={getValues}
                control={control} 
                errors={errors}
                sendTestMail={sendTestMail}
              >
              </SectionApplicantOption>
              
              {/* <!--プラン別確認事項の入力--> */}
              <SectionPlanCheck
                planSelectFormData={planSelectFormData.data}
                register={register}
                getValues={getValues}
                control={control} 
                errors={errors}
                toCircledNumberLabel={toCircledNumberLabel}
              >
              </SectionPlanCheck>

              {/* <!--次へ進む--> */}
              <div className="mt-48 mt-64-md">
                <ul className="form-step_button mt-24">
                  <li className="form-step_button-next">
                    <button
                      type="submit"
                      className="button-large-red"
                      // todo: class作る
                      style={{
                        display: "block",
                        border: "none",
                        width: "100%",
                      }}
                      // disabled={!isDirty || isSubmitting} // note: これだとhistory.back()で戻ったときにdisabledになる
                      disabled={isSubmitting}
                    >
                      <span className="label-number-orange mr-5">3</span>
                      {submitLabel}
                    </button>
                  </li>
                  <li className="form-step_button-back">
                    <button
                      type="button"
                      className="button-small-border_gray_blue button-medium-md"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <i className="icon-left_arrow mr-5"></i>
                      お客様情報入力に戻る
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>

    </MainTemplate>
  );
};

export default Form2;
