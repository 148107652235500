import React from "react";

// for google map
import GoogleMapTemplate from "../google/Maps";

// for styles
import {
  Button,
  Backdrop,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  callback: () => void,
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HotelScheduleModal: React.FC<Props> = ({
  isShow,
  callback
}) => {

  const [ModalHotelOpen, setModalHotelOpen] = React.useState(false);
  const ModalHotelHandleOpen = () => setModalHotelOpen(true);
  const ModalHotelHandleClose = () => setModalHotelOpen(false);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
    <Modal
        open={ModalHotelOpen}
        onClose={ModalHotelHandleClose}
        className="modal"
      >
        <div className="modal-fixed">
          <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-text">オプション選択</p>
            <p className="modal-fixed-close">
              <Button
                onClick={ModalHotelHandleClose}
                className="modal-fixed-close-button"
              >
                <i className="modal-fixed-close-button-icon"></i>閉じる
              </Button>
            </p>
          </div>
          <div className="modal-fixed-content">
            <div className="step-tab">
              <Box className="step-tab-option">
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="step-tab-option-list"
                    variant="fullWidth"
                    TabIndicatorProps={{ style: { background: "none" } }}
                  >
                    <Tab
                      label="詳細確認"
                      className="step-tab-option-list-item"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="日程選択"
                      className="step-tab-option-list-item"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>

                {/* タブ：詳細確認　*/}
                <TabPanel key={"tab_desc"} value={value} index={0}>
                  <div className="wrap-content-900-no_padding">
                    <div className="ta-c fz-36 mt-40 mb-40">
                      スライダー
                    </div>
                    
                  </div>

                  <div className="modal-fixed-bottom">
                    <button
                      onClick={ModalHotelHandleClose}
                      className="button-medium-orange padding-large w-100per"
                    >
                      日程を選択する
                    </button>
                  </div>
                </TabPanel>

                {/* タブ：日程選択　*/}
                <TabPanel key={"tab_date"} value={value} index={1}>
                  <div className="wrap-content-900-no_padding">
                    <h2 className="fz-20 fz-24-md mb-40">
                      日程を選択してください
                    </h2>
                    <div>
                      <p className="fz-12 fz-14-md">選択中のプラン</p>
                      <div className="box-light-gray small mb-24">
                        <p className="fz-10 fz-12-md">
                          【4～11月限定】BBQディナークルーズ（モビーディック3Fオープンデッキ）徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯
                        </p>
                      </div>
                    </div>
                    <form action="">
                      <div className="box_select-hotel">
                        <div className="box_select-hotel-schedule">
                          <ul className="box_select-hotel-schedule-list">
                            <li key="hotel-schedule-list" className="box_select-hotel-schedule-list-item">
                              <div className="box_select-hotel-schedule-list-item-date">
                                <div className="box_select-hotel-schedule-list-item-date-content">
                                  <ul className="box_select-hotel-schedule-list-item-date-content-list">
                                    <li key="hotel-schedule-list-item-date" className="box_select-hotel-schedule-list-item-date-content-list-label">
                                      <input
                                        type="radio"
                                        name="hotel"
                                        id="hotel_01_01_schedule"
                                        value="hotel_01_01_schedule"
                                        checked
                                      />
                                      <label
                                        htmlFor="hotel_01_01_schedule"
                                        className=""
                                      >
                                        2021年3月15日（月）
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-label-price">
                                          18,900
                                          <span className="fz-14">
                                            円〜
                                          </span>
                                        </span>
                                      </label>
                                    </li>
                                    <li key="hotel-schedule-list-item-date-status" className="box_select-hotel-schedule-list-item-date-content-list-status">
                                      <label
                                        htmlFor="hotel_01_01_schedule"
                                        className="box_select-hotel-schedule-list-item-date-content-list-status-label"
                                      >
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-status-label-text">
                                          空き
                                        </span>
                                        <i className="icon-possible"></i>
                                      </label>
                                    </li>
                                    <li key="hotel-schedule-list-item-date-result" className="box_select-hotel-schedule-list-item-date-content-list-result">
                                      <div className="w-100 w-100per-md d-f jc-c ai-c">
                                        <div className="select_box-default w-100per-md">
                                          <select name="hotel-option_of_hour">
                                            <option value="">時間</option>
                                            <option value="option_of_hour-1">
                                              14:00
                                            </option>
                                            <option value="option_of_hour-2">
                                              16:00
                                            </option>
                                            <option value="option_of_hour-3">
                                              18:00
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li key="hotel-schedule-list-item" className="box_select-hotel-schedule-list-item">
                              <div className="box_select-hotel-schedule-list-item-date">
                                <div className="box_select-hotel-schedule-list-item-date-content invalid">
                                  <ul className="box_select-hotel-schedule-list-item-date-content-list">
                                    <li key="hotel-schedule-list-item-label" className="box_select-hotel-schedule-list-item-date-content-list-label">
                                      <input
                                        type="radio"
                                        name="hotel"
                                        id="hotel_01_02_schedule"
                                        value="hotel_01_02_schedule"
                                        checked
                                      />
                                      <label
                                        htmlFor="hotel_01_02_schedule"
                                        className=""
                                      >
                                        2021年3月16日（火）
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-label-price">
                                          18,900
                                          <span className="fz-14">
                                            円〜
                                          </span>
                                        </span>
                                      </label>
                                    </li>
                                    <li key="hotel-schedule-list-item-status2" className="box_select-hotel-schedule-list-item-date-content-list-status">
                                      <label
                                        htmlFor="hotel_01_02_schedule"
                                        className="box_select-hotel-schedule-list-item-date-content-list-status-label"
                                      >
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-status-label-text">
                                          空き
                                        </span>
                                        <i className="icon-impossible"></i>
                                      </label>
                                    </li>
                                    <li key="hotel-schedule-list-item-result2" className="box_select-hotel-schedule-list-item-date-content-list-result">
                                      <div className="w-100 w-100per-md d-f jc-c ai-c">
                                        <div className="select_box-default w-100per-md">
                                          <select name="hotel-option_of_hour">
                                            <option value="">時間</option>
                                            <option value="option_of_hour-1">
                                              14:00
                                            </option>
                                            <option value="option_of_hour-2">
                                              16:00
                                            </option>
                                            <option value="option_of_hour-3">
                                              18:00
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li key="hotel-schedule-list-item2" className="box_select-hotel-schedule-list-item">
                              <div className="box_select-hotel-schedule-list-item-date">
                                <div className="box_select-hotel-schedule-list-item-date-content">
                                  <ul className="box_select-hotel-schedule-list-item-date-content-list">
                                    <li key="hotel-schedule-list-item-label2" className="box_select-hotel-schedule-list-item-date-content-list-label">
                                      <input
                                        type="radio"
                                        name="hotel"
                                        id="hotel_01_03_schedule"
                                        value="hotel_01_03_schedule"
                                        checked
                                      />
                                      <label
                                        htmlFor="hotel_01_03_schedule"
                                        className=""
                                      >
                                        2021年3月17日（水）
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-label-price">
                                          18,900
                                          <span className="fz-14">
                                            円〜
                                          </span>
                                        </span>
                                      </label>
                                    </li>
                                    <li className="box_select-hotel-schedule-list-item-date-content-list-status">
                                      <label
                                        htmlFor="hotel_01_03_schedule"
                                        className="box_select-hotel-schedule-list-item-date-content-list-status-label"
                                      >
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-status-label-text">
                                          空き
                                        </span>
                                        <span className="c-orange">
                                          9
                                        </span>
                                      </label>
                                    </li>
                                    <li className="box_select-hotel-schedule-list-item-date-content-list-result">
                                      <div className="w-100 w-100per-md d-f jc-c ai-c">
                                        <div className="select_box-default w-100per-md">
                                          <select name="hotel-option_of_hour">
                                            <option value="">時間</option>
                                            <option value="option_of_hour-1">
                                              14:00
                                            </option>
                                            <option value="option_of_hour-2">
                                              16:00
                                            </option>
                                            <option value="option_of_hour-3">
                                              18:00
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                    <div>
                      <p className="fz-14 fz-16-md mb-16">
                        アイコンについて
                      </p>
                      <div className="box-light-gray small mb-24">
                        <ul className="box_select-hotel-icon-list">
                          <li className="box_select-hotel-icon-list-item">
                            <div className="box_select-hotel-icon-status">
                              <span className="d-b fz-10 fz-14-md mb-5 mb-0-md">
                                空き
                              </span>
                              <i className="icon-possible"></i>
                            </div>
                            <div className="fz-12 fz-14-md">
                              10名以上の空きあり
                            </div>
                          </li>
                          <li className="box_select-hotel-icon-list-item">
                            <div className="box_select-hotel-icon-status">
                              <span className="d-b fz-10 fz-14-md mb-5 mb-0-md">
                                空き
                              </span>
                              <span className="fz-14 lh-1 c-orange">
                                9
                              </span>
                            </div>
                            <div className="fz-12 fz-14-md">
                              1-9名の空きあり
                            </div>
                          </li>
                          <li className="box_select-hotel-icon-list-item">
                            <div className="box_select-hotel-icon-status">
                              <span className="d-b fz-10 fz-14-md mb-5 mb-0-md">
                                空き
                              </span>
                              <i className="icon-impossible"></i>
                            </div>
                            <div className="fz-12 fz-14-md">受付終了</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="modal-fixed-bottom">
                    <button
                      onClick={ModalHotelHandleClose}
                      className="button-medium-orange padding-large w-100per"
                    >
                      確定する
                    </button>
                  </div>
                </TabPanel>
              </Box>
            </div>
          </div>
          {/* <div className="modal-fixed-bottom">
            <button
              onClick={ModalHotelHandleClose}
              className="button-medium-light_blue w-100per"
            >
              選択する
            </button>
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default HotelScheduleModal;
