import React, {useEffect} from "react";

// for styles
import Backdrop from "@material-ui/core/Backdrop";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import { formatToPrice } from "../../utils/price";
import {IActivity} from "../../types/Activity";
import dayjs from "dayjs";
dayjs.locale("ja");

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  onClickClose: () => void,
  missForFlightActivity: IActivity[],
  callbackDiscountApprove: (isDiscount: boolean) => void
  flightType: string | undefined
}

const FlightApproveModal: React.FC<Props> = ({
  isShow,
  onClickClose,
  missForFlightActivity,
  callbackDiscountApprove,
  flightType
}) => {

  const [flightAlertTime, setFlightAlertTime] = React.useState(0);
  useEffect(() => {
    if (isShow) {
      let alertTime = 0;
      let alertDay = "";
      missForFlightActivity.forEach(activity => {
        const date = activity.date + " " + activity.time;
        if (alertDay === "") {
          alertDay = date;
        }
        if ( flightType === 'outbound' && (dayjs(alertDay).isBefore(dayjs(date)) || alertDay === date) ) {
          alertTime = Number(activity.alertTime);
          alertDay = date;
        } else if ( flightType === 'inbound' && (dayjs(alertDay).isAfter(dayjs(date)) || alertDay === date) ) {
          alertTime = Number(activity.alertTime);
          alertDay = date;
        }
      });
      setFlightAlertTime(alertTime);
      
    }
  }, [isShow]);
  
  const disapprove = () => {
    onClickClose();
  }

  const approve = () => {
    callbackDiscountApprove(true);
  }

  return (
    <Modal
      open={isShow}
      onClose={onClickClose}
      className="modal-simple"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div className="modal-simple-content w-640">
        <div className="modal-simple-content-box">
          <div className="modal-simple-content-close">
            <button className="button-clear" onClick={onClickClose}>
              <i className="icon-modal-close-navy fz-30"></i>
            </button>
          </div>
          <div className="box-separate">
            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <div className="box-alert-red-small pc-small mt-16-md ta-c">
                  <p className="fz-14 fz-16-md c-red fw-b">
                    <i className="icon-alert"></i>選択されたフライトでは間に合いません
                  </p>
                </div>
                <p className="heading-2 fz-24-md mt-24 mb-24 mt-40-md ta-c">
                  {flightType === "outbound" ? "復路" : "往路"}のフライト
                </p>
                {flightType === "outbound" ? (
                  <>
                    <p className="mt-24 mb-4 lh-1_75">
                      選択された復路フライトの出発時間では、ご搭乗に間に合わない可能性があります。<br/>
                      基本プラン終了時刻の{flightAlertTime}分後以降に出発するフライトを選択するか、翌日のフライトをおすすめします。
                    </p>
                  </>
                ) : (
                  <>
                    <p className="mt-24 mb-4 lh-1_75">
                      選択された往路フライトの到着時間では、基本プランの参加時間に間に合わない可能性があります。<br/>
                      参加時間の{flightAlertTime}分前に到着するフライトを選択するか、前日のフライトをおすすめします。
                    </p>
                  </>
                )}
                <p className="fz-13">
                  ※推奨時間は車での移動を想定した目安です。交通事情により、実際の移動時間が変動する可能性がありますので、余裕を持った旅行計画をおすすめいたします。
                  なお、このアラートはお客様の予約を強制的に制限するものではありません。
                </p>
                <table className="table table-xsmall mt-32 mb-8">
                  <tbody>
                  <tr>
                    <th className="table-bgc-light_navy fw-b">基本プラン名</th>
                    <th className="table-bgc-light_navy fw-b">参加時間</th>
                  </tr>
                  {missForFlightActivity.map((activity, index) => (
                    <tr>
                      <th className="table-bgc-light_gray fw-b ta-l w-40per">
                        {activity.plan_info.plan_name}
                      </th>
                      <td className="ta-l">
                        参加時間：{activity.time}<br/>
                        {flightType === "outbound" ?
                          <>
                            所要時間：{activity.plan_info.time_required}時間<br/>
                            プラン終了{activity.alertTime}分後以降のフライトを推奨します
                          </>
                          :
                          (
                            <>
                              参加時間{activity.alertTime}分前以前のフライトを推奨します
                            </>
                          )
                        }
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <p className="mb-16 mt-8 ta-c">
                  <button onClick={approve} className="button-medium-red w-100per w-270-md fz-16 fw-b">
                    このまま予約を進める
                  </button>
                </p>

                <p className="ta-c">
                  <button onClick={disapprove} className="button-border-medium w-100per w-270-md">
                    戻って時間を変更する
                  </button>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FlightApproveModal;
