import React from "react";

// for styles
import Backdrop from "@material-ui/core/Backdrop";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import { formatToPrice } from "../../utils/price";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  onClickClose: () => void,
  priceBeforeDiscount: number,
  priceAfterDiscount: number,
  callbackDiscountApprove: (isDiscount: boolean) => void
}

const SupportRateApproveModal: React.FC<Props> = ({
  isShow,
  onClickClose,
  priceBeforeDiscount,
  priceAfterDiscount,
  callbackDiscountApprove,
}) => {

  const disapprove = () => {
    callbackDiscountApprove(false);
  }

  const approve = () => {
    callbackDiscountApprove(true);
  }

  return (
    <Modal
      open={isShow}
      onClose={onClickClose}
      className="modal-simple"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div className="modal-simple-content w-640">
        <div className="modal-simple-content-box">
          <div className="modal-simple-content-close">
            <button className="button-clear" onClick={onClickClose}>
              <i className="icon-modal-close-navy fz-30"></i>
            </button>
          </div>
          <div className="box-separate">
            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <div className="box-alert-red-small pc-small mt-16-md ta-c">
                  <p className="fz-14 fz-16-md c-red fw-b">
                    <i className="icon-alert"></i>全国旅行支援の割引適用における<span className="d-ib">注意事項</span>
                  </p>
                </div>
                <p className="mt-24 lh-1_75">
                  全国旅行支援における割引を適用するには、宿泊施設へのチェックイン時にご旅行者様全員の本人確認書類をご提示いただく必要がございます、<br />
                  また、各都道府県による独自のルールや提出が必要な書類がある場合がございますので、お客様ご自身にてそれらをご確認いただくことに同意いただく必要がございます。<br />
                  全国旅行支援による割引を適用してご予約を完了した後であっても、チェックイン時に必要な書類をご提示いただけなかったり、その他条件を満たさないことが判明した場合、後日割引相当額を請求させていただきます。
                </p>
                <table className="table table-xsmall mt-32 mb-8">
                  <tbody>
                    <tr>
                      <th className="table-bgc-light_navy fw-b" colSpan={2}>旅行代金</th>
                    </tr>
                    <tr>
                      <th className="table-bgc-light_gray fw-b ta-l w-50per">割引適用（前）</th>
                      <td className="fw-b ta-l">
                        <span>¥{formatToPrice(priceBeforeDiscount)}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="table-bgc-light_gray fw-b ta-l color-red">割引適用（後）</th>
                      <td className="fw-b ta-l color-red">
                        <span>¥{formatToPrice(priceAfterDiscount)}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <p className="mb-16 mt-8 ta-c">
                  <button onClick={approve} className="button-medium-red w-100per w-270-md fz-16 fw-b">
                    同意して予約へ進む
                  </button>
                </p>

                <p className="ta-c">
                  <button onClick={disapprove} className="button-border-medium w-100per w-270-md">
                    全国旅行支援を適用せずに<br />
                    （割引なしで）予約する
                  </button>
                </p>

                <p className="mt-32 ta-c">
                  <button className="button-text-link-blue fw-b" onClick={onClickClose}>閉じる</button>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SupportRateApproveModal;
