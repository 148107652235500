import React, {useContext, useEffect, useState} from "react";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

//パーツをインポート
import { PartsStep, PartsCancellationChargeText, PartsTutorialButton } from "./parts";

import { SectionDetail, SectionCheck } from "./section/PlanSelectComplete";
import { PlanSelectFormData } from "../types/planSelectForm";
import { useLocation, useNavigate } from "react-router-dom";
import { getPriceRequestParams } from "../utils/price";
import { useTravelPrice } from "../utils/useTravelPrice";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SectionTravelPrice from "./section/SectionTravelPrice";
import { isPlanSelectComplete } from "../utils/errors";
import Page404 from "./Page404";
import {defaultPlanSelectForm} from "../defaults/palnSelectForm";
import {getBackSearchURL} from "../utils/search";
import SectionTravelDiscountDetail from "./section/OkinawaSaihakken/SectionTravelDiscountDetail";
import {getHeadTitle} from "../utils/title";

//モーダルをインポート
import { SupportRateApproveModal} from "./modal"
import _ from "lodash";
import {FormSessionContext} from "../hooks/FormSession";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";

// lodingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));


const PlanSelectComplete: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  const {checkSession} = useContext(FormSessionContext);
  const classesForLoading = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [move, setMove] = useState(false);
  const planData = (location.state as { data: PlanSelectFormData }) ?? {data: defaultPlanSelectForm};
  const [planSelectFormData, setPlanSelectFormData] = useState<{data: PlanSelectFormData}>(planData); //TODO: need change
  const hidden_contents: string[] = [];
  if (planSelectFormData?.data?.planData?.sellerIndo?.rentalCarSettings?.status === "disabled") {
    hidden_contents.push("rentalCar");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    tagManagerContext.pageView(getHeadTitle(planSelectFormData.data, "旅程の最終確認"))
  }, []);

  /**
   * お客様情報入力ページに遷移
   */
  const handleMove = () => {
    const session = checkSession(planSelectFormData.data);
    if (
      session &&
      planSelectFormData &&
      planSelectFormData.data.hotel.length > 0 &&
      planSelectFormData.data.hotel[0].plan !== undefined
    ) {
      navigate("/form1", {
        state: {
          data: planSelectFormData.data,
        },
      });
    }
  };

  const _planSelectFormData = _.cloneDeep(planSelectFormData);
  _planSelectFormData.data.discounts = _planSelectFormData.data.discounts.map((discount) => {
    return {...discount, used: true};
  })
  const priceParams = getPriceRequestParams(_planSelectFormData.data, undefined, undefined);
  const [priceData, { isLoading, isError, error }] = useTravelPrice({data: priceParams});

  const [supportRateApproveModal, setSupportRateApproveModal] = React.useState(false);

  // 割引適用確認モーダル
  const handleSupportRateApproveModal = () => {
    if (priceData?.result?.discountPrices && priceData?.result?.discountPrices.length > 0) {
      setSupportRateApproveModal(!supportRateApproveModal)
    } else {
      handleMove();
    }
  }

  // モーダルの閉じるボタンをクリックした
  const handleCloseModal = () => {
    setSupportRateApproveModal(false);
  }

  const callbackDiscountApprove = (approve: boolean) => {
    const updatePlanSelectFormData = _.cloneDeep(planSelectFormData);
    updatePlanSelectFormData.data.discounts = updatePlanSelectFormData.data.discounts.map((discount) => {
      return {...discount, used: discount.key === "okinawaSaihakken" ? approve : discount.used};
    });
    setPlanSelectFormData(updatePlanSelectFormData);
    setMove(true);
  }

  useEffect(() => {
    if (priceData?.status === 200 && !isError) {
      planSelectFormData.data.travelPrice = priceData.result.insurance.insuranceCancelAmount;
    } else if ((priceData && priceData?.status !== 200) || isError) {
      alert("料金処理でエラーが発生しました、申し訳ありませんが時間を置いて再度プランの設定をお願いします。");
      window.location.href = getBackSearchURL(planSelectFormData.data.searchParams, planSelectFormData.data.id);
    }
  }, [priceData, isError]);

  useEffect(() => {
    if (move) {
      handleMove();
    }
  }, [planSelectFormData.data.discounts])

  if (!planSelectFormData || isPlanSelectComplete(planSelectFormData.data)) {
    return <Page404></Page404>;
  }
  return (
    <MainTemplate
      title={getHeadTitle(planSelectFormData.data, "旅程の最終確認")} 
      planSelectFormData={planSelectFormData.data}
    >
      {/* ローディングの表示(APIのレスポンス待ち) */}
      <Backdrop className={classesForLoading.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="wrap-content-900-no_padding">
        <PartsStep activeStep={3}></PartsStep>
      </div>

      {/* 操作ガイドボタン */}
      <PartsTutorialButton value="component-plan_select" />

      <div className="wrap-content-900">
        {/* <!--アラート--> */}
        <div className="box-border-blue small icon-alert mt-16-md">
          <p className="fz-15 fz-16-md c-blue fw-b">
            選択した内容、旅行代金をご確認ください
          </p>
        </div>

        {/* /行程表 */}
        <SectionDetail planSelectFormData={planSelectFormData.data}></SectionDetail>

        {/* 全国旅行支援 補助金内訳 */}
        {priceData?.result?.discountPrices && (
          <SectionTravelDiscountDetail discountPrices={priceData.result.discountPrices}></SectionTravelDiscountDetail>
        )}

        {/* 料金内訳 */}
        <SectionTravelPrice priceData={priceData}></SectionTravelPrice>

        {/* キャンセル料について */}
        <PartsCancellationChargeText></PartsCancellationChargeText>

        {/* 確認事項 */}
        <SectionCheck></SectionCheck>

        {/* モーダル支援割：承諾モーダル */}
        <SupportRateApproveModal
          isShow={supportRateApproveModal}
          onClickClose={handleCloseModal}
          priceBeforeDiscount={priceData?.result.notDiscountPriceTotal ?? 0}
          priceAfterDiscount={priceData?.result.taxTotalPrice ?? 0}
          callbackDiscountApprove={callbackDiscountApprove}
        />

        {/* <!--次へ進む--> */}
        <div className="mt-48 mt-64-md">
          <ul className="form-step_button mt-24">
            <li className="form-step_button-next">
              {/* Form1へ進む */}
              <button
                type="button"
                className="button-large-red"
                // onClick={handleMove}
                onClick={handleSupportRateApproveModal}
                style={{
                  display: "block",
                  border: "none",
                  width: "100%",
                }}
              >
                お客様情報入力へ進む
              </button>
            </li>
            <li className="form-step_button-back">
              <button
                className="button-small-border_gray_blue button-medium-md"
                onClick={() => {
                  navigate("/planselectconfirm", { state: {
                      data: planSelectFormData.data
                    } })
                }}
              >
                <i className="icon-left_arrow mr-5"></i>
                旅行日程の確認・変更へ戻る
              </button>
            </li>
          </ul>
        </div>
      </div>
    </MainTemplate>
  );
};

export default PlanSelectComplete;
