import { configureStore } from "@reduxjs/toolkit";
import form1Reducer from "./form1Slice";
import form2Reducer from "./form2Slice";
import form3Reducer from "./form3Slice";
import form4Reducer from "./form4Slice";
import form5Reducer from "./form5Slice";
import reservationCancelReducer from "./reservationCancelSlice";

export const store = configureStore({
  reducer: {
    form1: form1Reducer,
    form2: form2Reducer,
    form3: form3Reducer,
    form4: form4Reducer,
    form5: form5Reducer,
    reservationCancel: reservationCancelReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
