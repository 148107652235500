import {Airline, FlightEntity, Flights, FReservationFlightEntity, FReservationFlightsEntity,} from "../types/Flight";
import {PlanSearch, roomDetail} from "../types/Search";
import {PlanSelectFromFlightType} from "../types/planSelectForm";
import {convertReserveFlights, reserveFlightData} from "../types/reserve";
import {reserveCancelDataFlight, reserveCancelDetailFlights, reserveCancelDetailResponce} from "../types/reserveCancel";
import {addOtsRate} from "./price";

import {
  airCode,
  anaVacancyCodes,
  compositeAirport,
  connectionFlights,
  departureAirCode,
  jalVacancyCodes,
  multiAirport,
  multipleArrivals
} from "../config/Airport";
import dayjs from "dayjs";
import {devLog} from "./errors";
import {currentFlightEntity, FReservationConnectionFlight, FReservationFlight} from "../types/FlightV2";
import {convertAirCode} from "./convert";
import {availableCarRentalAirCodes} from "../config/carRental";

export const getFlightCodeLabel = (code:string|null|undefined) => {
  if(typeof code === "string" && airCode[code] !== undefined) {
    return airCode[code];
  }

  return code;
}

/**
 * フライトラベルからキー（コード）を取得
 * @param val
 */
export const getFlightCodeByLabel = (val:string) => {
  if(typeof val === "string") {
    const result = Object.keys(airCode).reduce( (r, key) => {
      return airCode[key] === val ? key : r
    }, '');

    if(result !== "") {
      return result;
    }
  }
  return val;
}

/**
 * フライトの席詳細を表示するラベルを取得
 * @param roomDetail
 */
export const getFlightPeopleConfirmLabel = (roomDetail:PlanSearch["roomDetail"]) => {
  let adult = 0;
  let child = 0;
  let infant = 0;
  if (roomDetail !== undefined && roomDetail.length > 0) {
    roomDetail.forEach((room) => {
      adult += Number(room.adult)?? 0;
      if (room?.child !== undefined && room.child.length > 0) {
        room.child.forEach((childData) => {
          if (childData.age >= 12) {
            adult++;
          }
          // 子供
          if(childData.age > 2 && childData.age <= 11){
            child++;
          }
          // 幼児
          if(childData.age < 3) {
            infant++;
          }
        });
      }
    })
  }
  const total = adult + child;

  const childTxt = child < 1 ? "" : `／子供${child}席`
  const infantTxt = infant < 1 ? "" : `／幼児${infant}名`
  return `大人${adult}席` + childTxt + infantTxt;
}

/**
 * フライトAPI用に時刻を変換
 * @param data
 */
export const getFlightTime = (data:string | undefined) => {

  if(data) {
    const a = data.slice(0, 2)
    const b = ':'
    const c = data.slice(2)
    return a + b + c;
  }

  return data

}

/**
 * フライトAPIのリクエストパラメータ用に人数データを作成
 * @param roomDetail
 */
export const getFlightPeopleNums = (roomDetail: PlanSearch["roomDetail"]) => {
  const people_detail = {
    count_adults: 0,
    count_children: 0,
    count_baby: 0,
    total: 0,
  };

  roomDetail.forEach((room) => {
    people_detail.count_adults += room.adult;
    people_detail.total += room.adult
    if (room.child.length > 0) {
      room.child.forEach((child) => {
        if (child.age >= 12) {
          people_detail.count_adults++;
        } else if (child.age >= 3)  {
          people_detail.count_children++;
        } else {
          people_detail.count_baby++;
        }
        people_detail.total++;
      })
    }
  });

  return people_detail;
}

/**
 * 選択されているフライトの合計金額を計算し、差分を返す。選択されているフライトのみ渡す
 * @param flights
 * @param roomDetail
 */
export const getFlightTotalPrice = (flights: FReservationFlightEntity[], roomDetail: PlanSearch["roomDetail"]) => {
  const peoples = getFlightPeopleNums(roomDetail);
  let price = 0;

  flights.forEach(f => {
    // 大人料金の計算
    const adultPrice = peoples.count_adults * f.adult_fee
    // 子ども料金の合計値を計算
    const childPrice = peoples.count_children * f.child_fee;
    price += (adultPrice + childPrice);
  })

  return price;
}

/**
 * 選択されているフライトを取得
 * @param action
 * @param flights
 */
export const getFlight = (action:string, flights: PlanSelectFromFlightType[]) => {
  const flight = flights.find((f) => {
    if(f.action == action && f.selected) {
      return f;
    }
  })
  return flight;
}

/**
 * フライトAPIのリクエストパラメータ用に性別を変換
 * @param gender
 */
export const getFlightGenderId = (gender:string) => {

  if (gender === "male") {
    return "1";
  }

  return  "2";
}

/**
 * フライトAPIのリクエストパラメータ用に誕生日の値を変換
 * @param birthdate
 */
export const getFlightBirthday = (birthdate: string) => {
  return birthdate.replaceAll('-', '');
}

/**
 * 予約パラメータ用にフライトデータを変換
 * @param flights
 */
export const convertReserveFlightData = (flights: PlanSelectFromFlightType[]) => {
  const flightsData: convertReserveFlights[] = [];
  const flightsInbound = flights.filter((flight) => {
    return flight.action === 'inbound';
  });
  const flightsOutbound= flights.filter((flight) => {
    return flight.action === 'outbound';
  });

  // todo 転泊対応時にフラグが必要
  if (flightsInbound.length > 0 && flightsOutbound.length > 0) {
    const data: convertReserveFlights = {
      inbound: flightsInbound,
      outbound: flightsOutbound
    }
    flightsData.push(data);
  }
  return flightsData;
}

export const convertCancelDetailFlightData = (flights:reserveCancelDataFlight[]) => {
  const detailFlights: reserveCancelDetailFlights = {
    inbound: [],
    outbound: [],
    seatDetail: {
      adult: 0,
      child: 0
    }
  }
  flights.forEach((flight) => {
    if (flight.data.reserveFlight.length > 0) {
      flight.data.reserveFlight.forEach((reserveFlight) => {
        if (reserveFlight.connection === 'inbound') {
          detailFlights.inbound.push(reserveFlight)
        } else if (reserveFlight.connection === 'outbound') {
          detailFlights.outbound.push(reserveFlight)
        }
      })
    }
  })
  return detailFlights;
}

/**
 * 検索状況の部屋詳細の子供の内容からフライト用の子供のデータを取得
 * @param searchParams
 */
export const getChildDetail = (searchParams: PlanSearch) => {
  const children: { age:number, meal:boolean, futon:boolean, check: boolean }[] = []

  searchParams.roomDetail.forEach((roomDetail) => {
    if (roomDetail.child.length > 0) {
      roomDetail.child.forEach((child) => {
        children.push({
          age: child.age,
          meal: child.meal,
          futon: child.futon,
          check:false,
        });
      })
    }
  })

  return children;
}

/**
 * フライトの金額計算
 * @param entity
 * @param roomDetail
 * @param rate
 */
export const getFlightPrice = (entity:FReservationFlightEntity, roomDetail: PlanSearch["roomDetail"], rate: number) => {
  let price = 0;
  // 大人以上の料金になる年齢
  const restrict = 12;
  // 3歳以上が小児、３未満幼児
  const childAge = 3;
  roomDetail.forEach(room => {
    // 大人料金の計算
    const adultPrice = room.adult * entity.adult_fee
    // 子ども料金の合計値を計算
    const childPrice = room.child.reduce((sum, c) => {
      if (c.age >= restrict) {
        sum += entity.adult_fee;
      } else if (c.age >= childAge) {
        sum += entity.child_fee;
      }
      return sum;
    },0)
    price = adultPrice + childPrice + price
  })
  return addOtsRate(price, rate);
}

// 乗継地から乗継便のフライトを一覧で取得する
export const getFlightByTransit = (transit:string, flights:Flights) => {
// TODO: フィルター用
}

// FReservationFlightEntity[]から、最安の料金を１つ取得する
export const getChpestFlight = (flights:FReservationFlightEntity[]):FReservationFlightEntity => {
  return flights.reduce((a: any,b: any) => {
    if(a && b) {
      return a.adult_fee < b.adult_fee ? a:b
    }
  }) as FReservationFlightEntity
}

// 直行便の空港コードを取得する
export const getDirectCodes = (code:string):Array<string> => {
  if(code && typeof code === "string") {
    const r = Object.keys(compositeAirport).filter((key) => compositeAirport[key] === code);
    return r;
  } else {
    return Object.keys({[code]:departureAirCode[code]})
  }
}

// ルートが変わった場合、乗継便を自動で取得して配列を取得する
export const getAutoSetFlight = () => {
// TODO: ルート変更時に自動でフライト可能な乗継の選択を行う
}

// export const convertToPlanSelectFromFlightType = (airline:string, action:string, flights: FReservationFlightEntity[]): PlanSelectFromFlightType => {
//   const updateRef = flights.map((f) => {
//     return {
//       airline: airline,
//       entity: f,
//       action: action,
//       connection: (flights.length >= 2),
//       useDate: f.date,
//       selected: true,
//       totalPrice: getFlightPrice(flights,planData.searchParams.roomDetail)
//     } as PlanSelectFromFlightType
//   })
//   return {} as PlanSelectFromFlightType
// }

/**
 * フライトのクラスコードに合わせて席名を取得
 * @param classCode
 */
export const getFlightClassLabel = (classCode: string) => {
  // @memo 現行はANA/JALともに普通席しかないので、全て普通席で表示する #1059
  return '普通席';
  /*
  if (flightClassJCodes.includes(classCode)) {
    return 'クラスJ';
  }

  if (flightClassNormalCodes.includes(classCode)) {
    return '普通席';
  }

  return '普通席';
  */
}

export const getReserveFlightPeoples = (reservation: reserveCancelDetailResponce) => {
  const peoples = {
    adults: 1,
    child: 0,
    infant: 0,
  }

  if (reservation.data.bookingData.travelCompanions.length > 0) {
    reservation.data.bookingData.travelCompanions.forEach((travelCompanions) => {
      if (travelCompanions.age >= 12) {
        peoples.adults++;
      } 
      // 子供
      if(travelCompanions.age > 2 && travelCompanions.age <= 11) {
        peoples.child++;
      }
      // 幼児
      if(travelCompanions.age < 3) {
        peoples.infant++;
      }

    })
  }


  return peoples;
}

// TODO:空港の取得系を1つの関数にある程度まとめたい
export const getAirports = () => {

}

// 到着空港を取得
export const getArrivalAirports = (airport:string, isCode:boolean, isInbound:boolean): string[] => {

  if(isInbound) {
    return Object.keys(departureAirCode)
  } 
  const code = isCode ? airport : getFlightCodeByLabel(airport)
  if(typeof code === "string" && multiAirport[code] !== undefined) {
    const area = multiAirport[code];
    const r = Object.keys(multiAirport).filter((key) => multiAirport[key] === area);
    return r;
  } else {
    return Object.keys({[code]:departureAirCode[code]})
  }
}

export const getCompositeAirports = (airport:string, isCode:boolean): string[] => {
  const code = isCode ? airport : getFlightCodeByLabel(airport)
  if(typeof code === "string" && compositeAirport[code] !== undefined) {
    const area = compositeAirport[code];
    const r = Object.keys(compositeAirport).filter((key) => compositeAirport[key] === area);
    return r;
  } else {
    return Object.keys({[code]:departureAirCode[code]})
  }
}

// 出発、到着空港を元に乗継専用の空港を取得する、無ければdepartureAirCodeを返す
export const getMultiAirports = (airport:string, isCode:boolean, isInbound:boolean): string[] => {

  if(isInbound) {
    return Object.keys(departureAirCode)
  } 

  const code = isCode ? airport : getFlightCodeByLabel(airport)
  if(typeof code === "string" && multiAirport[code] !== undefined) {
    const area = multiAirport[code];
    const r = Object.keys(multiAirport).filter((key) => multiAirport[key] === area);
    return r;
  } else {
    return Object.keys(departureAirCode)
  }
}

// 混在便（flights.reservationFlights.FReservationFlights）から乗継便を見つける
export const getConnectingFlights = (flights:Flights, to:string, action:string):FReservationFlightEntity[] => {
  // 直行
  const mixed = flights.reservationFlights.FReservationFlights;

  if(mixed && mixed.length > 0) {
    // arrapo
    const arrapo = to == 'CTS' ? 'SPK' : to;

    // reduce
    const results = mixed.reduce((accumulator, currentValue) => {
      if(currentValue.FReservationFlight) {
        if(currentValue.FReservationFlight[0].arrapo != arrapo) {
          accumulator.push(currentValue.FReservationFlight[0])
        }
      }
      return accumulator;
    }, [] as FReservationFlightEntity[])

    return results.sort((objA, objB) => Number(objA.dep) - Number(objB.dep))

  } else {
    return []
  }
}

// 混在便（flights.reservationFlights.FReservationFlights）から直行便便を見つける
export const getDirectFlights = (flights:Flights, to:string, action:string):FReservationFlightEntity[] => {
  // 直行
  const mixed = flights.reservationFlights.FReservationFlights;

  if(mixed && mixed.length > 0) {
    // arrapo
    const arrapo = to == 'CTS' ? 'SPK' : to;

    // reduce
    const results = mixed.reduce((accumulator, currentValue) => {
      if(currentValue.FReservationFlight) {
        if(currentValue.FReservationFlight[0].arrapo == arrapo) {
          accumulator.push(currentValue.FReservationFlight[0])
        }
      }
      return accumulator;
    }, [] as FReservationFlightEntity[])
    return results.sort((objA, objB) => Number(objA.dep) - Number(objB.dep))
  } else {
    return []
  }
}

// 出発空港、または到着空港が複数かどうか判定する
export const isMultipleArrivals = (code:string):boolean => {
  if(code) {
    return multipleArrivals.includes(code)
  } else {
    return false
  }
}

// TOKYOかOSAの空港を全て取得する
export const getMultipleAirports = (code:string) => {
  if(code && multipleArrivals.includes(code)) {
    return Object.keys(compositeAirport).filter((key) => compositeAirport[key] === code);
  } else {
    if (code === "CTS" || code === "SPK") {
      return ["CTS", "SPK"];
    } else {
      return Object.keys({[code]:departureAirCode[code]})
    }
  }
}

// flights.reservationFlights.FReservationFlightsみたいな感じで引数に値を渡す
export const sortByDate = (flights:FReservationFlightsEntity[]):FReservationFlightEntity[] => {
  if(flights && flights.length > 0) {
    const entities = flights.reduce((a, c) => {
      if(c.FReservationFlight) {
        a.push(c.FReservationFlight[0] as FReservationFlightEntity)
      }
      return a;
    }, [] as FReservationFlightEntity[])
    const sortedAsc = entities.sort((objA, objB) => Number(objA.dep) - Number(objB.dep))
    return sortedAsc;
  }
  return []
}

export const filterByTime = (flights:FReservationFlightsEntity[], fromTime:string, toTime:string):FReservationFlightEntity[] => {

  if(flights && flights.length > 0) {
    const entities = flights.reduce((a, c) => {
      if(c.FReservationFlight) {
        a.push(c.FReservationFlight[0] as FReservationFlightEntity)
      }
      return a;
    }, [] as FReservationFlightEntity[])

    // all
    if(fromTime == "" || toTime == "") {
      return entities.sort((objA, objB) => Number(objA.dep) - Number(objB.dep))
    }

    const filtered = entities.filter((f) => Number(f.dep) >= Number(fromTime) && Number(f.dep) <= Number(toTime))
    const sortedAsc = filtered.sort((objA, objB) => Number(objA.dep) - Number(objB.dep))

    return sortedAsc
  }
  return []
}

// 選択中の乗継便と混在便をマージする
export const mergeFlightSelection = (flights:Flights, selectedNumbers:string[]):FReservationFlightEntity[] => {
  // 直行
  const mixed = flights.reservationFlights.FReservationFlights;
  // 乗継
  const connecting = flights.viaReservationFlights.FReservationFlights;
  // result
  const mergeFlights: FReservationFlightEntity[] = []

  if(mixed && mixed.length > 0) {
    // mixed
    const mEntities = mixed.reduce((ma, mc) => {
      if(mc.FReservationFlight && selectedNumbers.includes(mc.FReservationFlight[0].air_sub_no)) {
        ma.push(mc.FReservationFlight[0] as FReservationFlightEntity)
      }
      return ma;
    }, [] as FReservationFlightEntity[])

    if(connecting && connecting.length > 0) {
      // connecting
      const cEntities = connecting.reduce((ca, cc) => {
        if(cc.FReservationFlight && selectedNumbers.includes(cc.FReservationFlight[0].air_sub_no)) {
          ca.push(cc.FReservationFlight[0] as FReservationFlightEntity)
        }
        return ca;
      }, [] as FReservationFlightEntity[])
      const comb = [...mEntities,...cEntities]
      mergeFlights.push(...comb)
    } else {
      mergeFlights.push(...mEntities)
    }
  }
  return mergeFlights
}

// Entities（flights）からEntityを見つける
export const searchFlightEntity = (flights:Flights, entity:FReservationFlightEntity):FReservationFlightEntity | undefined => {
  // 直行
  const mixed = flights.reservationFlights.FReservationFlights;
  // 乗継
  const connecting = flights.viaReservationFlights.FReservationFlights;
  
  if(mixed && mixed.length > 0) {
    const mEntities = mixed.reduce((ma,mc) => {
      if(mc.FReservationFlight && mc.FReservationFlight[0].air_sub_no == entity.air_sub_no) {
        ma.push(mc.FReservationFlight[0] as FReservationFlightEntity)
      }
      return ma;
    }, [] as FReservationFlightEntity[])
    if(mEntities && mEntities.length > 0) {
      return mEntities[0]
    }
  }

  if(connecting && connecting.length > 0) {
    const mEntities = connecting.reduce((ma,mc) => {
      if(mc.FReservationFlight && mc.FReservationFlight[0].air_sub_no == entity.air_sub_no) {
        ma.push(mc.FReservationFlight[0] as FReservationFlightEntity)
      }
      return ma;
    }, [] as FReservationFlightEntity[])
    if(mEntities && mEntities.length > 0) {
      return mEntities[0]
    }
  }

  return undefined
}

// FReservationFlightsEntity to FReservationFlightEntity[]
export const toFReservationFlightEntity = (flights: FReservationFlightsEntity[] | undefined | null): FReservationFlightEntity[] => {
  if(flights && flights.length > 0) {
    const closests = flights.reduce((a, c) => {
      if(c.FReservationFlight) {
        a.push(c.FReservationFlight[0] as FReservationFlightEntity)
      }
      return a;
    }, [] as FReservationFlightEntity[])
    const sortedAsc = closests.sort((objA, objB) => Number(objA.dep) - Number(objB.dep))
    return sortedAsc
  }
  return []
}

/**
 * フライト空席チェック
 * @param airline
 * @param flight
 * @param roomData
 */
export const isVacancy = (airline:string | undefined, flight:FReservationFlight, roomData: roomDetail[]):boolean => {
  const people = getFlightPeopleNums(roomData);
  switch (airline) {
    case "ANA":
      return isAnaVacancy(airline, flight, people.total);
    case "JAL":
      return isJalVacancy(airline, flight, people.total);
    default:
      break;
  }

  return false;
}

const isAnaVacancy = (airline:string | undefined, flight:FReservationFlight, people: number):boolean => {
  if(airline && flight) {
    const connectionAvailability = connectionFlightAvailability(flight.FReservationConnectionFlight, []);
    let connectionVacancy = true;
    if (connectionAvailability.length > 0) {
      connectionAvailability.forEach((availability) => {
        const stock = anaVacancyCodes[availability] ? Number(anaVacancyCodes[availability]) : Number(availability);
        if (connectionVacancy) {
          connectionVacancy = people <= stock;
        }
      });
    }

    const stock = anaVacancyCodes[flight.availability] ? Number(anaVacancyCodes[flight.availability]) : Number(flight.availability);
    return people <= stock && connectionVacancy;
  }

  return false
}

const isJalVacancy =(airline:string | undefined, flight:FReservationFlight, people: number):boolean => {
  if(airline && flight) {
    const connectionAvailability = connectionFlightAvailability(flight.FReservationConnectionFlight, []);
    let connectionVacancy: boolean = true;
    if (connectionAvailability.length > 0) {
      connectionAvailability.forEach((availability) => {
        const stock = jalVacancyCodes[availability] ? Number(jalVacancyCodes[availability]) : Number(availability);
        if (connectionVacancy) {
          connectionVacancy = people <= stock;
        }
      });
    }

    const stock = jalVacancyCodes[flight.availability] ? Number(jalVacancyCodes[flight.availability]) : Number(flight.availability);
    return people <= stock && connectionVacancy;
  }
  return false
}

export const connectionFlightAvailability = (connectionFLight: FReservationConnectionFlight[], availability: string[]) : string[] => {
  if (connectionFLight && connectionFLight.length > 0) {
    availability.push(connectionFLight[0].availability);
    if (connectionFLight[0].FReservationConnectionFlight.length > 0) {
      return connectionFlightAvailability(connectionFLight[0].FReservationConnectionFlight, availability);
    }
  }

  return availability;
}

// JALグループ内の航空会社名を返す
export const getAirLineNameInJalGroup = (airCode: string, airSubNo: string) => {
  const _airCode = convertAirCode(airCode);
  if (_airCode === "JL") {
    return "JAL";
  }

  if ((airSubNo.startsWith("7") || airSubNo.startsWith("8")) && airSubNo.length === 3) {
    return "RAC";
  }

  return "JTA";
}

// ANAグループ内の航空会社名を返す
export const getAirLineNameInAnaGroup = (airCode: string) => {
  // AN6みたいなパターンもあるのでswitchで分岐する
  switch (airCode) {
    case "SNA":
    case "ADO":
    case "SFJ":
    case "IBX":
    case "ORC":
      return airCode;

    default:      
      return "ANA";
  }
}

// 表示用フライト番号を返す(航空会社名 + フライトNo + "便")
export const getFlightNumberForDisplay = (airline: string | undefined, airCode: string, airSubNo: string ) => {
  if (airline === "JAL") {
    return `${getAirLineNameInJalGroup(airCode, airSubNo)}${airSubNo}便`;
  }

  return `${getAirLineNameInAnaGroup(airCode)}${airSubNo}便`;
}

// 各航空会社のロゴを返す
export const getAirLineLogo = (airline: string, airCode: string, airSubNo: string ) => {
  const airLineName = airline === "JAL" ? getAirLineNameInJalGroup(airCode, airSubNo) : getAirLineNameInAnaGroup(airCode);

  switch (airLineName) {
    case "SNA":
      return require("../img/share/flight/logo_sa.png").default;

    case "ADO":
      return require("../img/share/flight/logo_airdo.png").default;

    case "SFJ":
      return require("../img/share/flight/logo_sf.png").default;

    case "IBX":
      return require("../img/share/flight/logo_ibx.png").default;

    case "ORC":
      return require("../img/share/flight/logo_orc.png").default;

    case "JTA":
      return require("../img/share/flight/logo_jta.png").default;
    
    case "RAC":
      return require("../img/share/flight/logo_rac.png").default;
    
    case "JAL":
      return require("../img/share/flight/logo_jal.png").default;

    default:      
      return require("../img/share/flight/logo_ana.png").default;
  }
}

/**
 * 予約できるフライトが存在するか確認
 * @param currentFlightEntities
 */
export const isNotCurrentFlights = (currentFlightEntities: currentFlightEntity[]) => {
  if (currentFlightEntities.length > 0) {
    const anaFlights = currentFlightAirLineFilter(currentFlightEntities, 'ANA');
    const jalFlights = currentFlightAirLineFilter(currentFlightEntities, 'JAL');
    
    if (anaFlights.length > 0 || jalFlights.length >0) {
      return false;
    }
  }

  return true;
}

export const currentFlightAirLineFilter = (currentFlightEntities: currentFlightEntity[], airlineName: string) => {
  return currentFlightEntities.filter((flightEntities) => {
    return flightEntities.airline === airlineName;
  });
}

/**
 * 乗継便があるならtrueを返す
 * 
 * @param flightEntities 
 * @returns 
 */
export const isConnectionFlight = (flightEntities: FReservationFlightEntity[]): boolean => {
  return flightEntities && flightEntities.length > 1;
}

/**
 * レンタカーAPI用にフライトコードを変換
 * @param airCode
 * @param airline
 * @param airSubNo
 */
export const convertAirCodeForCarRental = (airCode: string, airline: string, airSubNo: string): string => {
  let carRentalAirCode = convertAirCode(airCode);
  
  if (airline === "JAL") {
    carRentalAirCode = getAirLineNameInJalGroup(airCode, airSubNo);
  }

  if (availableCarRentalAirCodes.includes(carRentalAirCode)) {
    return  carRentalAirCode;
  }

  return airline;
}

/**
 * 選択されたフライトが更新されたものかチェック
 * @param flights
 * @param flightAction
 * @param updateAirlineAction
 * @param airlineName
 * @param from
 * @param to
 */
export const checkUpdatedSelectFlights = (flights: FlightEntity[], flightAction: string, airlineName: string, from: string, to: string) => {
  const filterTravelTypeFlights = flights.filter((flight) => {
    return flight.travelType === flightAction && flight.airlineName === airlineName;
  })
  if (filterTravelTypeFlights.length > 0) {
    const findUpdatedFlight = filterTravelTypeFlights.find((filterTravelTypeFlight) => {
      const findEntity = filterTravelTypeFlight.currentFlights.filter((currentFlight) => {
        if (flightAction === 'inbound') {
          return getMultipleAirports(from).includes(currentFlight.depapo);
        } else {
          return getMultipleAirports(to).includes(currentFlight.arrapo);
        }
      })
      return findEntity.length > 0;
    });

    if (findUpdatedFlight) {
      return true;
    }
  }
  
  return false;
}

/**
 * 乗り継ぎ便の組み合わせのフライトか判定
 * @param fromCode
 * @param toCode
 * @param airlineCode
 * @param action
 */
export const isConnectionFlightCode = (fromCode:string|number, toCode: string|number, airlineCode: string, action: string): boolean => {
  const connectionFlightConfig = connectionFlights;
  const from = fromCode;
  const to = toCode;
  if (
      connectionFlightConfig[airlineCode] !== undefined &&
      connectionFlightConfig[airlineCode][to] !== undefined &&
      connectionFlightConfig[airlineCode][to][from] !== undefined
  ) {
    return true;
  }

  return false;
};

/**
 * 選択可能な乗り継ぎ便かチェック
 * @param selectedEntity
 * @param via
 */
export const isConnectFlightDateRange = (selectedEntity:FReservationFlightEntity, via:FReservationFlightsEntity) => {
  const startDate = dayjs(selectedEntity.date + ' ' + selectedEntity.arv).add(30, 'm')
  if (via.FReservationFlight) {
    const endDate = dayjs(via.FReservationFlight[0].date + ' ' + via.FReservationFlight[0].dep);
    return endDate.isAfter(startDate)
  }
  
  return false;
}

/**
 * 航空会社ごとにフライトのローディング表示確認
 * @param flights
 */
export const isFlightsLoading = (flights: currentFlightEntity[]) => {
  const isFlights = {
    all: true,
    ANA: true,
    JAL: true
  };
  const anaFlights = flights.filter((flight) => {
    return flight.airline === "ANA"
  })
  const jalFlights = flights.filter((flight) => {
    return flight.airline === "JAL";
  })
  if (anaFlights[0] && anaFlights[0].inbound && anaFlights[0].outbound) {
    isFlights.ANA = false;
  }
  if (jalFlights[0] && jalFlights[0].inbound && jalFlights[0].outbound) {
    isFlights.JAL = false;
  }
  
  return isFlights;
}