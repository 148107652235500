import React, {useEffect, useState} from "react";


//scss
import "../../scss/common.scss";
import "../../scss/component.scss";

const Error500: React.FC = () => {
 
  return (
      <section className="pt-50 pt-100-md">
        <div className="wrap-content-900">
          <div className="card sp-small">
            <p className="mb-10 ta-c">
              <img
                src={
                  require("../../img/share/icon/icon_searchzero.svg")
                    .default
                }
                className="d-ib"
                />
            </p>
            <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">通信エラーが発生しました</p>
            <p className="fz-16 ta-c-md c-blue_gray">
              通信エラーのため情報を取得できませんでした。<br className="d-n d-b-md" />
              しばらくしてから再度お試しください。
            </p>
          </div>
        </div>
      </section>
  );
};

export default Error500;
