import React, { Fragment, useState, useEffect } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

//input
import { InputText } from "./../../input/InputText";
import { InputRadio } from "./../../input/InputRadio";
import { InputsForm1 } from "./../../../types/InputsForm1";
import { transformFullCharToHalf } from "./../../../utils/transformFullCharToHalf";
import { PartsDay, PartsMonth } from "../../parts";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {getTravelerNums} from "../../../utils/search";
import {roomDetail, roomDetailChild} from "../../../types/Search";


type Props = {
  control: any;
  planSelectFormData: PlanSelectFormData,
  errors: FieldErrors;
  fellowCount: number;
  register: UseFormRegister<InputsForm1>;
  setValue: any;
}

const SectionFellow: React.FC<Props> = (props) => {
  const handleBlurInText = (
    event: React.FocusEvent<HTMLInputElement>,
    options: {
      transformFullCharToHalf?: boolean;
    }
  ) => {
    // 全角から半角に変換
    if (options.transformFullCharToHalf) {
      const targetName: any = event.target.name; // todo: 型指定したい
      const transformedValue = transformFullCharToHalf(event.target.value);
      props.setValue(targetName, transformedValue);
    }
  };
  
  // 同行者の人数を配列化する
  const convertFellowCountToArray = (count: number) => {
    return Array.from({ length: count }, (_, i) => i);
  };

  const travelerNums = getTravelerNums(props.planSelectFormData);
  // todo: inputやredux側で使っている名前もfellowsに変更する
  const fellows = convertFellowCountToArray(travelerNums);

  const [fellowLabels, setFellowLabels] = useState<string[]>([]); // 人数内訳のテキストを入れる配列
  /**
   * 人数内訳のテキストを取得する
   * @param index 
   * @returns 
   */
  const getFellowLabel = (index: number) => {
    return fellowLabels?.[index] ? `（${fellowLabels[index]}）` : "";
  }

  /**
   * // 人数内訳のテキストを配列に代入する
   */
  useEffect(() => {
    const newCountDetail: string[] = [];
    const roomDetailData = props.planSelectFormData.searchParams.roomDetail;
    roomDetailData.forEach((data: roomDetail) => {
      // 大人の内訳
      if (data?.adult && data.adult > 0) {
        for (let i = 0; i < data.adult; i++) {
          newCountDetail.push("大人");
        }
      }
      // 子供の内訳
      if (data?.child && data.child.length > 0) {
        data.child.reverse().forEach((child: roomDetailChild) => {
          let countDetailText = `子供${child.age}歳`;
          if (6 <= child.age && child.age <= 12) {
            countDetailText = "子供6-12歳";
          } else if (3 <= child.age && child.age <= 5) {
            countDetailText = (child.meal && child.futon) ? "子供3-5歳（食事・布団あり）" : "子供3-5歳（添い寝）";
          } else if (child.age <= 2) {
            countDetailText = (child.meal && child.futon) ? "幼児0-2歳（食事・布団あり）" : "幼児0-2歳（添い寝）";
          }
          newCountDetail.push(countDetailText);
        });
      }
    });
    newCountDetail.shift(); // 申込者の分を削除
    setFellowLabels(newCountDetail);
  }, [props.planSelectFormData.searchParams.roomDetail]);

  return (
    <>
      {/* <!--同行者情報の入力--> */}
      <section>
        <h2 className="heading-2" hidden={fellows.length === 0}>
          同行者情報の入力
        </h2>
        <div className="card" hidden={fellows.length === 0}>
          <ul className="list-note red mb-16">
            <li>申込後の氏名・年齢・性別の変更はできません。</li>
          </ul>
          <div className="form-accompany">
            {fellows.map((index) => (
              <Fragment key={index}>
                <div className="form-accompany-item">
                  <div className="form-accompany-item-heading">
                    {`同行者${index + 1}`}{getFellowLabel(index)}
                  </div>
                  <div className="form-accompany-item-content">
                    <div className="box-separator_bottom-large">
                      {/* <!--名前(フリガナ)--> */}
                      <div
                        id="form-name-hurigana"
                        className="box-separator_bottom-large-item"
                      >
                        <div className="form-content">
                          <div className="form-content-heading">
                            <h3 className="heading-3-small">
                              氏名 <span className="">( フリガナ )</span>
                              <span className="label-required ml-5">
                                必須
                              </span>
                            </h3>
                          </div>
                          <div className="form-content-input">
                            <div className="form-column-pc">
                              <div className="form-column-pc-item">
                                <div className="heading-item_6 fw-b">セイ</div>
                                <div>
                                  <InputText
                                    name={`followers.${index}.name.kana.last`}
                                    placeholder="例：オキナワ"
                                    register={props.register}
                                    options={{
                                      required: true,
                                      maxLength: 10,
                                      pattern: /^[ァ-ンヴー]+$/i,
                                    }}
                                    error={
                                      props.errors.followers?.[index]?.name
                                        ?.kana?.last
                                    }
                                  ></InputText>
                                  {props.errors.followers?.[index]?.name?.kana
                                    ?.last &&
                                    props.errors.followers?.[index]?.name?.kana
                                      ?.last?.type === "required" && (
                                      <p className="form-error_text">
                                        お名前（セイ）をご入力ください。
                                      </p>
                                    )}
                                  {props.errors.followers?.[index]?.name?.kana
                                    ?.last &&
                                    props.errors.followers?.[index]?.name?.kana
                                      ?.last?.type === "maxLength" && (
                                      <p className="form-error_text">
                                        お名前（セイ）は10文字以内でご入力ください。
                                      </p>
                                    )}
                                  {props.errors.followers?.[index]?.name?.kana?.last &&
                                    props.errors.followers?.[index]?.name?.kana?.last?.type === "pattern" && (
                                      <p className="form-error_text">
                                        全角カタカナでご入力ください。
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="form-column-pc-item">
                                <div className="heading-item_6 fw-b">メイ</div>
                                <div>
                                  <InputText
                                    name={`followers.${index}.name.kana.first`}
                                    placeholder="例：タロウ"
                                    register={props.register}
                                    options={{
                                      required: true,
                                      maxLength: 10,
                                      pattern: /^[ァ-ンヴー]+$/i,
                                    }}
                                    error={
                                      props.errors.followers?.[index]?.name
                                        ?.kana?.first
                                    }
                                  ></InputText>
                                  {props.errors.followers?.[index]?.name?.kana
                                    ?.first &&
                                    props.errors.followers?.[index]?.name?.kana
                                      ?.first?.type === "required" && (
                                      <p className="form-error_text">
                                        お名前（メイ）をご入力ください。
                                      </p>
                                    )}
                                  {props.errors.followers?.[index]?.name?.kana
                                    ?.first &&
                                    props.errors.followers?.[index]?.name?.kana
                                      ?.first?.type === "maxLength" && (
                                      <p className="form-error_text">
                                        お名前（メイ）は10文字以内でご入力ください。
                                      </p>
                                    )}
                                  {props.errors.followers?.[index]?.name?.kana?.first &&
                                    props.errors.followers?.[index]?.name?.kana?.first?.type ===
                                      "pattern" && (
                                      <p className="form-error_text">
                                        全角カタカナでご入力ください。
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--性別--> */}
                      <div
                        id="form-sexual"
                        className="box-separator_bottom-large-item"
                      >
                        <div className="form-content">
                          <div className="form-content-heading">
                            <h3 className="heading-3-small">
                              性別
                              <span className="label-required ml-5">
                                必須
                              </span>
                            </h3>
                          </div>

                          <div className="form-content-input">
                            <div
                              className={
                                "form-content-input-sexual" +
                                (props.errors.followers?.[index]?.gender ? " error" : "")
                              }
                            >
                              <InputRadio
                                name={`followers.${index}.gender`}
                                control={props.control}
                                radios={[
                                  { label: "男性", value: "male" },
                                  { label: "女性", value: "female" },
                                ]}
                                options={{ required: true }}
                                isRow={true}
                                defaultValue={"male"}
                              ></InputRadio>
                              {props.errors.followers?.[index]?.gender && (
                                <p className="form-error_text">
                                  性別をご入力ください。
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--生年月日--> */}
                      <div
                        id="form-name-birthday"
                        className="box-separator_bottom-large-item"
                      >
                        <div className="form-content">
                          <div className="form-content-heading">
                            <h3 className="heading-3-small">
                              生年月日
                              <span className="label-required ml-5">必須</span>
                            </h3>
                          </div>
                          <div className="form-content-input">
                            <div
                              className={
                                "form-birthday" +
                                (props.errors.followers?.[index]?.birthday_year ||
                                  props.errors.followers?.[index]?.birthday_month ||
                                  props.errors.followers?.[index]?.birthday_day
                                  ? " error"
                                  : "")
                              }
                            >
                              <div className="form-birthday-year">
                                <div>
                                  <InputText
                                    name={`followers.${index}.birthday_year`}
                                    placeholder="例: 1990"
                                    isSmall={true}
                                    register={props.register}
                                    options={{
                                      required: true,
                                      min: 1900,
                                      max: 3000,
                                      maxLength: 4,
                                      minLength: 4,
                                      onBlur: (event) =>
                                        handleBlurInText(event, {
                                          transformFullCharToHalf: true,
                                        }),
                                    }}
                                    error={props.errors.followers?.[index]?.birthday_year}
                                  ></InputText>
                                </div>
                                <p className="mr-10 ml-10 color-dark_blue">年</p>
                              </div>
                              <div className="form-birthday-month">
                                <div className="select_box-default">
                                  <PartsMonth
                                    name={`followers.${index}.birthday_month`}
                                    register={props.register}
                                    options={{ required: true }}
                                  />
                                </div>
                                <p className="mr-10 ml-10 color-dark_blue">月</p>
                              </div>
                              <div className="form-birthday-day">
                                <div className="select_box-default">
                                  <PartsDay
                                    name={`followers.${index}.birthday_day`}
                                    register={props.register}
                                    options={{ required: true }}
                                  />
                                </div>
                              </div>
                              {(props.errors.followers?.[index]?.birthday_year ||
                                props.errors.followers?.[index]?.birthday_month ||
                                props.errors.followers?.[index]?.birthday_day) && (
                                <p className="form-error_text">
                                  生年月日をご入力ください。
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--マイレージ番号--> */}
                      <div
                        id="form-name-mileage_number"
                        className="box-separator_bottom-large-item"
                      >
                        <div className="form-content">
                          <div className="form-content-heading">
                            <h3 className="heading-3-small">
                              マイレージ番号
                            </h3>
                          </div>
                          <div className="form-content-input">
                            <div>
                              <InputText
                                name={`followers.${index}.mileage_number`}
                                placeholder="ANA10桁 / JAL7桁 もしくは9桁"
                                register={props.register}
                                options={{
                                  required: false,
                                  pattern: /^(\d{7}|\d{9}|\d{10})$/
                                }}
                                error={props.errors.followers?.[index]?.mileage_number}
                              ></InputText>
                              <ul className="list-note light-gray">
                                <li>半角数字でご入力ください。</li>
                              </ul>
                              {props.errors.followers?.[index]?.mileage_number &&
                                props.errors.followers?.[index]?.mileage_number.type === "pattern" && (
                                  <p className="form-error_text">
                                    マイレージ番号を正しくご入力ください。
                                  </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
            {/*/form-accompany-item */}
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionFellow;
