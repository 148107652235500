import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {PlanSelectFormData} from "../../types/planSelectForm";
import React from "react";

const mapKey = process.env.REACT_APP_MAP_KEY;

type Props = {
  lat: string | undefined,
  lng: string | undefined,
  address: string | undefined,
  label: string
}

const containerStyle = {
  width: "100%",
  height: "400px",
};

const Map: React.FC<Props> = ({
  lat,
  lng,
  address,
  label
}) => {
  const center = {
    lat: Number(lat)?? 0,
    lng: Number(lng)?? 0,
  }
  return (
    <iframe
      src={"https://www.google.com/maps/embed/v1/place?key="+mapKey+"&q="+address+""}
      width="100%" height="450" style={{ border: 0 }}  loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"></iframe>
  );
};

export default Map;