import React from "react";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

const app = process.env.REACT_APP_MODE;

// モーダルに何か渡したい場合はここ追加する
type Props = {
    activeStep: number,
    isOption: boolean
}

const FormStep: React.FC<Props> = ({
    activeStep,
    isOption
}) => {

  let stepList: string[] = [];
  if (isOption) {
    stepList = [
      "お客様<br />情報入力",
      "オプション<br />情報入力",
      "保険加入の<br />選択",
      "決済情報の<br />入力",
      "予約<br />最終確認",
      "予約完了",
    ];
  } else {
    stepList = [
      "お客様<br />情報入力",
      "保険加入の<br />選択",
      "決済情報の<br />入力",
      "予約<br />最終確認",
      "予約完了",
    ];
    if (activeStep !== 1) {
      activeStep--;
    }
  }
  
  return (
    <>
         <div className="form-step">
            <ul className="form-step-list">
              {stepList.map((step, sIndex) => (
                <>
                  <li className={"form-step-list-item" + (activeStep === (sIndex + 1)? " active":"")}>
                    <span className="form-step-list-item-label">{sIndex + 1}</span>
                    <span dangerouslySetInnerHTML={{ __html: step }}></span>
                  </li>
                </>
              ))}
            </ul>
        </div>
    </>
  );
};

export default FormStep;
