import { useState } from "react";
import { useApi } from "./useApi";
import {reserveCancelDetailResponce} from "../types/reserveCancel";
import {devLog} from "./errors";

/**
 * 予約情報
 * 
 * @returns 
 */
export const useReserveInfo = () => {

  const [isLoading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const isError = error !== "";

  // note: レスポンスが固まったら型を指定したい・・
  const [reserveInfo, setReserveInfo] = useState<reserveCancelDetailResponce>();

  const fetchReserveInfo = async (input: { bookingId: string, bookingEmail: string }) => {
    setLoading(true);

    try {
      const response = await useApi.post({
        url: "/reserve/info",
        data: input   
      });
  
      const data = response.data as reserveCancelDetailResponce;
      devLog("data", data);
  
      setReserveInfo(data);

    } catch (error) {
      setError("APIの呼び出しに失敗しました。");
    }

    setLoading(false);

    return;
  }

  return [ fetchReserveInfo, reserveInfo, { isLoading, isError, error } ] as const;
};