import {PlanSearch, PlanSearchParams, roomDetail, roomDetailChild, searchResult} from "../types/Search";
import {PlanSelectFormData} from "../types/planSelectForm";
import {dateFormat, nextDate, rageDiffDate} from "./convert";
import {getHotelSearchRoomPeopleConfirmLabel} from "./hotel";
import {getArrivalAirports, getFlightPeopleConfirmLabel} from "./flight";
import {defaultSearchParams} from "../defaults/search";
import qs from "qs";
import {airCode} from "../config/Airport";

export const getSearchURL = (searchParams: PlanSearch) => {
  let params = new URLSearchParams();
  const search: PlanSearchParams = {
    c_airport_to: searchParams.c_airport_to,
    from: searchParams.from,
    to: searchParams.to,
    start: searchParams.start,
    end: searchParams.end,
    checkin: searchParams.checkin,
    checkout: searchParams.checkout,
    rooms: searchParams.rooms,
    days: searchParams.days,
    roomDetail: searchParams.roomDetail,
    isChange: searchParams.isChange,
  };
  params.append("params", JSON.stringify(search))

  return window.location.origin + window.location.pathname + "?" + params.toString();

}

export const getBackSearchURL = (searchParams: PlanSearch, id: string) => {
  // todo 型対応はあとで対応
  let params = new URLSearchParams();
  params.append("params", JSON.stringify(searchParams))

  return window.location.origin + '/' + id + "?" + params.toString();

}

export const getPlanSelectInfoData = (searchPlanParams:PlanSearch):PlanSelectFormData["info"] => {
  
  return {
    days: (rageDiffDate(searchPlanParams.start, searchPlanParams.end) + 1).toString(), //旅行日数
    travelParticipants: getHotelSearchRoomPeopleConfirmLabel(searchPlanParams?.roomDetail), //旅行参加人数
    flightParticipants: getFlightPeopleConfirmLabel(searchPlanParams?.roomDetail), //参加人数（航空便）
    start_date: dateFormat(searchPlanParams.start, "YYYY/MM/DD"), //旅行開始日
    end_date: dateFormat(searchPlanParams.end, "YYYY/MM/DD") //旅行終了日
  }
}

export const initSearchParams = (search:string): PlanSearch => {
  // get query parameter

  // query cast
  const urlEncoded = qs.parse(search);
  const params = urlEncoded.params;
  const searchParams = defaultSearchParams;
  if (typeof params === "string" && params)  {
    const paramsJson:PlanSearch = JSON.parse(params);
    let checkIn = paramsJson.checkin?? undefined;
    let checkOut = "";
    if (paramsJson.checkout) {
      checkOut = paramsJson.checkout;
    } else if (paramsJson.days && checkIn) {
      checkOut = nextDate(checkIn, Number(paramsJson.days), 'YYYY-MM-DD');
    }
    if (paramsJson.to && airCode[paramsJson.to]) {
      searchParams.to = paramsJson.to;
    }
    if (paramsJson.c_airport_to) {
      const arrivals = getArrivalAirports(paramsJson.from, true, false)
      if (arrivals && arrivals.includes(paramsJson.c_airport_to)) {
        searchParams.c_airport_to = paramsJson.c_airport_to;
      } else {
        searchParams.c_airport_to = '';
      }
    }
    if (paramsJson.from) {
      searchParams.from = paramsJson.from;
    }
    if (paramsJson.start) {
      searchParams.start = paramsJson.start;
    }
    if (paramsJson.end) {
      searchParams.end = paramsJson.end;
    }
    if (checkIn) {
      searchParams.checkin = checkIn;
    }
    if (checkOut) {
      searchParams.checkout = checkOut;
    }
    if (checkOut) {
      searchParams.checkout = checkOut;
    }
    if (paramsJson.days) {
      searchParams.days = paramsJson.days;
    }
    if (paramsJson.rooms) {
      searchParams.rooms = paramsJson.rooms;
    }
    if (paramsJson.roomDetail && paramsJson.roomDetail.length > 0) {
      searchParams.roomDetail = paramsJson.roomDetail.sort((a: roomDetail, b: roomDetail) => {
        // 大人が多い部屋順に変更
        let firstCount = 0;
        let secondCount = 0;
        firstCount = a.adult;
        firstCount += childrenPeople(a.child);
        secondCount = b.adult;
        secondCount += childrenPeople(b.child);

        return secondCount - firstCount;
      });
      searchParams.roomDetail = paramsJson.roomDetail;
    }
    
    searchParams.isChange = paramsJson.isChange
  }

  return searchParams;
}

export const checkSearchResult = (searchResult: searchResult) => {
  //todo 一旦フライトは無視
  if (searchResult.hotel === true ) {
    searchResult.result = true;
  } else if (searchResult.hotel === false ){
    searchResult.result = false
  }
  
  return searchResult;
}

export const getTravelerNums = (planSelectFormData: PlanSelectFormData) => {
  let total = 0;
  if (planSelectFormData.searchParams.roomDetail.length > 0) {
    planSelectFormData.searchParams.roomDetail.forEach((roomDetail) => {
      total += roomDetail.adult;
      if (roomDetail.child && roomDetail.child.length > 0){
        total += roomDetail.child.length;
      }
    })
  }
  if (total > 0) {
    total--;
  }
  return total;
}

export const getTotalTravelerNums = (planSelectFormData: PlanSelectFormData) => {
  let total = 0;
  if (planSelectFormData.searchParams.roomDetail.length > 0) {
    planSelectFormData.searchParams.roomDetail.forEach((roomDetail) => {
      total += roomDetail.adult;
      if (roomDetail.child && roomDetail.child.length > 0){
        total += roomDetail.child.length;
      }
    })
  }
  return total;
}

export const getFellowsCountArray = (planSelectFormData: PlanSelectFormData) => {
  let total = 0;
  const result = [];
  if (planSelectFormData.searchParams.roomDetail.length > 0) {
    planSelectFormData.searchParams.roomDetail.forEach((roomDetail) => {
      total += roomDetail.adult;
      if (roomDetail.child && roomDetail.child.length > 0){
        total += roomDetail.child.length;
      }
    })
  }
  if (total > 0) {
    total--;
  }
  
  if (total > 0) {
    for (let num = 0; num < total; num++) {
      result.push(num)
    }
  }
  
  return result;
}

export const checkBabyNums = (roomData: roomDetail[]) => {
  if (roomData.length > 0) {
    for(const [index, room] of roomData.entries()) {
      const adult = room.adult;
      let babyCount = 0;
      if (room.child.length > 0) {
        for(const [cIndex, child] of room.child.entries()) {
          if (child.age < 3) {
            babyCount++;
          }
          if (adult < babyCount) {
            return true;
          }
        }
      }
    }
  }
  
  return false;
}

export const getSearchTravelerNums = (roomData: roomDetail[]) => {
  let total = 0;
  if (roomData.length > 0) {
    roomData.forEach((roomDetail) => {
      total += roomDetail.adult;
      if (roomDetail.child && roomDetail.child.length > 0){
        total += roomDetail.child.length;
      }
    })
  }
  return total;
}


const childrenPeople = (roomDetailChild: roomDetailChild[]) => {
  let children_a = 0;
  let children_b = 0;
  let children_c = 0;
  let children_d = 0;
  let children_e = 0;
  let children_f = 0;

  roomDetailChild.forEach((child) => {
    const age = Number(child.age);
    if (age >= 10) {
      children_a++;
    } else if(age < 10 && age >= 6) {
      children_b++;
    } else if(age < 6){
      if (child.meal && child.futon) {
        children_c++
      } else if (child.meal) {
        children_d++
      } else if (child.futon) {
        children_e++;
      } else {
        children_f++
      }
    }
  });

  return children_a + children_b + children_c + children_f;
}