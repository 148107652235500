import axios, { AxiosInstance } from "axios";

class Api {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_FUNCTIONS_BASE_URL,
      timeout: 30000, // 30s
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      validateStatus: () => true,
    });
  }

  get = async (params: { url: string; config?: any }) => {
    return await this.axiosInstance.get(params.url, {
      ...params.config,
    });
  };

  post = async (params: { url: string; data: any; config?: any }) => {
    return await this.axiosInstance.post(params.url, params.data, {
      ...params.config,
    });
  };
}

export const useApi = new Api();
