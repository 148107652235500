import {IActivity} from "../types/Activity";
import {FReservationFlight} from "../types/FlightV2";
import dayjs from "dayjs";
import _ from "lodash";

dayjs.locale("ja");

/**
 * フライトの時間に間に合わないアクティビティを取得
 * @param selectActivity
 * @param selectedFlights
 * @param flightType
 */
export const checkOnTimeForFlight = (selectActivity: IActivity[], selectedFlights: FReservationFlight, flightType: string) => {
  
  const missFlightActivity: IActivity[] = _.cloneDeep(selectActivity).filter((activity) => {
    if (activity?.active && activity.plan_info?.time_required && activity.isAlertActive === 'enabled') {
      const alertTime = activity.alertTime;
      if (flightType === 'inbound') {
        const activityTime = dayjs(activity.date + " " + activity.time);
        const connectionLastFlight = selectedFlights.FReservationConnectionFlight;
        const outboundFlight = connectionLastFlight.length > 0 ? connectionLastFlight[0] : selectedFlights;
        const flightDay = dayjs(outboundFlight.date + outboundFlight.arv);
        
        // 行きのフライトの場合、アクティビティの開始時間がフライトより前の場合、アクティビティを返す
        if (activityTime.diff(flightDay, 'minute') < Number(activity.alertTime)) {
          return activity;
        }
      } else {
        const flightDay = dayjs(selectedFlights.date + selectedFlights.dep);
        const activityTime = dayjs(activity.date + " " + activity.time).add(Number(activity.plan_info.time_required), 'h').add(Number(alertTime), 'm');

        // 帰りのフライトの場合、アクティビティの終了時間がフライトより後の場合、アクティビティを返す
        if (flightDay.isBefore(activityTime)) {
          return activity;
        }
      }
    }
  });

  return missFlightActivity
}