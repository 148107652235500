import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  // useRoutes,
} from "react-router-dom";

import { ROUTES } from "./constants/routes";

import {
  Form1,
  Form2,
  Form3,
  Form4,
  Form5,
  Form6,
  PlanSelectTop,
  PlanSelectConfirm,
  PlanSelectComplete,
  ReservationCancelLogin,
  ReservationCancelDetail,
  ReservationCancelFee,
  Page404,
  PageServiceStop,
  Conditions,
  Agreement,
  Faq
} from "./pages/";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import {FormSessionProvider} from "./hooks/FormSession";
import {TagManagerProvider} from "./hooks/GTM";

// interface AppProps {
// }
// interface AppState {
//     authed: boolean;
//     loading: boolean;
// }

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <>
      <FormSessionProvider>
        <Provider store={store}>
        <Routes>
          {/* Private Route */}
          <Route path={ROUTES.Default} element={<Page404 />} />
          <Route path={ROUTES.Form1} element={<Form1 />} />
          <Route path={ROUTES.Form2} element={<Form2 />} />
          <Route path={ROUTES.Form3} element={<Form3 />} />
          <Route path={ROUTES.Form4} element={<Form4 />} />
          <Route path={ROUTES.Form5} element={<Form5 />} />
          <Route path={ROUTES.Form6} element={<Form6 />} />
          <Route path={ROUTES.Default} element={<PlanSelectTop />} />
          <Route path={ROUTES.PlanSelectTop} element={<PlanSelectTop />} />
          <Route path={ROUTES.PlanSelectConfirm} element={<PlanSelectConfirm />} />
          <Route path={ROUTES.PlanSelectComplete} element={<PlanSelectComplete />}/>
          <Route path={ROUTES.ReservationCancelLogin} element={<ReservationCancelLogin />} />
          <Route path={ROUTES.ReservationCancelDetail} element={<ReservationCancelDetail />} />
          <Route path={ROUTES.ReservationCancelFee} element={<ReservationCancelFee />} />
          <Route path={ROUTES.Page404} element={<Page404 />} />
          <Route path={ROUTES.PageServiceStop} element={<PageServiceStop />} />
          <Route path={ROUTES.Conditions} element={<Conditions />} />
          <Route path={ROUTES.Agreement} element={<Agreement />} />
          <Route path={ROUTES.Faq} element={<Faq />} />
        </Routes>
      </Provider>
      </FormSessionProvider>
    </>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <TagManagerProvider >
        <ScrollToTop />
        <App />
      </TagManagerProvider>
    </Router>
  );
};

export default AppWrapper;
