import {apiClient} from "./apiClient";
import {
  carRentalAnshinOptionType,
  carRentalChildSeatOptionType,
  carRentalChildSheetData, carRentalCountOption, carRentalDetail, CarRentalFlightArrivalTimes,
  carRentalPlanData, carRentalPlansResponse,
  carRentalSaveData, carRentalSearchResponse, carRentalSearchResponsePlanList
} from "../types/carRental";
import {dateFormat, inDateTimeRange} from "./convert";
import {
  carRentalAnshinOptions,
  carRentalBorrowingTimeOptions,
  carRentalChildSeatOptions,
  carRentalOfficeCodes,
  carRentalReturnTimeOptions,
  chitoseCarRentalBorrowingTimeOptions,
  chitoseCarRentalOfficeCodes,
  chitoseCarRentalReturnTimeOptions, heiseiCarRentalBorrowingTimeOptions,
  heiseiCarRentalOfficeCodes,
  heiseiCarRentalReturnTimeOptions
} from "../config/carRental";
import {PlanSearch} from "../types/Search";
import {reserveRentalCar} from "../types/reserve";
import _ from "lodash";
import {PlanSelectFormData, PlanSelectFromFlightType} from "../types/planSelectForm";
import { FReservationFlight } from "../types/FlightV2";
import { getFlightTime } from "./flight";
import {lastCurrentFlight} from "./flightV2";
import dayjs from "dayjs";
import {airCode} from "../config/Airport";
dayjs.locale("ja");


export const carRentalSearch = async (input: { data: any }): Promise<carRentalSearchResponse | undefined> => {
  try {
    const response = await apiClient.post("/carRental/v2/carRental-search", input.data);
    return response.data as carRentalSearchResponse;
  } catch (error) {
    return undefined;
  }

}

export const carRentalPlans = async (input: { data: any }): Promise<carRentalPlansResponse | undefined> => {
  try {
    const response = await apiClient.post("/carRental/v2/plans", input.data);
    return response.data as carRentalPlansResponse;
  } catch (error) {
    return undefined;
  }
}

/**
 * レンタカープラン取得（レンタカーAPI使用）
 * @param input
 */
export const carRentalPlanApi = async (input: { data: any }) => {
  const carRentalRowsData: carRentalPlanData[] = [];
  const childSeatRowsData: carRentalChildSheetData[] = [];
  const childSheetPrices = 1100;
  childSeatRowsData.push({
    img: require("../img/share/rentacar/rentacar_img_Seat_01.png").default,
    heading: "乳幼児ベビーシート",
    target_age: "新生児から生後1歳位まで",
    weight: "10kg位まで",
    height: "75cm位まで",
    additional_fee: "+" + childSheetPrices.toLocaleString(),
    code: 'baby_sheet',
    price: childSheetPrices,
  });

  childSeatRowsData.push({
    img: require("../img/share/rentacar/rentacar_img_Seat_02.png").default,
    heading: "幼児用チャイルドシート",
    target_age: "生後6ヶ月から4歳位まで",
    weight: "18kg位まで",
    height: "100cm位まで",
    additional_fee: "+" + childSheetPrices.toLocaleString(),
    code: 'child_sheet',
    price: childSheetPrices,
  });

  childSeatRowsData.push({
    img: require("../img/share/rentacar/rentacar_img_Seat_03.png").default,
    heading: "学童用ジュニアシート",
    target_age: "4歳から6歳位まで",
    weight: "32kg位まで",
    height: "135cm位まで",
    additional_fee: "+" + childSheetPrices.toLocaleString(),
    code: 'junior_sheet',
    price: childSheetPrices,
  });

  try {
    const response = await apiClient.post("/carRental/plans", input.data);
    const carRentalData = await response.data;

    carRentalData.result.forEach((carDental: { [key: string]: any }) => {
      if (carDental.success) {
        const tagRows: string[] = []
        carDental.response.plan_info.default_option_list.forEach((defaultOptionList: any) => {
          tagRows.push(defaultOptionList.option_name);
        });
        const fee = Number(carDental.response.plan_info.total_price);
        carRentalRowsData.push({
          data: carDental.response,
          plan_code: carDental.response.plan_info.plan_code,
          img: carDental.response.plan_info.class_image_url,
          status: true,
          grade: carDental.response.plan_info.class_name,
          car_model: carDental.response.plan_info.class_model,
          engine_size: carDental.response.plan_info.class_cc,
          tag_rows: tagRows,
          max_member: carDental.response.plan_info.max_member,
          price: carDental.response.plan_info.total_price,
          basic_price: carDental.response.plan_info.basic_price,
          indemnity_price: carDental.response.plan_info.indemnity_price ?? 0,
          additional_fee: "+" + fee.toLocaleString()
        });
      }
    });

    return {error: false, carRentalRowsData: carRentalRowsData, childSeatRowsData: childSeatRowsData};
  } catch (error) {
    return {error: true, carRentalRowsData: carRentalRowsData, childSeatRowsData: childSeatRowsData};
  }

}

/**
 * レンタカー在庫確認API
 * @param input
 */
export const carRentalStockAPI = async (input: { data: reserveRentalCar }) => {
  try {
    const response = await apiClient.post("/carRental/stock", input.data);
    const carRentalData = response.data;
    if (response.status === 200 && carRentalData.success) {
      return {status: 200, result: true, errorCode: carRentalData.errorCode ?? "", errorMessage: carRentalData.errorMessage ?? ""};
    }
    return {status: 200, result: false, errorCode: carRentalData.errorCode ?? "", errorMessage: carRentalData.errorMessage ?? ""};
  } catch (e) {
    return {status: 500, result: false, errorCode: "", errorMessage: ""};
  }
}

/**
 * チャイルドシートの料金取得
 * @param carRentalPlanOptionList
 */
const getChildSheetPrices = (carRentalPlanOptionList: { [key: string]: any }[]) => {
  const result = {
    babySheet: 0,
    childSheet: 0,
    juniorSheet: 0
  };
  carRentalPlanOptionList.forEach((option) => {
    if (option["option_name"] === "ベビーシート") {
      result.babySheet = option.option_vaule_list[1]["price"] ?? 0;
    } else if (option["option_name"] === "チャイルドシート") {
      result.childSheet = option.option_vaule_list[1]["price"] ?? 0;
    } else if (option["option_name"] === "ジュニアシート") {
      result.juniorSheet = option.option_vaule_list[1]["price"] ?? 0;
    }
  });

  return result;
};

/**
 * 選択したレンタカー情報の保存
 * @param carRentalSaveData
 * @param carRentalPlanData
 * @param childSeatRows
 * @param type
 * @param plan_code
 * @param count
 */
export const updateCarRentalSaveData = (carRentalSaveData: carRentalSaveData, carRentalPlanData: carRentalSearchResponsePlanList[], type: string, plan_code: string, count: number) => {
  if (type === "car") {
    const saveDataIndex = carRentalSaveDataIndex(carRentalSaveData, plan_code);
    const saveData = carRentalSaveData.detail.filter((carRentalPlan) => { return carRentalPlan.plan_code === plan_code });
    const plan = carRentalPlanData.find((carRentalPlan) => {
      return carRentalPlan.plan_code === plan_code
    })

    if (carRentalSaveData.detail.length > 0) {
      if (saveData.length > count) {
        carRentalSaveData.detail.splice(saveDataIndex, 1);
      } else if (saveData.length < count && plan) {
        carRentalSaveData.detail.push({
          plan_code: plan.plan_code,
          img: "",
          grade: "",
          car_model: "",
          engine_size: "",
          tag_rows: [],
          additional_fee: "",
          price: 0,
          basic_price: 0,
          indemnity_price: 0,
          max_member: 0,
          status: true,
          num: 1,
          detail: plan,
          total: Number(plan.price),
          count_option: {
            "baby_seat": {
              num: 0,
              option_code: "",
              price: 0
            },
            "child_seat": {
              num: 0,
              option_code: "",
              price: 0
            },
            "junior_seat": {
              num: 0,
              option_code: "",
              price: 0
            },
            "anshin": {
              used: false,
              option_name: "",
              price: 0
            },
            "anshin_premium": {
              used: false,
              option_name: "",
              price: 0
            }
          }
        });
      }
    } else if (count > 0 && plan) {
      carRentalSaveData.detail.push({
        plan_code: plan.plan_code,
        img: "",
        grade: "",
        car_model: "",
        engine_size: "",
        tag_rows: [],
        additional_fee: "",
        price: 0,
        basic_price: 0,
        indemnity_price: 0,
        max_member: 0,
        status: true,
        num: 1,
        detail: plan,
        total: Number(plan.price),
        count_option: {
          "baby_seat": {
            num: 0,
            option_code: "",
            price: 0
          },
          "child_seat": {
            num: 0,
            option_code: "",
            price: 0
          },
          "junior_seat": {
            num: 0,
            option_code: "",
            price: 0
          },
          "anshin": {
            used: false,
            option_name: "",
            price: 0
          },
          "anshin_premium": {
            used: false,
            option_name: "",
            price: 0
          }
        }
      });
    }
  }

  carRentalSaveData.total = calculationCarRentalTotalPrice(carRentalSaveData);
  return {...carRentalSaveData} as carRentalSaveData;
}

/**
 * 保存するレンタカー情報を追加
 * @param carRentalPlanData
 * @param plan_code
 * @param count
 */
const addCarRentalDetailData = (carRentalPlanData: carRentalPlanData[], plan_code: string, count: number) => {
  const planData = getSelectCarRentalPlan(carRentalPlanData, plan_code);
  if (planData.length > 0) {
    return {
      plan_code: planData[0]["plan_code"],
      img: planData[0]["img"],
      status: planData[0]["status"],
      grade: planData[0]["grade"],
      car_model: planData[0]["car_model"],
      engine_size: planData[0]["engine_size"],
      tag_rows: planData[0]["tag_rows"],
      additional_fee: planData[0]["additional_fee"],
      max_member: planData[0]["max_member"],
      price: planData[0]["price"],
      basic_price: planData[0]["basic_price"],
      indemnity_price: planData[0]["indemnity_price"],
      num: count,
      option: planData[0]["data"]["plan_info"]["option_list"]
    };
  }
  return false;
}

/**
 * 取得したレンタカー情報から指定したプランコードのデータを取得
 * @param carRentalPlanData
 * @param plan_code
 */
const getSelectCarRentalPlan = (carRentalPlanData: carRentalPlanData[], plan_code: string) => {
  return carRentalPlanData.filter((carRentalPlan) => {
    return carRentalPlan.plan_code === plan_code
  });
}

/**
 指定したプランコードのプランがすでに設定されているか確認
 * @param carRentalSaveData
 * @param plan_code
 */
const carRentalSaveDataIndex = (carRentalSaveData: carRentalSaveData, plan_code: string) => {
  return carRentalSaveData.detail.findIndex((carRentalPlan) => {
    return carRentalPlan.plan_code === plan_code
  });
}

/**
 * 設定したレンタカープランの合計金額を計算
 * @param carRentalSaveData
 */
const calculationCarRentalTotalPrice = (carRentalSaveData: carRentalSaveData) => {
  let total = 0;
  carRentalSaveData.detail.forEach((carRental) => {
    total += carRental.total;
  });

  return total;
}

export const getDateLabel = (date: string, time: string) => {
  const jpDate = dateFormat(date, "YYYY年MM月DD日（ddd）");
  return jpDate + " " + time;
}

export const getCarRentalOfficeLabel = (office_code: string) => {
  const officeCodes = carRentalOfficeCodes.filter((office) => {
    return office.code === office_code
  });
  const hokkaidouOfficeCodes = chitoseCarRentalOfficeCodes.filter((office) => {
    return office.code === office_code
  });
  const heiseiOfficeCodes = heiseiCarRentalOfficeCodes.filter((office) => {
    return office.code === office_code
  });

  if (heiseiOfficeCodes.length > 0) {
    return heiseiOfficeCodes[0].label
  }
  if (officeCodes.length > 0) {
    return officeCodes[0].label
  }

  if (hokkaidouOfficeCodes.length > 0) {
    return hokkaidouOfficeCodes[0].label
  }
  return "";
}

/**
 * レンタカーのご来店手段を取得
 * @param office_code
 */
export const getCarRentalOfficeAccessType = (office_code: string) => {
  const officeCodes = carRentalOfficeCodes.filter((office) => {
    return office.code === office_code
  });
  const hokkaidouOfficeCodes = chitoseCarRentalOfficeCodes.filter((office) => {
    return office.code === office_code
  });
  const heiseiOfficeCodes = heiseiCarRentalOfficeCodes.filter((office) => {
    return office.code === office_code
  });

  if (heiseiOfficeCodes.length > 0) {
    return heiseiOfficeCodes[0].accessType
  }
  if (officeCodes.length > 0) {
    return officeCodes[0].accessType
  }

  if (hokkaidouOfficeCodes.length > 0) {
    return hokkaidouOfficeCodes[0].accessType
  }
  return "";
}

export const getCarRentalCountNum = (saveData: carRentalSaveData, plan_code: string) => {

  if (saveData !== undefined && saveData.detail !== undefined && saveData.detail.length > 0) {
    const carRentalDetail = saveData.detail.filter((detail) => {
      return detail.plan_code === plan_code
    });
    return carRentalDetail.length ?? 0;
  }

  return 0;
}

export const getCarRentalSheetCountNum = (saveData: carRentalSaveData, code: string) => {
  if (saveData !== undefined) {
    // switch (code) {
    //   case "baby_sheet":
    //     return saveData.count_option.baby_seat;
    //   case "child_sheet":
    //     return saveData.count_option.child_seat;
    //   case "junior_sheet":
    //     return saveData.count_option.junior_seat;
    //   default:
    //     break;
    // }
  }

  return 0;
}

export const getCarRentalReservePeople = (searchParams: PlanSearch) => {
  const people = {
    "joshaadt": 0,
    "joshainf": 0,
  }
  
  searchParams.roomDetail.forEach((roomDetail) => {
    people.joshaadt += roomDetail.adult;
    if (roomDetail.child.length > 0) {
      roomDetail.child.forEach((child) => {
        if (child.age > 1) {
          people.joshaadt++;
        } else {
          people.joshainf++;
        }
      })
    }
  })
  
  return people;
}

export const getCarRentalOptionCode = (type: string, optionDetail:carRentalDetail ) => {
  
  if (type === 'baby_seat'){
    const optionIndex = optionDetail.option.findIndex((option) => {
      return option.option_name === 'ベビーシート';
    })
    if (optionIndex >= 0) {
      return optionDetail.option[optionIndex];
    }
  } else if (type === 'child_seat'){
    const optionIndex = optionDetail.option.findIndex((option) => {
      return option.option_name === 'チャイルドシート';
    })
    if (optionIndex >= 0) {
      return optionDetail.option[optionIndex];
    }
    
  } else if (type === 'junior_seat'){
    const optionIndex = optionDetail.option.findIndex((option) => {
      return option.option_name === 'ジュニアシート';
    })
    if (optionIndex >= 0) {
      return optionDetail.option[optionIndex];
    }
  }
  
  return undefined;
}

/**
 * `airCode`からレンタカー店舗の`OfficeCode`と`OfficePlaceIndex`を取得する
 * @param airCode
 * @returns
 */
export const getCarRentalOfficeDataFromAirCode = (planSelectFormData: PlanSelectFormData) => {
  const rentalCarStatus = planSelectFormData.sellerProduct?.rentalCarSettings.status;
  const airCode = planSelectFormData?.searchParams?.to;
  let officeCode = "toyosaki";
  if (rentalCarStatus === "chitose") {
    officeCode = "chitose";

    return {
      code: officeCode,
      index: _.findIndex(chitoseCarRentalOfficeCodes, {code: officeCode}) ?? 0,
    };
  } else if (rentalCarStatus === "heisei") {
    switch (airCode) {
      case "OKJ":
        officeCode = "okayama-airport";
        break;
      case "TAK":
        officeCode = "takamatsu-airport";
        break;
      case "MYJ":
        officeCode = "matsuyama-airport";
        break;
      default:
        officeCode = "okayama-airport";
        break;
    }
    return {
      code: officeCode,
      index: _.findIndex(heiseiCarRentalOfficeCodes, { code: officeCode }) ?? 0,
    };
  } else {
    switch (airCode) {
      case "MMY":
        officeCode = "miyako";
        break;
      case "ISG":
        officeCode = "ishigaki";
        break;
      default:
        break;
    }

    return {
      code: officeCode,
      index: _.findIndex(carRentalOfficeCodes, { code: officeCode }) ?? 0,
    };

  }
};

/**
 * フライトの往路と復路の到着時刻を取得する
 * @param planSelectFromFlight 
 * @returns 
 */
export const getFlightArrivalTimes = (planSelectFromFlight: PlanSelectFromFlightType[]): CarRentalFlightArrivalTimes => {
  const arrivalTimes: CarRentalFlightArrivalTimes = {
    inbound: {
      date: "",
      time: "",
    },
    outbound: {
      date: "",
      time: "",
    }
  };

  _.forEach(planSelectFromFlight, (flight: PlanSelectFromFlightType) => {
    const flightDate = {
      date: "",
      time: "",
    }
    const flightEntity: FReservationFlight | undefined = _.head(flight.entity);
    flightDate.date = flightEntity?.date ?? "";
    if (flight.action === 'inbound') {
      flightDate.time = getFlightTime(flightEntity?.arv)?? "";
    } else {
      flightDate.time = getFlightTime(flightEntity?.dep)?? "";
    }

    // 乗継便
    if (flightEntity && flightEntity.FReservationConnectionFlight.length > 0) {
      const lastConnectionFlight = lastCurrentFlight(flightEntity.FReservationConnectionFlight);
      if (lastConnectionFlight.length > 0) {
        flightDate.date = lastConnectionFlight[0].date ?? "";
        if (flight.action === 'inbound') {
          flightDate.time = getFlightTime(lastConnectionFlight[0].arv)?? "";
        } else {
          flightDate.time = getFlightTime(lastConnectionFlight[0].dep)?? "";
        }
      }
    }
    
    switch (flight.action){
      case "inbound":
        arrivalTimes.inbound = flightDate;
        break;
      case "outbound":
        arrivalTimes.outbound = flightDate;
        break;
      default:
        break;
    }
  });

  return arrivalTimes;
}

/**
 * フライトの到着時刻をもとに初期値を取得する
 * @param flightArea 
 * @param flightDate 
 * @param flightTime 
 * @param type 
 * @returns 
 */
export const getCarRentalDefaultTime = (flightArea: string, flightDate: string, flightTime: string, type: string, rentalCarStatus: string) => {

  // 貸出または返却の時間を計算
  const date = dayjs(flightDate + " " + flightTime);
  const baseDate = date.format("YYYY/MM/DD");
  const targetDateTime = date.format("HH:mm");
  if (type === "borrowing") {
    /* 貸し出し時間 */
    const isFirstDateTimeRange = inDateTimeRange(targetDateTime, baseDate, "18:15", "23:59") || inDateTimeRange(targetDateTime, baseDate, "00:00", "07:15");
    const isHeiseiLastDateTimeRange = inDateTimeRange(targetDateTime, baseDate, "17:00", "23:59") || inDateTimeRange(targetDateTime, baseDate, "00:00", "09:45");

    if (rentalCarStatus === 'heisei' && isHeiseiLastDateTimeRange) {
      return "09:00";
    }else if (isFirstDateTimeRange) {
      return "08:00";
    } else if (date.minute() >= 15 && date.minute() < 45) {
      // 30分台に設定
      const targetDate = date.add(1, "hour");
      return targetDate.format("HH") + ":30";
    } else {
      // 00分台に設定
      const addHour: number = (date.minute() <= 15) ? 1 : 2;
      const targetDate = date.add(addHour, "hour");
      return targetDate.format("HH") + ":00";
    }
  } else if (type === "return") {
    /* 返却時間 */
    const isChitoseLastDateTimeRange = inDateTimeRange(targetDateTime, baseDate, "20:15", "23:59") || inDateTimeRange(targetDateTime, baseDate, "00:00", "09:45");
    const isHeiseiLastDateTimeRange = inDateTimeRange(targetDateTime, baseDate, "18:01", "23:59") || inDateTimeRange(targetDateTime, baseDate, "00:00", "09:45");
    const isLastDateTimeRange = inDateTimeRange(targetDateTime, baseDate, "20:45", "23:59") || inDateTimeRange(targetDateTime, baseDate, "00:00", "09:45");
    
    if (flightArea === 'chitose' && isChitoseLastDateTimeRange) {
      return "18:30";
    } else if (rentalCarStatus === 'heisei' && isHeiseiLastDateTimeRange) {
      return "18:00";
    } else if (isLastDateTimeRange) {
      return "19:00";
    } else if (date.minute() >= 15 && date.minute() < 45) {
      // 30分台に設定
      const targetDate = date.add(-2, "hour");
      return targetDate.format("HH") + ":30";
    } else {
      // 00分台に設定
      const addHour: number = (date.minute() <= 15) ? -2 : -1;
      const targetDate = date.add(addHour, "hour");
      return targetDate.format("HH") + ":00";
    }
  }
  
  return "08:00";
}

/**
 * レンタカーの貸出、返却時間の初期indexを取得する
 * @param flightTime 
 * @param timeOptions 
 * @returns 
 */
export const getCarRentalDefaultTimeIndex = (flightTime: string, timeOptions: string[]) => {
  return _.indexOf(timeOptions, flightTime);
}

/**
 * レンタカーの貸出、返却時間範囲取得
 * @param flightArea
 * @param type
 */
export const getCarRentalTimeOptions = (flightArea: string, type: string) => {
  switch (flightArea){
    case 'chitose': 
      return type === 'return'? chitoseCarRentalReturnTimeOptions : chitoseCarRentalBorrowingTimeOptions;
    case 'heisei':
      return type === 'return'? heiseiCarRentalReturnTimeOptions : heiseiCarRentalBorrowingTimeOptions;
    default:
      break;
  }

  return type === 'return' ? carRentalReturnTimeOptions : carRentalBorrowingTimeOptions;
}

export const getCarRentalChildOptionItem = (name: string, key: keyof carRentalChildSeatOptionType) => {
  const findOption = carRentalChildSeatOptions.find((option) => {
    return option.title === name;
  });
  
  return findOption ? findOption[key] : "";
}

export const getCarRentalAnshinOptionItem = (name: string, key: keyof carRentalAnshinOptionType) => {
  const findOption = carRentalAnshinOptions.find((option) => {
    return option.name === name;
  });
  
  return findOption ? findOption[key] : "";
}

/**
 * レンタカーのオプションの有無
 * @param countOption
 */
export const isCarRentalOptions = (countOption: carRentalCountOption) => {
  return countOption.baby_seat.num > 0 || countOption.child_seat.num > 0 || countOption.junior_seat.num > 0 || countOption.anshin.used || countOption.anshin_premium.used;
}


export const getCarRentalOfficeCodes = (rentalCarStatus: string) => {
  if (rentalCarStatus === "chitose") {
    return chitoseCarRentalOfficeCodes;
  } else if (rentalCarStatus === "heisei") {
    return heiseiCarRentalOfficeCodes
  }
  return carRentalOfficeCodes;
  
}

export const carRentalStockErrorMessages = (errorCode: string, message: string) => {
  console.log("carRentalStockErrorMessages", {
    errorCode: errorCode,
    message: message,
  })
  if (errorCode === "E2006" || errorCode === "E2007") {
    return message.replace("departure_time", "貸出時間").replace("return_time", "返却時間");
  }
  
  return "選択したレンタカーの設定の中に在庫切れのものがあります。再度選択をお願いします。";
}