import React, { Fragment } from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import { InputText } from "../input/InputText";
import {hotelTagImageName} from "../../utils/hotel";
import {hotelFacilityList} from "../../types/Hotel";


type Props = {
  hotel_facility_list: hotelFacilityList[]|undefined;
  ulClass?:string
}

const PartsHotelTagList: React.FC<Props> = (props) => {

  return (
    <ul className={props.ulClass}>
      {props.hotel_facility_list?.map((facilityList, index) => (
        <Fragment key={index}>
        {facilityList.name === '全室キッチン付、IHコンロ、電子レンジ完備' || facilityList.name === '全室キッチン付' ?
          <>
            <li key={"tag1-"+index} className="parts-hotel_tag-list-item">
              <img
                src={
                  require("../../img/share/tag/hotel/28_tag_induction-cooker.svg").default
                }
                alt=""
              />
            </li>
            <li key={"tag2-"+index} className="parts-hotel_tag-list-item">
              <img
                src={
                  require("../../img/share/tag/hotel/29__tag_microwave.svg").default
                }
                alt=""
              />
            </li>
          </>
          : facilityList.name === 'IHコンロ' ?
            <>
              <li className="parts-hotel_tag-list-item">
                <img
                  src={
                    require("../../img/share/tag/hotel/28_tag_induction-cooker.svg").default
                  }
                  alt=""
                />
              </li>
            </>
          : facilityList.name === '電子レンジ完備' ?
            <>
              <li key={"tag3"+index} className="parts-hotel_tag-list-item">
                <img
                  src={
                    require("../../img/share/tag/hotel/28_tag_induction-cooker.svg").default
                  }
                  alt=""
                />
              </li>
            </>
          : hotelTagImageName(facilityList.name) ?
            <>
              <li key={"tag4"+index} className="parts-hotel_tag-list-item">
                <img
                  src={
                    require('../../img/share/tag/hotel/' + hotelTagImageName(facilityList.name)).default
                  }
                  alt=""
                />
              </li>
            </>:<></>
          }
        </Fragment>
      ))}
    </ul>
  );
};

export default PartsHotelTagList;
