import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InputsForm1 } from "../types/InputsForm1";

export interface Form1State {
  form1: InputsForm1;
}

const initialState: Form1State = {
  form1: {
    agree: "",
    applicant: {
      name: {
        kanji: {
          last: "",
          first: "",
        },
        kana: {
          last: "",
          first: "",
        },
      },
      birthday_year: 1980,
      birthday_month: "01",
      birthday_day: "01",
      email: "",
      email_confirm: "",
      gender: "male",
      address: {
        zip_code: "",
        prefecture: "東京都", // 初期値をセットしておかないと、AjaxZip3で都道府県をセットしても必須チェックに引っかかる
        address_main: "",
        address_sub: "",
      },
      tel: "",
      emergency_tel: "",
      mileage_number: "",
    },
    representative: {
      is_applicant_join: "yes",
      name: {
        kanji: {
          last: "",
          first: "",
        },
        kana: {
          last: "",
          first: "",
        },
      },
      birthday_year: 1980,
      birthday_month: "01",
      birthday_day: "01",
      gender: "male",
      tel: "",
      mileage_number: "",
    },
    followers: [],
    note: "",
  },
};

export const form1Slice = createSlice({
  name: "form1",
  initialState,
  reducers: {
    save: (state, action: PayloadAction<Form1State>) => {
      state.form1 = { ...state.form1, ...action.payload.form1 };
    },
    clear: (state) => {
      state.form1 = initialState.form1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { save, clear } = form1Slice.actions;

export default form1Slice.reducer;
