import React, {useEffect} from "react";

// for styles
import {
  Button,
  Backdrop,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,

} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {defaultSearchRoomChildData, defaultSearchRoomData} from "../../defaults/search";
import {childAgeFiledItems} from "../../config/search";
import {roomDetail, roomDetailChild} from "../../types/Search";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  roomDetail:roomDetail[],
  count:number,
  callback: () => void,
  updateRoomDetail(roomDetailData: any[]|undefined):void
}

const SearchCounter: React.FC<Props> = ({
  isShow,
  roomDetail,
  count,
  callback,
  updateRoomDetail
}) => {
  
  const [roomDetailData, setRoomDetailData] = React.useState<roomDetail[]>([]);
  const disabledAdultCountRoom = (count:number) => {
    return count <= 1;
  };
  const disabledCountRoom = (count:number) => {
    return count <= 0;
  };
  const disabledPlusCountRoom = (index:number) => {
    const roomDetail = [...roomDetailData];
    let count = 0;
    if (roomDetail[index] !== undefined) {
      count += Number(roomDetail[index]?.adult)?? 0;
      if (roomDetail[index]?.child !== undefined) {
        count += roomDetail[index]?.child.length?? 0;
      }
    }
    return count >= 9;
  };

  useEffect(() => {
    if (isShow) {
      const _roomDetail: roomDetail[] = [];
      roomDetail.forEach((room) => {
        const _child: roomDetailChild[] = [];
        if (room.child.length > 0) {
          room.child.forEach((child) => {
            _child.push({
              ...child
            })
          })
        }
        
        _roomDetail.push({
          adult: room.adult,
          child: _child
        });
      });
      setRoomDetailData([..._roomDetail]);
    }
  }, [isShow]);

  useEffect(() => {

    if (roomDetail !== undefined && 1 !== count && count > roomDetail.length) {
      roomDetail.push({
        adult:1,
        child:[]
      });
      setRoomDetailData(roomDetail);
    } else if (roomDetail !== undefined && count < roomDetail.length) {
      roomDetail.splice(roomDetail.length - 1, 1);
      setRoomDetailData([...roomDetail]);
    }
  },[roomDetail, count]);
  
  const setRoomAdult = (count:number, index:number) => {
    if (roomDetailData !== undefined) {
      const roomData = [...roomDetailData];
      if (roomData[index].adult >= 0) {
        roomData[index].adult = count;
        setRoomDetailData([...roomData]);
      }
    }
  }

  const setRoomChild = (count:number, index:number) => {
    if (roomDetailData !== undefined) {
      const roomData = [...roomDetailData];
      if (roomData[index].child !== undefined && roomData[index].child.length < count) {
        roomData[index].child.push({
          age: 12,
          meal: true,
          futon: true
        });
        setRoomDetailData([...roomData]);
      } else if(roomData[index].child !== undefined && roomData[index].child.length > count) {
        roomData[index].child.splice(count, 1);
        setRoomDetailData([...roomData]);
      }
    }
  }
  
  const setChildAge = (event: React.ChangeEvent<HTMLSelectElement>, index:number, childIndex:number) => {
    if (roomDetailData !== undefined && roomDetailData.length > 0) {
      const roomData = [...roomDetailData];
      if (roomData[index].child !== undefined && roomData[index].child[childIndex] !== undefined) {
        const age = Number(event.target.value);
        roomData[index].child = [...roomData[index].child]
        roomData[index].child[childIndex].age = age;
        if (age > 5) {
          roomData[index].child[childIndex].meal = true;
          roomData[index].child[childIndex].futon = true;
        } else {
          roomData[index].child[childIndex].meal = false;
          roomData[index].child[childIndex].futon = false;
        }
        setRoomDetailData([...roomData]);
      }
    }
  }

  const setChildMealAndFuton = (event: React.ChangeEvent<HTMLInputElement>,  index:number, childIndex:number) => {
    if (roomDetailData !== undefined) {
      const roomData = [...roomDetailData];
      if (roomData[index].child !== undefined && roomData[index].child[childIndex] !== undefined) {
        roomData[index].child[childIndex].meal = event.target.checked;
        roomData[index].child[childIndex].futon = event.target.checked;
        setRoomDetailData([...roomData]);
      }
    }
  }

  const setChildMeal = (event: React.ChangeEvent<HTMLInputElement>,  index:number, childIndex:number) => {
    if (roomDetailData !== undefined) {
      const roomData = [...roomDetailData];
      if (roomData[index].child !== undefined && roomData[index].child[childIndex] !== undefined) {
        roomData[index].child[childIndex].meal = event.target.checked;
        setRoomDetailData([...roomData]);
      }
    }
  }

  const setChildFuton = (event: React.ChangeEvent<HTMLInputElement>,  index:number, childIndex:number) => {
    if (roomDetailData !== undefined) {
      const roomData = [...roomDetailData];
      if (roomData[index].child !== undefined && roomData[index].child[childIndex] !== undefined) {
        roomData[index].child[childIndex].futon = event.target.checked;
        setRoomDetailData([...roomData]);
      }
    }
  }

  return (
    <>
    {/* モーダル：人数選択 */}
    <div>
          <div>
            <Modal
              open={isShow}
              onClose={callback}
              className="modal"
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 300,
              }}
            >
              <div className="modal-fixed">
                <div className="modal-fixed-heading">
                  <p className="modal-fixed-heading-text">
                    人数設定（1室あたり）
                  </p>
                  <p className="modal-fixed-close">
                    <Button
                      onClick={callback}
                      className="modal-fixed-close-button"
                    >
                      <i className="modal-fixed-close-button-icon"></i>閉じる
                    </Button>
                  </p>
                </div>
                <div className="modal-fixed-content">
                  <div className="modal-fixed-content-inner">
                    <p className={"list-note p-10"}>最大９名まで同時にお申し込み可能です</p>
                    <ul className="parts-room-list">
                      {roomDetailData?.map((room, index) => (
                        <li key={"roomlist"+index} className="parts-room-list-item">
                          <div className="parts-room-list-item-heading">
                            お部屋{index + 1}
                          </div>
                          <ul className="parts-room-list-item-counter">
                            <li key={"counter_adult"+index} className="parts-room-list-item-counter-item">
                              <div className="parts-room-list-item-counter-item-heading">
                                大人
                                <span className="fz-13 fw-n">（13歳以上）</span>
                                <ul className="list-note mt-24">
                                  <li>予約者（申込者）が予約日に18歳未満の場合はお申し込みできません。</li>
                                </ul>
                              </div>
                              <div className="parts-room-list-item-counter-item-content">
                                {/* カウンター */}
                                <div className="counter pl-32-md pr-32-md">
                                  <div className="counter-minus">
                                    <button
                                      type="button"
                                      className="counter-minus-icon"
                                      onClick={() => setRoomAdult(Number(room?.adult) - 1, index)}
                                      disabled={disabledAdultCountRoom(Number(room?.adult) ?? 1)}
                                    ></button>
                                  </div>
                                  <div className="counter-value">{room?.adult ?? 0}</div>
                                  <div className="counter-plus">
                                    <button
                                      type="button"
                                      className="counter-plus-icon"
                                      disabled={disabledPlusCountRoom(index)}
                                      onClick={() => setRoomAdult(Number(room?.adult) + 1, index)}
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li key={"counter_child"+index} className="parts-room-list-item-counter-item">
                              <div className="parts-room-list-item-counter-item-heading">
                                子供
                                <span className="fz-13 fw-n">（12歳以下）</span>
                                <ul className="list-note mt-24">
                                  <li>3歳未満の人数が大人の人数を超えている場合はお申し込みできません。</li>
                                  <li>航空座席について、12歳以上は大人運賃が適用されます。</li>
                                </ul>
                              </div>
                              <div className="parts-room-list-item-counter-item-content">
                                {/* カウンター */}
                                <div className="counter pl-32-md pr-32-md">
                                  <div className="counter-minus">
                                    <button
                                      type="button"
                                      className="counter-minus-icon"
                                      onClick={() => setRoomChild(Number(room.child?.length ?? 0) -1, index)}
                                      disabled={disabledCountRoom(Number(room.child?.length ?? 0))}
                                    ></button>
                                  </div>
                                  <div className="counter-value">{room.child?.length ?? 0}</div>
                                  <div className="counter-plus">
                                    <button
                                      type="button"
                                      className="counter-plus-icon"
                                      onClick={() => setRoomChild(Number(room.child?.length ?? 0) +1, index)}
                                      disabled={disabledPlusCountRoom(index)}
                                    ></button>
                                  </div>
                                </div>
                              </div>
                              <div className="parts-room-list-item-counter-item-content-child">
                                {room?.child?.map((childData:any, childIndex:number) => (
                                  <div className="parts-room-list-item-counter-item-content-child-item">
                                    <div className="parts-room-list-item-counter-item-content-child-item-old">
                                      <div className="parts-room-list-item-counter-item-content-child-item-old-heading">
                                        {childIndex + 1}人目
                                      </div>
                                      <div className="parts-room-list-item-counter-item-content-child-item-old-set">
                                        <div className="d-f ai-c">
                                          <div className="select_box-default w-80">
                                            <select 
                                              name={"child-age-" + index + "-" + childIndex}
                                              defaultValue={childData?.age ?? 12}
                                              onChange={(event) => setChildAge(event, index, childIndex)}
                                            >
                                              {childAgeFiledItems.map((value) => (
                                                <option value={value}>
                                                  {value}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          <div className="ml-4">歳</div>
                                        </div>
                                      </div>
                                    </div>

                                    {childData?.age > 5 ? (
                                      <div className="parts-room-list-item-counter-item-content-child-item-option">
                                        <p className="parts-room-list-item-counter-item-content-child-item-option-set">
                                          食事・布団あり
                                        </p>
                                      </div>
                                    ):(
                                      <div className="parts-room-list-item-counter-item-content-child-item-option">
                                        <div className="parts-room-list-item-counter-item-content-child-item-option-check">
                                          <FormControl
                                            component="fieldset"
                                            className="checkbox-group"
                                          >
                                            <FormGroup aria-label="position" row>
                                              <FormControlLabel
                                                value="end"
                                                control={<Checkbox
                                                  checked={childData?.meal ?? false}
                                                  onChange={(event) => setChildMealAndFuton(event, index, childIndex)}
                                                />}
                                                label={
                                                  <span className="fz-12 fz-16-md d-ib lh-1_5">
                                                  食事あり・布団あり
                                                </span>
                                                }
                                                labelPlacement="end"
                                                className="checkbox-group-item"
                                              />
                                            </FormGroup>
                                          </FormControl>
                                        </div>
                                      </div>
                                    )}
                                    
                                  </div>
                                ))}

                              </div>
                            </li>
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="modal-fixed-bottom">
                  <button
                    onClick={() => updateRoomDetail(roomDetailData)}
                    className="button-medium-orange padding-large w-100per"
                  >
                    確定する
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>

    </>
  );
};

export default SearchCounter;
