import React, {useState} from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

import { InputsForm4 } from "../../../types/InputsForm4";
import { InputText } from "../../input/InputText";
import { Select } from "../../input/Select";
import { PartsCardExpireYear, PartsMonth } from "../../parts";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";



type Props = {
  control: any;
  errors: FieldErrors;
  getValues: any;
  register: UseFormRegister<InputsForm4>;
}

const SectionPayment: React.FC<Props> = (props) => {
  return (
    <>
      <section>
        <h2 className="heading-2">お支払い方法</h2>
        <div className="card mb-20">
          <div className="box-light-gray small mb-24">
            <p>お支払いはクレジットカード決済のみとなります。</p>
            <p>
              クレジットカードの名義は、お申込者様(契約者)のものでなければお引き受けできません。予めご確認、ご了承ください。
            </p>
          </div>
          <div className="form-accompany">
            <div className="form-accompany-item">
              <div className="form-accompany-item-heading">
                クレジットカード情報
                <span className="label-required ml-5">必須</span>
              </div>
              <div className="form-accompany-item-content">
                <div className="box-separator_bottom-large">
                  {/*利用可能なクレジットカード */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">
                          利用可能な
                          <br />
                          クレジットカード
                        </h3>
                      </div>
                      <div className="form-content-input">
                        <img
                          src={
                            require("../../../img/share/icon/card_brand.jpg")
                              .default
                          }
                          alt="利用可能なクレジットカード"
                        />
                      </div>
                    </div>
                  </div>

                  {/*カード番号 */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">カード番号</h3>
                      </div>
                      <div className="form-content-input">
                        <div>
                          <InputText
                            name="payment.cashcard_number"
                            placeholder="例：1234 5678 9012 3456"
                            register={props.register}
                            options={{
                              required: true,
                              maxLength: 16,
                              pattern: /^(\d{14}|\d{15}|\d{16})$/
                            }}
                            error={props.errors.payment?.cashcard_number}
                          ></InputText>
                          <ul className="list-note">
                            <li>「 - 」ハイフン無しでご入力ください。</li>
                          </ul>
                          {/* todo: error表示までcomponentに入れるか？ 入れないとmaxlengthとかの制御がめんどくさい。*/}
                          {props.errors.payment?.cashcard_number &&
                            props.errors.payment?.cashcard_number.type ===
                              "required" && (
                              <p className="form-error_text">
                                カード番号をご入力ください。
                              </p>
                            )}
                          {props.errors.payment?.cashcard_number &&
                            props.errors.payment?.cashcard_number.type ===
                              "pattern" && (
                              <p className="form-error_text">
                                カード番号を正しくご入力ください。
                              </p>
                            )}
                          {props.errors.payment?.cashcard_number &&
                            props.errors.payment?.cashcard_number.type ===
                              "maxLength" && (
                              <p className="form-error_text">
                                16桁以内でご入力ください。
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*有効期限 */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">有効期限</h3>
                      </div>
                      <div className="form-content-input">
                        <div
                          className={
                            "form-birthday" +
                            (props.errors.payment?.cashcard_month ||
                            props.errors.payment?.cashcard_year
                              ? " error"
                              : "")
                          }
                        >
                          <div className="form-birthday-month">
                            <div className="select_box-default">
                              <PartsMonth
                                name="payment.cashcard_month"
                                register={props.register}
                                options={{ required: true }}
                              />
                            </div>
                            <p className="mr-10 ml-10 color-dark_blue">
                              月
                            </p>
                          </div>

                          <div className="form-birthday-year">
                            <div className="select_box-default">
                              <PartsCardExpireYear
                                name="payment.cashcard_year"
                                register={props.register}
                                options={{ required: true }}
                              />
                            </div>
                            <p className="mr-10 ml-10 color-dark_blue">
                              年
                            </p>
                          </div>
                          {(props.errors.payment?.cashcard_month ||
                            props.errors.payment?.cashcard_year) && (
                            <p className="form-error_text">
                              有効期限を選択ください。
                            </p>
                          )}      
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*カード名義人 */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">カード名義人</h3>
                      </div>
                      <div className="form-content-input">
                        <div>
                          <InputText
                            name="payment.cashcard_name"
                            placeholder="例：TARO OKINAWA"
                            register={props.register}
                            options={{
                              required: true,
                              maxLength: 50,
                            }}
                            error={props.errors.payment?.cashcard_name}
                          ></InputText>
                          <ul className="list-note">
                            <li>
                              半角英数字、半角スペースで入力してください。
                            </li>
                          </ul>
                          {props.errors.payment?.cashcard_name &&
                            props.errors.payment?.cashcard_name.type ===
                              "required" && (
                              <p className="form-error_text">
                                カード名義人をご入力ください。
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*支払い回数 */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">支払い回数</h3>
                      </div>
                      <div className="form-content-input">
                        <div className="w-100 d-f ai-c">
                          <div className="select_box-default">
                            <Select
                                name="payment.cashcard_number_of_payments"
                                register={props.register}
                                options={{ required: true }}
                                selectOptions={[
                                  { value: "10", label: "01" },
                                  { value: "02", label: "02" },
                                  { value: "03", label: "03" },
                                  { value: "06", label: "06" },
                                  { value: "12", label: "12" },
                                  { value: "24", label: "24" },
                                ]}
                              />
                          </div>
                          <p className="mr-10 ml-10 color-dark_blue">
                            回
                          </p>
                        </div>
                        {props.errors.payment?.cashcard_number_of_payments && (
                          <p className="form-error_text">
                            支払い回数を選択ください。
                          </p>
                        )} 
                      </div>
                    </div>
                  </div>

                  {/*セキュリティコード */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">セキュリティコード</h3>
                      </div>
                      <div
                        className={
                          "form-content-input" +
                          (props.errors.payment?.cashcard_cvc
                            ? " error"
                            : "")
                        }
                      >
                        <div className="w-100">
                          <InputText
                            name="payment.cashcard_cvc"
                            placeholder="例：123"
                            register={props.register}
                            options={{
                              required: true,
                              maxLength: 4, 
                              pattern: /^(\d{3}|\d{4})$/  // アメリカン・エキスプレスは4桁
                            }}
                            error={props.errors.payment?.cashcard_cvc}
                          ></InputText>
                        </div>
                        {props.errors.payment?.cashcard_cvc &&
                          props.errors.payment?.cashcard_cvc.type === "required" && (
                            <p className="form-error_text">
                              セキュリティコードをご入力ください。
                            </p>
                        )}
                        {props.errors.payment?.cashcard_cvc &&
                          props.errors.payment?.cashcard_cvc.type === "pattern" && (
                            <p className="form-error_text">
                              セキュリティコードを正しくご入力ください。
                            </p>
                        )}
                        {props.errors.payment?.cashcard_cvc &&
                          props.errors.payment?.cashcard_cvc.type === "maxLength" && (
                            <p className="form-error_text">
                              4桁以内でご入力ください。
                            </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionPayment;
