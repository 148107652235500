import { useState } from "react";
import { useApi } from "./useApi";

export const useSendTestMail = () => {
    const [isLoading, setLoading] = useState(false);

    const [error, setError] = useState("");

    const isError = error !== null;

    const sendTestMail = async (targetEmail: string): Promise<boolean> => {
      if (!targetEmail) {
        alert("メールアドレスを正しくご入力ください。");
        return false;
      }

      // メアドの簡易チェック
      const regex = /^\S+@\S+$/i;
      if (!regex.test(targetEmail)) {
        alert("メールアドレスを正しくご入力ください。");
        return false;
      }

      const confirm = window.confirm("このメールアドレスにテストメールを送信します。よろしいですか？");
      if (!confirm) {
        return true;
      }

      setLoading(true);

      try {
        const response = await useApi.post({
          url: "/testMail",
          data: {
            address: targetEmail,
          }
        })

        if (response.status !== 200) {
          throw new Error("送信に失敗しました。");
        }

        alert("テストメールを送信しました。メールが受信できているかご確認ください。");

        setLoading(false);

        return true;

      } catch (error) {
        setError("送信に失敗しました。");
        alert("テストメールの送信に失敗しました。しばらくしてから再度お試しください。");
      }

      setLoading(false);

      return false;
    }

    return [ sendTestMail, { isLoading, isError, error } ] as const;
};