import {dateFormat, toCircled} from "../../../utils/convert";
import React, {useEffect} from "react";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {anshinPackList, carRentalSaveData, carRentalSaveDataDetail} from "../../../types/carRental";
import CounterNumberOfUnitsCarRental from "../../counter/carRentalCounterNumberOfUnits";
import {
  getCarRentalAnshinOptionItem,
  getCarRentalChildOptionItem,
  getCarRentalCountNum
} from "../../../utils/carRental";
import {carRentalAnshinOption} from "../../../config/carRental";

type Props = {
  step: number,
  carRentalSaveData: carRentalSaveData
  updateChildSeatCount: (count: number, plan_code: string, type: string, option_code:string, detailIndex: number) => void
  updateAnshinPack: (anshin_pack: string, detailIndex: number, price: number) => void
  addData: () => void
  prevStep: () => void
  cancel: (detailIndex: number) => void
}

const scrollToModalSection = () => {
  const modalSection = document.querySelector('#modal-section-rental-car');
  if (modalSection) {
    modalSection.scrollTop = 0;
  }
}

const RentalModalStep2: React.FC<Props> = ({ ...props }) => {

  useEffect(() => {
    if (props.step === 2) {
      scrollToModalSection();
    }
  }, [props.step]);
  
  const carRentalChildSeatCount = (carRentalDetail: carRentalSaveDataDetail, optionName: string) => {
    let count = 0;
    switch (optionName) {
      case "ベビーシート":
        count = carRentalDetail.count_option.baby_seat.num;
        break;
      case "チャイルドシート":
        count = carRentalDetail.count_option.child_seat.num;
        break;
      case "ジュニアシート":
        count = carRentalDetail.count_option.junior_seat.num;
        break;
    }
    
    return count;
  }
  
  const sortAnshinPack = (anshin_pack_a: anshinPackList, anshin_pack_b: anshinPackList) => {
    return anshin_pack_a.anshin_name.length > anshin_pack_b.anshin_name.length ? -1 : 1;
  }

  return (
    <>
      {props.step === 2 && (<>
        <div id="modal-section-renal-car-step-2" className="bgc-white pt-48-md pt-32 pb-48-md pb-32 pr-40-md pl-40-md pr-16 pl-16">
          <p className="heading-2 fz-26-md mt-0 mb-0">オプションを選択してください</p>
        </div>

        <div className="wrap-content-900-large wrap-padding-md ">
          {/* レンタカー */}
          <h2 className="heading-2 fz-26-md mt-40 mb-16 mt-48-md mb-20-md">レンタカー</h2>


          <div id="add_rentacar" className="sticky-top-list-item rentalcar-detail-select-content">
            <AccordionDetails className="accordion-schedule-content large ">
              <div className="box-rentalcar-set">
                {props.carRentalSaveData.detail?.map((detailRow, detailIndex) => (
                  <div key={detailIndex} className="box-rentalcar-set-item">
                    <Accordion
                      className="accordion light_gray"
                      defaultExpanded={true}
                    >
                      <AccordionSummary className="accordion-heading">
                        レンタカー{toCircled(detailIndex + 1)}
                      </AccordionSummary>
                      <AccordionDetails className="accordion-content xsmall">
                        <div className="w-100per p-24 p-12-md ">
                          {/* 貸出・返却 */}
                          <div className="mb-24">
                            <table className="table-small">
                              <tbody>
                              <tr>
                                <th className="w-60 ta-c pl-8-md pr-8-md fz-14-md fw-b">
                                  貸出
                                </th>
                                <td className="fz-12 fz-14">
                                      <span className="d-b d-ib-md mr-10-md">
                                        {props.carRentalSaveData.departure_date_label}
                                      </span>
                                  <span>{props.carRentalSaveData.departure_office_label}</span>
                                </td>
                              </tr>
                              <tr>
                                <th className="w-60 ta-c pl-8-md pr-8-md fz-14-md fw-b">
                                  返却
                                </th>
                                <td className="fz-12 fz-14">
                                      <span className="d-b d-ib-md mr-10-md">
                                        {props.carRentalSaveData.return_date_label}
                                      </span>
                                  <span>{props.carRentalSaveData.return_office_label}</span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>

                          {/* 車種 */}
                          <div className="box-rentalcar-set-item-select-detail-car-heading">
                            車種
                          </div>
                          <div className="box-rentalcar-set-item-select mb-40-md mb-24">
                            <div key={detailIndex} className="box-card-rentacar-item">
                              <div className="box-card-rentacar-detail">
                                <div className="box-card-rentacar-detail-left">
                                  <div>
                                    <img src={detailRow.planDetail?.class_image_url} alt={detailRow.planDetail?.car_type_code} className="d-b w-100per"/>
                                  </div>
                                </div>
                                <div className="box-card-rentacar-detail-right">
                                  <p className="fz-14 fz-16-sm fw-b c-navy">
                                    {detailRow.planDetail?.plan_name}<br/>
                                    <span className={"fz-14-md fz-12"}>{detailRow.planDetail?.class_name}／{detailRow.planDetail?.class_cc}</span>
                                  </p>
                                  <dl className="box-card-rentacar-detail-right-table mt-8">
                                    <div className="box-card-rentacar-detail-right-table-item">
                                      <dt className="box-card-rentacar-detail-right-table-item-heading">車種</dt>
                                      <dd
                                        className="box-card-rentacar-detail-right-table-item-text">{detailRow.planDetail?.class_model}</dd>
                                    </div>
                                    {detailRow.planDetail?.max_member &&
                                      <div className="box-card-rentacar-detail-right-table-item">
                                        <dt className="box-card-rentacar-detail-right-table-item-heading">定員</dt>
                                        <dd
                                          className="box-card-rentacar-detail-right-table-item-text">{detailRow.planDetail?.max_member.toString()}人
                                        </dd>
                                      </div>
                                    }
                                  </dl>
                                  <ul className="d-f fxw-w mt-4">
                                    {detailRow.planDetail?.default_option_list.map((tagRow, tIndex) => (
                                      <li key={tIndex}><span
                                        className="label-gray-small mr-4 mt-4">{tagRow.option_name}</span></li>
                                    ))}
                                  </ul>
                                </div>
                              </div>

                              <div className="box-card-rentacar-price-right">
                                <div className="box-rentalcar-set-item-select-price-inner">
                                  <div className="box-rentalcar-set-item-select-price-total">
                                  <span className="mr-5 fz-13 fw-b">
                                    合計
                                  </span>
                                    <span className="c-red fz-20 fw-b">
                                    +{detailRow.total.toLocaleString()}
                                  </span>
                                    <span className="c-red fz-12 fw-b">
                                    円
                                  </span>
                                  </div>
                                  <div className="box-rentalcar-set-item-select-price-link w-120-md">
                                      <button
                                        className="button-border-medium w-100per"
                                        onClick={() => {props.prevStep()}}
                                      >
                                      変更する
                                    </button>
                                  </div>
                                </div>
                                <div className="box-rentalcar-set-item-select-cancel">
                                  <p>
                                    <button
                                      className="button-text-link fz-12"
                                      onClick={() => {props.cancel(detailIndex)}}
                                    >
                                      <i className="icon-bad-circle-black mr-5"></i>
                                      キャンセル
                                    </button>
                                  </p>
                                </div>

                              </div>
                            </div>
                          </div>

                          {(detailRow?.planDetail && detailRow?.planDetail?.anshin_pack_list.length > 0) && (
                            <>
                              <div className="box-rentalcar-set-item-select-detail-car-heading mb-16">
                                【充実した保証】<br className={"d-n-sm d-b"}/>安心パックシリーズの加入
                              </div>

                              <div className="box-radio-select-content">

                                <form action="">
                                  <div className="box_select-hotel ">
                                    <div className="box_select-hotel-schedule">
                                      <ul className="box_select-hotel-schedule-list">
                                        <>
                                          <li key={detailIndex + "-anshin_pack-list"}
                                              className="box_select-hotel-schedule-list-item">
                                            <div className="box_select-hotel-schedule-list-item-date">
                                              <div className="box_select-hotel-schedule-list-item-date-content">
                                                <ul className="box_select-hotel-schedule-list-item-date-content-list">
                                                  <li key={detailIndex + "-anshin_pack-"}
                                                      className="box_select-hotel-schedule-list-item-date-content-list-label">
                                                    <input
                                                      type="radio"
                                                      name={detailIndex + "-anshin_pack"}
                                                      id={detailIndex + "-anshin_pack-default"}
                                                      value={"加入しない"}
                                                      checked={(!detailRow.count_option.anshin.used && !detailRow.count_option.anshin_premium.used)}
                                                      onChange={(event) => {
                                                        props.updateAnshinPack("加入しない", detailIndex, 0)
                                                      }}
                                                    />
                                                    <label
                                                      htmlFor={detailIndex + "-anshin_pack-default"}
                                                      className=""
                                                    >
                                                      加入しない
                                                    </label>
                                                  </li>
                                                  <li key={detailIndex + "-anshin_pack-label"}
                                                      className="box_select-hotel-schedule-list-item-date-content-list-result box-border-left-gray">
                                                    <div className="w-90 w-100per-md d-f jc-c ai-c">
                                                    <span className="d-b-sm fz-20 fz-24-sm fw-b lh-1-md mt-5-md">0
                                                      <span className="fz-12 fz-12-sm fw-b">円</span>
                                                    </span>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </li>
                                          {detailRow.planDetail.anshin_pack_list.sort(sortAnshinPack).map((anshin_pack, anshin_pack_index) => (
                                            <>
                                              <li key={detailIndex + "-anshin_pack-list" + anshin_pack_index}
                                                  className="box_select-hotel-schedule-list-item">
                                                <div className="box_select-hotel-schedule-list-item-date">
                                                  <div className="box_select-hotel-schedule-list-item-date-content">
                                                    <ul
                                                      className="box_select-hotel-schedule-list-item-date-content-list">
                                                      <li key={detailIndex + "-anshin_pack-" + anshin_pack_index}
                                                          className="box_select-hotel-schedule-list-item-date-content-list-label ">
                                                        <input
                                                          type="radio"
                                                          name={detailIndex + "-anshin_pack"}
                                                          id={detailIndex + "-anshin_pack-" + anshin_pack_index}
                                                          value={anshin_pack.anshin_name}
                                                          checked={(detailRow.count_option.anshin.used && detailRow.count_option.anshin.option_name === anshin_pack.anshin_name || detailRow.count_option.anshin_premium.used && detailRow.count_option.anshin_premium.option_name === anshin_pack.anshin_name)}
                                                          onChange={(event) => {
                                                            props.updateAnshinPack(event.target.value, detailIndex, anshin_pack.anshin_price)
                                                          }}
                                                        />
                                                        <label
                                                          htmlFor={detailIndex + "-anshin_pack-" + anshin_pack_index}
                                                          className=""
                                                        >
                                                        <span
                                                          className={"fz-12 box-img-label"}>
                                                        <img
                                                          src={getCarRentalAnshinOptionItem(anshin_pack.anshin_name, "img")}
                                                          className={"box-img-size"}/>
                                                          <span
                                                            className={"fz-14-md fz-10 ta-c ta-l-sm d-ib box-img-label-text"}>
                                                          {getCarRentalAnshinOptionItem(anshin_pack.anshin_name, "heading")}
                                                            <span
                                                              className="d-b fz-14 fz-18-md fw-b">{getCarRentalAnshinOptionItem(anshin_pack.anshin_name, "title")}
                                                            </span>
                                                          </span>
                                                        </span>
                                                        </label>
                                                      </li>
                                                      <li
                                                        key={detailIndex + "-anshin_pack-label-" + anshin_pack_index}
                                                        className="box_select-hotel-schedule-list-item-date-content-list-result box-border-left-gray">
                                                        <div className="w-90 w-100per-md d-f jc-c ai-c">
                                                          <span
                                                            className="d-b-sm fz-20 fz-24-sm fw-b lh-1-md c-red mt-5-md">{anshin_pack.anshin_price.toLocaleString()}
                                                            <span className="fz-12 fz-12-sm fw-b c-red">円/24時間</span>
                                                          </span>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                            </>
                                          ))}
                                        </>
                                      </ul>
                                    </div>

                                    <div className='d-b ta-r fz-14-md fz-12 pt-16'>
                                      <a href={'https://www.otsinternational.jp/otsrentacar/guide/safety-pack-series/'}
                                         target='_blank' rel="noreferrer">プラン詳細・比較を見る
                                        <i className="icon-blank ml-5"></i>
                                      </a>
                                    </div>
                                  </div>

                                </form>
                              </div>
                            </>
                          )}

                          {(detailRow.planDetail && detailRow.planDetail?.option_list.length > 0) && (
                            <div className="box-rentalcar-set-item-select-detail-car-heading">
                              チャイルドシート
                            </div>
                          )}
                          <div className="box-card-rentacar">
                            {detailRow.planDetail?.option_list.map((childSeatRow, childSeatIndex) => (
                              <div key={childSeatIndex} className={(() => {
                                if (childSeatIndex !== 0) {
                                  return ("box-card-rentacar-item mt-8")
                                } else {
                                  return ("box-card-rentacar-item")
                                }
                              })()}>
                                <div className="box-card-rentacar-detail">
                                  <div className="box-card-rentacar-detail-left child_seat">
                                    <div><img src={getCarRentalChildOptionItem(childSeatRow.option_name, 'img')}
                                              alt={getCarRentalChildOptionItem(childSeatRow.option_name, 'heading')}
                                              className="d-b w-100per"/></div>
                                  </div>
                                  <div className="box-card-rentacar-detail-right">
                                    <p className="fz-14 fz-16-sm fw-b c-navy">{childSeatRow.option_name}</p>
                                    <dl className="box-card-rentacar-detail-right-table mt-8">
                                      <div className="box-card-rentacar-detail-right-table-item">
                                        <dt
                                          className="box-card-rentacar-detail-right-table-item-heading child_seat">年齢
                                        </dt>
                                        <dd
                                          className="box-card-rentacar-detail-right-table-item-text">{getCarRentalChildOptionItem(childSeatRow.option_name, 'target_age')}</dd>
                                      </div>
                                      <div className="box-card-rentacar-detail-right-table-item">
                                        <dt
                                          className="box-card-rentacar-detail-right-table-item-heading child_seat">体重
                                        </dt>
                                        <dd
                                          className="box-card-rentacar-detail-right-table-item-text">{getCarRentalChildOptionItem(childSeatRow.option_name, 'weight')}</dd>
                                      </div>
                                      <div className="box-card-rentacar-detail-right-table-item">
                                        <dt
                                          className="box-card-rentacar-detail-right-table-item-heading child_seat">身長
                                        </dt>
                                        <dd
                                          className="box-card-rentacar-detail-right-table-item-text">{getCarRentalChildOptionItem(childSeatRow.option_name, 'height')}</dd>
                                      </div>
                                    </dl>
                                  </div>
                                </div>

                                <div className="box-card-rentacar-price">
                                  <p className="mr-8 mr-0-md ta-r-sm">
                                    <span
                                      className="d-ib d-b-sm fz-12 fz-13-sm fw-b mr-8 mr-0-md c-navy">追加代金</span>
                                    <span
                                      className="d-b-sm fz-20 fz-24-sm fw-b lh-1-md c-red mt-5-md">{childSeatRow.option_vaule_list[1]?.price.toLocaleString() ?? ""}<span
                                      className="fz-12 fz-12-sm fw-b c-red">円</span></span>
                                  </p>

                                  {/* カウンター */}
                                  <div className="pl-32-md pr-0-md mt-24-sm">
                                    <CounterNumberOfUnitsCarRental type="option"
                                                                   defaultCount={carRentalChildSeatCount(detailRow, childSeatRow.option_name)}
                                                                   plan_code={childSeatRow.option_code}
                                                                   updateCounter={(count, plan_code, type) => {
                                                                     props.updateChildSeatCount(count, plan_code, type, childSeatRow.option_name, detailIndex)
                                                                   }}></CounterNumberOfUnitsCarRental>
                                  </div>

                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </div>

          {/* チャイルドシート */}
          <div className="box-light_navy medium mb-16">
            <div className="fz-12 fz-13-md fw-b c-navy mb-8">【注意事項】</div>
            <ul className="list-dot-blue_gray">
              <li>6歳未満の幼児を同乗されるお客さまは、レンタカーをご予約の際に子供用補助シートを必ずご予約ください。<br/>なお、ご予約のない場合、お客さまが子供用補助シートをご用意されていないければ、レンタカーのご利用をお断りいたします。
              </li>
              <li>子供用補助シートは、お客さまに装着していただくこととしており、係員がお手伝いさせていただいた場合でも、安全のご確認はお客さまにお願いしております。</li>
              <li>当社では、子供用補助シートの装着不具合により生じた事故については、責任を負いかねます。</li>
              <li>子供用補助シートが、お客さまの取り扱いまたは管理の不注意により破損・紛失した場合は、その費用のご負担をお願いすることがあります。</li>
            </ul>
          </div>


          {/* キャンセル料のご案内 */}
          <h2 className="heading-2 fz-26-md mt-40 mb-16 mt-48-md mb-24-md">キャンセル料のご案内</h2>
          <div>
            <p className="heading-3 mb-5">予約取消手数料について</p>
            <p
              className="fz-14 lh-1_75">ご予約内容をやむをえない場合取り消し可能です。但し規定により下記の取消手数料を申し受けます。</p>
            <table className="table-responsive-vertical large mt-12 mt-24-md">
              <tbody>
              <tr>
                <th className="w-40per table-bgc-light_navy fz-16-md fw-b ta-c c-blue_gray">乗車日の7日前</th>
                <td className="bgc-white fz-16-md fw-b ta-c c-blue_gray">無料</td>
              </tr>
              <tr>
                <th className="w-40per table-bgc-light_navy fz-16-md fw-b ta-c c-blue_gray">6日前〜3日前</th>
                <td className="bgc-white fz-16-md fw-b ta-c c-blue_gray">20%</td>
              </tr>
              <tr>
                <th className="w-40per table-bgc-light_navy fz-16-md fw-b ta-c c-blue_gray">2日前〜前日</th>
                <td className="bgc-white fz-16-md fw-b ta-c c-blue_gray">30%</td>
              </tr>
              <tr>
                <th className="w-40per table-bgc-light_navy fz-16-md fw-b ta-c c-blue_gray">当日以降</th>
                <td className="bgc-white fz-16-md fw-b ta-c c-blue_gray">50%</td>
              </tr>
              </tbody>
            </table>
            <p
              className="fz-14 lh-1_75 mt-16 mt-12-md">予約乗車時間を1時間以上過ぎてもご連絡のない場合は、予約の取り消しとして処理させて頂きますのでご了承ください。</p>
          </div>
          <div className="mt-16 mt-32-md">
            <p className="heading-3 mb-8">契約時間の遅延について</p>
            <p className="fz-14 lh-1_75">契約時間内に変換できない事態が発生したときは、営業所に連絡し、許可を得てください。<br/>無断で契約時間を延長した場合は、貸渡約款違反隣、延滞違約金をお支払い頂きますので、特にご注意ください。
            </p>
          </div>
        </div>

        <div className="modal-fixed-bottom">
          <button onClick={props.addData} className="button-medium-orange padding-large w-100per">
            上記内容で追加する
          </button>
        </div>
      </>)}
    </>
  );
}

export default RentalModalStep2;