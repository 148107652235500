import React from "react";

// for styles
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {getBackSearchURL, getSearchURL} from "../../utils/search";


// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  planSelectFormData: PlanSelectFormData|undefined,
  callback: () => void,
}

const DialogChange: React.FC<Props> = ({
  isShow,
  planSelectFormData,
  callback
}) => {
  
  const back = () => {
    if (planSelectFormData !== undefined) {
      window.location.href = getBackSearchURL(planSelectFormData.searchParams, planSelectFormData.id);
    }
    callback();
  }

  return (
    <>
    {/* ダイアログ:検索変更 */}
    <div>
        <Dialog
          open={isShow}
          onClose={callback}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialog"
        >
          <DialogTitle id="alert-dialog-title" className="dialog-title">
          </DialogTitle>
          <DialogContent className="dialog-content">
            <DialogContentText id="alert-dialog-description" className="dialog-content-text">
              選択中の条件を変更するには、トップページへ戻る必要がございます。よろしければトップページへ戻り選択条件をご変更ください。
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action">
            <Button className="button-dialog-border-small-blue w-110" onClick={callback}>キャンセル</Button>
            <Button className="button-dialog-small-blue w-110" onClick={back} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default DialogChange;
