import React from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

import { Select } from "../input/Select";

type Props = {
  name: string;
  register: UseFormRegister<any>; // todo: どこかで定義したい
  options: RegisterOptions;
}

const PartsMonth: React.FC<Props> = (props) => {
  
  return (
    <Select
      name={props.name}
      register={props.register}
      options={props.options}
      selectOptions={[
        { value: "01", label: "01" },
        { value: "02", label: "02" },
        { value: "03", label: "03" },
        { value: "04", label: "04" },
        { value: "05", label: "05" },
        { value: "06", label: "06" },
        { value: "07", label: "07" },
        { value: "08", label: "08" },
        { value: "09", label: "09" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
      ]}
    />
  );
};

export default PartsMonth;