import React, {Fragment, useEffect, useMemo} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails, Backdrop, CircularProgress,
} from "@material-ui/core";

//ファイル分割化
import {
  FlightModalApi,
  FlightModal,
  ConnectingFlightModal,
  HotelCheckInTimeModal,
  StaySelectModal,
  ScheduleDetailModal,
  NumberOfPeopleModal,
  LinkListModal,
  ComprehensiveOptionDetailsModal
} from "./../../modal"

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";

//ダイアログインポート
import {
  DialogCancel,
  DialogChange,
} from "./../../dialog"

// types
import {PlanSearch} from "../../../types/Search";

import {PlanSelectFormData, PlanSelectFromFlightType, PlanSelectFromHotelType} from "../../../types/planSelectForm";
import {planSelectScheduleList, scheduleListModalData} from "../../../types/planSelectConfirm";
import {IActivity} from "../../../types/Activity";
import {FReservationFlightEntity,FlightType} from "../../../types/Flight";

// lib
import {
  getFlightCodeLabel,
  getFlightCodeByLabel,
  getFlightTotalPrice,
  getFlightPeopleConfirmLabel, getFlightTime, getFlightNumberForDisplay, getAirLineLogo, getAirLineNameInJalGroup, getAirLineNameInAnaGroup, isConnectionFlight
} from "../../../utils/flight";
import {dateFormat, dayAgo, inDateTimeRange, rangeDate} from "../../../utils/convert";
import {getPlanSelectSchedule} from "../../../utils/schedule";
import {
  getHotelAreaCode,
  getHotelTypeLabel,
  hotelCancelDisabled,
  hotelChangeDisabled,
  hotelRoomSearch
} from "../../../utils/hotel";
import DialogHotelCancel from "../../dialog/DialogHotelCancel";
import {getBasicTotalPrice} from "../../../utils/price";
import {makeStyles} from "@material-ui/core/styles";
import {devLog} from "../../../utils/errors";
import {isOkinawaSaihakkenLabel} from "../../../utils/discount";
import {FReservationConnectionFlight, FReservationFlight} from "../../../types/FlightV2";
import {getFlightBookingClass, isConnection, lastCurrentFlight, getFlightClassLabel} from "../../../utils/flightV2";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

type Props = {
  planSelectFormData: PlanSelectFormData,
  scheduleListData: scheduleListModalData,
  updatePlanSelectFormData(updatePlanData: PlanSelectFormData): void
  updateScheduleListData(scheduleListData: scheduleListModalData): void
}

/**
 * 旅程の確認・変更・追加 旅行日程セクション
 * @param planSelectFormData
 * @param updatePlanSelectFormData
 * @constructor
 */
const SectionSchedule: React.FC<Props> = ({
    planSelectFormData,
    updatePlanSelectFormData,
    scheduleListData,
    updateScheduleListData,
  }) => {
  const classesForLoading = useStyles();
  const [isSectionScheduleLoading, setIsSectionScheduleLoading] = React.useState(false);
  //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  //モーダル初期表示ページを設定
  const [step, setStep] = React.useState(1);
  const stepHandle = (step: number) => {
    setStep(step)
  }

  // モーダルのステータスを追加する
  const [staySelectModal1, setStaySelectModal1] = React.useState(false);
  const [staySelectModal2, setStaySelectModal2] = React.useState(false);
  const [scheduleDetailModal, setScheduleDetailModal] = React.useState(false);
  const [numberOfPeopleModal, setNumberOfPeopleModal] = React.useState(false);
  const [linkListModal, setLinkListModal] = React.useState(false);
  const [comprehensiveOptionDetailsModal2, setComprehensiveOptionDetailsModal2] = React.useState(false);
  const hotelAreaCode = getHotelAreaCode(planSelectFormData);
  const hotelPlanAbortController = new AbortController();

  // モーダルのhandleを追加する

  //モーダル：宿泊施設を変更
  const handleHotelModal = (step: number) => {
    setStep(step)
    setStaySelectModal1(!staySelectModal1)
    setHotelCode("");
  }

  //プチモーダル：日程詳細
  const handleScheduleDetailModal = () => {
    setScheduleDetailModal(!scheduleDetailModal)
  }
  const onClickScheduleDetailModal = (activity: IActivity) => {
    if (activity !== undefined) {
      setSelectActivity({...activity});
      setScheduleDetailModal(!scheduleDetailModal)
    }
  }

  //プチモーダル：人数詳細
  const handleNumberOfPeopleModal = () => {
    setNumberOfPeopleModal(!numberOfPeopleModal)
  }

  //プチモーダル：ページ内遷移用
  const handleLinkListModal = () => {
    setLinkListModal(!linkListModal)
  }

  //モーダル：包括オプション設定2
  const handleComprehensiveOptionDetailsModal2 = () => {
    setStep(2)
    setComprehensiveOptionDetailsModal2(!comprehensiveOptionDetailsModal2)
  }

  const onClickComprehensiveOptionDetailsModal2 = (activityData: IActivity) => {
    if (activityData !== undefined) {
      setSelectActivity({...activityData})
      setStep(2)
      setComprehensiveOptionDetailsModal2(!comprehensiveOptionDetailsModal2)
    }
  }

  /**
   *  基本プラン更新
   * @param updateActivity
   */
  const handleCallbackOptionDetailsModal = (updateActivity: IActivity | undefined) => {
    if (updateActivity !== undefined) {
      const activityIndex = planSelectFormData.activity.findIndex((activityData) => {
        return activityData.plan_info.plan_code === updateActivity.plan_info.plan_code;
      })

      if (activityIndex >= 0) {
        planSelectFormData.activity[activityIndex] = updateActivity;
        updateData({...planSelectFormData});
      }
    }

    setStep(2)
    setComprehensiveOptionDetailsModal2(!comprehensiveOptionDetailsModal2)
  }
  //モーダル：日程確認
  const [ConnectingFlightModalState, setConnectingFlightModalState] = React.useState(false);
  const handleConnectingFlightModal = (airline:string, travelType:string, flight:FReservationFlight[] | undefined) => {
    if(flight) {
      setSelectedEntities(flight)
    }
    setConnectingFlightModalState(!ConnectingFlightModalState);
  };

  const handleConnectingDetailModalCallback = () => {
    setConnectingFlightModalState(!ConnectingFlightModalState);
  }

  // flight
  const [selectedEntities, setSelectedEntities] = React.useState<FReservationFlight[]>();
  const [flightModalState, setFlightModalState] = React.useState(false);
  const handleFlightModal = (airline:string, travelType:string, flight:FReservationFlight[] | undefined) => {
    // setUpdateIndex(index);
    devLog("OPEN")
    devLog("airline", airline)
    devLog("flight", flight)
    devLog("travelType", travelType)
    if(flight) {
      setFlightType({
        ...flightType,
        airline: airline,
        page: "confirm",
        currentNumbers: flight.map((f) => f.air_sub_no),
        action: travelType,
        flight: flight
      })
      setSelectedEntities(flight)
      setFlightModalState(!flightModalState);
    }
  };

  const flightModalCallback = () => {
    setFlightModalState(!flightModalState);      
  }

  //モーダル：チェックイン時間を変更する
  const [hotelCheckInTimeModalState, setHotelCheckInTimeModalState] = React.useState(false);
  const handleHotelCheckInTimeModal = (hotel: PlanSelectFromHotelType) => {
    setHotelCheckInInfoForModal({
      currentCheckInTime: hotel.checkTime ?? "15:00",
      checkInDate: hotel.checkIn,
      checkInStartTime: hotel.plan?.room_plan_detail.check_in_start ?? "12:00",
      checkInEndTime: hotel.plan?.room_plan_detail.check_in_end ?? "24:00",
    });

    setHotelCheckInTimeModalState(!hotelCheckInTimeModalState);
  };

  // チェックイン時間変更のモーダルでokがclickされた
  const handleChangeCheckInTimeInModal = (time: string, checkIn: string, checkInStart: string, checkInEnd: string) => {
    // validation
    const isValidTime = inDateTimeRange(time, checkIn, checkInStart, checkInEnd);

    if (!isValidTime) {
      alert(`チェックイン時間は${checkInStart}～${checkInEnd}の範囲内で入力してください。`);
      return;
    }

    changeCheckInTime(time, checkIn)

    setHotelCheckInTimeModalState(!hotelCheckInTimeModalState);
  };

  // チェックイン時間変更のモーダルのxもしくは枠外がclickされた
  const handleCloseInModal = () => {
    setHotelCheckInTimeModalState(!hotelCheckInTimeModalState);
  };

  //plan
  const [selectActivity, setSelectActivity] = React.useState<IActivity>();

  const [rangeDates, setRageDates] = React.useState<string[]>([]);
  const [planData, setPlanData] = React.useState<PlanSelectFormData>(planSelectFormData);
  const [planSchedule, setPlanSchedule] = React.useState<planSelectScheduleList[]>([]);

  const [airline, setAirline] = React.useState("ANA")

  // flight type
  const [flightType, setFlightType] = React.useState<FlightType>();
  
  useEffect(() => {
    const searchParams = planSelectFormData.searchParams;
    const dates = rangeDate(searchParams.start, searchParams.end);
    setRageDates([...dates]);
    const planSelectSchedule = getPlanSelectSchedule(planSelectFormData, dates);
    setPlanSchedule([...planSelectSchedule]);
    if (dates.length > 0 && scheduleListData.travelSchedule.length <= 0) {
      dates.forEach((date, index) => {
        const num = index + 1;
        scheduleListData.travelSchedule.push({
          label: num + "日目 " + dateFormat(date, "YYYY年MM月DD日（ddd）"),
          anchor: "#schedule" + num,
        });
      })
    }
    if(planData.flight && planData.flight.length > 0) {
      setAirline(planData.flight[0].airline)
    }
    
    updateScheduleListData(scheduleListData);

  },[planData])

  //-------------ここまでモーダルの処理------------------

  //ダイアログの処理
  const [dialogCancel, setDialogCancel] = React.useState(false);
  const [dialogChange, setDialogChange] = React.useState(false);

  //モーダル：キャンセル
  const handleDialogCancel = () => {
    setDialogCancel(!dialogCancel)
  }

  //モーダル：変更
  const handleDialogChange = () => {
    setDialogChange(!dialogChange)
  }

  /**
   * ホテル日程セクション
   */
  const [dialogHotelCancel, setDialogHotelCancel] = React.useState(false);
  const [hotelCancelDate, setHotelCancelDate] = React.useState("");
  const [hotelCancelCheckIn, setHotelCancelCheckIn] = React.useState("");
  const [hotelCancelHotelName, setHotelCancelHotelName] = React.useState("");
  const [isUpdateHotel, setIsUpdateHotel] = React.useState(true);
  const [updateHotelDate, setUpdateHotelDate] = React.useState("");
  const [hotelCode, setHotelCode] = React.useState<string>("");
  const [hotelIndex, setHotelIndex] = React.useState<number|undefined>();

  const [hotelCheckInInfoForModal, setHotelCheckInInfoForModal] = React.useState<{
    currentCheckInTime: string,
    checkInDate: string;
    checkInStartTime: string;
    checkInEndTime: string;
  }>({
    currentCheckInTime: "",
    checkInDate: "",
    checkInStartTime: "",
    checkInEndTime: "",
  });

  const getHotelIndex = (checkInDate: string, code: string) => {
    return planSelectFormData.hotel.findIndex((hotel) => {
      return hotel.checkIn === checkInDate && hotel.detail.hotel_code === code;
    })
  }

  const addHotelModal = (date: string) => {
    setUpdateHotelDate(date)
    setIsUpdateHotel(false)
    setHotelIndex(undefined);
    setStep(1)
    setStaySelectModal1(!staySelectModal1)
  }

  /**
   * 宿泊施設を変更するボタンクリック時
   * @param checkInDate 
   * @param code 
   */
  const changeSelectHotel = (checkInDate: string, code: string) => {
    const index = getHotelIndex(checkInDate, code);
    setHotelIndex(index);
    setUpdateHotelDate(checkInDate)
    setIsUpdateHotel(true)
    setStep(1)
    setStaySelectModal1(!staySelectModal1)
  }

  /**
   * 宿泊プランを変更するボタンクリック時
   * @param checkInDate 
   * @param code 
   */
  const handleUpdateStaySelectModal = (checkInDate: string, code: string) => {
    const index = getHotelIndex(checkInDate, code);
    setHotelIndex(index);
    setHotelCode(code);
    setUpdateHotelDate(checkInDate)
    setIsUpdateHotel(true)
    setStep(2)
    setStaySelectModal1(!staySelectModal1)
  }

  const clickCancelHotelDate = (date:string, checkIn:string, hotelName: string) => {
    setHotelCancelCheckIn(checkIn);
    setHotelCancelHotelName(hotelName);
    setDialogHotelCancel(!dialogHotelCancel)
    setHotelCancelDate(date);
  };

  /**
   * 指定した日付のホテルの日程を変更（チェックアウト日の変更）
   * @param date
   * @param checkIn
   */
  const cancelHotelDate = (date:string, checkIn:string) => {
    const updatePlanSelectHotel = planSelectFormData.hotel;
    const cancelHotelIndex = updatePlanSelectHotel.findIndex((hotel) => {
      return checkIn === hotel.checkIn;
    });
    const cancelHotel = updatePlanSelectHotel[cancelHotelIndex];
    if (cancelHotel.checkIn === date) {
      // 指定したホテル日程を削除
      updatePlanSelectHotel.splice(cancelHotelIndex, 1);
      planSelectFormData.hotel = updatePlanSelectHotel;
      setPlanData({...planSelectFormData});
      setDialogHotelCancel(!dialogHotelCancel)
      updatePlanSelectFormData({...planSelectFormData});
    } else {
      // 指定したホテルのチェックアウト日を変更
      updatePlanSelectHotel[cancelHotelIndex].checkOut = date;

      ( async () => {
        // 日程の変更により金額を再設定
        setIsSectionScheduleLoading(true);
        const roomPlanCode = updatePlanSelectHotel[cancelHotelIndex].roomData?.room_code ?? "";
        const planCode = updatePlanSelectHotel[cancelHotelIndex].planCode ?? "";
        const searchParams = {...planSelectFormData.searchParams};
        searchParams.checkin = checkIn;
        searchParams.checkout = date;
        const resultRoom = await hotelRoomSearch(hotelPlanAbortController, hotelAreaCode, searchParams, updatePlanSelectHotel[cancelHotelIndex].detail.hotel_code, roomPlanCode, planCode);

        if (resultRoom && resultRoom.success) {
          if (updatePlanSelectHotel[cancelHotelIndex].plan) {
            const _plan = updatePlanSelectHotel[cancelHotelIndex].plan;
            if (_plan !== undefined) {
              updatePlanSelectHotel[cancelHotelIndex].plan = resultRoom.response
            }
          }
          updatePlanSelectHotel[cancelHotelIndex].total = resultRoom.response.room_plan_detail.total_price;
          planSelectFormData.hotel = updatePlanSelectHotel;
          setPlanData({...planSelectFormData});
          setDialogHotelCancel(!dialogHotelCancel)
          updatePlanSelectFormData({...planSelectFormData});
        } else {
          if (resultRoom?.ErrorInfo && ["E1009","E1010","E1011"].includes(resultRoom?.ErrorInfo.error_code)) {
            alert("本宿泊プランの連泊条件によりこの日程でのキャンセルはできません。\n宿泊施設の変更・キャンセルをご希望の場合は、宿泊プランの変更を行うか\nトップページへ戻り選択条件をご変更ください。");
          } else {
            alert("プランの取得に失敗しました。");
          }
        }
        setIsSectionScheduleLoading(false);
      })();
    }
    
  };

  const callbackDialogHotelCancel = () => {
    setDialogHotelCancel(!dialogHotelCancel)
  }

  const updateData = (updatePlanData: PlanSelectFormData) => {
    if (updatePlanData !== undefined) {
      setPlanData({...updatePlanData});
      updatePlanSelectFormData({...updatePlanData});
    }
  }


  /**
   * 指定した日付のホテルのチェックイン時間を変更
   * 
   * @param changedCheckInTime 
   * @param changedCheckIn 
   */
  const changeCheckInTime = (changedCheckInTime: string, changedCheckIn: string) => {
    const updatePlanSelectHotel = planSelectFormData.hotel;
    const changedHotelIndex = updatePlanSelectHotel.findIndex((hotel) => {
      return changedCheckIn === hotel.checkIn;
    });

    updatePlanSelectHotel[changedHotelIndex].checkTime = changedCheckInTime;

    planSelectFormData.hotel = updatePlanSelectHotel;
    updatePlanSelectFormData({...planSelectFormData});
    setPlanData({...planSelectFormData});
  };


  const [inboundFlight, setInboundFlight] = React.useState<PlanSelectFromFlightType>();
  const [outboundFlight, setOutboundFlight] = React.useState<PlanSelectFromFlightType>();
  const [currentFlights, setFlights] = React.useState<PlanSelectFromFlightType[]>([]);
  const [flightUpdateIndex, setUpdateIndex] = React.useState(0);

  // from -> to 往路
  // const handleInboundButtonClick = (airline: string) => {
  //  
  //   const iFlight = planData.flight.find(elm => {
  //     return elm.airline == airline && elm.action == "inbound"
  //   })
  //
  //   if(airline == 'ANA' && iFlight) {
  //     setFlightType({
  //       airline: 'ANA',
  //       currentNumbers: iFlight?.entity[0].air_sub_no,
  //       action: 'inbound'
  //     })
  //   }
  //
  //   if(airline == 'JAL' && iFlight) {
  //     setFlightType({
  //       airline: 'JAL',
  //       currentNumbers: iFlight?.entity[0].air_sub_no,
  //       action: 'inbound'
  //     })
  //   }
  //   setFlightModalState(!flightModalState);
  // }

  // to -> from 復路
  // const handleOutboundButtonClick = (airline: string) => {
  //
  //   const iFlight = planData.flight.find(elm => {
  //     return elm.airline == airline && elm.action == "outbound"
  //   })
  //
  //   if(airline == 'ANA' && iFlight) {
  //     setFlightType({
  //       airline: 'ANA',
  //       currentNumbers: iFlight?.entity[0].air_sub_no,
  //       action: 'outbound'
  //     })
  //   }
  //
  //   if(airline == 'JAL' && iFlight) {
  //     setFlightType({
  //       airline: 'JAL',
  //       currentNumbers: iFlight?.entity[0].air_sub_no,
  //       action: 'outbound'
  //     })
  //   }
  //   setFlightModalState(!flightModalState);
  // }

  // 利用空港の変更callback（いつか確認画面でも空港が変更できるようになるかも？）
  const changeAirportCallback = (airport: string) => {
    const code = getFlightCodeByLabel(airport);
    if(code) {
      // // TODO:いつかフュージョンする
      // setSearchParams({...searchParams,
      //   from: code, // need set code
      // });

      // // TODO:いつかフュージョンする
      // setPlanSelectFormData({
      //   ...planSelectFormData,
      //   searchParams: searchParams
      // })
    }
  }

  // 乗継便の到着時間を取得
  const getArrivalFlight = (flights:FReservationFlight[]): FReservationConnectionFlight | undefined  => {
    if(flights && flights.length > 0) {
      const lastFlight = lastCurrentFlight(flights[0].FReservationConnectionFlight);
      if (lastFlight.length > 0) {
        return lastFlight[0];
      }
    }
    return undefined;
  }

  // フライトの更新
  const flightUpdateDate = (airline:string, action:string, flights:FReservationFlightEntity[]) => {

    if(airline == undefined || action == undefined || flights == undefined) {
      devLog('undefined flight udpate data')
      return;
    }
    const planSelectFlights = planSelectFormData.flight;
    const updateFlights = planSelectFlights.filter((pf) => pf.action != action)
    const newPlanSelectFlights = {
      airline: airline,
      entity: flights,
      action: action,
      connection: flights.length > 1,
      bookingClass: getFlightBookingClass(flights[0].class_code),
      useDate: flights ? flights[0].date : new Date(),
      selected: true,
      totalPrice: getFlightTotalPrice(flights, planSelectFormData.searchParams.roomDetail)
    } as PlanSelectFromFlightType
    updateFlights.push(newPlanSelectFlights)
    setFlightModalState(!flightModalState);
    let flightsPrice = 0;
    updateFlights.forEach((data) => {
      flightsPrice += data.totalPrice;
    })

    devLog("フライト金額")
    devLog(flightsPrice)

    setPlanData({
      ...planData,
      flight: updateFlights,
      flightTotal:flightsPrice,
    })
    updatePlanSelectFormData({
      ...planData,
      flight: updateFlights,
      flightTotal: flightsPrice,
    })
  }

  return (
    <>
      <section>
        <Backdrop className={classesForLoading.backdrop} open={isSectionScheduleLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="wrap-content-900">
          <h2 className="heading-2 mb-16 mb-14-md">旅行日程</h2>
          <div className="sticky-top-list">
            {planSchedule.length > 0?
              <>
                {planSchedule.map((schedule, sIndex) => (
                  <Fragment key={sIndex}>
                    <div id={"schedule"+(sIndex + 1)} className="sticky-top-list-item">
                      <Accordion className="accordion-schedule" expanded={true}>
                        <AccordionSummary
                          className="accordion-schedule-heading"
                          onClick={handleLinkListModal}
                        >
                          <span className="label-navy mr-10">{sIndex+1}日目</span>
                          {dateFormat(schedule.date, "YYYY年MM月DD日（ddd）")}
                        </AccordionSummary>
                        <AccordionDetails className="accordion-schedule-content">
                          <div className="box-separator_bottom-border_small w-100per">
                            {schedule.flight !== undefined  && (
                              <>
                                {schedule.flight.map((flight, fIndex) => (
                                  <Fragment key={fIndex}>
                                    {/* フライト */}
                                    <div className="box-separator_bottom-border_small-item">
                                      <p className="mb-16 fz-18 fz-20-md fw-b">
                                        <i className="icon-flight-circle mr-8 fz-26"></i>
                                        フライト
                                      </p>
                                      <div className="box-flight">
                                        <div className="box-flight-seat">
                                          <div className="box-flight-seat-label">

                                            { isConnection(flight.entity[0]) ?
                                              <span className="label-dark_orange mr-10 mb-16-md">
                                                  乗継便
                                                </span> : ""
                                            }
                                          </div>
                                          <div className="box-flight-seat-detail">
                                            <p>{getFlightClassLabel(flight.entity[0].class_code)} {getFlightPeopleConfirmLabel(planSelectFormData.searchParams.roomDetail)}</p>
                                            <ul className="list-note light-gray">
                                              <li key={"section-schedule-seat-detail-note"} className="fz-10 fz-12-md">12歳以上は大人席となります。</li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="box-flight-time">
                                          <div className="box-flight-time-airline">
                                            <div className="mr-10">
                                              <p className="box-flight-time-airline-logo">
                                                <img
                                                    src={ getAirLineLogo(flight.airline, flight.entity[0].air_code, flight.entity[0].air_sub_no) }
                                                    alt={`フライト：${flight.airline === "JAL" ?
                                                                      getAirLineNameInJalGroup(flight.entity[0].air_code, flight.entity[0].air_sub_no) 
                                                                      : getAirLineNameInAnaGroup(flight.entity[0].air_code)}`}
                                                />
                                              </p>
                                              <p className="box-flight-time-airline-label">
                                                { getFlightNumberForDisplay(flight.airline, flight.entity[0].air_code, flight.entity[0].air_sub_no) }
                                              </p>
                                            </div>

                                            <div>
                                              <ul className="box-flight-time-airline-time">
                                                <li key={"flight-time-airline-time-item-1"} className="box-flight-time-airline-time-item">
                                                  <p className="box-flight-time-airline-time-item-heading">
                                                    {getFlightTime(flight.entity[0].dep)}
                                                  </p>
                                                  <p className="box-flight-time-airline-time-item-label">
                                                    {getFlightCodeLabel(flight.entity[0].depapo)}
                                                  </p>
                                                </li>
                                                <li  key={"flight-time-airline-time-item-2"} className="box-flight-time-airline-time-item">
                                                  <p className="box-flight-time-airline-time-item-heading">
                                                    {/* { flight.entity[0].connection ? getFlightTime(getArrivalFlight(flight.action)?.arv) : getFlightTime(flight.entity[0].arv) } */}
                                                    { isConnection(flight.entity[0]) ? getFlightTime(getArrivalFlight(flight.entity)?.arv) : getFlightTime(flight.entity[0].arv) }
                                                  </p>
                                                  <p className="box-flight-time-airline-time-item-label">
                                                    {/* { flight.entity[0].connection ? getFlightCodeLabel(getArrivalFlight(flight.action)?.arrapo) : getFlightCodeLabel(flight.entity[0].arrapo) } */}
                                                    { isConnection(flight.entity[0]) ? getFlightCodeLabel(getArrivalFlight(flight.entity)?.arrapo) : getFlightCodeLabel(flight.entity[0].arrapo) }
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="box-flight-detail">
                                          { isConnection(flight.entity[0]) ?
                                            <p className="box-flight-detail-change">
                                              <button
                                                onClick={() => handleConnectingFlightModal(flight.airline, flight.action, flight.entity)}
                                                className="button-clear-blue fz-13 fw-b"
                                              >
                                                乗継便詳細を見る
                                              </button>
                                            </p> : ""
                                          }
                                          <p className="box-flight-detail-button">
                                            <button
                                              className="button-border-medium w-100per"
                                              onClick={() => handleFlightModal(flight.airline, flight.action, flight.entity)}
                                            >
                                              変更する
                                            </button>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                ))}
                              </>
                            )}
                            {schedule.activity !== undefined && schedule.activity.length > 0 ?
                              <>
                                {/* 基本プラン */}
                                <div className="box-separator_bottom-border_small-item">
                                  <p className="mb-16 fz-18 fz-20-md fw-b">
                                    <i className="icon-flag-circle mr-5 fz-26"></i>
                                    基本プラン
                                  </p>
                                  <div className="box-plan-content">
                                    {schedule.activity.map((activity, aIndex) => (
                                      <div key={aIndex} className="box-plan-content-item">
                                        <div className="box-plan-content-item-description">
                                          <div className="box-plan-content-item-description-thumbnail">
                                            <img
                                              src={activity.plan_info.image_list[0].image_url}
                                              alt=""
                                            />
                                          </div>
                                          <div className="box-plan-content-item-description-text">
                                            <p className="mb-5">
                                              <span className="label-orange">
                                                <i className="icon-flag-orange mr-5"></i>
                                                プラン{aIndex + 1}
                                              </span>
                                            </p>
                                            <p>
                                              {activity.plan_info.plan_name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="box-plan-content-item-status">
                                          <div className="box-plan-content-item-status-inner w-100per">
                                            <p className="mb-10-md ta-c">
                                              <button
                                                onClick={() => onClickScheduleDetailModal(activity)}
                                                className="button-clear-blue fz-13 fw-b"
                                              >
                                                日程詳細を見る
                                              </button>
                                            </p>
                                            <p>
                                              <button
                                                className="button-border-medium w-100per"
                                                onClick={() => onClickComprehensiveOptionDetailsModal2(activity)}
                                              >
                                                変更する
                                              </button>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>:<></>
                            }

                            {schedule.hotel !== undefined && schedule.hotel.length > 0 ?
                              <>
                                {/* 宿泊 */}
                                <div className="box-separator_bottom-border_small-item">
                                  <p className="mb-20 mb-14-md fz-18 fz-20-md fw-b">
                                    <i className="icon-sleep-circle mr-5 fz-32"></i>宿泊
                                  </p>
                                  {schedule.hotel.map((hotel, hIndex) => (
                                    <div key={hIndex} className="box-stay-item">
                                      <div className="box-stay-item-thumbnail small">
                                        <img
                                          src={hotel.detail.image_l_url}
                                          alt=""
                                        />
                                        {isOkinawaSaihakkenLabel(planSelectFormData, hotel.detail.hotel_code) && (
                                          <span className={"label-travel-support"}>全国割対象</span>
                                        )}
                                      </div>
                                      <div className="box-stay-item-content">
                                        <div className="box-stay-item-content-area">
                                          {hotel.detail.sub_area_name !== undefined ? (
                                            <ul className="box-stay-item-content-area-list">
                                              <li key={"tay-item-content-area-list-" + hIndex} className="box-stay-item-content-area-list-item">{hotel.detail.sub_area_name}</li>
                                            </ul>
                                          ) : (<></>)}
                                        </div>
                                        <div className="box-stay-item-content-heading">
                                          {hotel.detail.hotel_name}
                                        </div>
                                        <div className="box-stay-item-content-label">
                                          <ul className="box-stay-item-content-label-list">
                                            {hotel.detail.hotel_type_list?.map((list, index) => (
                                              <li key={"hotel-type-list-" + index} className="box-stay-item-content-label-list-item">{getHotelTypeLabel(list.hotel_type_code)}</li>
                                            ))}
                                          </ul>
                                        </div>
                                        {/*pc用*/}
                                        <div className="box-stay-item-content-option">
                                          {schedule.date === hotel.checkIn?
                                            <>
                                              <dl className="box-stay-item-content-option-dl">
                                                <dt className="box-stay-item-content-option-dl-dt">
                                                  チェックイン
                                                </dt>
                                                <dd className="box-stay-item-content-option-dl-dd">
                                                  {hotel.checkTime}
                                                  <button
                                                    onClick={() => handleHotelCheckInTimeModal(hotel)}
                                                    className="button-clear-blue fz-10 fz-12-md"
                                                  >
                                                    チェックイン時間を変更する
                                                  </button>
                                                </dd>
                                              </dl>
                                            </>:<></>
                                          }
                                          <dl className="box-stay-item-content-option-dl">
                                            <dt className="box-stay-item-content-option-dl-dt">
                                              客室
                                            </dt>
                                            <dd className="box-stay-item-content-option-dl-dd">
                                              {hotel.roomData?.room_name}
                                            </dd>
                                          </dl>
                                          <dl className="box-stay-item-content-option-dl">
                                            <dt className="box-stay-item-content-option-dl-dt">
                                              宿泊プラン
                                            </dt>
                                            <dd className="box-stay-item-content-option-dl-dd">
                                              {hotel.plan?.room_plan_detail.plan_name}
                                            </dd>
                                          </dl>
                                        </div>
                                      </div>
                                      <div className="box-stay-item-optipn">
                                        {/*sp用*/}
                                        <div className="box-stay-item-content-option">
                                          {schedule.date === hotel.checkIn?
                                            <>
                                              <dl className="box-stay-item-content-option-dl">
                                                <dt className="box-stay-item-content-option-dl-dt">
                                                  チェックイン
                                                </dt>
                                                <dd className="box-stay-item-content-option-dl-dd">
                                                  {hotel.checkTime}
                                                  <button
                                                    onClick={() => handleHotelCheckInTimeModal(hotel)}
                                                    className="button-clear-blue fz-10 fz-12-md"
                                                  >
                                                    チェックイン時間を変更する
                                                  </button>
                                                </dd>
                                              </dl>
                                            </>:<></>
                                          }
                                          <dl className="box-stay-item-content-option-dl">
                                            <dt className="box-stay-item-content-option-dl-dt">
                                              客室
                                            </dt>
                                            <dd className="box-stay-item-content-option-dl-dd">
                                              {hotel.roomData?.room_name}
                                            </dd>
                                          </dl>
                                          <dl className="box-stay-item-content-option-dl">
                                            <dt className="box-stay-item-content-option-dl-dt">
                                              宿泊プラン
                                            </dt>
                                            <dd className="box-stay-item-content-option-dl-dd">
                                              {hotel.plan?.room_plan_detail.plan_name}
                                            </dd>
                                          </dl>
                                        </div>
                                      </div>
                                      <div className="box-stay-item-detail large padding-large-bottom-medium">
                                        <div className="w-100per">
                                          <p className="mb-12-md mb-16 ta-c">
                                            <button
                                              onClick={handleNumberOfPeopleModal}
                                              className="button-clear-blue fz-13 fw-b"
                                            >
                                              人数内訳を見る
                                            </button>
                                          </p>
                                          {hotelChangeDisabled(schedule.date, hotel.checkIn) ?
                                            <>
                                              {planSelectFormData.isHotels && (
                                                <>
                                                  <p className="mb-16 mb-8-md">
                                                    <button className="button-border-medium disable w-100per">
                                                      宿泊施設を変更する
                                                    </button>
                                                  </p>
                                                </>
                                              )}
                                              <p>
                                                <button className="button-border-medium disable w-100per">
                                                  宿泊プランを変更する
                                                </button>
                                              </p>
                                              <p className="fz-12 c-blue_gray mt-20 mt-12-md ws-nw ta-c lh-1">変更は<span className="c-red fw-b">初泊日のみ</span>で行なえます。</p>
                                            </>
                                            :
                                            <>
                                              {planSelectFormData.isHotels && (
                                                <>
                                                  <p className="mb-16 mb-8-md">
                                                    <button
                                                      onClick={() => changeSelectHotel(hotel.checkIn, hotel.detail.hotel_code)}
                                                      className="button-border-medium w-100per"
                                                    >
                                                      宿泊施設を変更する
                                                    </button>
                                                  </p>
                                                </>
                                              )}
                                              <p>
                                                <button
                                                  onClick={() => handleUpdateStaySelectModal(hotel.checkIn, hotel.detail.hotel_code)}
                                                  className="button-border-medium w-100per"
                                                >
                                                  宿泊プランを変更する
                                                </button>
                                              </p>
                                            </>
                                          }
                                        </div>
                                      </div>
                                      {hotelCancelDisabled(schedule.date, hotel.checkIn, planSelectFormData.hotel)?
                                        <>
                                          <div className="box-stay-item-cancel">
                                            <p>
                                              <button
                                                className="button-text-link fz-12"
                                                onClick={() => clickCancelHotelDate(schedule.date, hotel.checkIn, hotel.detail.hotel_name)}
                                              >
                                                <i className="icon-bad-circle-black mr-5"></i>
                                                この日程以降の宿泊をキャンセル
                                              </button>
                                            </p>
                                          </div>
                                        </>:<></>
                                      }
                                    </div>
                                  ))}
                                </div>
                              </>
                              :schedule.date !== planSelectFormData.searchParams.end?
                              <>
                                {/* ホテル */}
                                <div className="box-separator_bottom-border_small-item">
                                  <p className="mb-20 mb-14-md fz-18 fz-20-md fw-b">
                                    <i className="icon-sleep-circle mr-5 fz-32"></i>宿泊
                                  </p>
                                  <div className="box-light-gray small">
                                    <button
                                      className="button-medium-orange w-100per"
                                      onClick={() => addHotelModal(schedule.date)}
                                    >
                                      ホテルを追加する
                                    </button>
                                  </div>
                                </div>
                              </>:<></>
                            }
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Fragment>
                ))}

              </>:<></>
            }

          </div>
        </div>
      </section>

      {/* ここに作成したモーダルを追加する */}
      {/* モーダル：宿泊施設・宿泊プラン */}
      {staySelectModal1 && (
        <StaySelectModal
          updateHotelIndex={hotelIndex}
          date={updateHotelDate}
          isUpdate={isUpdateHotel}
          selectHotelCode={hotelCode}
          updateSelectHotel={updateData}
          planSelectFormData={planSelectFormData}
          stepHandler={stepHandle}
          defaultStep={step}
          isShow={staySelectModal1}
          callback={() => handleHotelModal(1)}
        />
      )}

      {/* プチモーダル：基本プラン 日程詳細 */}
      <ScheduleDetailModal isShow={scheduleDetailModal} callback={handleScheduleDetailModal} activity={selectActivity} />
      {/* モーダル：基本プラン */}
      <ComprehensiveOptionDetailsModal stepHandler={stepHandle} defaultStep={step} isShow={comprehensiveOptionDetailsModal2} callback={handleCallbackOptionDetailsModal} activity={selectActivity} planSelectFormData={planSelectFormData} />

      {/* プチモーダル：人数詳細 */}
      <NumberOfPeopleModal planSelectFormData={planSelectFormData} isShow={numberOfPeopleModal} callback={handleNumberOfPeopleModal} />

      {/* リンクリスト */}
      <LinkListModal isShow={linkListModal} scheduleListModalData={scheduleListData} callback={handleLinkListModal} />

      {/* モーダル：フライト選択 */}
      {/* <FlightModal isShow={flightModalState} callback={handleFlightModal} /> */}
      <FlightModalApi planSelectFormData={planSelectFormData} profitRate={planSelectFormData.planData?.sellerIndo.profitRate?? "0"} pageRef="schedule" changeFlightCallback={flightUpdateDate} search={planSelectFormData.searchParams} currentFlights={flightType} changeAirportCallback={changeAirportCallback} isShow={flightModalState} callback={flightModalCallback} />
      {/* モーダル：乗継便確認 */}
      <ConnectingFlightModal flights={selectedEntities} airline={airline} isShow={ConnectingFlightModalState} callback={handleConnectingDetailModalCallback} />

      {/* モーダル：チェックイン時間の変更 */}
      <HotelCheckInTimeModal
        hotelInfo={hotelCheckInInfoForModal}
        isShow={hotelCheckInTimeModalState}
        handleChangeCheckInTime={handleChangeCheckInTimeInModal}
        callback={handleCloseInModal}
      />

      {/* ダイアログ： キャンセル */}
      <DialogCancel isShow={dialogCancel} callback={handleDialogCancel} />
      <DialogHotelCancel date={hotelCancelDate} checkIn={hotelCancelCheckIn} hotelName={hotelCancelHotelName} isShow={dialogHotelCancel} callback={callbackDialogHotelCancel} callbackHotelCancel={cancelHotelDate} />
    </>
  );
};

export default SectionSchedule;
