import React, { SyntheticEvent } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

import { transformFullCharToHalf } from "./../../../utils/transformFullCharToHalf";

//input
import { SearchAddress } from "./../../input/SearchAddress";
import { InputText } from "./../../input/InputText";
import { InputEmail } from "./../../input/InputEmail";
import { InputRadio } from "./../../input/InputRadio";
import { InputsForm1 } from "./../../../types/InputsForm1";

import { PartsDay, PartsMonth, PartsPrefecture } from "../../parts";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";

import dayjs from "dayjs";

type Props = {
  control: any;
  errors: FieldErrors;
  getValues: any;
  register: UseFormRegister<InputsForm1>;
  setValue: any;
  sendTestMail: (email: string) => Promise<boolean>;
}

const SectionApplicant: React.FC<Props> = (props) => {

  const handleBlurInText = (
    event: React.FocusEvent<HTMLInputElement>,
    options: {
      transformFullCharToHalf?: boolean;
    }
  ) => {
    // 全角から半角に変換
    if (options.transformFullCharToHalf) {
      const targetName: any = event.target.name; // todo: 型指定したい
      const transformedValue = transformFullCharToHalf(event.target.value);
      props.setValue(targetName, transformedValue);
    }
  };

  //ラジオボタンで表示・非表示
  // todo: 非表示にした際に別の代表者情報は消した方がいいかも(必須はfalseにしているけど、入力されているデータ自体にはvalidationがかかるので)
  const isApplicantJoin = props.getValues("representative.is_applicant_join");
  const [radioStatus, setRadioStatus] = React.useState(isApplicantJoin); // 初期値を変える場合は「app/front/src/redux/form1Slice.ts」の値も変える

  // テストメール送信ボタンの使用可・不可
  const [sendingTestMail, setSendingTestMail] = React.useState(false);

  // テストメール送信
  const handleSendTestMailClick = async (event: SyntheticEvent) => {
    setSendingTestMail(true);

    event.preventDefault();

    const targetEmail = props.getValues("applicant.email");

    await props.sendTestMail(targetEmail);

    setSendingTestMail(false);
  }

  // 申込者の年齢バリデーション
  const minAge = () => {
    const minAge = 18;
    const year = props.getValues("applicant.birthday_year");
    const month = props.getValues("applicant.birthday_month");
    const day = props.getValues("applicant.birthday_day");
    const birthday = dayjs(year+'-'+month+'-'+day);
    const today = dayjs();
    const age = today.diff(birthday, "year");
    return minAge <= age;
  }

  return (
    <>
      {/* <!--申込者情報入力--> */}
      <section>
        <h2 className="heading-2 mb-8">申込者情報の入力</h2>
        <p className="mb-24">
          内容を確認のうえ、必要事項を入力してください。
        </p>
        <div className="card mb-20">
          <div className="box-separator_bottom-large">
            {/* <!--名前--> */}
            <div
              id="form-name-kanji"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    氏名 <span className="">( 漢字 )</span>
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-pc">
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">姓</div>
                      <div>
                        <InputText
                          name="applicant.name.kanji.last"
                          placeholder="例：沖縄"
                          register={props.register}
                          options={{
                            required: true,
                            maxLength: 10,
                          }}
                          error={props.errors.applicant?.name?.kanji?.last}
                        ></InputText>
                        {/* todo: error表示までcomponentに入れるか？ 入れないとmaxlengthとかの制御がめんどくさい。*/}
                        {props.errors.applicant?.name?.kanji?.last &&
                          props.errors.applicant?.name?.kanji?.last.type ===
                            "required" && (
                            <p className="form-error_text">
                              お名前（姓）をご入力ください。
                            </p>
                          )}
                        {props.errors.applicant?.name?.kanji?.last &&
                          props.errors.applicant?.name?.kanji?.last.type ===
                            "maxLength" && (
                            <p className="form-error_text">
                              お名前（姓）は10文字以内でご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">名</div>
                      <div>
                        <InputText
                          name="applicant.name.kanji.first"
                          placeholder="例：太郎"
                          register={props.register}
                          options={{ required: true, maxLength: 10 }}
                          error={props.errors.applicant?.name?.kanji?.first}
                        ></InputText>
                        {props.errors.applicant?.name?.kanji?.first &&
                          props.errors.applicant?.name?.kanji?.first.type ===
                            "required" && (
                            <p className="form-error_text">
                              お名前（名）をご入力ください。
                            </p>
                          )}
                        {props.errors.applicant?.name?.kanji?.first &&
                          props.errors.applicant?.name?.kanji?.first.type ===
                            "maxLength" && (
                            <p className="form-error_text">
                              お名前（名）は10文字以内でご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--名前(フリガナ)--> */}
            <div
              id="form-name-hurigana"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    氏名 <span className="">( フリガナ )</span>
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-pc">
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">セイ</div>
                      <div>
                        <InputText
                          name="applicant.name.kana.last"
                          placeholder="例：オキナワ"
                          register={props.register}
                          options={{ required: true, pattern: /^[ァ-ンヴー]+$/i }}
                          error={props.errors.applicant?.name?.kana?.last}
                        ></InputText>
                        {props.errors.applicant?.name?.kana?.last &&
                          props.errors.applicant?.name?.kana?.last.type ===
                            "required" && (
                            <p className="form-error_text">
                              お名前（セイ）をご入力ください。
                            </p>
                          )}
                        {props.errors.applicant?.name?.kana?.last &&
                          props.errors.applicant?.name?.kana?.last.type ===
                            "pattern" && (
                            <p className="form-error_text">
                              全角カタカナでご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">メイ</div>
                      <div>
                        <InputText
                          name="applicant.name.kana.first"
                          placeholder="例：タロウ"
                          register={props.register}
                          options={{ required: true, pattern: /^[ァ-ンヴー]+$/i }}
                          error={props.errors.applicant?.name?.kana?.first}
                        ></InputText>
                        {props.errors.applicant?.name?.kana?.first &&
                          props.errors.applicant?.name?.kana?.first.type ===
                            "required" && (
                            <p className="form-error_text">
                              お名前（メイ）をご入力ください。
                            </p>
                          )}
                        {props.errors.applicant?.name?.kana?.first &&
                          props.errors.applicant?.name?.kana?.first.type ===
                            "pattern" && (
                            <p className="form-error_text">
                              全角カタカナでご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--生年月日--> */}
            <div
              id="form-name-birthday"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    生年月日
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div
                    className={
                      "form-birthday" +
                      (props.errors.applicant?.birthday_year ||
                      props.errors.applicant?.birthday_month ||
                      props.errors.applicant?.birthday_day ||
                      props.errors.applicant?.minAge
                        ? " error"
                        : "")
                    }
                  >
                    <div className="form-birthday-year">
                      <div>
                        <InputText
                          name="applicant.birthday_year"
                          placeholder="例: 1990"
                          isSmall={true}
                          register={props.register}
                          options={{
                            required: true,
                            min: 1900,
                            max: 3000,
                            maxLength: 4,
                            minLength: 4,
                            onBlur: (event) =>
                              handleBlurInText(event, {
                                transformFullCharToHalf: true,
                              }),
                            validate: {minAge},
                            pattern: /\d{4}/
                          }}
                          error={props.errors.applicant?.birthday_year}
                        ></InputText>
                      </div>
                      <p className="mr-10 ml-10 color-dark_blue">年</p>
                    </div>
                    <div className="form-birthday-month">
                      <div className="select_box-default">
                        <PartsMonth
                          name="applicant.birthday_month"
                          register={props.register}
                          options={{ required: true, validate: {minAge} }}
                        />
                      </div>
                      <p className="mr-10 ml-10 color-dark_blue">月</p>
                    </div>
                    <div className="form-birthday-day">
                      <div className="select_box-default">
                        <PartsDay
                          name="applicant.birthday_day"
                          register={props.register}
                          options={{ required: true, validate: {minAge} }}
                        />
                      </div>
                    </div>
                    {(props.errors.applicant?.birthday_year?.type === "minAge" ||
                      props.errors.applicant?.birthday_month?.type === "minAge" ||
                      props.errors.applicant?.birthday_day?.type === "minAge")
                      ?
                        <><p className="form-error_text">※予約日に18歳未満の方はお申し込みできません。</p></>
                      :
                        (props.errors.applicant?.birthday_year ||
                        props.errors.applicant?.birthday_month ||
                        props.errors.applicant?.birthday_day) &&
                          <><p className="form-error_text">生年月日をご入力ください。</p></>
                    }
                  </div>
                  <ul className="list-note light-gray mt-8">
                    <li>予約日に18歳未満の方はお申し込みできません。</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <!--性別--> */}
            <div
              id="form-sexual"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    性別
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div
                    className={
                      "form-content-input-sexual" +
                      (props.errors.applicant?.gender ? " error" : "")
                    }
                  >
                    <InputRadio
                      name="applicant.gender"
                      control={props.control}
                      radios={[
                        { label: "男性", value: "male" },
                        { label: "女性", value: "female" },
                      ]}
                      options={{ required: true }}
                      isRow={true}
                    ></InputRadio>
                    {props.errors.applicant?.gender && (
                      <p className="form-error_text">
                        性別をご選択ください。
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--メールアドレス--> */}
            <div
              id="form-name-mailaddress"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    メールアドレス
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-mail">
                    <div className="form-column-mail-item large">
                      <div>
                        {/* todo: typeも渡せるようなcomponentにする？ */}
                        <InputEmail
                          name="applicant.email"
                          placeholder="例：mail@example.com"
                          register={props.register}
                          options={{
                            required: true,
                            maxLength: 200,
                            pattern: /^\S+@\S+$/i, // todo: もっとちゃんとしたのを入れる
                            onBlur: (event) =>
                              handleBlurInText(event, {
                                transformFullCharToHalf: true,
                              }),
                          }}
                          error={props.errors.applicant?.email}
                        ></InputEmail>
                        {props.errors.applicant?.email &&
                          props.errors.applicant?.email.type === "required" && (
                            <p className="form-error_text">
                              メールアドレスをご入力ください。
                            </p>
                          )}
                        {props.errors.applicant?.email &&
                          props.errors.applicant?.email.type ===
                            "maxLength" && (
                            <p className="form-error_text">
                              メールアドレスは200文字以内でご入力ください。
                            </p>
                          )}
                        {props.errors.applicant?.email &&
                          props.errors.applicant?.email.type === "pattern" && (
                            <p className="form-error_text">
                              メールアドレスを正しくご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-column-mail-item small">
                      <div>
                        <button className="button-blue-form_button-small" onClick={handleSendTestMailClick} disabled={sendingTestMail}>
                          テストメールを送信
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul className="list-note light-gray">
                    <li>半角英数字で入力ください。</li>
                  </ul>
                  <div className="box-small-light-red mt-16">
                    <ul className="list-note">
                      <li>
                        予約完了メールは予約の確認、問い合わせ先などが記載されている大切なメールです。確実に受信できるように「テストメール送信」をお試しください。
                      </li>
                      <li>
                        迷惑メール設定をしている場合、「digitaldmoplatform.com
                        」のメールを受信出来るように設定をお願いいたします。
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="mt-16 mb-8 fw-b">
                      メールアドレス(確認)
                      <span className="label-required ml-5">必須</span>
                    </p>
                    <InputEmail
                      name="applicant.email_confirm"
                      placeholder="例：mail@example.com"
                      register={props.register}
                      options={{
                        required: true,
                        validate: {
                          sameEmail: (value) => {
                            return props.getValues("applicant.email") === value;
                          },
                        },
                      }}
                      error={props.errors.applicant?.email_confirm}
                    ></InputEmail>
                    {props.errors.applicant?.email_confirm &&
                      props.errors.applicant?.email_confirm.type === "required" && (
                        <p className="form-error_text">
                          メールアドレスをご入力ください。
                        </p>
                      )}
                    {props.errors.applicant?.email_confirm &&
                      props.errors.applicant?.email_confirm.type === "sameEmail" && (
                        <p className="form-error_text">
                          メールアドレスが異なります。
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--住所--> */}
            <div
              id="form-name-address"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    住所
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <p className="mb-8 fw-b">郵便番号</p>
                  <div className="form-column-address">
                    <div className="form-column-address-item">
                      <div>
                        <InputText
                          name="applicant.address.zip_code"
                          placeholder="例：9010000"
                          register={props.register}
                          options={{ required: true, pattern: /^[0-9]+$/i }}
                          error={props.errors.applicant?.address?.zip_code}
                        ></InputText>
                        {props.errors.applicant?.address?.zip_code &&
                          props.errors.applicant?.address?.zip_code.type ===
                            "required" && (
                            <p className="form-error_text">
                              郵便番号をご入力ください。
                            </p>
                          )}
                        {props.errors.applicant?.address?.zip_code &&
                          props.errors.applicant?.address?.zip_code.type ===
                            "pattern" && (
                            <p className="form-error_text">
                              郵便番号の形式でご入力ください。
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-column-mail-item">
                      <div className="ml-20-md">
                        <SearchAddress
                          elementNames={{
                            zipCode: "applicant.address.zip_code",
                            prefecture: "applicant.address.prefecture",
                            addressMain: "applicant.address.address_main",
                          }}
                        ></SearchAddress>
                      </div>
                    </div>
                    <div className="form-column-mail-item mt-8 mt-10-md">
                      <div className="ml-20-md">
                        <a href="https://www.post.japanpost.jp/zipcode/" target="_blank" className="fz-12 fz-13-md" rel="noreferrer">
                          郵便番号検索<i className="icon-blank ml-5"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <ul className="list-note light-gray mb-16">
                    <li>半角数字ハイフン無しでご入力ください。</li>
                  </ul>

                  <div>
                    <p className="mb-8 fw-b">都道府県</p>
                    <div className="select_box-default w-200">
                      <PartsPrefecture 
                        name="applicant.address.prefecture"
                        register={props.register}
                        options={{required: true}}
                      />
                    </div>
                  </div>

                  <div>
                    <p className="mt-16 mb-8 fw-b">市区町村 町名番地</p>
                    <InputText
                      name="applicant.address.address_main"
                      placeholder="例：港区浜松町2丁目8-14"
                      register={props.register}
                      options={{ required: true }}
                      error={props.errors.applicant?.address?.address_main}
                    ></InputText>
                    {props.errors.applicant?.address?.address_main &&
                      props.errors.applicant?.address?.address_main.type ===
                        "required" && (
                        <p className="form-error_text">
                          市区町村 町名番地をご入力ください。
                        </p>
                      )}
                  </div>
                  <div>
                    <p className="mt-16 mb-8 fw-b">
                      ビル・マンション・お部屋番号
                    </p>
                    <InputText
                      name="applicant.address.address_sub"
                      placeholder="例：OTSマンション2-202"
                      register={props.register}
                      options={{ required: false }}
                      error={props.errors.applicant?.address?.address_sub}
                    ></InputText>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--ご連絡先--> */}
            <div
              id="form-name-tel"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    ご連絡先
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="heading-item_6 fw-b">携帯番号</div>
                  <div>
                    <InputText
                      name="applicant.tel"
                      placeholder="例：00011112222"
                      register={props.register}
                      options={{ required: true, pattern: /^(0{1}\d{9,10})$/i }}
                      error={props.errors.applicant?.tel}
                    ></InputText>
                    <ul className="list-note light-gray">
                      <li>半角数字ハイフン無しでご入力ください。</li>
                    </ul>
                    {props.errors.applicant?.tel &&
                      props.errors.applicant?.tel.type ===
                        "required" && (
                        <p className="form-error_text">
                          携帯番号をご入力ください。
                        </p>
                      )}
                    {props.errors.applicant?.tel &&
                      props.errors.applicant?.tel.type ===
                        "pattern" && (
                        <p className="form-error_text">
                          携帯番号の形式でご入力ください。
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--緊急連絡先--> */}
            <div
              id="form-name-emergency_tel"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">緊急連絡先</h3>
                </div>
                <div className="form-content-input">
                  <div className="heading-item_6 fw-b">電話番号</div>
                  <div>
                    <InputText
                      name="applicant.emergency_tel"
                      placeholder="例：00011112222"
                      register={props.register}
                      options={{ required: false, pattern: /^(0{1}\d{9,10})$/i }}
                      error={props.errors.applicant?.emergency_tel}
                    ></InputText>
                    <ul className="list-note light-gray">
                      <li>半角数字ハイフン無しでご入力ください。</li>
                    </ul>
                    {props.errors.applicant?.emergency_tel &&
                      props.errors.applicant?.emergency_tel.type ===
                        "pattern" && (
                        <p className="form-error_text">
                          電話番号の形式でご入力ください。
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>


            {/* <!--マイレージ番号--> */}
            <div
              id="form-name-mileage_number"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    マイレージ番号
                  </h3>
                </div>
                <div className="form-content-input">
                  <div>
                    <InputText
                      name="applicant.mileage_number"
                      placeholder="ANA10桁 / JAL7桁 もしくは9桁"
                      register={props.register}
                      options={{ required: false }}
                      error={props.errors.applicant?.mileage_number}
                    ></InputText>
                    {props.errors.applicant?.tel &&
                      props.errors.applicant?.tel.type ===
                      "required" && (
                        <p className="form-error_text">
                          マイレージ番号をご入力ください。
                        </p>
                      )}
                  </div>
                  <ul className="list-note light-gray">
                    <li>半角数字ハイフン無しでご入力ください。</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <!--代表者情報--> */}
            <div
              id="form-representative"
              className="box-separator_bottom-large-item"
            >
              <h3 className="heading-3-small mb-16 mb-8-md">
                申込者のご旅行参加の有無
                <span className="label-required ml-5">必須</span>
              </h3>
              <div className="form-content-input mb-16">
                <div>
                  <InputRadio
                    name="representative.is_applicant_join"
                    control={props.control}
                    radios={[
                      {
                        label: "お申込者様本人が、代表者として旅行に参加する。",
                        value: "yes",
                      },
                      {
                        label: (
                          <span>
                            <span className="marker-orange fw-b">
                              お申込者様は旅行に参加せず
                            </span>
                            、別途、旅行の代表者を決定する。
                          </span>
                        ),
                        value: "no",
                      },
                    ]}
                    options={{ required: true }}
                    isRow={false}
                    onChange={(event: any) => {
                      setRadioStatus(event.target.value);
                    }}
                  ></InputRadio>
                  {props.errors.representative?.is_applicant_join &&
                    props.errors.representative?.is_applicant_join.type ===
                      "required" && (
                      <p className="form-error_text">
                        申込者のご旅行参加の有無をご選択ください。
                      </p>
                    )}
                </div>
              </div>

              <div className={"box-heading-blue" + (radioStatus === "yes" ? " d-n" : "")}>
                <div className="box-heading-blue-text">
                  旅行の代表者様情報入力
                </div>
                <div className="box-heading-blue-content">
                  <div className="box-separator_bottom-large">
                    {/* <!--名前--> */}
                    <div
                      id="form-name-kanji"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            氏名 <span className="">( 漢字 )</span>
                            <span className="label-required ml-5">
                              必須
                            </span>
                          </h3>
                        </div>
                        <div className="form-content-input">
                          <div className="form-column-pc">
                            <div className="form-column-pc-item">
                              <div className="heading-item_6 fw-b">姓</div>
                              <div>
                              <InputText
                                name="representative.name.kanji.last"
                                placeholder="例：沖縄"
                                register={props.register}
                                options={{
                                  required: radioStatus !== "yes",
                                  maxLength: 10,
                                }}
                                error={props.errors.representative?.name?.kanji?.last}
                              ></InputText>
                              {props.errors.representative?.name?.kanji?.last &&
                                props.errors.representative?.name?.kanji?.last.type ===
                                  "required" && (
                                  <p className="form-error_text">
                                    お名前（姓）をご入力ください。
                                  </p>
                                )}
                              {props.errors.representative?.name?.kanji?.last &&
                                props.errors.representative?.name?.kanji?.last.type ===
                                  "maxLength" && (
                                  <p className="form-error_text">
                                    お名前（姓）は10文字以内でご入力ください。
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="form-column-pc-item">
                              <div className="heading-item_6 fw-b">名</div>
                              <div>
                              <InputText
                                name="representative.name.kanji.first"
                                placeholder="例：太郎"
                                register={props.register}
                                options={{ required: radioStatus !== "yes", maxLength: 10 }}
                                error={props.errors.representative?.name?.kanji?.first}
                              ></InputText>
                              {props.errors.representative?.name?.kanji?.first &&
                                props.errors.representative?.name?.kanji?.first.type ===
                                  "required" && (
                                  <p className="form-error_text">
                                    お名前（名）をご入力ください。
                                  </p>
                                )}
                              {props.errors.representative?.name?.kanji?.first &&
                                props.errors.representative?.name?.kanji?.first.type ===
                                  "maxLength" && (
                                  <p className="form-error_text">
                                    お名前（名）は10文字以内でご入力ください。
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--名前(フリガナ)--> */}
                    <div
                      id="form-name-hurigana"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            氏名 <span className="">( フリガナ )</span>
                            <span className="label-required ml-5">
                              必須
                            </span>
                          </h3>
                        </div>
                        <div className="form-content-input">
                          <div className="form-column-pc">
                            <div className="form-column-pc-item">
                              <div className="heading-item_6 fw-b">セイ</div>
                              <div>
                              <InputText
                                name="representative.name.kana.last"
                                placeholder="例：オキナワ"
                                register={props.register}
                                options={{ required: radioStatus !== "yes", pattern: /^[ァ-ンヴー]+$/i }}
                                error={props.errors.representative?.name?.kana?.last}
                              ></InputText>
                              {props.errors.representative?.name?.kana?.last &&
                                props.errors.representative?.name?.kana?.last.type ===
                                  "required" && (
                                  <p className="form-error_text">
                                    お名前（セイ）をご入力ください。
                                  </p>
                                )}
                              {props.errors.representative?.name?.kana?.last &&
                                props.errors.representative?.name?.kana?.last.type ===
                                  "pattern" && (
                                  <p className="form-error_text">
                                    全角カタカナでご入力ください。
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="form-column-pc-item">
                              <div className="heading-item_6 fw-b">メイ</div>
                              <div>
                              <InputText
                                name="representative.name.kana.first"
                                placeholder="例：タロウ"
                                register={props.register}
                                options={{ required: radioStatus !== "yes", pattern: /^[ァ-ンヴー]+$/i }}
                                error={props.errors.representative?.name?.kana?.first}
                              ></InputText>
                              {props.errors.representative?.name?.kana?.first &&
                                props.errors.representative?.name?.kana?.first.type ===
                                  "required" && (
                                  <p className="form-error_text">
                                    お名前（メイ）をご入力ください。
                                  </p>
                                )}
                              {props.errors.representative?.name?.kana?.first &&
                                props.errors.representative?.name?.kana?.first.type ===
                                  "pattern" && (
                                  <p className="form-error_text">
                                    全角カタカナでご入力ください。
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--性別--> */}
                    <div
                      id="form-sexual"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            性別
                            <span className="label-required ml-5">
                              必須
                            </span>
                          </h3>
                        </div>

                        <div className="form-content-input">
                          <div
                            className={
                              "form-content-input-sexual" +
                              (props.errors.representative?.gender ? " error" : "")
                            }
                          >
                            <InputRadio
                              name="representative.gender"
                              control={props.control}
                              radios={[
                                { label: "男性", value: "male" },
                                { label: "女性", value: "female" },
                              ]}
                              options={{ required: radioStatus !== "yes" }}
                              isRow={true}
                            ></InputRadio>
                            {props.errors.representative?.gender && (
                              <p className="form-error_text">
                                性別をご選択ください。
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--生年月日--> */}
                    <div
                      id="form-name-birthday"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            生年月日
                            <span className="label-required ml-5">必須</span>
                          </h3>
                        </div>
                        <div className="form-content-input">
                          <div
                            className={
                              "form-birthday" +
                              (props.errors.representative?.birthday_year ||
                              props.errors.representative?.birthday_month ||
                              props.errors.representative?.birthday_day
                                ? " error"
                                : "")
                            }
                          >
                            <div className="form-birthday-year">
                              <div>
                                <InputText
                                  name="representative.birthday_year"
                                  placeholder="例: 1990"
                                  isSmall={true}
                                  register={props.register}
                                  options={{
                                    required: true,
                                    min: 1900,
                                    max: 3000,
                                    maxLength: 4,
                                    minLength: 4,
                                    onBlur: (event) =>
                                      handleBlurInText(event, {
                                        transformFullCharToHalf: true,
                                      }),
                                  }}
                                  error={props.errors.representative?.birthday_year}
                                ></InputText>
                              </div>
                              <p className="mr-10 ml-10 color-dark_blue">年</p>
                            </div>
                            <div className="form-birthday-month">
                              <div className="select_box-default">
                                <PartsMonth
                                  name="representative.birthday_month"
                                  register={props.register}
                                  options={{ required: true }}
                                />
                              </div>
                              <p className="mr-10 ml-10 color-dark_blue">月</p>
                            </div>
                            <div className="form-birthday-day">
                              <div className="select_box-default">
                                <PartsDay
                                  name="representative.birthday_day"
                                  register={props.register}
                                  options={{ required: true }}
                                />
                              </div>
                            </div>
                            {(props.errors.representative?.birthday_year ||
                              props.errors.representative?.birthday_month ||
                              props.errors.representative?.birthday_day) && (
                              <p className="form-error_text">
                                生年月日をご入力ください。
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {/* <!--ご連絡先--> */}
                    <div
                      id="form-name-tel"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            ご連絡先
                            <span className="label-required ml-5">
                              必須
                            </span>
                          </h3>
                        </div>
                        <div className="form-content-input">
                          <div className="heading-item_6 fw-b">携帯番号</div>
                          <div>
                            <InputText
                              name="representative.tel"
                              placeholder="例：00011112222"
                              register={props.register}
                              options={{ required: radioStatus !== "yes", pattern: /^(0{1}\d{9,10})$/i }}
                              error={props.errors.representative?.tel}
                            ></InputText>
                            <ul className="list-note light-gray">
                                <li>半角数字ハイフン無しでご入力ください。</li>
                                <li>
                                  ご旅行先でもご連絡の取れる番号を優先してご記入ください。
                                </li>
                            </ul>
                            {props.errors.representative?.tel &&
                              props.errors.representative?.tel.type === "required" && (
                                <p className="form-error_text">
                                  携帯番号をご入力ください。
                                </p>
                              )}
                            {props.errors.representative?.tel &&
                              props.errors.representative?.tel.type === "pattern" && (
                                <p className="form-error_text">
                                  携帯番号の形式でご入力ください。
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--マイレージ番号--> */}
                    <div
                      id="form-name-mileage_number"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            マイレージ番号
                          </h3>
                        </div>
                        <div className="form-content-input">
                          <InputText
                            name="representative.mileage_number"
                            placeholder="ANA10桁 / JAL7桁 もしくは9桁"
                            register={props.register}
                            options={{
                              required: false,
                              pattern: /^(\d{7}|\d{9}|\d{10})$/
                            }}
                            error={props.errors.representative?.mileage_number}
                          ></InputText>
                          <ul className="list-note light-gray">
                            <li>半角数字でご入力ください。</li>
                          </ul>
                          {props.errors.representative?.mileage_number &&
                            props.errors.representative?.mileage_number.type === "pattern" && (
                              <p className="form-error_text">
                                マイレージ番号を正しくご入力ください。
                              </p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <!--マイレージ番号--> */}
                    <div className="box-separator_bottom-large-item">
                      <div className="box-navy">
                        <div className="box-navy-heading small">
                          <ul>
                            <li className="fw-b">
                              <i className="icon-alert-white mr-5"></i>お申込者様と旅行の代表者様が異なる場合のご連絡方法
                            </li>
                          </ul>
                        </div>
                        <div className="box-navy-content small">
                          <p className="mb-16">
                            お申込者様と旅行の代表者様が異なる場合、当社のご連絡は以下のようになります。下記以外の連絡方法をご希望されるお客様はデジタルDMOカスタマーサポートにお問い合わせください。
                          </p>
                          <ul className="list-note red mb-16">
                            <li>航空券(eチケット)などの送付先を指定したい場合は、その他情報に入力してください。</li>
                          </ul>

                          <table>
                            <tbody>
                              <tr>
                                <th colSpan={2} className="table-bgc-light_navy fw-b fz-16-md">
                                  予約後の自動返信メール・予約確認メールの発信
                                </th>
                              </tr>
                              <tr>
                                <th className="w-40per fz-16-md">お申込み者様</th>
                                <td className="ta-c">○</td>
                              </tr>
                              <tr>
                                <th className="fz-16-md">旅行の代表者様</th>
                                <td className="ta-c">✕</td>
                              </tr>
                              <tr>
                                <th colSpan={2} className="table-bgc-light_navy fw-b fz-16-md">
                                  予約内容の確認・変更・取消を含むご連絡(旅行開始前)
                                </th>
                              </tr>
                              <tr>
                                <th className="fz-16-md">お申込み者様</th>
                                <td className="ta-c">○</td>
                              </tr>
                              <tr>
                                <th className="fz-16-md">旅行の代表者様</th>
                                <td className="ta-c">✕</td>
                              </tr>
                              <tr>
                                <th colSpan={2} className="table-bgc-light_navy fw-b fz-16-md">
                                  最終案内書・航空券 (eチケット) の発送
                                </th>
                              </tr>
                              <tr>
                                <th className="fz-16-md">お申込み者様</th>
                                <td className="ta-c">○</td>
                              </tr>
                              <tr>
                                <th className="fz-16-md">旅行の代表者様</th>
                                <td className="ta-c">✕</td>
                              </tr>
                              <tr>
                                <th colSpan={2} className="table-bgc-light_red fw-b fz-16-md">
                                  旅行開始後のご連絡
                                </th>
                              </tr>
                              <tr>
                                <th className="fz-16-md">お申込み者様</th>
                                <td className="ta-c">△</td>
                              </tr>
                              <tr>
                                <th className="fz-16-md">旅行の代表者様</th>
                                <td className="ta-c">○</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* /box-navy-content */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionApplicant;
