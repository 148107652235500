import React from "react";
import AppTemplate from "../components/Form6";

const Form6: React.FC = () => {
  return (
    <AppTemplate>
      <>ダッシュボード</>;
    </AppTemplate>
  );
};

export default Form6;
