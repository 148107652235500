import React, {useEffect, useState, createContext, ChangeEvent} from "react";
import {
  Tabs,
  Tab,
  Box,
  Backdrop
} from "@material-ui/core";

//swiper
import { Navigation, Pagination, A11y, EffectFade, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import 'swiper/modules/pagination/pagination.min.css';

// for styles
import Button from "@material-ui/core/Button";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

//モーダルをインポート
import Modal from "@material-ui/core/Modal";
import {
  
} from "./"
import {IActivity, ImageList, planStocks} from "../../types/Activity";
import DOMPurify from 'dompurify';
import {dateFormat} from "../../utils/convert";
import {checkPlanStockListType, getPlanPeopleNum, getSelectActivityTimePrices} from "../../utils/plan";
import {PlanSelectFormData} from "../../types/planSelectForm";
import Map from "../google/Maps";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  callback: ( decision: IActivity | undefined ) => void,
  defaultStep: number,
  stepHandler: ( step: number ) => void,
  activity: IActivity | undefined,
  planSelectFormData: PlanSelectFormData
}

const ComprehensiveOptionDetailsModal: React.FC<Props> = ({
  isShow,
  callback,
  defaultStep,
  stepHandler,
  activity,
  planSelectFormData
}) => {

  const [selectActivity, setSelectActivity] = useState(activity);
  
  const onPageHandle = ( step: number ) => {
    stepHandler(step)
  }
  
  const setDefaultTimeSelect = (stockData: planStocks) => {
    if (selectActivity?.date === stockData.use_date) {
      return selectActivity.time_code;
    }
    return "";
  }
  
  const checkedDate = (stockData: planStocks) => {
    return selectActivity?.date === stockData.use_date;
  }
  
  const selectedTime = (stockData: planStocks, time_code: string) => {
    if (selectActivity?.date === stockData.use_date && selectActivity?.time_code === time_code) {
      return true;
    } else if (time_code === "") {
      return true;
    }
    return false;
  }
  

  // 日程決定handle
  const setUseDate = (event: ChangeEvent<HTMLInputElement>, stockData: planStocks) => {
    let setActivity = selectActivity;
    if (stockData.timeType === "no_settime") {
      const timeList = stockData.time_stock_list.filter((time) => {
        return time.time === stockData.timeType;
      })
      if (timeList.length > 0) {
        if (setActivity !== undefined) {
          setActivity.time_code = timeList[0].time_code;
          setActivity.time = timeList[0].time;
        }
      }
    } else if (setActivity !== undefined) {
      setActivity.time_code = "";
      setActivity.time = "";
    }

    if (setActivity !== undefined) {
      setActivity.date = event.target.value;
      setSelectActivity({...setActivity});
    }
  }

  // 日程決定handle
  const setUseTime = (event: React.ChangeEvent<HTMLSelectElement>, stockData: planStocks) => {
    let setActivity = selectActivity;
    const timeList = stockData.time_stock_list.filter((time) => {
      return time.time_code === event.target.value;
    })
    if (timeList.length > 0) {
      if (setActivity !== undefined) {
        setActivity.time_code = timeList[0].time_code;
        setActivity.time = timeList[0].time;
        setActivity.date = stockData.use_date;
      }
    }

    if (setActivity !== undefined) {
      setSelectActivity({...setActivity});
    }
  }

  const createMarkup = (data: string | undefined) => {
    if(activity && data) {
      const html = data;
      return  {
          __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] })
      }
    }
  }

  useEffect(() => {
    setSelectActivity(activity)
  }, [activity]);
  
  const callbackInActive = () => {
    if (!selectActivity?.date) {
      alert("日程選択してください。");
    } else if(!selectActivity?.time_code || !selectActivity.time ) {
      alert("時間を設定してください。");
    } else {
      let setActivity = selectActivity;
      const comprehensiveItems = planSelectFormData?.sellerProduct?.comprehensiveItems;
      const peopleNum = getPlanPeopleNum(planSelectFormData.searchParams.roomDetail, comprehensiveItems, setActivity.plan_info.plan_code);

      setActivity.price_list = getSelectActivityTimePrices(selectActivity, selectActivity.time_code, setActivity.date);
      if (setActivity.price_list !== undefined) {
        setActivity.price_list[0].num = peopleNum;
      }
      setSelectActivity({...setActivity});
      callback(selectActivity);
    }
  }

  return (
    <>
    <Modal
      open={isShow}
      onClose={() => callback(undefined)}
      className="modal"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div>
        {/* 1 */}
        <div className={"modal-fixed" + (defaultStep == 1? " d-b":" d-n")}>
          <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-text">オプション選択</p>
            <p className="modal-fixed-close">
              <Button onClick={() => callback(undefined)} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>閉じる
              </Button>
            </p>
          </div>
          <div className="modal-fixed-content pb-medium">

            {/* STEP */}
            <div className="step-option no-padding">
              <ul className="step-option-list">
                <li key={"step1_desc"} className="step-option-list-item active">詳細確認</li>
                <li key={"step1_date"} className="step-option-list-item">日程選択</li>
              </ul>
            </div>

            <div className="modal-fixed-content-item">
              <div className="modal-fixed-content-item-carousel">              
              <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, A11y, EffectFade]}
                  loop={true}
                  effect="fade"
                  pagination={{ clickable: true }}
                >
                  {
                    selectActivity == undefined ? '' :
                    <>
                    { selectActivity.plan_info?.image_list.map((data: ImageList, index:any) => (
                      <SwiperSlide key={"swiperSlide"+index} ><img src={data.image_url} /></SwiperSlide>
                    ))}
                    </>
                  }
                </Swiper>
              </div>
              <div className="modal-fixed-content-item-heading">
                {selectActivity?.plan_info.plan_name}
              </div>
            </div>

            <div className="modal-fixed-content-inner">
              <div className="box-separator_bottom-medium lh-1_75 lh-1_5-md">
                <div className="box-separator_bottom-medium-item">
                  <div className="box-light-gray medium mb-8">
                    <div className="" dangerouslySetInnerHTML={createMarkup(selectActivity?.plan_info.description)}></div>
                  </div>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">行程・内容</p>
                  <p dangerouslySetInnerHTML={createMarkup(selectActivity?.plan_info.contents)}></p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">対象年齢</p>
                  <div className="">
                    {selectActivity?.plan_info.age_min && selectActivity?.plan_info.age_max ?
                      <>
                        {selectActivity?.plan_info.age_min}歳 ～ {selectActivity?.plan_info.age_max}歳
                      </>
                      : selectActivity?.plan_info.age_min && !selectActivity?.plan_info.age_max ?
                      <>
                        {selectActivity?.plan_info.age_min}歳 ～
                      </>
                      : !selectActivity?.plan_info.age_min && selectActivity?.plan_info.age_max ?
                      <>
                        ～ {selectActivity?.plan_info.age_max}歳
                      </>
                      :<></>
                    }
                  </div>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">最少催行人数</p>
                  <p>{activity?.plan_info?.least_number}人</p>
                </div>
                {activity?.plan_info?.include ? 
                  <>
                    <div className="box-separator_bottom-medium-item">
                      <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">料金に含まれるもの</p>
                      <p className="" dangerouslySetInnerHTML={createMarkup(selectActivity?.plan_info?.include)}></p>
                    </div>
                  </>
                  :<></>
                }
                
                <div className="box-separator_bottom-medium-item">
                  <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">集合場所</p>
                  <p>
                    <>
                    〒{activity?.plan_info.gather_place_zip}
                      <br/>
                    {activity?.plan_info.gather_place_address}
                      <br/>
                      {activity?.plan_info.gather_place_map_lat && activity?.plan_info.gather_place_map_lng?
                      <>
                        <Map address={activity?.plan_info.gather_place_address} lat={activity?.plan_info.gather_place_map_lat} lng={activity?.plan_info.gather_place_map_lng} label={activity?.plan_info.supplier_name}></Map>
                      </>:<></>
                      }
                      マップコード：{activity?.plan_info.gather_place_mapcode}
                    </>
                  </p>
                </div>

                {activity?.plan_info.gather_time ?
                <>
                  <div className="box-separator_bottom-medium-item">
                    <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">集合時間</p>
                    <p dangerouslySetInnerHTML={createMarkup(selectActivity?.plan_info.gather_time)}></p>
                  </div>
                </>
                  :<></>
                }

                {activity?.plan_info?.notes ? 
                <>
                  <div className="box-separator_bottom-medium-item">
                    <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">注意事項</p>
                    <p dangerouslySetInnerHTML={createMarkup(selectActivity?.plan_info?.notes)}></p>
                  </div>
                </>
                  :<></>
                }
                <div className="box-separator_bottom-medium-item">
                  <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">主催会社</p>
                  <p className="" dangerouslySetInnerHTML={createMarkup(selectActivity?.plan_info.supplier_name)}></p>
                  <p className="" dangerouslySetInnerHTML={createMarkup(selectActivity?.plan_info.time_remarks)}></p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-fixed-bottom">
            <button onClick={()=> onPageHandle(defaultStep+1)} className="button-medium-orange padding-large w-100per">日程を選択する</button>
          </div>
        </div>

        {/* 2 */}
        <div className={"modal-fixed" + (defaultStep == 2? " d-b":" d-n")}>
          <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-back">
              <button className="button-clear" onClick={()=> onPageHandle(defaultStep-1)}><i className="icon-left-arrow-blue fz-20"></i></button>
            </p>
            <p className="modal-fixed-heading-text">オプション選択</p>
            <p className="modal-fixed-close">
              <Button onClick={() => callback(undefined)} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>閉じる
              </Button>
            </p>
          </div>
          <div className="modal-fixed-content pb-medium">
            {/* STEP */}
            <div className="step-option no-padding">
              <ul className="step-option-list">
                <li key={"step2_desc"} className="step-option-list-item">詳細確認</li>
                <li key={"step2_date"} className="step-option-list-item active">日程選択</li>
              </ul>
            </div>

            <div className="modal-fixed-content-inner no-padding">
              <div className="pl-8 pl-40-md pr-8 pr-40-md">
                <h2 className="fz-20 fz-24-md mt-12 mt-32-md mb-24">日程を選択してください</h2>
                <div>
                  <p className="fz-12 fz-14-md fw-b mb-5">選択中のプラン</p>
                  <div className="box-light-gray xsmall mb-24">
                    <p className="fz-10 fz-14-md lh-1_75 lh-1_5-md">
                      {selectActivity?.plan_info.plan_name}
                    </p>
                  </div>
                </div>
              </div>
              <form action="">
                <div className="box_select-hotel pl-40-md pr-40-md">
                  <div className="box_select-hotel-schedule">
                    <ul className="box_select-hotel-schedule-list">
                      {selectActivity?.stocks.map( (stockData, stockIndex) => (
                        <li key={"hstock"+stockIndex} className="box_select-hotel-schedule-list-item">
                          <div className="box_select-hotel-schedule-list-item-date">
                            <div className="box_select-hotel-schedule-list-item-date-content">
                              <ul className="box_select-hotel-schedule-list-item-date-content-list">
                                <li key={"hstock_label"+stockIndex} className="box_select-hotel-schedule-list-item-date-content-list-label">
                                  <input
                                    type="radio"
                                    name={selectActivity?.plan_info.plan_code + "_schedule"}
                                    id={selectActivity?.plan_info.plan_code + "_schedule_" + stockIndex}
                                    value={stockData.use_date}
                                    checked={checkedDate(stockData)}
                                    onChange={(event) => setUseDate(event, stockData)}
                                  />
                                  <label
                                    htmlFor={selectActivity?.plan_info.plan_code + "_schedule_" + stockIndex}
                                    className=""
                                  >
                                    {dateFormat(stockData.use_date, "YYYY年MM月DD日（ddd）")}
                                  </label>
                                </li>
                                <li key={"hstock_shedule"+stockIndex} className="box_select-hotel-schedule-list-item-date-content-list-status">
                                  <label
                                    htmlFor="hotel_01_01_schedule"
                                    className="box_select-hotel-schedule-list-item-date-content-list-status-label"
                                  >
                                    {!stockData.stock?
                                      <>
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-status-label-text">
                                          空き
                                        </span>
                                        <i className="icon-impossible"></i>
                                      </>
                                      : stockData.totalStock > 9 ?
                                      <>
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-status-label-text">
                                        空き
                                        </span>
                                        <i className="icon-possible"></i>
                                      </>
                                      : stockData.totalStock < 10 ?
                                      <>
                                        <span className="box_select-hotel-schedule-list-item-date-content-list-status-label-text">
                                          空き
                                        </span>
                                        <span className="c-orange fz-14">
                                            {stockData.totalStock}
                                        </span>
                                      </>:<></>
                                    }
                                  </label>
                                </li>
                                <li key={"hstock_nostock"+stockIndex} className="box_select-hotel-schedule-list-item-date-content-list-result">
                                  <div className="w-90 w-100per-md d-f jc-c ai-c">
                                    {!stockData.stock?
                                    <>
                                      <div className="ta-c w-100 w-180-md">選択できません</div>
                                    </>
                                      : checkPlanStockListType(stockData.time_stock_list) === "list" ?
                                      <>
                                      <div className="select_box-default w-100 w-180-md">
                                        <select
                                          defaultValue={setDefaultTimeSelect(stockData)}
                                          onChange={(event) => setUseTime(event, stockData)}
                                        >
                                          <option selected={selectedTime(stockData, "")} value="">時間</option>
                                          {stockData.time_stock_list.map((timeStock,timeStockListIndex) => (
                                            <>
                                            {timeStock.reservation_type !== 3?
                                                <option key={"timeStockList_"+timeStockListIndex} selected={selectedTime(stockData, timeStock.time_code)} value={timeStock.time_code}>
                                                  {timeStock.time}
                                                </option>
                                                :
                                                <></>
                                            }
                                            </>
                                          ))
                                          }
                                        </select>
                                      </div>
                                      </>
                                      :
                                      <i className="icon-bar fz-18"></i>
                                    }
                                    
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </form>

              <div className="pl-8 pl-40-md pr-8 pr-40-md">
                <p className="fz-14 fz-16-md fw-b c-navy mb-8 mb-16-md">アイコンについて</p>
                <div className="box-light-gray medium mb-24">
                  <ul className="box_select-hotel-icon-list">
                    <li key={"stockdetail1"} className="box_select-hotel-icon-list-item">
                      <div className="box_select-hotel-icon-status">
                        <span className="d-b fz-10 fz-14-md fw-b mb-5 mb-0-md c-navy">空き</span>
                        <i className="icon-possible fz-14 fz-16-md ml-5-md"></i>
                      </div>
                      <div className="fz-12 fz-14-md c-blue_gray">10名以上の空きあり</div>
                    </li>
                    <li key={"stockdetail2"} className="box_select-hotel-icon-list-item">
                      <div className="box_select-hotel-icon-status">
                        <span className="d-b fz-10 fz-14-md fw-b mb-5 mb-0-md c-navy">空き</span>
                        <span className="fz-14 lh-1 c-orange">9</span>
                      </div>
                      <div className="fz-12 fz-14-md c-blue_gray">1-9名の空きあり</div>
                    </li>
                    <li key={"stockdetail3"} className="box_select-hotel-icon-list-item">
                      <div className="box_select-hotel-icon-status">
                        <span className="d-b fz-10 fz-14-md fw-b mb-5 mb-0-md c-navy">空き</span>
                        <i className="icon-impossible fz-14 fz-16-md ml-5-md"></i>
                      </div>
                      <div className="fz-12 fz-14-md c-blue_gray">受付終了</div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div className="modal-fixed-bottom">
            <button onClick={() => callbackInActive()} className="button-medium-orange padding-large w-100per">確定する</button>
          </div>
        </div>
      </div>
    </Modal>
    </>
  );
};

export default ComprehensiveOptionDetailsModal;
