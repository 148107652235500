import dayjs from "dayjs";

/**
 * 予約受け付け可能期間か確認
 * 旅行出発の5日前まで予約可能とし、出発4日前を切ってからの申込は出来ない
 * @param startDate
 */
export const validateFlightStartDate = (startDate: string) => {
  const today = dayjs();
  const fromDay = dayjs(startDate);
  const diff = fromDay.diff(today, "day", false);
  if (diff >= 4) {
    return true;
  }

  return false;
}