import React, {Fragment, useEffect, useState} from "react";

//モーダルインポート
import {
  HotelAllRoomSearchResponse,
  HotelAllSearchResponse,
  selectHotelDetail,
  roomData,
  roomDataPlanType,
} from "../../types/Hotel";
import {
  diffHotelPrice,
  getHotelDetailSearchParms,
  getRoomTypeLabel, hotelRoomSearch,
  getHotelAreaCode, isHotelStaySubPrice, scrollToHotelSection, filterAndSortRooms, isOneHotel
} from "../../utils/hotel";
import RoomDetailModal from "../modal/RoomDetailModal";
import PlanDetailModal from "../modal/PlanDetailModal";
import {makeStyles} from "@material-ui/core/styles";
import {Backdrop, CircularProgress} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay-ts";
import {apiClient, signalApiClient} from "../../utils/apiClient";
import {PlanSelectFormData} from "../../types/planSelectForm";
import _ from "lodash";
import {roomTypeCode} from "../../config/hotel";

type Props = {
  staySearchPageView: number
  planSelectFormData: PlanSelectFormData | undefined,
  hotelCode: string,
  isLoading: boolean,
  updateSelectHotel(planSelectFormData: PlanSelectFormData): void,
  callback: () => void,
  mealFilter: any,
  setMealFilter: any,
  roomTypeFilter: any,
  setRoomTypeFilter: any,
  hotelAllData: HotelAllSearchResponse | undefined,
  selectedHotel: selectHotelDetail | undefined,
  selectedRoomSort: string,
  showHotelView: () => void,
  setPriceLoading: (isPriceLoading: boolean) => void,
  hotelRoomController: AbortController,
  hotelRoomAllController: AbortController,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const StaySearchbox: React.VFC<Props> = ({
    staySearchPageView,
    hotelCode,
    isLoading,
    planSelectFormData,
    updateSelectHotel,
    callback,
    mealFilter,
    setMealFilter,
    roomTypeFilter,
    setRoomTypeFilter,
    hotelAllData,
    selectedHotel,
    selectedRoomSort,
    showHotelView,
    setPriceLoading,
    hotelRoomController,
    hotelRoomAllController
}) => {
  const classesForLoading = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [roomData, setRoomData] = React.useState<roomData|undefined>(undefined);
  const [planData, setPlanData] = React.useState<roomDataPlanType|undefined>(undefined);
  const [planExists, setPlanExists] = React.useState(false); // プランが1つでも存在するか
  const checkIn = planSelectFormData?.searchParams.checkin ?? '';
  const checkOut = planSelectFormData?.searchParams.checkout ?? '';
  const hotelAreaCode = getHotelAreaCode(planSelectFormData);
  const [isSelectedRooms, setIsSelectedRooms] = React.useState(false);
  const [selectedRoomsDetail, setSelectedRoomsDetail] = React.useState<HotelAllRoomSearchResponse>();
  const [defaultSelectedRoomsDetail, setDefaultSelectedRoomsDetail] = React.useState<HotelAllRoomSearchResponse>();

  //アコーディオン：制御
  const[StayAccordionStateList, SetStayAccordionStateList] = React.useState<boolean[]>([]);
  const handleStayAccordionList = (index: number) => {
    if (StayAccordionStateList[index] !== undefined) {
      const updateStatusList = _.cloneDeep(StayAccordionStateList);
      updateStatusList[index] = !updateStatusList[index];
      SetStayAccordionStateList(updateStatusList);
    }
  }

  // モーダル：客室詳細
  const [roomDetailModalState, setRoomDetailModalState] = React.useState(false);
  const handleRoomDetailModal = () => {
    setRoomDetailModalState(!roomDetailModalState);
  };
  const openRoomDetailModal = (roomData: roomData) => {
    setRoomData({...roomData});
    setRoomDetailModalState(!roomDetailModalState);
  }

  //モーダル：プラン詳細詳細
  const [PlanDetailModalState, setPlanDetailModalState] = React.useState(false);
  const callbackHandlPlandetailModal = () => {
    setPlanDetailModalState(!PlanDetailModalState);
  };
  const handlPlandetailModal = (planDetail: roomDataPlanType|undefined, roomData: roomData) => {
    setRoomData({...roomData});
    if (planDetail !== undefined) {
      setPlanData({...planDetail})
    }
    setPlanDetailModalState(!PlanDetailModalState);
  };

  useEffect(() => {
    if (selectedHotel && planSelectFormData) {
      (async () => {
        setLoading(true); // ローディング開始
        setPlanExists(!selectedHotel.sold_out_flg);
        const hotelSearchParams = getHotelDetailSearchParms(hotelAreaCode, selectedHotel.hotel_code, planSelectFormData.searchParams);
        // ホテル部屋一覧を取得する
        await signalApiClient(hotelRoomAllController).post("/hotel/hotel-all-room", JSON.stringify(hotelSearchParams))
          .then(function (response) {
            const roomsDetail: HotelAllRoomSearchResponse = response?.data?.hotelDetail;
            let roomTypeList: {label: string, value: string}[] = []; // 選択可能なルームタイプ
            let mealList: {label: string, value: string}[] = []; // 選択可能な食事
            if (selectedHotel) {
              // 部屋一覧から絞り込み条件を作成する
              roomsDetail.room_list.forEach((room: roomData) => {
                // ルームタイプ
                const roomType = roomTypeCode[room.room_type_code];
                if (["シングル", "セミダブル", "ダブル", "ツイン", "トリプル", "4ベッド"].includes(roomType)) {
                  roomTypeList.push({label: "洋室", value: "western_style"});
                } else {
                  roomTypeList.push({label: roomType, value: room.room_type_code});
                }

                // 食事の有無
                room.plan_list.forEach((plan: roomDataPlanType) => {
                  if (plan.meal_breakfast === "1") {
                    mealList.push({label: "朝食付き", value: "meal_breakfast"});
                  }
                  if (plan.meal_lunch === "1") {
                    mealList.push({label: "昼食付き", value: "meal_lunch"});
                  }
                  if (plan.meal_dinner === "1") {
                    mealList.push({label: "夕食付き", value: "meal_dinner"});
                  }
                  if (plan.meal_breakfast === "0" && plan.meal_lunch === "0" && plan.meal_dinner === "0") {
                    mealList.push({label: "食事なし", value: "meal_nothing"});
                  }
                })
              });
            }
            roomTypeList = _.uniqBy(roomTypeList, "label");
            mealList = _.uniqBy(mealList, "label");
            setRoomTypeFilter(roomTypeList); // 絞り込み条件（ルームタイプ）
            setMealFilter(mealList); // 絞り込み条件（食事）
            const sortRoomDetail = filterAndSortRooms(roomsDetail, mealFilter, roomTypeFilter, selectedRoomSort);
            setSelectedRoomsDetail(sortRoomDetail);
            setDefaultSelectedRoomsDetail(sortRoomDetail);
            SetStayAccordionStateList([...Array(roomsDetail.room_list.length)].map(() => false));
            setIsSelectedRooms(true);
          })
          .catch(function (error) {
            console.log('Error');
            console.log(error);
          });
        setLoading(false); // ローディング終了
        if (!isOneHotel(planSelectFormData)) {
          scrollToHotelSection(); // `宿泊の選択`の場所にスクロールする
        }
      })();
    }
}, [hotelCode, hotelAllData]);

  const roomBaxClass = (index:number) => {
    if (index > 0) {
      return "box-white radius-none small-pc mt-40";
    }
    return "box-white radius-none small-pc "
  };

  useEffect(() => {
    if(isSelectedRooms && planSelectFormData !== undefined && selectedRoomsDetail?.room_list && selectedRoomsDetail.room_list.length > 0) {
      (async () => {
        const newSelectedRoomsDetail = _.cloneDeep(selectedRoomsDetail);
        // 部屋ごとに処理
        for (let roomIndex = 0; roomIndex < newSelectedRoomsDetail.room_list.length; roomIndex++) {
          const room: roomData = newSelectedRoomsDetail.room_list[roomIndex];
          // プランごとに処理
          for (let planIndex = 0; planIndex < room.plan_list.length; planIndex++) {
            const plan = room.plan_list[planIndex];
            // 部屋詳細を取得
            const resultRoom = await hotelRoomSearch(hotelRoomController, hotelAreaCode, planSelectFormData.searchParams, hotelCode, room.room_code, plan.plan_code);
            if (resultRoom?.success) {
              // `stay_detail_list`の項目を追加
              const stay_detail_list = resultRoom.response.room_plan_detail.stay_detail_list;
              newSelectedRoomsDetail.room_list[roomIndex].plan_list[planIndex].stay_detail_list = stay_detail_list;
            } else {
              newSelectedRoomsDetail.room_list[roomIndex].plan_list[planIndex].stay_detail_list = [];
            }
            setSelectedRoomsDetail({...newSelectedRoomsDetail});
            setDefaultSelectedRoomsDetail({...newSelectedRoomsDetail});
          }
        }
        setPriceLoading(false);
      })();
    }
  },[isSelectedRooms]);
  
  const callbackUpdateSelectHotel = (planData: roomDataPlanType|undefined, room:roomData) => {
    if (planSelectFormData !== undefined && selectedHotel !== undefined) {
      ( async () => {
        setUpdateLoading(true);
        
        // ルーム詳細取得
        const resultRoom = await hotelRoomSearch(hotelRoomController, hotelAreaCode, planSelectFormData.searchParams, hotelCode, room.room_code, planData?.plan_code);

        if (resultRoom && resultRoom.success) {
          planSelectFormData.hotel[0].detail = selectedHotel;
          planSelectFormData.hotel[0].plan = resultRoom.response;
          planSelectFormData.hotel[0].roomData = room;
          planSelectFormData.hotel[0].checkTime = planData?.check_in_start;
          planSelectFormData.hotel[0].planCode = planData?.plan_code;
          planSelectFormData.hotel[0].planRoomCode = resultRoom.response.room_plan_detail.room_plan_code;
          planSelectFormData.hotel[0].total = planData?.total_price?? 0;
          updateSelectHotel({...planSelectFormData});
        } else {
          alert("プランの取得に失敗しました。");
        }
        setUpdateLoading(false);
      })();
    }
  }

  const getHotelPlanThumbnailUrl = (roomData: roomData) => {
    if (roomData.plan_list[0]?.plan_image_list != null) {
      return roomData.plan_list[0].plan_image_list[0].plan_image_url;
    }

    if (roomData.image_list != null) {
      return roomData.image_list[0]?.image_url ;
    }

    return selectedHotel?.image_l_url;
  }

  /**
   * プラン絞り込みと並び替え
   */
  useEffect(() => {
    if (defaultSelectedRoomsDetail) {
      const resultRooms = filterAndSortRooms(defaultSelectedRoomsDetail, mealFilter, roomTypeFilter, selectedRoomSort);
      setSelectedRoomsDetail({...resultRooms});
    }
  }, [roomTypeFilter, mealFilter, selectedRoomSort]);

  return (
    <>
      <div className={"animation-fade" + (staySearchPageView === 2 && " active")}>
        <Backdrop className={classesForLoading.backdrop} open={updateLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {loading || isLoading ? (
          <LoadingOverlay active={loading || isLoading} spinner>
            <div className="pb-100"></div>
          </LoadingOverlay>
        ) : selectedRoomsDetail !== undefined && selectedRoomsDetail.hotel_info && (
          <div>
            <div className="box-heading-navy">{selectedRoomsDetail.hotel_info.hotel_name}</div>
            {/* 部屋ごとのプラン */}
            {planExists && selectedRoomsDetail.room_list.length > 0 ? (
              selectedRoomsDetail.room_list.map((room_data, rindex) => (
                room_data.plan_list && room_data.plan_list.length > 0 && (
                  <Fragment key={room_data.room_code}>
                    {/* 部屋情報 */}
                    <div className={roomBaxClass(rindex)}>
                      <div className="box-stay">
                        <div className="box-stay-item">
                          <div className="box-stay-item-thumbnail large">
                            {room_data.image_list !== undefined ? (
                              <img src={room_data.image_list[0]?.image_url} alt="" />
                            ) :(
                              <img src={selectedRoomsDetail.hotel_info.pr_main_image_l_url} alt="" />
                            )}
                          </div>
                          <div className="box-stay-item-content">
                            <div className="box-stay-item-content-heading">
                              {room_data.room_name}
                            </div>
                            <div className="mt-5 mb-16-md">
                              <button
                                type="button"
                                onClick={() => openRoomDetailModal(room_data)}
                                className="button-border-small-blue w-100per w-a-md"
                              >客室詳細を見る</button>
                            </div>

                            {/* 以下PCのみ */}
                            <div className="box-stay-item-content-label d-n d-b-md">
                              <div className="list-stay-label">
                                <ul className="list-stay-label-list">
                                  {room_data.room_feature_list?.map((room_feature: {name: string}) => (
                                    <li key={room_feature.name} className="list-stay-label-list-item">{room_feature.name}</li>
                                  ))}
                                </ul>
                              </div>
                              <p className="fz-13">
                                【ルームタイプ】{getRoomTypeLabel(room_data.room_type_code)}
                                {room_data.room_size && (<>【広さ】{room_data.room_size}㎡</>)}
                                {room_data.bedding_list && (
                                  <>
                                    【ベッドサイズ】
                                    {room_data.bedding_list?.map((bedding: {name: string}) => (
                                      <Fragment key={bedding.name}>{bedding.name}</Fragment>
                                    ))}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>

                          {/* sp時 */}
                          <div className="box-stay-item-content-sp mt-16 mt-0-md mb-16 mb-0-md w-100per">
                            <div className="d-n-md">
                              <div className="list-stay-label">
                                <ul className="list-stay-label-list mb-20-md">
                                  {room_data.room_feature_list?.map((room_feature: {name: string}) => (
                                    <li key={room_feature.name} className="list-stay-label-list-item">{room_feature.name}</li>
                                  ))}
                                </ul>
                              </div>
                              <p className="fz-13">
                                【ルームタイプ】{getRoomTypeLabel(room_data.room_type_code)}
                                {room_data.room_size && (<>【広さ】{room_data.room_size}㎡</>)}
                                {room_data.bedding_list && (
                                  <>
                                    【ベッドサイズ】
                                    {room_data.bedding_list?.map((bedding: {name: string})  => (
                                      <Fragment key={bedding.name}>{bedding.name}</Fragment>
                                    ))}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box-light_navy small radius-bottom">
                      {/* アコーディオンに隠れない一個目の内藤 */}
                      {room_data.plan_list[0] !== undefined && (
                        <div className="box-stay-list">
                          <div className="box-stay-list-item">
                            <div className="box-white small-pc mb-8 mb-16-md">
                              <div className="box-stay">
                                <div className="box-stay-item">
                                  <div className="box-stay-item-thumbnail xmiddle">
                                    <img
                                      src={getHotelPlanThumbnailUrl(room_data)}
                                      alt=""
                                    />
                                  </div>
                                  <div className="box-stay-item-content">

                                    <div className="box-stay-item-content-heading">
                                      {room_data.plan_list[0].plan_name}
                                    </div>
                                    <div className="mt-16 mt-20-md mb-16 mb-20-md">
                                      {room_data.plan_list[0].stay_detail_list && (
                                        <button
                                          onClick={() => handlPlandetailModal(room_data.plan_list[0], room_data)}
                                          className="button-border-small-blue w-100per w-a-md"
                                        >
                                          プラン詳細を見る
                                        </button>
                                      )}
                                    </div>
                                    <div className="box-stay-item-content-label">
                                      <ul className="box-stay-item-content-label-list light-green">
                                        {room_data.plan_list[0].meal_breakfast === "0" && room_data.plan_list[0].meal_lunch === "0" && room_data.plan_list[0].meal_dinner === "0" && (
                                          <li className="box-stay-item-content-label-list-item">
                                            食事なし
                                          </li>
                                        )}
                                        {room_data.plan_list[0].meal_breakfast === "1" && (
                                          <li className="box-stay-item-content-label-list-item">
                                            朝食
                                          </li>
                                        )}
                                        {room_data.plan_list[0].meal_lunch === "1" && (
                                          <li className="box-stay-item-content-label-list-item">
                                            昼食
                                          </li>
                                        )}
                                        {room_data.plan_list[0].meal_dinner === "1" && (
                                          <li className="box-stay-item-content-label-list-item">
                                            夕食
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                    <p className="fz-13">
                                      チェックイン：{room_data.plan_list[0].check_in_start}　チェックアウト：{room_data.plan_list[0].check_out}
                                    </p>
                                  </div>

                                  <div className="box-stay-item-detail top-hotel_select mt-10 mt-0-md">
                                    <div className="w-100per">
                                      {room_data.plan_list[0].stay_detail_list ? (
                                        <>
                                        {isHotelStaySubPrice(room_data.plan_list[0].stay_detail_list) ? (<>
                                          <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                            <p className="mb-0-md fz-13 fw-b ta-c-md">
                                              追加料金<br />
                                              <span className="fz-10 fz-11-md">（１人あたり）</span>
                                            </p>
                                            <p className="mb-5-md c-red fz-24 fw-b ta-c-md">
                                              {diffHotelPrice(planSelectFormData, {
                                                isUpdate: false,
                                                step: 'plan',
                                                updateHotelIndex: 0,
                                                updatePlan: room_data.plan_list[0],
                                                updateHotel: undefined,
                                                checkIn: checkIn,
                                                checkOut: checkOut
                                              })}<span className="fz-12">円</span>
                                            </p>
                                          </div>
                                          <p className="mb-0-md">
                                            <button
                                              onClick={() => callbackUpdateSelectHotel(room_data.plan_list[0], room_data)}
                                              className="button-medium-orange button-variable sp-medium w-100per"
                                            >
                                              このプランを<br className="d-n d-b-md" />
                                              選択する
                                            </button>
                                          </p>
                                        </>) : (<>
                                          <div className="box-plan-bt_gray-list-item-content-status">
                                            <div className="d-f d-b-md jc-sb ai-c fx-1">
                                              <span className="button-medium-disabled w-100per">受付終了</span>
                                            </div>
                                          </div>
                                        </>)}
                                        </>
                                      ) : (
                                        <LoadingOverlay active={true} spinner>
                                          <div className="pb-100"></div>
                                        </LoadingOverlay>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* アコーディオンに隠れるコンテンツ */}
                      {room_data.plan_list.length > 1 && (
                        <div>
                          <div className="accordion-stay">
                            <div className={"accordion-stay-detail" + (!StayAccordionStateList[rindex] ? "" : " active")}>
                              <div className="box-stay-list mb-8 mb-16-md">
                                {room_data.plan_list?.map((plan_data: roomDataPlanType, pIndex: number) => (
                                  <Fragment key={plan_data.plan_code}>
                                    {pIndex !== 0 && (
                                      <div className="box-stay-list-item">
                                        <div className="box-white small-pc">
                                          <div className="box-stay">
                                            <div className="box-stay-item">
                                              <div className="box-stay-item-thumbnail sp_small-pc_large">
                                                {plan_data.plan_image_list !== undefined ? (
                                                  <img src={plan_data.plan_image_list[0].plan_image_url} alt="" />
                                                ) : (
                                                  <img src={roomData?.image_list[0].image_url} alt="" />
                                                )}
                                              </div>
                                              <div className="box-stay-item-content">
                                                <div className="box-stay-item-content-heading">{plan_data.plan_name}</div>
                                                <div className="mt-16 mt-20-md mb-16 mb-20-md">
                                                  {plan_data?.stay_detail_list && (
                                                    <button
                                                      onClick={() => handlPlandetailModal(plan_data, room_data)}
                                                      className="button-border-small-blue w-100per w-a-md"
                                                    >
                                                      プラン詳細を見る
                                                    </button>
                                                  )}
                                                </div>
                                                <div className="box-stay-item-content-label">
                                                  <ul className="box-stay-item-content-label-list light-green">
                                                    {plan_data.meal_breakfast === "0" && plan_data.meal_lunch === "0" && plan_data.meal_dinner === "0" && (
                                                      <li className="box-stay-item-content-label-list-item">
                                                        食事なし
                                                      </li>
                                                    )}
                                                    {plan_data.meal_breakfast === "1" && (
                                                      <li className="box-stay-item-content-label-list-item">
                                                        朝食
                                                      </li>
                                                    )}
                                                    {plan_data.meal_lunch === "1" && (
                                                      <li className="box-stay-item-content-label-list-item">
                                                        昼食
                                                      </li>
                                                    )}
                                                    {plan_data.meal_dinner === "1" && (
                                                      <li className="box-stay-item-content-label-list-item">
                                                        夕食
                                                      </li>
                                                    )}
                                                  </ul>
                                                </div>
                                                <p className="fz-13">
                                                  チェックイン：{plan_data.check_in_start}　チェックアウト：{plan_data.check_out}
                                                </p>
                                              </div>

                                              <div className="box-stay-item-detail top-hotel_select mt-10 mt-0-md">
                                                <div className="w-100per">
                                                  <>
                                                    {plan_data?.stay_detail_list ? (
                                                      <>
                                                        {isHotelStaySubPrice(plan_data?.stay_detail_list) ? (
                                                          <>
                                                            <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                                              <p className="mb-0-md fz-13 fw-b ta-c-md">
                                                                追加料金<br />
                                                                <span className="fz-10 fz-11-md">（１人あたり）</span>
                                                              </p>
                                                              <p className="mb-5-md c-red fz-24 fw-b ta-c-md">
                                                                {diffHotelPrice(planSelectFormData, {
                                                                  isUpdate: false,
                                                                  step: 'plan',
                                                                  updateHotelIndex: 0,
                                                                  updatePlan: plan_data,
                                                                  updateHotel: undefined,
                                                                  checkIn: checkIn,
                                                                  checkOut: checkOut
                                                                })}<span className="fz-12">円</span>
                                                              </p>
                                                            </div>
                                                            <p className="mb-10-md">
                                                              <button
                                                                onClick={() => callbackUpdateSelectHotel(plan_data, room_data)}
                                                                className="button-medium-orange button-variable sp-medium w-100per"
                                                              >
                                                                このプランを<br className="d-n d-b-md" />
                                                                選択する
                                                              </button>
                                                            </p>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="box-plan-bt_gray-list-item-content-status">
                                                              <div className="d-f d-b-md jc-sb ai-c fx-1">
                                                                <span className="button-medium-disabled w-100per">受付終了</span>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    ) : (
                                                      <LoadingOverlay active={true} spinner>
                                                        <div className="pb-100"></div>
                                                      </LoadingOverlay>
                                                    )}
                                                  </>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Fragment>
                                ))}
                              </div>
                            </div>

                            {/* アコーディオンの開閉ボタン */}
                            <div className="accordion-stay-heading">
                              <button onClick={() => handleStayAccordionList(rindex)} className="accordion-stay-button button-clear">
                                {StayAccordionStateList[rindex] ? (
                                  <span>
                                    <span className="pr-24">閉じる</span>
                                    <span className="accordion-stay-icon active"></span>
                                  </span>
                                ) : (
                                  <span>
                                    <span className="pr-24">宿泊プランをすべて見る</span>
                                    <span className="accordion-stay-icon"></span>
                                  </span>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                )
              ))
            ) : (
              <>
                {/* プランが存在しない場合 */}
                <div className="box-white radius-none small-pc">
                  <div className="box-stay">
                    <div className="box-stay-item">
                      <div className="box-stay-item-content">
                        <section className="pt-20 pb-20">
                          <div className="wrap-content-900">
                            <p className="mb-10 ta-c">
                              <img src={require("../../img/share/icon/icon_searchzero.svg").default} alt="" className="d-ib" />
                            </p>
                            <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">選択可能なプランが存在しません</p>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {planSelectFormData && planSelectFormData?.hotelData && planSelectFormData?.hotelData?.length > 1 && (
          <div className="mt-32 ta-c">
            <button
              onClick={()=> showHotelView()}
              className="button-border-small-blue medium w-100per w-a-md ml-a-md mr-a-md"
            >
              <i className="icon-left-arrow-light_blue mr-5"></i>宿泊施設の選択に戻る
            </button>
          </div>
        )}
      </div>

      {/* モーダル：客室詳細 */}
      <RoomDetailModal
        isShow={roomDetailModalState}
        callback={handleRoomDetailModal}
        roomData={roomData}
      />
      
      {/*/!* モーダル：プラン詳細 *!/*/}
      <PlanDetailModal
        planSelectFormData={planSelectFormData}
        isUpdate={true}
        updateIndex={0}
        isShow={PlanDetailModalState}
        updateSelectHotel={callbackUpdateSelectHotel}
        callback={callbackHandlPlandetailModal}
        planData={planData}
        roomData={roomData}
      />
    </>
  );
}
export default StaySearchbox;