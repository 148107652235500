import { useState } from "react";
import { useApi } from "./useApi";
import {devLog} from "./errors";

type ReserveCancel = {
  bookingId: string;
  bookingEmail: string;
  cancelType: "all" | "part";
  carRental?: {
    id: string;
  }[];
  option?: {
    id: string;
  }[];
}

/**
 * 予約キャンセル
 * 
 * @returns 
 */
export const useReserveCancel = () => {

  const [isCancelLoading, setCancelLoading] = useState(false);

  const [cancelError, setCancelError] = useState("");

  const isCancelError = cancelError !== null;

  // note: レスポンスが固まったら型を指定したい・・
  const [cancelResult, setCancelResult] = useState<any>({});

  const cancelReservation = async (input: ReserveCancel) => {
    setCancelLoading(true);

    try {
      const response = await useApi.post({
        url: "/reserve/cancel",
        data: input   
      });

      devLog("response", response);
  
      setCancelResult(response.data);

    } catch (error) {
      setCancelError("APIの呼び出しに失敗しました。");
    }

    setCancelLoading(false);

    return;
  }

  return [ cancelReservation, cancelResult, { isCancelLoading, isCancelError, cancelError } ] as const;
};