import React, { SyntheticEvent, useEffect } from "react";

interface AjaxZip3Window extends Window {
  AjaxZip3: any;
}

declare const window: AjaxZip3Window;

type InputProps = {
  elementNames: {
    zipCode: string;
    prefecture: string;
    addressMain: string;
  };
};

export const SearchAddress: React.VFC<InputProps> = (props) => {
  const { elementNames } = props;

  const complementAddress = (e: SyntheticEvent) => {
    e.preventDefault();

    const { AjaxZip3 } = window;
    AjaxZip3.zip2addr(
      elementNames.zipCode,
      "",
      elementNames.prefecture,
      elementNames.addressMain
    );
  };

  useEffect(() => {
    // AjaxZip3読み込み
    // todo: 使っても大丈夫？ ケンオールとかGeocoding API使った方がいい？
    const head = document.getElementsByTagName("head")[0] as HTMLElement;
    const scriptUrl = document.createElement("script");
    scriptUrl.type = "text/javascript";
    scriptUrl.src = "https://ajaxzip3.github.io/ajaxzip3.js";
    head.appendChild(scriptUrl);
  }, []);

  return (
    <>
      <button
        className="button-blue-form_button-small"
        onClick={(e: SyntheticEvent) => complementAddress(e)}
      >
        住所入力
      </button>
    </>
  );
};
