import dayjs from "dayjs";
import {PlanSearch} from "../types/Search";

export const defaultSearchRoomData = {
  adult: 0,
  child: [],
}

export const defaultSearchRoomChildData = {
  age: 12,
  "meal": false,
  "futon": false
}

const startDate = dayjs().add(1, 'month').format('YYYY-MM-DD');
const endDate = dayjs().add(1, 'month').add(1,'day').format('YYYY-MM-DD');


export const defaultSearchParams: PlanSearch = {
  c_airport_from: "", 
  c_airport_to: "",
  isChange: false,
  from: "HND",
  to: "",
  start: startDate,
  end : endDate,
  checkin: startDate,
  checkout: endDate,
  days: 1,
  rooms: 1,
  roomDetail: [
    {
      adult: 2,
      child: [],
    }
  ]
}