import React, {ReactNode, useEffect, useState} from "react";

// for styles
import Backdrop from "@material-ui/core/Backdrop";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {reserveCancelDetailResponce} from "../../types/reserveCancel";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  onClickCancel: () => void,
  onClickClose: () => void,
  reserveData: reserveCancelDetailResponce,
  cancelRentalCarIds: {id: string}[]
  cancelOptionIds: {id: string}[]
}

const CancelIndividualModal: React.FC<Props> = ({
  isShow,
  onClickCancel,
  onClickClose,
  reserveData,
  cancelRentalCarIds,
  cancelOptionIds
}) => {

  const [alertContent, setAlertContent] = useState<ReactNode>();
  
  useEffect(() => {
    if (isShow) {
      const coupon = reserveData.data.bookingData.priceDetail.couponsPrices[0] ?? undefined;
      if (coupon && coupon.detail.conditions ) {
        switch (coupon.detail.conditions) {
          case "rentalCar":
            if (cancelRentalCarIds.length === reserveData.data.subData.rentalCar.length) {
              setAlertContent((
                <>
                  <p className="ta-c fw-b">
                    割引クーポンの適用がなくなりますが、キャンセルしてもよろしいですか？
                  </p>
                  <span className="fz-14-sm">（旅行代金の増額分については後日デジタルDMOカスタマーサポートよりご連絡いたします。）</span>
                </>
              ))
              ;
            }
            break;
          case "option":
            if (cancelOptionIds.length === reserveData.data.subData.options.length) {
              setAlertContent((
                <>
                  <p className="ta-c fw-b">
                    割引クーポンの適用がなくなりますが、キャンセルしてもよろしいですか？
                  </p>
                  <span className="fz-14-sm">（旅行代金の増額分については後日デジタルDMOカスタマーサポートよりご連絡いたします。）</span>
                </>
            ))
            }
            break;
          default:
            setAlertContent((
              <p className="ta-c fw-b">選択いただいた予約内容を<br/>キャンセルしてもよろしいですか?</p>));
            break;
        }
      } else {
        setAlertContent((
          <p className="ta-c fw-b">選択いただいた予約内容を<br/>キャンセルしてもよろしいですか?</p>));
      }
    }
  }, [isShow]);


  return (
    <>
    <Modal
      open={isShow}
      onClose={onClickClose}
      className="modal-simple"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div className="modal-simple-content">
        <div className="modal-simple-content-close">
          <button className="button-clear" onClick={onClickClose}>
            <i className="icon-modal-close-navy fz-30"></i>
          </button>
        </div>
        <div className="box-separate">

          <div className="box-separate-item">
            <div className="box-separate-item-block">
              {alertContent}
            </div>
          </div>

          <div className="box-separate-item">
            <div className="box-separate-item-block">
              <p className="mb-16">
                <button onClick={onClickCancel} className="button-medium-red w-100per fz-16 fw-b">
                  キャンセルする
                </button>
              </p>

              <p>
                <button onClick={onClickClose} className="button-border-medium w-100per">
                  閉じる
                </button>
              </p>

            </div>
          </div>
        </div>
      </div>
        
      </Modal>
    </>
  );
};

export default CancelIndividualModal;
