import React from "react";
import AppTemplate from "../components/Form3";

const Form3: React.FC = () => {
  return (
    <AppTemplate>
      <>ダッシュボード</>;
    </AppTemplate>
  );
};

export default Form3;
