import React from "react";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";

type InputProps = {
  name: string;
  register: UseFormRegister<any>; // todo: どこかで定義したい
  placeholder: string;
  options: RegisterOptions;
  error?: FieldError;
};

// <input>要素を含んだ子コンポーネント
export const InputTextarea: React.VFC<InputProps> = (props) => {
  const { name, register, placeholder, options, error } = props;

  return (
    <>
      <textarea
        cols={30}
        rows={5}
        className={
          "form-input-textarea" +
          (error ? " error" : "")
        }
        placeholder={placeholder}
        {...register(name, options)}
      />
    </>
  );
};
