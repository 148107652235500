import React from "react";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

type Props = {
  value: string;
};

const PartsTutorialButton: React.FC<Props> = ({ value }) => {
  const className = `button-withdesk-tutorial ${
    value === "component-header" ? "header-button" : ""
  }`;

  const InnerComponent = (
    <div className={className}>
      <i className="icon-infomation-blue" />
      操作ガイドを表示する
    </div>
  );

  const wrapWithDiv = (className: string, children: React.ReactNode) => (
    <div className={className}>{children}</div>
  );
  const InnerComponentWrapped = wrapWithDiv("mb-20 mb-0-md", InnerComponent);

  const renderHeader = () => wrapWithDiv("", InnerComponent);
  const renderContentPlan = () =>
    wrapWithDiv("wrap-content-900", InnerComponentWrapped);
  const renderContentDefault = () => wrapWithDiv("", InnerComponentWrapped);

  switch (value) {
    case "component-header":
      return renderHeader();
    case "component-plan_select":
      return renderContentPlan();
    default:
      return renderContentDefault();
  }
};

export default PartsTutorialButton;
