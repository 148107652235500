import React from "react";
import { okinawaSaihakken } from "../../../config/price";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import { discountPrices } from "../../../types/price";

type Props = {
  discountPrices: discountPrices[];
};

const SectionTravelDiscountDetail: React.FC<Props> = (props) => {
  const discountPrices = props.discountPrices.find((params: discountPrices) => params.name === "okinawaSaihakken");
  const discountPrice = discountPrices?.price.toLocaleString() ?? false;
  return okinawaSaihakken && discountPrice ? (
    <section>
      <h2 className="heading-2 mb-8 mb-16-md">全国旅行支援　補助金内訳</h2>
      <div className="card large">
        <h3 className="c-red fz-16 fz-18-md fw-b">販売補助金</h3>
        <div className="box-separate">
          <div className="box-separate-item">
            <div className="box-separate-item-block">
              <div className="box-separate-item-block-subtotal_list">
                <div className="box-separate-item-block-subtotal_list-item large">
                  <p className="box-separate-item-block-subtotal_list-item-label">
                    <span className="fz-16 fw-b">補助金交付額</span>
                  </p>
                  <p className="box-separate-item-block-subtotal_list-item-subtotal">
                    {discountPrice}円<span className="ml-5">×</span>1
                  </p>
                  <p className="box-separate-item-block-subtotal_list-total-total">
                    <span className="fz-16 fz-18 fz-22-md c-red">
                      {discountPrice}
                    </span>
                    <span className="fz-14 c-red">円</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="box-border-blue x-small mb-32">
          <div className="d-f jc-sb ai-c">
            <p className="fz-16 fz-18-md fw-b">販売補助金 合計</p>
            <p>
              <span className="c-red">
                <span className="fz-28 fw-b">{discountPrice}</span>円
              </span>
            </p>
          </div>
        </div>

        <table className="table-small-top_bottom">
          <tbody>
            <tr className="table-plan-subtitle">
              <th className="table-bgc-light_navy fz-16-md fw-b w-100 w-270-md ta-c ta-l-md">
                地域クーポンのお渡しについて
              </th>
              <td>
                地域クーポンは宿泊施設でのお渡し（チェックイン時）となります。
                <ul className="list-note light-gray">
                  <li>
                    ご旅行期間中に複数施設に宿泊される場合は、宿泊施設ごと（チェックイン時）でのお渡しとなります。
                  </li>
                </ul>
              </td>
            </tr>

            <tr className="table-plan-subtitle">
              <th className="table-bgc-light_navy fz-16-md fw-b w-100 w-270-md ta-c ta-l-md">
                お取消料について
              </th>
              <td>
                <span className="c-red">
                  全国旅行支援を適用した旅行をお取消しされる場合、キャンペーン適用前の旅行代金が取消料算出基準となります。
                </span>
                全国旅行支援の補助金は、取消料のお支払いに充当できません。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default SectionTravelDiscountDetail;
