import React, {useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

//parts import
import FormStep from "./Form/FormStep";
import {useLocation} from "react-router-dom";
import {PlanSelectFormData} from "../types/planSelectForm";
import {isOption} from "../utils/reserve";
import {getHeadTitle} from "../utils/title";
import {FormSessionContext} from "../hooks/FormSession";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";
import {convertSellerIdForDisplay} from "../utils/convert"
import { PartsTutorialButton } from "./parts";


const Form6: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  const {checkSession} = useContext(FormSessionContext);
  const location = useLocation()
  const [planSelectFormData, setPlanSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData }) //TODO: need change
  const savedForm5State = useSelector((state: RootState) => state.form5.form5);

  const bookingId = savedForm5State.bookingId;
  
  useEffect(() => {
    let timeoutId = setTimeout(() => {
      tagManagerContext.pageView(getHeadTitle(planSelectFormData.data, "予約完了"))
      tagManagerContext.purchase({
        price: planSelectFormData.data.priceData?.result.taxTotalPrice ?? 0,
        reserveNumber: bookingId,
        itemId: convertSellerIdForDisplay(planSelectFormData.data.planData?.sellerIndo.sellerId ?? 0),
        itemName: planSelectFormData.data.planData?.sellerIndo.companyInfo.name ?? "",
      })
    }, 200)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <MainTemplate title={getHeadTitle(planSelectFormData.data, "予約完了")} planSelectFormData={planSelectFormData.data}>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">予約完了</h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
            <FormStep activeStep={6} isOption={isOption(planSelectFormData.data)}></FormStep>

            {/* 操作ガイドボタン */}
            <PartsTutorialButton value="component-content" />

            {/* /予約完了 */}
            <h2 className="heading-2 mt-24">予約完了</h2>
            <div className="card">
              <p className="mb-5-md">
                このたびは、お申し込みいただき、ありがとうございます。
              </p>
              <p className="mb-16 fw-b">
                <span className="marker-yellow">
                  ご登録のメールアドレスに確認メールを送信していますので必ずご確認ください。
                </span>
              </p>
              <ul className="mb-20 list-note light-gray">
                <li>
                確認メールが届かない場合は、ご登録いただいたメールアドレスに誤りがある可能性があります。大変お手数ではございますがデジタルDMOカスタマーサポートへお問い合わせください。
                </li>
              </ul>
              <div className="box-light-gray">
                <p className="mb-24 fz-14 fz-16-md ta-c-md">
                  お問い合わせの際に
                  <span className="c-dark_red fw-b">旅行予約番号</span>
                  が必要になりますので必ずお控えください。
                </p>
                <div className="box-border-red">
                  <p className="ta-c">旅行予約番号</p>
                  <p className="ta-c c-dark_red fw-b fz-26">{bookingId}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

    </MainTemplate>
  );
};

export default Form6;
