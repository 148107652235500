import React from "react";

// toggle button
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

// accordion・media-query
import {
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

// narrow-down-flame
const SearchBoxStay: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="card-narrow-down">
    <div className="animation-fade active">
      <div className="serchbox-stay">{children}</div>
    </div>
  </div>
);

// props(エリア・ホテルタイプの共通部分用)
type FilterGroupProps = {
  filterItems: string[];
  filterState: string[];
  handleFilter: (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string[]
  ) => void;
  codeLabel: (code: string) => string;
  title: string;
  firstItem?: boolean;
};

// Props(デフォルトコンポーネント用)
type Props = {
  hotelAreas: string[];
  hotelTypes: string[];
  hotelAreasFilterState: string[];
  hotelTypesFilterState: string[];
  handleHotelAreasFilter: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string[]
  ) => void;
  handleHotelTypesFilter: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string[]
  ) => void;
  hotelAreaCodeLabel: (code: string) => string;
  hotelTypeCodeLabel: (code: string) => string;
};

// コンポーネント(エリア・ホテルタイプの共通部分用)
const FilterGroup: React.FC<FilterGroupProps> = ({
  filterItems,
  filterState,
  handleFilter,
  codeLabel,
  title,
  firstItem = false,
}) => (
  <div>
    <dt
      className={`c-navy fz-13 fz-14-md fw-b fx-sh pb-8 ${
        firstItem ? "" : "pt-16"
      }`}
    >
      {title}
    </dt>
    <dd className="fx-1">
      <div
        role="group"
        className="MuiToggleButtonGroup-root w-100per d-b fw-w"
        aria-label="all"
      >
        <ToggleButtonGroup
          value={filterState}
          onChange={handleFilter}
          aria-label="text formatting"
          className="box-toggle_button box-narrow w-a"
        >
          {filterItems.map((row, index) => (
            <ToggleButton
              key={title + "Toggle_" + index}
              value={row}
              aria-label={row}
              className="box-toggle_button-item"
            >
              {codeLabel(row)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </dd>
  </div>
);

// デフォルトコンポーネント
const PartsHotelNarrowDown: React.FC<Props> = ({
  hotelAreas,
  hotelTypes,
  hotelAreasFilterState,
  hotelTypesFilterState,
  handleHotelAreasFilter,
  handleHotelTypesFilter,
  hotelAreaCodeLabel,
  hotelTypeCodeLabel,
}) => {
  const matches = useMediaQuery("(min-width:992px)");

  return matches ? (
    <SearchBoxStay>
      <p className="serchbox-stay-item fz-16 mb-16 mb-0-md fw-b ta-c pr-20-md w-250-md">
        条件で絞り込む
      </p>
      <dl className="serchbox-stay-item d-f-md fw-w fd-c bl-gray-md pl-40-md">
        {hotelAreas && hotelAreas.length > 0 && (
          <FilterGroup
            filterItems={hotelAreas}
            filterState={hotelAreasFilterState}
            handleFilter={handleHotelAreasFilter}
            codeLabel={hotelAreaCodeLabel}
            title="エリア"
            firstItem
          />
        )}

        {hotelTypes && hotelTypes.length > 0 && (
          <FilterGroup
            filterItems={hotelTypes}
            filterState={hotelTypesFilterState}
            handleFilter={handleHotelTypesFilter}
            codeLabel={hotelTypeCodeLabel}
            title="ホテルタイプ"
          />
        )}
      </dl>
    </SearchBoxStay>
  ) : (
    <SearchBoxStay>
      <Accordion>
        <AccordionSummary>
          <div className="d-f jc-c w-100per">
            <p className="serchbox-stay-item fz-16 fw-b ta-c pr-20-md w-250-md">
              条件で絞り込む
            </p>
            <span className="icon-accodion-toggle bg-lightblue ml-8"></span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <dl className="serchbox-stay-item d-f-md fw-w w-100per bdr-t-n-md bdr-t-gray bl-gray-md pl-40-md">
            {hotelAreas && hotelAreas.length > 0 && (
              <FilterGroup
                filterItems={hotelAreas}
                filterState={hotelAreasFilterState}
                handleFilter={handleHotelAreasFilter}
                codeLabel={hotelAreaCodeLabel}
                title="エリア"
              />
            )}

            {hotelTypes && hotelTypes.length > 0 && (
              <FilterGroup
                filterItems={hotelTypes}
                filterState={hotelTypesFilterState}
                handleFilter={handleHotelTypesFilter}
                codeLabel={hotelTypeCodeLabel}
                title="ホテルタイプ"
              />
            )}
          </dl>
        </AccordionDetails>
      </Accordion>
    </SearchBoxStay>
  );
};

export default PartsHotelNarrowDown;
