import {flightsEntity} from "../types/FlightV2";

export const defaultFlightEntities:flightsEntity = {
  ANA: {
    inbound: {
      flights: [],
      cheepsIndex: 0,
    },
    outbound: {
      flights: [],
      cheepsIndex: 0,
    },
  },
  JAL: {
    inbound: {
      flights: [],
      cheepsIndex: 0,
    },
    outbound: {
      flights: [],
      cheepsIndex: 0,
    }
  }
}