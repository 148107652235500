import React from "react";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";


type Props = {
  activeStep: number;
}

const PartsStep: React.FC<Props> = ({
  activeStep,
}) => {

  return (
    <>
      <div className="step-option">
        <ul className="step-option-list">
          <li className={"step-option-list-item" + (activeStep === 1? " active":"")}>
            航空券・宿泊を選択
          </li>
          <li className={"step-option-list-item" + (activeStep === 2? " active":"")}>
            旅程の
            <br />
            確認・変更・追加
          </li>
          <li className={"step-option-list-item" + (activeStep === 3? " active":"")}>
            旅程の最終確認
          </li>
        </ul>
      </div>        
    </>
  );
};

export default PartsStep;
