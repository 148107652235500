import React, {Fragment, useEffect, useMemo} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

//ファイル分割化
import {
  DateOfUseModal,
  OptionSelectModal,
  LinkListModal,
} from "./../../modal"

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";


import {PlanSelectFormData} from "../../../types/planSelectForm";
import {activityMinPrice, getActivityTotalPrice} from "../../../utils/plan";
import {IActivity} from "../../../types/Activity";
import {dateFormat, toDisplayTime} from "../../../utils/convert";
import {scheduleListModalData} from "../../../types/planSelectConfirm";
import DialogOptionCancel from "../../dialog/DialogOptionCancel";


type Props = {
  planSelectFormData: PlanSelectFormData,
  scheduleListData: scheduleListModalData,
  updatePlanSelectFormData(updatePlanData: PlanSelectFormData): void
  updateScheduleListData(scheduleListData: scheduleListModalData): void
  isOption: boolean
}

const SectionOption: React.FC<Props> = ({
  planSelectFormData,
  scheduleListData,
  updateScheduleListData,
  updatePlanSelectFormData,
  isOption
}) => {
  const [reserveOptions, setReserveOptions] = React.useState<IActivity[]>([]);
  const [selectOptions, setSelectOptions] = React.useState<IActivity[]>([]);
  const [activity, setActvity] = React.useState<IActivity|undefined>(undefined);
  const [planData, setPlanData] = React.useState<PlanSelectFormData>(planSelectFormData);
  const [selectIndex, setSelectIndex] = React.useState<number>();

  const [dialogCancel, setDialogCancel] = React.useState(false);
  const [optionName, setOptionName] = React.useState("");
  const [optionIndex, setOptionIndex] = React.useState(0);

  useEffect(() => {
    setPlanData(planSelectFormData)
  }, [planSelectFormData]);
  

  useEffect(() => {
    if (isOption) {
      setPlanData({...planSelectFormData});
    }
  }, [isOption])
  
  //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  
  // モーダルのステータスを追加する
  const [linkListModal, setLinkListModal] = React.useState(false);

  // モーダルのhandleを追加する

  //プチモーダル：ページ内遷移用
  const handleLinkListModal = () => {
    setLinkListModal(!linkListModal)
  }

  //モーダル：日程確認
  const [DateOfUseModalState, setDateOfUseModalState] = React.useState(false);
  const handleDateOfUseModal = () => {
    setDateOfUseModalState(!DateOfUseModalState);
  };
  const onClickhandleDateOfUseModal = (index: number) => {
    if (reserveOptions[index]) {
      setActvity({...reserveOptions[index]})
    }
    setDateOfUseModalState(!DateOfUseModalState);
  };
  const onClickCancelDateOfUseModal = (index: number) => {
    if (reserveOptions[index]) {
      reserveOptions.splice(index, 1);
      setReserveOptions([...reserveOptions]);
      planSelectFormData.reserveOptions = [...reserveOptions];
      setPlanData({...planSelectFormData})
      updatePlanSelectFormData({...planSelectFormData})
    }
    setDialogCancel(false)
  };

  //オプション選択
  const [optionSelectModalStep, setOptionSelectModalStep] = React.useState(1);
  const handlOptionSelectModal = (optionSelectModalStep: number) => {
    setOptionSelectModalStep(optionSelectModalStep)
  }
  const [optionSelectModal, setOptionSelectModal] = React.useState(false);
  // 1
  const onClickOptionSelectModal = (step:number, index: number | undefined) => {
    if ( step === 3 && index !== undefined && reserveOptions[index]) {
      setSelectIndex(index);
      setActvity({...reserveOptions[index]})
    } else if (index !== undefined && selectOptions[index]) {
      setSelectIndex(undefined);
      setActvity({...selectOptions[index]})
    }
    setOptionSelectModalStep(step)
    setOptionSelectModal(!optionSelectModal)
  }
  const changeOptionSelectModal = (selectActivity: IActivity|undefined) => {
    if (selectActivity) {
      setActvity({...selectActivity})
    }
    setOptionSelectModalStep(3)
    setOptionSelectModal(!optionSelectModal)
  }
  // 1
  const callbackOptionSelectModal = () => {
    setActvity(undefined)
    setOptionSelectModal(!optionSelectModal)
  }

  //-------------ここまでモーダルの処理------------------

  //ダイアログの処理
  const [dialogChange, setDialogChange] = React.useState(false);
  
   //モーダル：キャンセル
   const handleDialogCancelNo = () => {
    setDialogCancel(!dialogCancel)
  }

  //モーダル：変更
  const handleDialogChange = () => {
    setDialogChange(!dialogChange)
  }

  const handleDialogCancel = (cancelName: string, cancelIndex: number) => {
    setOptionName(cancelName);
    setOptionIndex(cancelIndex);
    setDialogCancel(!dialogCancel);
  }
  
  //-------------ここまでダイアログの処理------------------
  
  useEffect(() => {
    setSelectOptions([...planSelectFormData.options])
    setReserveOptions([...planSelectFormData.reserveOptions])
    if (planSelectFormData.options.length > 0) {
      scheduleListData.useOptions = true;
    } else {
      scheduleListData.useOptions = false;
    }
    updateScheduleListData({...scheduleListData});
    
  }, [planData])
  
  const callbackUpdateOptionData = (updatePlanData: PlanSelectFormData) => {
    setPlanData({...updatePlanData})
    setOptionSelectModal(!optionSelectModal)
    updatePlanSelectFormData(updatePlanData)
    setActvity(undefined)
  }

  return (
    <>
      {selectOptions !== undefined && (reserveOptions.length > 0 || selectOptions.length > 0) ?
      <>
      <section>
        <div className="wrap-content-900">
          <h2 className="heading-2 mb-14 mb-16-md">オプションの追加</h2>
          <p className="fz-16 fw-b mb-16 mb-24-md">
            ご希望の方はオプションを下記より追加してください
          </p>

          <div className="sticky-top-list">
            { reserveOptions.length > 0 ?
              <>
                {/* 追加済みのオプション */}
                <div id="set_option" className="sticky-top-list-item">
                  <Accordion className="accordion-schedule" expanded={true}>
                    <AccordionSummary
                      className="accordion-schedule-heading"
                      onClick={handleLinkListModal}
                    >
                      追加済みのオプション
                    </AccordionSummary>
                    <AccordionDetails className="accordion-schedule-content fw-w">
                        {reserveOptions.map((reserveOption,rIndex) => (
                          <div key={rIndex} className="box-separator_bottom-border_small-item w-100per">
                            <p className="fz-14 fw-b mb-16 mb-24-md">
                              <span className="fz-13 c-light_gray">ご利用日</span>
                              <span>：</span>
                              {dateFormat(reserveOption.date, "YYYY年MM月DD日(ddd)")} {toDisplayTime(reserveOption.time)}
                            </p>
                            <div className="box-option-content">
                              <div className="box-option-content-description">
                                <div className="box-option-content-description-thumbnail">
                                  <img
                                    src={reserveOption.plan_info.image_list[0].image_url}
                                    alt=""
                                  />
                                </div>
                                <div className="box-option-content-description-text">
                                  <p className="mb-5">
                                    <span className="label-orange">
                                      <i className="icon-flag-orange mr-5"></i>
                                      プラン{rIndex + 1}
                                    </span>
                                  </p>
                                  <p>
                                    {reserveOption.plan_info.plan_name}
                                  </p>
                                </div>
                              </div>
                              <div className="box-option-content-status">
                                <div className="box-option-content-status-inner w-100per">
                                  <div className="mb-10-md ta-c-md">
                                    <p>
                                    <span className="mr-5 fz-13 fw-b">
                                      合計料金
                                    </span>
                                      <span className="c-red fz-18 fw-b">
                                        {getActivityTotalPrice(reserveOption).toLocaleString()}
                                      </span>
                                      <span className="c-red fz-12 fw-b">円</span>
                                    </p>
                                    <p>
                                      <button
                                        onClick={() => onClickhandleDateOfUseModal(rIndex)}
                                        className="button-clear-blue fz-13 fw-b"
                                      >
                                        日程・人数詳細を見る
                                      </button>
                                    </p>
                                  </div>
                                  <p>
                                    <button onClick={() => onClickOptionSelectModal(3, rIndex)} className="button-border-medium w-100per">
                                      変更する
                                    </button>
                                  </p>
                                </div>
                              </div>
                              <div className="box-option-cancel">
                                <p>
                                  <button
                                    className="button-text-link fz-12"
                                    onClick={() => handleDialogCancel(reserveOption.plan_info.plan_name, rIndex)}
                                  >
                                    <i className="icon-bad-circle-black mr-5"></i>
                                    キャンセル
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>: <></>
            }

            {/* 追加可能なオプション */}
            {selectOptions.length > 0 ?
            <>
              <div id="add_option" className="sticky-top-list-item">
                <Accordion
                    className="accordion-schedule box-shadow"
                    expanded={true}
                >
                  <AccordionSummary
                      className="accordion-schedule-heading"
                      onClick={handleLinkListModal}
                  >
                    追加可能なオプション
                  </AccordionSummary>
                  <AccordionDetails className="accordion-schedule-content slider">
                    <div className="box-slider_sp">
                      {selectOptions.map((optionData, oIndex) => (
                          <Fragment key={oIndex}>
                            {oIndex < 5 ?
                                <>
                                  <div className="box-slider_sp-item">
                                    <div className="box-slider_sp-item-content">
                                      <div className="box-slider_sp-item-content-thumbnail">
                                        <img
                                            src={optionData.plan_info.image_list[0].image_url}
                                            alt=""
                                        />
                                      </div>
                                      <div className="box-slider_sp-item-content-detail">
                                        <div className="box-slider_sp-item-content-detail-description">
                                          {optionData.plan_info.plan_name}
                                        </div>
                                        <div className="box-slider_sp-item-content-detail-label">
                                          <ul className="box-slider_sp-item-content-detail-label-list">
                                            {optionData.plan_info.time_required ?
                                                <>
                                                  <li key={"plan-detail-label-time_required"} className="box-slider_sp-item-content-detail-label-list-item">
                                                    所要時間：{optionData.plan_info.time_required}時間以内
                                                  </li>
                                                </>:<></>
                                            }
                                          </ul>
                                        </div>
                                        <div className="box-slider_sp-item-content-detail-status">
                                          <div className="box-slider_sp-item-content-detail-status-price">
                                              <span className="mr-5 fz-10 fz-12-md fw-b">
                                                お一人様
                                              </span>
                                            <span className="c-red fz-18 fz-20-md fw-b">
                                                + {activityMinPrice(optionData).toLocaleString()}
                                              </span>
                                            <span className="c-red fz-12 fz-14-md fw-b">
                                                円〜
                                              </span>
                                          </div>
                                          <div className="box-slider_sp-item-content-detail-status-link">
                                            <button onClick={() => onClickOptionSelectModal(2,oIndex)} className="button-border-medium w-100per">
                                              詳細を見る
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>:<></>
                            }
                          </Fragment>
                      ))}


                      {selectOptions.length > 5 ?
                          <>
                            {/* SP最後の要素 */}
                            <div className="box-slider_sp-item more">
                              <div className="box-slider_sp-item-content">
                                <div className="box-slider_sp-item-content more">
                                  <button
                                      className="button-clear"
                                      onClick={() => onClickOptionSelectModal(1, undefined)}
                                  >
                                    <p className="mb-8 ta-c fz-24">
                                      <i className="icon-right-arrow-circle-blue"></i>
                                    </p>
                                    <p className="ta-c fw-b">もっと見る</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>:<><div className="box-slider_sp-item more"></div></>
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>

                {selectOptions.length > 5 ?
                    <>
                      {/* PC最後の要素 */}
                      <div className="mt-24 d-n d-b-md">
                        <button
                            className="button-medium-orange large w-100per"
                            onClick={() => onClickOptionSelectModal(1, undefined)}
                        >
                          もっと見る
                        </button>
                      </div>
                    </>:<></>
                }

              </div>
            </>:<></>
            }
          </div>
        </div>
      </section>
      </>:<></>
      }

        {/* ここに作成したモーダルを追加する */}
        {/* リンクリスト */}
        <LinkListModal isShow={linkListModal} scheduleListModalData={scheduleListData} callback={handleLinkListModal} />
        {/* モーダル：日付確認 */}
         <DateOfUseModal activity={activity} isShow={DateOfUseModalState} callback={handleDateOfUseModal} changeActivity={changeOptionSelectModal} /> 
        {/* モーダル：オプション選択 1 */}
        <OptionSelectModal selectIndex={selectIndex} selectActivity={selectOptions} updateSelectFormData={callbackUpdateOptionData} planSelectFormData={planData} activity={activity} stepHandler={handlOptionSelectModal} defaultStep={optionSelectModalStep} isShow={optionSelectModal} callback={callbackOptionSelectModal} />

      {/* ダイアログ： キャンセル */}
      <DialogOptionCancel isShow={dialogCancel} callback={handleDialogCancelNo} optionName={optionName} optionIndex={optionIndex} optionCancel={onClickCancelDateOfUseModal} />
    </>
  );
};

export default SectionOption;
