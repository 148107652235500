import React, {Fragment, useEffect, useState} from "react";

// for google map
import GoogleMapTemplate from "../google/Maps";

// for styles
import {
  Button,
  Backdrop
} from "@material-ui/core/";

import {
  connectionFlights
} from "../../types/FlightV2";
import dayjs from "dayjs";

// lib
import { 
  getFlightCodeLabel,
  getFlightCodeByLabel, 
  getFlightTime,
  getAirLineLogo,
  getFlightNumberForDisplay
} from "../../utils/flight";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {getConnectionFlights} from "../../utils/flightV2";
import {FReservationFlight} from "../../types/FlightV2";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  callback: () => void,
  airline: string | undefined,
  flights: FReservationFlight[] | undefined
}

const ConnectingFlightModal: React.FC<Props> = ({
  isShow,
  callback,
  airline,
  flights,
}) => {

  const [connectionFlights, setConnectionFlights]  = useState<connectionFlights[]>([]);

  useEffect(() => {
    const reservationConnectionFlight = flights && flights.length > 0 ? flights[0].FReservationConnectionFlight : undefined;
    setConnectionFlights(getConnectionFlights(reservationConnectionFlight, []));
  }, [flights])

  return (
    <>
    <div>
      <Modal
        open={isShow}
        onClose={callback}
        className="modal-simple"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        { airline && flights && flights.length > 0 ?
          <div className="modal-simple-content pt-0 pb-0">
            <div className="modal-simple-content-close">
              <button className="button-clear" onClick={callback}>
                <i className="icon-modal-close-navy fz-30"></i>
              </button>
            </div>
            <div className="box-separate">

              <div className="box-separate-item">
                <div className="box-separate-item-block">
                  <p className="mb-16 fz-16 fw-b">{dayjs(flights[0].date, "YYYYMMDD").format('YYYY年MM月DD日（ddd）')}</p>
                  <ul className="list-flight-connecting">
                    { flights.map((f, index) => (
                      <Fragment key={index}>
                        <li key={"cm-depapo"+index} className="list-flight-connecting-item">
                          {/* depapo */}
                          <div className="d-f ai-c mb-5">
                            <p className="fz-20 fw-b lh-1">{getFlightTime(f.dep)}</p>
                            <p className="ml-10 fz-14 fw-b lh-1">{getFlightCodeLabel(f.depapo)}</p>
                          </div>
                          <div className="d-f ai-c">
                            <p><img src={ getAirLineLogo(airline, f.air_code, f.air_sub_no) } alt="airline" className="w-55" /></p>
                            <p className="ml-10 fz-13">{ getFlightNumberForDisplay(airline, f.air_code, f.air_sub_no) }</p>
                          </div>
                        </li>
                        <li key={"cm-arrapo"+index} className="list-flight-connecting-item">
                          {/* arrapo */}
                          <div className="d-f ai-c mb-5">
                            <p className="fz-20 fw-b lh-1">{getFlightTime(f.arv)}</p>
                            <p className="ml-10 fz-14 fw-b lh-1">{getFlightCodeLabel(f.arrapo)}</p>
                          </div>
                          <div className="d-f ai-c">
                            <p><img src={ getAirLineLogo(airline, f.air_code, f.air_sub_no) } alt="airline" className="w-55" /></p>
                            <p className="ml-10 fz-13">{ getFlightNumberForDisplay(airline, f.air_code, f.air_sub_no) }</p>
                          </div>
                        </li>
                        {connectionFlights.length > 0 && (<>
                          {connectionFlights.map((connectionFlight, cIndex) => (
                            <>
                              <li key={"connection-cm-depapo"+cIndex} className="list-flight-connecting-item">
                                {/* depapo */}
                                <div className="d-f ai-c mb-5">
                                  <p className="fz-20 fw-b lh-1">{getFlightTime(connectionFlight.dep)}</p>
                                  <p className="ml-10 fz-14 fw-b lh-1">{getFlightCodeLabel(connectionFlight.depapo)}</p>
                                </div>
                                <div className="d-f ai-c">
                                  <p><img src={ getAirLineLogo(airline, connectionFlight.air_code, connectionFlight.air_sub_no) } alt="airline" className="w-55" /></p>
                                  <p className="ml-10 fz-13">{ getFlightNumberForDisplay(airline, connectionFlight.air_code, connectionFlight.air_sub_no) }</p>
                                </div>
                              </li>
                              <li key={"connection-cm-arrapo"+cIndex} className="list-flight-connecting-item">
                                {/* arrapo */}
                                <div className="d-f ai-c mb-5">
                                  <p className="fz-20 fw-b lh-1">{getFlightTime(connectionFlight.arv)}</p>
                                  <p className="ml-10 fz-14 fw-b lh-1">{getFlightCodeLabel(connectionFlight.arrapo)}</p>
                                </div>
                                <div className="d-f ai-c">
                                  <p><img src={ getAirLineLogo(airline, connectionFlight.air_code, connectionFlight.air_sub_no) } alt="airline" className="w-55" /></p>
                                  <p className="ml-10 fz-13">{ getFlightNumberForDisplay(airline, connectionFlight.air_code, connectionFlight.air_sub_no) }</p>
                                </div>
                              </li>
                            </>
                          ))}
                        </>)}
                      </Fragment>
                    ))
                    }
                  </ul>
              
                </div>
              </div>

              <div className="box-separate-item">
                <div className="box-separate-item-block small-md">
                  <div className="ta-r">
                    <p>
                      <button onClick={callback} className="button-medium-orange w-60">
                      OK
                      </button>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div> : <></>
        }
      </Modal>
    </div>
    </>
  );
};

export default ConnectingFlightModal;
