import React, {ChangeEvent, Fragment, useEffect} from "react";
import {
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";

//swiper
import { Navigation, Pagination, A11y, EffectFade, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import LoadingOverlay from 'react-loading-overlay-ts';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import 'swiper/modules/pagination/pagination.min.css';

// for google map
import GoogleMapTemplate from "../google/Maps";

// for styles
import {
  Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import CounterNumberOfPeaple from "./../counter/counterNumberOfPeaple";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {activityPriceList, IActivity, planStocks} from "../../types/Activity";
import {
  activityMinPrice, activityPeopleMaxData, activityPeopleMinData,
  checkPlanStockListType, checkReserveOptionsSelectDate,
  getActivityPriceCount,
  getActivityTotalPrice,
  getPlanStocks, getSelectActivityTimePrices, hasActivityStock
} from "../../utils/plan";
import DOMPurify from "dompurify";
import CounterNumberOfOptionPlan from "../counter/counterNumberOfOptionPlan";
import {dateFormat} from "../../utils/convert";
import Map from "../google/Maps";
import {devLog} from "../../utils/errors";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  callback: () => void,
  activity: IActivity| undefined,
}

const MyPageOptionSelectModal: React.FC<Props> = ({
  isShow,
  callback,
  activity,
}) => {
  
  const [useActivity, setUseActivity] = React.useState<IActivity|undefined>(activity);

  useEffect(() => {
    if (activity !== undefined) {
      setUseActivity({...activity})
    }
  }, [activity])

  const createMarkup = (data: string | undefined) => {
    if(data) {
      const html = data;
      return  {
        __html: DOMPurify.sanitize(html)
      }
    }
  }

  const [loading, setLoading] = React.useState(false);
  return (
    <>
    <Modal
      open={isShow}
      onClose={callback}
      className="modal"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div>
        <LoadingOverlay
          active={loading}
          spinner
        >
        <div className={"modal-fixed"}>
          <div className="modal-fixed-heading">
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>閉じる
              </Button>
              </p>
          </div>
          <div className="modal-fixed-content">

            <div className="modal-fixed-content-item">
              <div className="modal-fixed-content-item-carousel">              
              <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, A11y, EffectFade]}
                  loop={true}
                  effect="fade"
                  pagination={{ clickable: true }}
                >
                {useActivity?.plan_info.image_list.map((image, iIndex) => (
                  <SwiperSlide key={iIndex}><img src={image.image_url} /></SwiperSlide>
                ))}
                </Swiper>
              </div>
              <div className="modal-fixed-content-item-heading">
                {useActivity?.plan_info.plan_name}
              </div>
            </div>

            <div className="wrap-content-900-large wrap-padding-md pt-24 pt-16-md">
              <div className="box-separator_bottom-medium lh-1_75 lh-1_5-md">
                <div className="box-separator_bottom-medium-item">
                  <div className="box-light-gray medium mb-8">
                    <p className="lh-1_75 lh-1_5-md" dangerouslySetInnerHTML={createMarkup(activity?.plan_info.description)}>
                    </p>
                  </div>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">行程・内容</h3>
                  <p dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info.contents)}></p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">対象年齢</h3>
                  <p>
                    {useActivity?.plan_info.age_min && useActivity?.plan_info.age_max ?
                      <>
                        {useActivity?.plan_info.age_min}歳 ～ {useActivity?.plan_info.age_max}歳
                      </>
                    : useActivity?.plan_info.age_min && !useActivity?.plan_info.age_max ?
                      <>
                        {useActivity?.plan_info.age_min}歳 ～
                      </>
                    : !useActivity?.plan_info.age_min && useActivity?.plan_info.age_max ?
                      <>
                        ～ {useActivity?.plan_info.age_max}歳
                      </>
                    :<></>
                    }
                    </p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">最少催行人数</h3>
                  <p>{useActivity?.plan_info.least_number}人</p>
                </div>
                {useActivity?.plan_info?.include ?
                    <>
                      <div className="box-separator_bottom-medium-item">
                        <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">料金に含まれるもの</p>
                        <p className="" dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info?.include)}></p>
                      </div>
                    </>
                    :<></>
                }
                <div className="box-separator_bottom-medium-item">
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">集合場所</h3>
                  <p>
                    <>
                      〒{useActivity?.plan_info.gather_place_zip}
                      <br/>
                      {useActivity?.plan_info.gather_place_address}
                      <br/>
                      {useActivity?.plan_info.gather_place_map_lat && useActivity?.plan_info.gather_place_map_lng?
                        <>
                          <Map address={useActivity?.plan_info.gather_place_address} lat={useActivity?.plan_info.gather_place_map_lat} lng={useActivity?.plan_info.gather_place_map_lng} label={useActivity?.plan_info.supplier_name}></Map>
                        </>:<></>
                      }
                      マップコード：{useActivity?.plan_info.gather_place_mapcode}
                    </>
                  </p>
                </div>

                {useActivity?.plan_info.gather_time ?
                    <>
                      <div className="box-separator_bottom-medium-item">
                        <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">集合時間</p>
                        <p dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info.gather_time)}></p>
                      </div>
                    </>
                    :<></>
                }

                <div className="box-separator_bottom-medium-item">
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">注意事項</h3>
                  <p dangerouslySetInnerHTML={createMarkup(activity?.plan_info.notes)}>
                  </p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">キャンセルポリシー</h3>
                  <>
                    <table className="mb-16 table-xsmall">
                      <tbody>
                        <tr>
                          <th className="table-bgc-light_navy fw-b ta-c w-55per w-40per-md">契約の解除日</th>
                          <th className="table-bgc-light_navy fw-b ta-c">取消料</th>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始日の4日前まで</th>
                          <td>無料</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始日の3日前～2日前まで</th>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始日前日</th>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始日当日</th>
                          <td>50%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始後又は無連絡不参加</th>
                          <td>100%</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <p className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">主催会社</p>
                  <p className="" dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info.supplier_name)}></p>
                  <p className="" dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info.time_remarks)}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </LoadingOverlay>
      </div>
    </Modal>
    </>
  );
};

export default MyPageOptionSelectModal;
