import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InputsForm5 } from "../types/InputsForm5";

export interface Form5State {
  form5: InputsForm5;
}

const initialState: Form5State = {
  form5: {
    other: {
      need_consideration: "no",
      agree: "",
    },
    bookingId: "",
  },
};

export const form5Slice = createSlice({
  name: "form5",
  initialState,
  reducers: {
    save: (state, action: PayloadAction<Form5State>) => {
      state.form5 = { ...state.form5, ...action.payload.form5 };
    },
  },
});

// Action creators are generated for each case reducer function
export const { save } = form5Slice.actions;

export default form5Slice.reducer;
