import React, { FocusEventHandler } from "react";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

// note: radioとcheckbox用のコンポーネントです。
// iOSではスクロールしない問題に対処するためのものです。
// refs. React Hook FormをしっかりSafari on iOS / Firefox対応する https://zenn.dev/daisuke7924/articles/5839e0094c5fdf
const InputForRadioAndCheckbox = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const onFocus: FocusEventHandler<HTMLInputElement> = (event) => {
      // バリデーションエラー発生時にradio/checkboxのinputにしっかりスクロールしない問題のワークアラウンド
      // 【問題詳細】
      // Safari on iOS: 全くスクロールしない, FireFox,IE：スクロールが不十分でエラーメッセージが見えない,
      if (props.type !== "radio" && props.type !== "checkbox") {
        return;
      }

      try {
        const element = event.currentTarget;

        // radio/checkboxをタップしたときはスクロールしない
        if (isInviewAll(element)) return;

        // バリデーションエラー発生時のみスクロールする
        // 注意：本対応はIE対応できていない(scrollIntoViewはIE非対応)
        element.scrollIntoView({
          block: "center",
          behavior: "auto",
        });
      } catch (e) {
        console.error(e);
      }
    };

    return <input ref={ref} onFocus={onFocus} {...props} />;
  }
);

const isInviewAll = (element: HTMLInputElement) => {
  const rect = element.getBoundingClientRect();

  // 1 < rect.top は firefox対応
  return 1 < rect.top && rect.bottom < window.innerHeight;
};

InputForRadioAndCheckbox.displayName = "InputForRadioAndCheckbox";

export default InputForRadioAndCheckbox;
