import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ReservationState {
  // reservation: {
  //   bookingId: string;
  // };
  reservation: any;
}

const initialState: ReservationState = {
  reservation: {
    bookingId: "",
  },
};

export const reservationCancelSlice = createSlice({
  name: "reservationCancel",
  initialState,
  reducers: {
    save: (state, action: PayloadAction<ReservationState>) => {
      state.reservation = { ...state.reservation, ...action.payload.reservation };
    },
    clear: (state) => {
      state.reservation = initialState.reservation;
    },
  },
});

// Action creators are generated for each case reducer function
export const { save, clear } = reservationCancelSlice.actions;

export default reservationCancelSlice.reducer;
