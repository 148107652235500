import React from "react";
import AppTemplate from "../components/ReservationCancelFee";

const ReservationCancelFee: React.FC = () => {
  return (
    <AppTemplate>
      <>ダッシュボード</>;
    </AppTemplate>
  );
};

export default ReservationCancelFee;
