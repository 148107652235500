/**
 * 年齢計算
 * 
 * @returns 
 */
export const useCalcAge = () => {

    const calcAge = (input: {year: number, month: number, day: number}): number => {
      const today = new Date();
  
      // 今年の誕生日
      const thisYearsBirthday = new Date(today.getFullYear(), input.month - 1, input.day);

      // 年齢
      const age = today.getFullYear() - input.year;

      // 今年まだ誕生日が来ていない場合は-1して返す
      return today < thisYearsBirthday ? age - 1 : age;
    }

    return [ calcAge ] as const;
};