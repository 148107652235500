import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Control, Controller, RegisterOptions } from "react-hook-form";

type InputProps = {
  control: Control<any, any>;
  isRow?: boolean;
  name: string;
  options: RegisterOptions;
  radios: { label: any; value: any }[];
  defaultValue?: any;
  onChange?: any;
};

export const InputRadio: React.VFC<InputProps> = (props) => {
  const { control, isRow, name, options, radios, defaultValue, onChange } = props;

  const generateRadioOptions = () => {
    return radios.map((radio) => (
      <FormControlLabel
        key={radio.value}
        value={radio.value}
        label={radio.label}
        control={<Radio />}
        onChange={onChange}
      />
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={options}
      render={({ field }) => (
        <RadioGroup row={isRow} {...field} defaultValue={defaultValue}>
          {generateRadioOptions()}
        </RadioGroup>
      )}
    />
  );
};
