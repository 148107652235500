import React from "react";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

const ServiceStop: React.FC = () => {
  return (
    <>
      <section className="pt-50 pt-100-md">
        <div className="wrap-content-900">
          <div className="card sp-small">
            <p className="mb-10 ta-c">
              <img
                src={
                  require("../img/share/icon/icon_searchzero.svg")
                    .default
                }
                className="d-ib"
              />
            </p>
            <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">サービス停止中です</p>
            <p className="fz-16 ta-c-md c-blue_gray">
              一時的にサービスを停止しております。<br />
              サービス再開日時については現時点で未定となっております。<br />
              大変ご迷惑をお掛けしますが、<br className="d-n d-b-md" />
              何卒ご理解いただけますようお願い申し上げます。
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceStop;
