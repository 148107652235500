import { useEffect, useState } from "react";
import { useApi } from "./useApi";
import {priceResponce} from "../types/price";

/**
 * 旅行代金確認
 * 
 * @returns 
 */
export const useTravelPrice = (input: {data: any}) => {

  const [isLoading, setLoading] = useState(false);

  const [error, setError] = useState<string|null>(null);

  let isError = error !== null;

  // note: レスポンスが固まったら型を指定したい・・
  const [priceData, setPriceData] = useState<priceResponce>();

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await useApi.post({
        url: "/price",
        data: input.data   
      });

      const priceData = response.data as priceResponce;
  
      if (priceData.status === 200) {
        setPriceData(priceData);
      } else {
        setError("料金の取得に失敗しました。");
        isError = true;
      }

    } catch (error) {
      setError("APIの呼び出しに失敗しました。");
      isError = true;
    }

    setLoading(false);

    return;
  }

  useEffect(() => {
    fetchData();
  }, [])

  return [ priceData, { isLoading, isError, error } ] as const;
};