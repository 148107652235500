
export const getSellerId = (sellerId:string) => {

  if(sellerId) {
    const a = sellerId.slice(0, 3)
    const b = '-'
    const c = sellerId.slice(3)
    return a + b + c;
  }

  return sellerId

}
